import * as React from 'react';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import FilterApplied from "../../transaction-buttons/filter-applied/filterApplied";
import CircularProgress from "@mui/material/CircularProgress";
import TransactionTable from "../../transaction-tables/transaction-table";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { fetchMerchantProfiles } from '../../../../stores/merchantProfiles';
import { getProfileCSVAllTransactions } from '../../../../services/transactionService';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import SearchBySelect from "../../transaction-selects/searchBySelect";
import ProfileSelect from '../../../../components/profile-select/profileSelect';
import { TransactionStatus } from '../../../../constants/transactionStatus';
import { useCallback } from 'react';
import ComponentLoader from '../../../../components/component-loader/componentLoader';
import { height } from '@mui/system';

function AllProducts() {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvLoading, setCsvIsLoading] = useState(false);
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [selectedMerchants] = useOutletContext();
    const [selectedDisplayName, setSelectedDisplayName] = useState("");

    const [isProfilesLoading, setIsProfilesLoading] = useState(false);

    const [request, setRequest] = useState({
        profileIds: [],
        merchantIds: [],
        fromDate: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        page: 0,
        limit: 25,
        sortBy: "createdAt",
        sortOrder: "desc",
        merchantRef: "",
        transactionStatuses: [],
        transactionId: "",
        paymentType: "",
    })

    const [requestProfile, setRequestProfile] = useState({
        profileIds: [],
        merchantIds: [],
        page :0,
        size: 10000,
        displayName: "",
        profileId: ""
    })

    useEffect(() => {
        if (selectedMerchants) {
            setIsProfilesLoading(true)
            setRequest({...request, merchantIds: selectedMerchants})
            setRequestProfile({...requestProfile, merchantIds: selectedMerchants})
        }
    }, [selectedMerchants])

    useEffect(() =>{
        dispatch(fetchMerchantProfiles(requestProfile));
    }, [requestProfile?.merchantIds])

    useEffect(() => {
        if (merchantProfiles) {
            setIsProfilesLoading(false);
        }
    }, [merchantProfiles])

    const setSelectedProfile = (profileId) => {
        setRequest({
            ...request,
            profileIds: profileId,
        })
    }

    useEffect(() => {
        let displayNames = [];
        request?.profileIds.forEach(profileId =>{
            const foundProfile = merchantProfiles?.content?.find(p => p.profileId === profileId);
            if(foundProfile)
            displayNames.push(foundProfile?.displayName)
        })
        setSelectedDisplayName(displayNames)
        
    
    }, [request?.profileIds])


    const downloadCSV = () => {
        let transactionStatuses = [];
        if (request?.profileIds && request?.profileIds !== "") {

            request?.transactionStatuses?.forEach(transactionStatus => {
                let index = Object.values(TransactionStatus).indexOf(transactionStatus);
                if (Object.keys(TransactionStatus)[index] === "_3DS_INITIATED") {
                    transactionStatuses.push("3DS_INITIATED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_AUTHORISED") {
                    transactionStatuses.push("3DS_AUTHORISED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_FAILED") {
                    transactionStatuses.push("3DS_FAILED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_ABANDONED") {
                    transactionStatuses.push("3DS_ABANDONED")
                }else {
                    transactionStatuses.push(Object.keys(TransactionStatus)[index])
                }
            })
        }

        setCsvIsLoading(true);
        getProfileCSVAllTransactions({...request, transactionStatuses: transactionStatuses}).then(response => {
            setCsvIsLoading(false);
            if (!window.navigator.msSaveOrOpenBlob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `All Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
                document.body.appendChild(link);
                link.click();
            } else {
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `All Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
            }
        }).catch(error => {
            console.log(error);
            setCsvIsLoading(false);
        })
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Box sx={{ mt: 2 }}>
                <Grid container columns={15} columnSpacing={2} sx={{ position: "sticky", zIndex: 1004, background: "#FFFFFF", mb: 2 }}>
                    <Grid item sx={{ minWidth: "250px" }}>
                        <ProfileSelect sx={{ width: "100%" }} profiles={merchantProfiles?.content ? merchantProfiles?.content : []} handleChange={setSelectedProfile} selectedProfile={request?.profileIds} isLoading={isProfilesLoading} />
                    </Grid>
                    <Grid item>
                        <SearchBySelect request={request} setRequest={setRequest} />
                    </Grid>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            className="transaction-button"
                            startIcon={isCsvLoading ?<CircularProgress color='inherit' size={"25px"}/>:<Download />}
                            onClick={isCsvLoading ?null:downloadCSV}
                            sx={{ textTransform: 'none', width: '175px', height: 40, color: 'white', fontSize: 12, boxShadow: 2 }}
                        >
                            Download CSV
                        </Button>
                    </Grid>
                    <Grid item>
                        <FilterApplied request={request} setRequest={setRequest} displayName={selectedDisplayName} />
                    </Grid>
                </Grid>
                <Grid>
                    <TransactionTable profiles={merchantProfiles?.content ? merchantProfiles?.content : []} request={request} isLoading={isLoading} setIsLoading={setIsLoading} setRequest={setRequest}/>
                </Grid>
            </Box>
        </Box>
    )
}

export default AllProducts;