import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    LineChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import moment from "moment";
import { useEffect, useState } from 'react';
import { getTransactionsTimeline } from '../../../services/statisticsService';
import { CircularProgress, Grid, Typography } from '@mui/material';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, LegendComponent]
);

const colorMapping = ['#034EA2', '#df372d', '#00cf3a'];

function PaymentChart(props) {
    const [transactions, setTransactions] = useState([]);
    const [xAxisDateTime, setxAxisDateTime] = useState();

    useEffect(() => {
        if (props.profileIdsMerchantIds?.profileIds && props.selectedRange && moment(props.selectedRange.startDate).isValid() && moment(props.selectedRange.endDate).isValid()) {
            const currencyIds = props.selectedCurrencies ? props.selectedCurrencies.map(currency => currency.id) : [0];

            getTransactionsTimeline({
                profileIds: props.profileIdsMerchantIds?.profileIds,
                merchantIds: props.profileIdsMerchantIds?.merchantIds,
                fromDate: moment(props.selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(props.selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType: props.paymentType,
                currencyId: currencyIds
            }).then(response => {
                let allDates = new Set(response.data.map(data => data.date));
                let tempData = {};
                props.selectedCurrencies.forEach(currency => {
                    tempData[currency.symbol] = Array.from(allDates).map(date => ({ date, amount: 0 }));
                });
                response.data.forEach(data => {
                    const currencySymbol = data.symbol || 'All';
                    const entry = tempData[currencySymbol]?.find(e => e.date === data.date);
                    if (entry) entry.amount = data.amount;
                });
                setTransactions(Object.keys(tempData).map((symbol, index) => ({
                    name: symbol,
                    data: tempData[symbol].map(e => [e.date, e.amount]),
                    color: colorMapping[index % colorMapping.length]
                })));
                setxAxisDateTime(moment(props.selectedRange.endDate).diff(moment(props.selectedRange.startDate), "days"));
            }).catch(error => {
                console.log(error);
            });
        } else {
            setTransactions([]);
        }
    }, [props.selectedRange, props.profileIdsMerchantIds?.profileIds, props.selectedCurrencies]);

    const legendOption = {
        data: transactions.map(line => ({
            name: line.name,
            lineStyle: {
                color: line.color
            },
            itemStyle: {
                color: line.color
            },
            symbol: 'circle'
        })),
        bottom: 0,
        right: 10,
        itemGap: 25,
        textStyle: {
            color: "#9898A2",
            fontFamily: "montserrat",
            fontSize: "10"
        },
        lineStyle:{
            opacity:0
        }
    };

    const seriesData = transactions.map(line => ({
        name: line.name,
        data: line.data,
        type: 'line',
        smooth: true,
        lineStyle: {
            color: line.color
        },
        itemStyle: {
            color: line.color
        },
        symbol: 'circle',
        symbolSize: 8
    }));

    return (
        <>
            {transactions.length > 0 ? (
                <ReactEChartsCore
                    echarts={echarts}
                    option={{
                        grid: {
                            right: 80,
                            top: 20,
                            left: 80
                        },
                        xAxis: {
                            type: 'time',
                            axisLabel: {
                                rotate: 45,
                                formatter: axisValue => {
                                    return (xAxisDateTime === 0) ? moment(axisValue).format("HH:mm") :
                                        (xAxisDateTime >= 1 && xAxisDateTime <= 99) ? moment(axisValue).format("DD MMM") :
                                            moment(axisValue).format("MMM");
                                }
                            },
                            name: "Date/Time",
                            nameLocation: "start",
                            nameGap: 20,
                            nameTextStyle: {
                                align: 'right',
                                verticalAlign: 'top',
                                color: "#9898A2",
                                fontFamily: "montserrat",
                                fontSize: "12",
                                fontWeight: "bold",
                                padding: [52, -85, 0, 0],
                            },
                            axisLine: {
                                symbol: 'none',
                                lineStyle: {
                                    type: 'solid'
                                }
                            },
                            axisTick: {
                                show: false
                            }
                        },
                        yAxis: {
                            alignTicks: true,
                            type: 'value',
                            name: "Value",
                            nameRotate: "90",
                            minInterval: 1,
                            nameLocation: "center",
                            nameTextStyle: {
                                color: "#9898A2",
                                fontFamily: "montserrat",
                                fontSize: "12",
                                fontWeight: "bold",
                                padding: [0, 0, 50, 0]
                            },
                            axisLine: {
                                show: true,
                                symbol: "none",
                                lineStyle: {
                                    type: 'solid',
                                    width: 1,
                                    opacity: 1
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    width: 1,
                                    opacity: 1
                                }
                            },
                            axisPointer: {
                                show: true
                            }
                        },
                        legend: legendOption,
                        series: seriesData,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                type: 'cross'
                            }
                        }
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"theme_name"}
                />
            ) : (
                <Grid container width="100%" height="300px" alignItems="center" justifyContent="center">
                    {!props.isLoading ? (
                        <Typography>No Data Available</Typography>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            )}
        </>
    );
}

PaymentChart.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    selectedRange: PropTypes.object,
    isLoading: PropTypes.bool,
    paymentType: PropTypes.string.isRequired,
    selectedCurrencies: PropTypes.array
};

PaymentChart.defaultProps = {
    selectedCurrencies: [1]
};

export default PaymentChart;
