import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import Container from '@mui/system/Container';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import PieChartIcon from '@mui/icons-material/PieChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Logo from '../logo/tj-logo';
import UserButton from './app-bar-buttons/userButton';
import NotificationButton from './app-bar-buttons/notificationButton';
import MerchantSelect from './app-bar-selects/merchantSelect';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchMerchant } from '../../stores/merchants';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import AuthorizedElement from '../../keycloak/permissionsGate';
import KeycloakService from '../../keycloak/keycloakService';
import LinkIcon from '@mui/icons-material/Link';
import GoogleAnalytics from '../../utils/googleAnalyticsHelper';
import ThemeDropdown from '../theme-dropdown/themeDropdown';

function AppBar(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { merchant } = useSelector((state) => state.merchant);

  const [selected, setSelected] = useState('dashboard');
  const [merchantId, setMerchantId] = useState('');

  const switchPage = useCallback(
    (page) => {
      setSelected(page);
      navigate(page, { replace: false });
    },
    [navigate]
  );

  const onMouseClick = () => {
    KeycloakService.updateToken(300);
  };

  const beforeUnload = () => {
    window.removeEventListener('click', onMouseClick);
    window.removeEventListener('beforeunload', beforeUnload);
  };

  useEffect(() => {
    window.addEventListener('click', onMouseClick);
    window.addEventListener('beforeunload', beforeUnload);
  }, []);

  useEffect(() => {
    if (merchant !== null) {
      GoogleAnalytics.merchantsSelected(merchant.name);
    }
  }, [props.selectedMerchant]);

  useEffect(() => {
    setMerchantId(window.sessionStorage.getItem('merchantId'));
    if (window.location.href.includes('dashboard')) {
      setSelected('dashboard');
    } else if (window.location.href.includes('transactions')) {
      setSelected('transactions');
    } else if (window.location.href.includes('reporting')) {
      setSelected('reporting');
    } else if (window.location.href.includes('config-manager')) {
      setSelected('config-manager');
    } else if (window.location.href.includes('support')) {
      setSelected('support');
    } else if (window.location.href.includes('payment-link')) {
      setSelected('payment-link');
    }

    if (window.location.pathname === '/') {
      switchPage('dashboard');
    }
  }, [switchPage]);

  useEffect(() => {
    if (merchantId) {
      dispatch(fetchMerchant(merchantId));
    }
  }, [merchantId, dispatch]);

  return (
    <>
      {/*<ThemeDropdown />*/}
      <MuiAppBar position="sticky" sx={{ height: 150 }}>
        <Container
          sx={{ backgroundColor: '#FFFFFF', height: '100px', minWidth: '100%' }}
          component={null}
        >
          <Grid
            container
            sx={{
              direction: 'row',
              height: '100%',
              alignItems: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
              flexWrap: 'no-wrap',
            }}
          >
            <Grid item sx={{ display: 'flex' }}>
              <Logo />
            </Grid>
            <Grid item sx={{ display: 'flex', ml: 4, width: 360 }}>
              <MerchantSelect
                merchant={merchant}
                selectedMerchants={props.selectedMerchants}
                setSelectedMerchants={props.setSelectedMerchants}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', flexGrow: 1, ml: 4 }}>
              <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                Merchant Portal
              </Typography>
            </Grid>
            <Grid container alignItems="center" sx={{ width: 'fit-content' }}>
              <Typography sx={{ fontSize: 20, mr: 2 }}>
                Hi {KeycloakService.getDisplayName()}
              </Typography>
              <NotificationButton sx={{ mr: 2 }} />
              <UserButton />
            </Grid>
            {/* <Grid item sx={{ display: 'flex', ml: 2 }}>
                        <NotificationButton />
                    </Grid>
                    <Grid item sx={{ display: 'flex', ml: 2 }}>
                        <UserButton />
                    </Grid> */}
          </Grid>
        </Container>
        <Container
          sx={{ backgroundColor: '#0098D8', height: '50px', minWidth: '100%' }}
          component={null}
        >
          <Grid
            container
            sx={{ direction: 'row', height: '100%', alignItems: 'center' }}
          >
            <Grid
              item
              sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
            >
              <Button
                size="small"
                startIcon={<PieChartIcon />}
                sx={{ color: 'white', textTransform: 'none' }}
                className={
                  selected === 'dashboard'
                    ? 'selected-button hover-button'
                    : 'hover-button'
                }
                onClick={() => switchPage('dashboard')}
              >
                Dashboard
              </Button>
              <Button
                size="small"
                startIcon={<CreditCardIcon />}
                sx={{ color: 'white', textTransform: 'none', ml: 4 }}
                className={
                  selected === 'transactions'
                    ? 'selected-button hover-button'
                    : 'hover-button'
                }
                onClick={() => switchPage('transactions')}
              >
                Transactions
              </Button>
              <Button
                size="small"
                startIcon={<AssessmentIcon />}
                sx={{ color: 'white', textTransform: 'none', ml: 4 }}
                className={
                  selected === 'reporting'
                    ? 'selected-button hover-button'
                    : 'hover-button'
                }
                onClick={() => switchPage('reporting')}
              >
                Reporting
              </Button>
              {/* <AuthorizedElement roles={ROLES.administrator}> <Button size="small" startIcon={<FactCheckIcon/>} sx={{color: 'white', textTransform: 'none', ml: 4}} className={selected === "reconciliation" ? "selected-button hover-button" : "hover-button"} onClick={() => switchPage("/k")}>Reconciliation</Button> </AuthorizedElement>
                        <Button size="small" startIcon={<AssignmentIcon />} sx={{ color: 'white', textTransform: 'none', ml: 4 }} className={selected === "settlements" ? "selected-button hover-button" : "hover-button"} onClick={() => switchPage("/k")}>Settlements</Button> */}
              <Grid item sx={{ display: 'flex', flexGrow: 1 }}>
                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                  <Button
                    size="small"
                    startIcon={<BorderColorIcon />}
                    sx={{ color: 'white', textTransform: 'none', ml: 4 }}
                    className={
                      selected === 'config-manager'
                        ? 'selected-button hover-button'
                        : 'hover-button'
                    }
                    onClick={() => switchPage('config-manager')}
                  >
                    Settings
                  </Button>
                </AuthorizedElement>
                <Button
                  size="small"
                  startIcon={<LinkIcon />}
                  sx={{ color: 'white', textTransform: 'none', ml: 4 }}
                  className={
                    selected === 'payment-link'
                      ? 'selected-button hover-button'
                      : 'hover-button'
                  }
                  onClick={() => switchPage('payment-link')}
                >
                  Payment Link
                </Button>
              </Grid>

              <Button
                size="small"
                startIcon={<HelpIcon />}
                sx={{ color: 'white', textTransform: 'none', ml: 4 }}
                className={
                  selected === 'support'
                    ? 'selected-button hover-button'
                    : 'hover-button'
                }
                onClick={() => switchPage('support')}
              >
                Help & Support
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Box id="inactivity-dialog"></Box>
      </MuiAppBar>
    </>
  );
}

AppBar.propTypes = {
  selectedMerchants: PropTypes.array.isRequired,
  setSelectedMerchants: PropTypes.func.isRequired,
};

export default AppBar;
