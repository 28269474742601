import { SaveOutlined } from "@mui/icons-material";
import { Backdrop, Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import "../../styles.scss";
import { CustomTextField } from "../custom-text-field/customTextField";

export default function
    SaveFilterBackdrop(props) {
    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(40px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid container direction="column" width="400px" sx={{ boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px", p: 3 }}>
                    <Stack spacing={3}>
                        <Typography color="#404047" fontSize={16} fontWeight="bold">
                            Save Filter
                        </Typography>
                        <CustomTextField 
                            size="small"
                            label="Filter Name"
                            value={props.name} 
                            onChange={props.handleNameChange}
                        />
                        <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                            <Button onClick={props.handleCancel} variant="contained" sx={{ "&:hover": { background: "#EBFAFF" }, boxShadow: "0px 3px 6px #00000029", textTransform: "none", background: "#FFFFFF", color: "#0098D8", borderRadius: "5px", width: "180px", mr: 1 }}>
                                Cancel
                            </Button>
                            <Button type="button" onClick={props.handleSave} variant="contained" sx={{ textTransform: "none", background: "#00C1FF", width: "160px", height: "100%", color: "#FFFFFF" }} startIcon={<SaveOutlined />}>
                                Save Filter
                            </Button>
                        </Grid>
                    </Stack>
                </Grid>
            </Backdrop>
        </Box>
    );
}

SaveFilterBackdrop.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    handleNameChange: PropTypes.func.isRequired
};