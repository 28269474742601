import { Box, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const OverflowTip = props => {

    const textElementRef = useRef();

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            title={props.value}
            interactive
            disableHoverListener={!hoverStatus}
            style={{ fontSize: '2em' }}
        >
            <Box
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {props.text}
            </Box>
        </Tooltip>
    );
};

export default OverflowTip;