import { ArrowBack, Close } from "@mui/icons-material";
import { Backdrop, Button, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import "../../styles.scss";

export default function ConfirmationBackdrop(props) {
    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(40px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid container direction="column" width="450px" sx={{ boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <Grid display="flex" justifyContent="end">
                        <IconButton
                            sx={{ m: 1 }}
                            size='small'
                            className='raised-icon'
                            onClick={props.handleCancel}>
                            <Close sx={{ color: "#034EA2" }} />
                        </IconButton>
                    </Grid>
                    <Grid>
                        <Typography color="#404047" fontSize={16} fontWeight="bold" textAlign="center">
                            Are you sure you want to go back?
                        </Typography>
                    </Grid>
                    <Grid display="flex" justifyContent="center" sx={{ mt: 2 }}>
                        <Typography color="#9898A2" fontSize={14} textAlign="center" sx={{ maxWidth: "70%" }}>
                            Going back will reset all your unchanged config to their previous state.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                        <Button onClick={props.handleCancel} variant="contained" sx={{ "&:hover": { background: "#EBFAFF" }, boxShadow: "0px 3px 6px #00000029", textTransform: "none", background: "#FFFFFF", color: "#0098D8", borderRadius: "5px", width: "180px", mr: 1 }}>
                            Cancel
                        </Button>
                        <Button onClick={props.handleBack} variant="outlined" sx={{  border: "1px solid", boxShadow: "0px 3px 6px #00000029", textTransform: "none", borderRadius: "5px", width: "180px", ml: 1 }} startIcon={<ArrowBack />}>
                            Go Back
                        </Button>                  
                    </Grid>
                </Grid>
            </Backdrop>
        </Box>
    );
}

ConfirmationBackdrop.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired
};