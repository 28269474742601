import * as React from 'react';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Download from "@mui/icons-material/Download";
import CardTransactions from '../card-transactions/cardTransactions';
import CircularProgress from "@mui/material/CircularProgress";
import CardFilterApplied from "../../transaction-buttons/filter-applied/cardFilterApplied";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { fetchMerchantProfiles } from "../../../../stores/merchantProfiles";
import {getCardProfileCSVAllTransactions} from "../../../../services/transactionService";
import CardSearchBySelect from "../../transaction-selects/cardSearchBySelect";
import ProfileSelect from '../../../../components/profile-select/profileSelect';
import { TransactionStatus } from '../../../../constants/transactionStatus';
import { TransactionCardStatus } from '../../../../constants/transactionCardStatus';
import ComponentLoader from '../../../../components/component-loader/componentLoader';
import RcsTransactions from './rcsTransactions';
import { getRcsCsvTransactions } from '../../../../services/rcsTransactionService';
import RcsSearchBySelect from '../../transaction-selects/rcsSearchBySelect';
import RcsFilterApplied from '../../transaction-buttons/filter-applied/rcsFilterApplied';
import GoogleAnalytics from '../../../../utils/googleAnalyticsHelper';
import { PaymentType } from '../../../../constants/paymentType';

function RcsProducts(){

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvLoading, setIsCsvLoading] = useState(false);
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [selectedMerchant] = useOutletContext();
    const [selectedDisplayName, setSelectedDisplayName] = useState("");

    const [isProfilesLoading, setIsProfilesLoading] = useState(false);


    const [request, setRequest] = useState({
        profileIds: [],
        merchantIds: [],
        fromDate: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        page: 0,
        limit: 25,
        sortBy: "createdAt",
        sortOrder: "desc",
        merchantRef: "",
        transactionStatuses: [],
        route: "",
        pan:"",
        scheme: "",
        transactionId : "",
        paymentType: "",
    });

    const [requestProfile, setRequestProfile] = useState({
        profileIds: [],
        merchantIds: [],
        page :0,
        size: 10000,
        displayName: "",
        profileId: ""
    })

    useEffect(() => {
        if (selectedMerchant) {
            setIsProfilesLoading(true)
            setRequest({...request, merchantIds: selectedMerchant})
            setRequestProfile({...requestProfile, merchantIds: selectedMerchant})

        }
    }, [selectedMerchant])

    useEffect(() =>{
        dispatch(fetchMerchantProfiles(requestProfile));
    }, [requestProfile?.merchantIds])

    useEffect(() => {
        setIsProfilesLoading(false)
        if (merchantProfiles?.content && merchantProfiles?.content?.length > 0) {
            setIsLoading(false);
        }
    }, [merchantProfiles])

    const setSelectedProfile = (profileId) => {
        setRequest({
            ...request,
            profileIds: profileId,
        })
    }

   
    const downloadCSV = () => {
        setIsCsvLoading(true);
        getRcsCsvTransactions(request).then(response => {
            setIsCsvLoading(false);
            if (!window.navigator.msSaveOrOpenBlob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `RCS Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
                document.body.appendChild(link);
                link.click();
                GoogleAnalytics.csvRcsDownloaded(PaymentType.RCS);
            } else {
                const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `Card Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
            }
        }).catch(error => {
            console.log(error);
            setIsCsvLoading(false);
        })
    }

    useEffect(() => {
        let displayNames = [];
        request?.profileIds.forEach(profileId =>{
            const foundProfile = merchantProfiles?.content?.find(p => p.profileId === profileId);
            if(foundProfile)
            displayNames.push(foundProfile?.displayName)
        })
        setSelectedDisplayName(displayNames)
        
    
    }, [request?.profileIds])

    return(
        <Box sx={{ mt: 2, mb: 2 }}>
            <Box sx={{mt: 2}}>
                <Grid container columns={15} columnSpacing={2} sx={{ position: "sticky", zIndex: 1004, background: "#FFFFFF", mb: 2 }}>
                 <Grid item sx={{ minWidth: "250px" }}>
                        <ProfileSelect sx={{ width: "100%" }} profiles={merchantProfiles?.content ? merchantProfiles?.content : []} handleChange={setSelectedProfile} selectedProfile={request.profileIds} isLoading={isProfilesLoading} />
                 </Grid>
                 <Grid item>
                        <RcsSearchBySelect request={request} setRequest={setRequest}/>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            className="transaction-button"
                            startIcon={isCsvLoading ?<CircularProgress color='inherit' size={"25px"}/>:<Download />}
                            onClick={isCsvLoading ?null:downloadCSV}
                            sx={{ textTransform: 'none', width: '175px', height: 40, color: 'white', fontSize: 12, boxShadow: 2 }}
                        >
                            Download CSV
                        </Button>
                    </Grid>
                    <Grid item>
                        <RcsFilterApplied request={request} setRequest={setRequest} displayName={selectedDisplayName}/>
                    </Grid>
                </Grid>
                <Grid>
                    <RcsTransactions profiles={merchantProfiles?.content ? merchantProfiles?.content : []} request={request} isLoading={isLoading} setIsLoading={setIsLoading} setRequest={setRequest} merchantId={selectedMerchant} profileId={request.profileId} />
                </Grid>
            </Box>
        </Box>
    )



}



export default RcsProducts;