export const ZapperTransactionStatus = {
    
    PAYMENT_INITIATED :"Initiated",
    PAYMENT_FAILED: "Failed",
    PAYMENT_CANCELLED: "Cancelled",
    PAYMENT_COMPLETED : "Complete",
    PAYMENT_REFUNDED: "Refunded",
    PAYMENT_DECLINED: "Declined",
    PAYMENT_PARTIALLY_REFUNDED:"Partially Refunded",
    PAYMENT_TIMED_OUT: "Timed Out",
}