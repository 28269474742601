import axios from 'axios';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';

export const getMerchantProfile = (profileId) => {
  GoogleAnalytics.getMerchantProfileIds(profileId);
  return axios.get(`${process.env.REACT_APP_PROFILE_PATH}/${profileId}`);
}

export const getMerchantProfiles = (request) => {
  GoogleAnalytics.getMerchantProfileRequest();
  return axios.post(`${process.env.REACT_APP_PROFILE_ALL_PATH}`, request);
}

export const updateProfile = (profileId, request) => {
  GoogleAnalytics.updatedProfile(profileId);
  return axios.put(`${process.env.REACT_APP_PROFILE_PATH}/${profileId}`, request);
}

export const getWebhookAuth = (profileId) => {
  GoogleAnalytics.getWebhookAuth(profileId);
  return axios.get(`${process.env.REACT_APP_PROFILE_PATH}/${profileId}/webhook`);
}

export const getWebhookDetails = (transactionId) => {
  return axios.get(`${process.env.REACT_APP_WEBHOOK_DETAILS_PATH}/${transactionId}`);
}

export const createWebhookAuth = (profileId, request) => {
  GoogleAnalytics.createdWebhookAuth(profileId);
  return axios.post(`${process.env.REACT_APP_PROFILE_PATH}/${profileId}/webhook`, request);
}

export const updateWebhookAuth = (webhookConfigId, request) => {
  GoogleAnalytics.updateWebhookAuth(webhookConfigId);
  return axios.put(`${process.env.REACT_APP_PROFILE_PATH}/webhook/${webhookConfigId}`, request);
}

export const deleteWebhookAuth = (profileId) => {
  GoogleAnalytics.deleteWebhookAuth(profileId);
  return axios.delete(`${process.env.REACT_APP_PROFILE_PATH}/${profileId}/webhook`);
}