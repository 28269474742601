import axios from 'axios';
import React from 'react';
import GoogleAnalytics from '../utils/googleAnalyticsHelper'

export const getOZOWTransaction = (transactionId) => {
    GoogleAnalytics.getOzowTransactionId(transactionId);
    return axios.get(`${process.env.REACT_APP_TRANSACTION_OZOW_PATH}/${transactionId}`)
}

export const getOZOWTransactions = (request) => {
    GoogleAnalytics.getOzowTransactionRequest();
    return axios.post(`${process.env.REACT_APP_TRANSACTIONS_OZOW_PATH}`, request);
}

export const getOzowCSVTransations = (request) => {
  return axios.post(`${process.env.REACT_APP_CSV_OZOW_TRANSACTIONS_PATH}`, request)
}


export const refundOzowTransaction = (request) => {
 GoogleAnalytics.refundOzowTransactionRequest(request);

return axios.post(`${process.env.REACT_APP_OZOW_REFUND_TRANSACTION_PATH}`,request)}