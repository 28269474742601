import * as React from 'react';
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeycloakService from '../../../keycloak/keycloakService';

function UserButton() {

    const navigate = useNavigate();
    const [anchor, setAnchor] = useState('');
    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    function handleChangePassword() {
       KeycloakService.UpdatePassword();
    }

    return(
        <Box>
            <IconButton className="app-bar-button" onClick={handleClick}>
                <PersonIcon sx={{ color: '#FFFFFF' }}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Typography sx={{ p: 3 }}>
                    <Grid sx={{ display: 'flex', mb: 2 }}>
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            className="user-button"
                            startIcon={<LockOpenIcon/>}
                            onClick={() => {handleChangePassword()}}
                        >
                            Change Password
                        </Button>
                    </Grid>
                    <Grid sx={{ display: 'flex' }}>


                        {!KeycloakService.isLoggedIn && (
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                className="user-button"
                                startIcon={<LoginIcon/>}
                                onClick={() => KeycloakService.doLogin()}
                            >
                                Login
                            </Button>
                        )}

                        {!!KeycloakService.isLoggedIn && (
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                className="user-button"
                                startIcon={<LogoutIcon/>}
                                onClick={() => KeycloakService.doLogout()}
                            >
                                Logout 
                                ({KeycloakService.getUsername()})
                            </Button>
                        )}
                    </Grid>
                </Typography>
            </Popover>
        </Box>
    )
}

export default UserButton;