import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import CustomDateRangePicker from "../custom-date-range-picker/customDateRangePicker";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Popover, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomAutoComplete } from '../custom-autocomplete/customAutoComplete';
import { TransactionStatus } from '../../constants/transactionStatus';
import { CustomTextField } from '../custom-text-field/customTextField';
import moment from 'moment';
import SaveFilterBackdrop from '../save-filter-backdrop/saveFilterBackdrop';
import { SaveOutlined } from '@mui/icons-material';
import { PaymentType } from '../../constants/paymentType';

function AllProductsFilter(props) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [values, setValues] = useState({
        fromDate: "",
        toDate: "",
        transactionStatuses: [],
        merchantRef: "",
        transactionId: "",
        paymentType: []
    });
    const [saveFilterOpen, setSaveFilterOpen] = useState(false);
    const [saveFilterName, setSaveFilterName] = useState("");


    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (props.request) {
            setValues({
                ...values,
                fromDate: moment(props.request.fromDate),
                toDate: moment(props.request.toDate),
                transactionStatuses: props.request.transactionStatuses,
                merchantRef: props.request.merchantRef,
                paymentType: props.request.paymentType
            })
        }
    }, [props.request])

    const handleStatusChange = (event, newValue) => {
        console.log(newValue)
        setValues({
            ...values,
            transactionStatuses: newValue ,
        })
    }

    const handleDateSelectionChange = (newRange) => {
        console.log(newRange)
        setValues({
            ...values,
            fromDate: moment(newRange.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            toDate: moment(newRange.endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        })
    }

    const handleMerchantRefChange = (event) => {
        console.log(event.target.value);
        setValues({
            ...values,
            merchantRef: event.target.value
        })
    }

    const handlePaymentTypeChange = (event , newValue) => {
        console.log(event.target.value);
        setValues({
            ...values,
            paymentType: newValue
        })
    }

    const handleApply = () => {
        props.setRequest({
            ...props.request,
            transactionStatuses: values.transactionStatuses,
            fromDate: moment(values.fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            toDate: moment(values.toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            merchantRef: values.merchantRef,
            paymentType: values.paymentType
        })

        props.setFilterApplied(true);
        handleClose();
    }

    const handleClear = () => {
        props.setRequest({
            ...props.request,
            transactionStatuses: [],
            fromDate: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            merchantRef: "",
            paymentType: "",
        })

        props.setFilterApplied(false);
        handleClose()
    }

    const handleSave = () => {
        setSaveFilterOpen(true);
    }

    const handleCancelSave = () => {
        setSaveFilterOpen(false);
    }

    const checkAndSave = () => {
        const savedFilters = window.localStorage.getItem("allProductFilters");
        if (savedFilters) {
            const tempList = JSON.parse(savedFilters);
            if (tempList.find(f => f.name === saveFilterName)) {
                setDialogOpen(true);
            } else {
                handleSaveFilter(false)
            }
        } else {
            handleSaveFilter(false);
        }
    }

    const handleSaveFilter = (overwrite) => {
        props.setRequest({
            ...props.request,
            transactionStatuses: values.transactionStatuses,
            fromDate: moment(values.fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            toDate: moment(values.toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
            merchantRef: values.merchantRef,
            paymentType: values.paymentType
        })

        let filters = window.localStorage.getItem("allProductFilters");

        if (!filters) {
            window.localStorage.setItem("allProductFilters", JSON.stringify([
                {
                    "name": saveFilterName,
                    request: {
                        ...props.request,
                        transactionStatuses: values.transactionStatuses,
                        fromDate: moment(values.fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                        toDate: moment(values.toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                        merchantRef: values.merchantRef,
                        paymentType: values.paymentType
                    }
                }
            ]));
        } else {
            if (overwrite) {
                var tempList = JSON.parse(filters);
                tempList.splice(tempList.findIndex(l => l.name === saveFilterName), 1);
                window.localStorage.setItem("allProductFilters", JSON.stringify([
                    ...tempList,
                    {
                        "name": saveFilterName,
                        request: {
                            ...props.request,
                            transactionStatuses: values.transactionStatuses,
                            fromDate: moment(values.fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            toDate: moment(values.toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            merchantRef: values.merchantRef,
                            paymentType: values.paymentType
                        }
                    }
                ]));
            } else {
                window.localStorage.setItem("allProductFilters", JSON.stringify([
                    ...JSON.parse(filters),
                    {
                        "name": saveFilterName,
                        request: {
                            ...props.request,
                            transactionStatuses: values.transactionStatuses,
                            fromDate: moment(values.fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            toDate: moment(values.toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            merchantRef: values.merchantRef,
                            paymentType: values.paymentType
                        }
                    }
                ]));
            }
        }

        props.setFilterApplied(true);
        setSaveFilterOpen(false);
        setDialogOpen(false);
        handleClose();
    }

    const handleSaveFilterChange = (event) => {
        setSaveFilterName(event.target.value);
    }


    return (
        <Box>
            <Button variant="contained" startIcon={<FilterListIcon />} sx={{ textTransform: 'none', height: 40, color: 'white', fontSize: 12, boxShadow: 2, width: "150px" }} onClick={handleOpen}>
                Filter
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ zIndex: 999 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableEnforceFocus={true}
            >
                <Box sx={{ background: "#FFFFFF", p: 3, maxWidth: "750px", minWidth: "500px" }}>
                    <Stack spacing={3}>
                        <CustomDateRangePicker selectedRange={{ startDate: values.fromDate, endDate: values.toDate }} setSelectedRange={handleDateSelectionChange} />
                        <CustomAutoComplete  ListboxProps={{ style: { maxHeight: '15rem' } }}
                            multiple
                            size="small"
                            options={Object.values(TransactionStatus).filter((value) =>  value !== 'Sent' && value !=='Paid'  && value !=='Expired')}
                            onChange={(event, newValue) => handleStatusChange(event, newValue)}
                            value={values.transactionStatuses}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                        />
                        <CustomAutoComplete  ListboxProps={{ style: { maxHeight: '15rem' } }}
                            size="small"
                            options={Object.values(PaymentType)}
                            onChange={(event, newValue) => handlePaymentTypeChange(event, newValue)}
                            value={values.paymentType}
                            renderInput={(params) => <TextField {...params} label="Payment Method" />}
                        />
                        <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                            <Button onClick={handleClear} variant="contained" sx={{ letterSpacing: "0px", fontSize: "12px", fontWeight: "bold", "&:hover": { background: "#EBFAFF" }, boxShadow: "0px 3px 6px #00000029", textTransform: "none", background: "#FFFFFF", color: "#0098D8", borderRadius: "5px", width: "160px", mr: 1 }}>
                                Clear Filter
                            </Button>
                            <Button type="button" onClick={handleApply} variant="contained" sx={{ letterSpacing: "0px", fontSize: "12px", fontWeight: "bold", textTransform: "none", background: "#00C1FF", width: "160px", height: "40px", color: "#FFFFFF", mr: 1 }}>
                                Apply Filter
                            </Button>
                            <Button type="button" onClick={handleSave} variant="contained" sx={{ letterSpacing: "0px", fontSize: "12px", fontWeight: "bold", textTransform: "none", background: "#00C1FF", width: "160px", height: "40px", color: "#FFFFFF" }} startIcon={<SaveOutlined />}>
                                Save Filter
                            </Button>
                        </Grid>
                    </Stack>
                </Box>
            </Popover>
            <SaveFilterBackdrop isOpen={saveFilterOpen} handleCancel={handleCancelSave} handleSave={checkAndSave} name={saveFilterName} handleNameChange={handleSaveFilterChange} />
            <Dialog
                sx={{ borderRadius: "5px" }}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Overwrite Filter"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You already have a filter saved named {saveFilterName}. Would you like to overwrite it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button autoFocus onClick={() => setDialogOpen(false)} type="button" variant="contained" sx={{
                        textTransform: "none",
                        "&:hover": { background: "#EBFAFF" },
                        background: "#FFFFFF",
                        width: "100px",
                        height: "100%",
                        color: "#00C1FF"
                    }}>
                        No
                    </Button>
                    <Button onClick={() => handleSaveFilter(true)} autoFocus type="button" variant="contained" sx={{
                        textTransform: "none",
                        background: "#00C1FF",
                        width: "100px",
                        height: "100%",
                        color: "#FFFFFF"
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

AllProductsFilter.propTypes = {
    request: PropTypes.object.isRequired,
    setRequest: PropTypes.func.isRequired,
    setFilterApplied: PropTypes.func.isRequired
};

export default AllProductsFilter;