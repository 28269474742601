import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import Button from '@mui/material/Button';
import Download from '@mui/icons-material/Download';
import CardTransactions from './cardTransactions';
import CircularProgress from '@mui/material/CircularProgress';
import CardFilterApplied from '../../transaction-buttons/filter-applied/cardFilterApplied';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { fetchMerchantProfiles } from '../../../../stores/merchantProfiles';
import { getCardProfileCSVAllTransactions } from '../../../../services/transactionService';
import CardSearchBySelect from '../../transaction-selects/cardSearchBySelect';
import ProfileSelect from '../../../../components/profile-select/profileSelect';
import { TransactionCardStatus } from '../../../../constants/transactionCardStatus';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

function CardFilterDash(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const { merchantProfiles } = useSelector((state) => state.merchantProfiles);
  const [selectedMerchant] = useOutletContext();
  const [selectedDisplayName, setSelectedDisplayName] = useState([]);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [request, setRequest] = useState({
    profileIds: [],
    fromDate: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS'),
    toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    page: 0,
    limit: 25,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    merchantRef: '',
    transactionStatuses: [],
    acquiringBank: '',
    pan: '',
    scheme: '',
    transactionId: '',
  });

  const activateSelect = () => {
    setBulkSelect(true);
  };

  const deactivateSelect = () => {
    setBulkSelect(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked === !true) {
      activateSelect();
    } else {
      deactivateSelect();
    }
  };

  useEffect(() => {
    if (selectedMerchant) {
      setIsProfilesLoading(true);
      dispatch(fetchMerchantProfiles(selectedMerchant));
    }
  }, [selectedMerchant]);

  useEffect(() => {
    setIsProfilesLoading(false);
    if (merchantProfiles?.content && merchantProfiles?.content?.length > 0) {
      setIsLoading(false);
    }
  }, [merchantProfiles]);

  const setSelectedProfile = (profileId) => {
    setRequest({
      ...request,
      profileId: profileId,
    });
  };

  const downloadCardCSV = () => {
    let transactionStatuses = [];
    if (request.profileId && request.profileId !== '') {
      request?.transactionStatuses?.forEach((transactionStatus) => {
        let index = Object.values(TransactionCardStatus).indexOf(
          transactionStatus
        );
        if (Object.keys(TransactionCardStatus)[index] === '_3DS_INITIATED') {
          transactionStatuses.push('_3DS_INITIATED');
        } else if (
          Object.keys(TransactionCardStatus)[index] === '_3DS_AUTHORISED'
        ) {
          transactionStatuses.push('_3DS_AUTHORISED');
        } else if (
          Object.keys(TransactionCardStatus)[index] === '_3DS_FAILED'
        ) {
          transactionStatuses.push('_3DS_FAILED');
        } else {
          transactionStatuses.push(Object.keys(TransactionCardStatus)[index]);
        }
      });
    }
    setIsCsvLoading(true);
    getCardProfileCSVAllTransactions(request, transactionStatuses)
      .then((response) => {
        setIsCsvLoading(false);
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Card Transactions - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          ReactGA.event({
            category: 'transaction',
            action: 'download card csv',
          });
        } else {
          const url = window.navigator.msSaveOrOpenBlob(
            new Blob([response.data]),
            `Card Transactions - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setIsCsvLoading(false);
      });
  };

  useEffect(() => {
    let displayNames = [];
    request?.profileIds.forEach(profileId =>{
        const foundProfile = merchantProfiles?.content?.find(p => p.profileId === profileId);
        if(foundProfile)
        displayNames.push(foundProfile?.displayName)
    })
    setSelectedDisplayName(displayNames)
    

}, [request?.profileIds])

  return (
    <Box sx={{ mb: 2 }}>
        <Grid
          container
          columns={15}
          columnSpacing={2}
          sx={{
            position: 'sticky',
            zIndex: 1004,
            background: '#FFFFFF',
            mb: 2,
          }}
        >
          <Grid item sx={{ minWidth: '250px', paddingLeft: '0px !important' }}>
            <ProfileSelect
              sx={{ width: '100%' }}
              profiles={
                merchantProfiles?.content ? merchantProfiles?.content : []
              }
              handleChange={props.setSelectedProfile}
              selectedProfile={request.profileId}
              isLoading={isProfilesLoading}
            />
          </Grid>
          <Grid item>
            <CardSearchBySelect
              request={props.request}
              setRequest={props.setRequest}
            />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              type="submit"
              variant="contained"
              className="transaction-button"
              startIcon={
                props.isCsvLoading ? (
                  <CircularProgress color="inherit" size={'25px'} />
                ) : (
                  <Download />
                )
              }
              onClick={props.isCsvLoading ? null : props.downloadCardCSV}
              sx={{
                textTransform: 'none',
                width: '175px',
                height: 40,
                color: 'white',
                fontSize: 12,
                boxShadow: 2,
              }}
            >
              Download CSV
            </Button>
          </Grid>
          <Grid item>
            <CardFilterApplied
              request={props?.request}
              setRequest={props?.setRequest}
              displayName={props?.selectedDisplayName}
            />
          </Grid>
        </Grid>
    </Box>
  );
}

CardFilterDash.propType = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  selectedDisplayName: PropTypes.func.isRequired,
  setSelectedProfile: PropTypes.array.isRequired,
  isCsvLoading: PropTypes.func.isRequired,
  downloadCardCSV: PropTypes.func.isRequired,
};

export default CardFilterDash;
