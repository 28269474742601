import { createSlice } from "@reduxjs/toolkit";
import {
    getProfileAllTransactions,
} from "../services/transactionService";

const slice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: []
    },
    reducers: {
        success: (state, action) => {
            state.transactions = action.payload;
            state.isLoading = false;
        },
        notFound: (state, action) => {
            state.transactions = action;
            state.isLoading = false;
        },
        internalServerError: (state, action) => {
            state.transactions = action;
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

const { success, notFound, internalServerError } = slice.actions;

export const fetchProfileAllTransactions = (request) => async dispatch => {
    try {
        await getProfileAllTransactions(request)
            .then((response) => dispatch(success(response.data)));
    } catch(e) {
        console.log(e);
        switch (e.response.status) {
            case 404:
                dispatch(notFound(e.response.data));
                break;
            case 500:
                dispatch(internalServerError(e.response.data));
                break;
            default:
                dispatch(internalServerError(e.response.data));
                break;
        }
    }
}

export const updateProfileAllTransactions = (transactions) => async dispatch => {
    dispatch(success(transactions));
}