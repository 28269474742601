import { Button, createFilterOptions, FormGroup, Divider } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { CustomTextField } from "../../custom-text-field/customTextField";
import {logJiraIssue} from "../../../services/emailService"
import ConfirmationBackdropLogTicket from "../../confirmation-backdrop/confirmationBackdropLogTicket";
import KeycloakService from "../../../keycloak/keycloakService";
import { Email } from "@mui/icons-material";
import { Regex } from "../../../constants/regex";

function TicketLog(props) {
  const filter = createFilterOptions();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [emailValid ,setEmailValid] = useState(false);
  const [jiraTicketSend , setJiraTicketSend] = useState(false);

  const [jiraRequest , setJiraRequest] = useState({
    fields : {
      project : {
        id : "13310"
      },
      summary : "",
      issuetype : {
        name : "Merchant Portal Request"
      }
    },
    displayName : "",
    email : "",
  })

    useEffect(() => {
      setJiraRequest({
        ...jiraRequest,
        email : KeycloakService.getEmail()
    })
  },[])

  const handleClose = () => {
    setIsLoading(true)
    setJiraRequest({
      ...jiraRequest,
      displayName: "",
      email: "",
      summary : ""
    });
    setConfirmation(false);
    props.setOpenTicket(false);
  };

  useEffect(()=>{
    if(!emailValid && jiraRequest?.email?.match(new RegExp(Regex.EMAIL_PATTERN)) ){
      setEmailValid(true);
    }
  },[jiraRequest?.email])


  function sendJiraTicket() {    
    logJiraIssue(jiraRequest)
      .then((response) => {
        setJiraTicketSend(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setJiraTicketSend(false);
        setIsLoading(false);
      });
      
    if (!confirmation) {
      setConfirmation(true);
    }
    console.log(jiraRequest);
  }

  const setIssue = (event) => {
    setJiraRequest({
      ...jiraRequest,
      fields: {
        ...jiraRequest.fields,
        summary: event.target.value,
      }
    });
  };

  const setEmail = (event) => {
    setJiraRequest({
      ...jiraRequest,
      email: event.target.value,
      displayName: event.target.value
    });
  };


  return (
    <Box>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "80%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle color="404047" textAlign="left" fontWeight="bold">
          Log a Ticket
        </DialogTitle>
        <DialogContent container direction="column">
          <CustomTextField
                      sx={{width:"100%", mt:1,boxShadow : "none"}}
                      id="email-id"
                        size="small"
                        className="transaction-select"
                        label={"From email"}
                        error={!emailValid}
                        helperText={emailValid ? "":"Email is not valid"}
                        value={jiraRequest.email}
                        onChange={event => setEmail(event)}
                    />
          <FormGroup sx={{ mt: 3, mb: 2 }}>
            <CustomTextField
              id="outlined-multiline-static"
              label="Describe your issue"
              placeholder="Describe your issue"
              multiline
              inputProps={{
                maxLength: 200,
              }}
              rows={4}
              value={jiraRequest.issue}
              onChange={setIssue}
            />
            <Divider />
          </FormGroup>
        </DialogContent>
        <DialogActions style={{ justifyContent: "left" }} sx={{ ml: 2 }}>
          <Button
            style={{ textTransform: "none" }}
            variant="outlined"
            sx={{
              height: "48px",
              borderColor: "white",
              alignContent: "left",
              letterSpacing: "0px",
              fontSize: "12px",
              fontWeight: "bold",
              "&:hover": { background: "#EBFAFF" },
              boxShadow: "0px 3px 6px #00000029",
              textTransform: "none",
              background: "#FFFFFF",
              color: "#0098D8",
              borderRadius: "5px",
              width: "180px",
              mr: 1,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            sx={{
              color: "white",
              width: "180px",
              height: "48px",
              fontSize: "12px",
              textAlign: "center",
            }}
            onClick={sendJiraTicket}
          >
            Log Ticket
          </Button>
          <ConfirmationBackdropLogTicket
            isOpen={confirmation}
            handleCancel={handleClose}
            isSuccessfull={jiraTicketSend}
            isLoading={isLoading}
          ></ConfirmationBackdropLogTicket>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

TicketLog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenTicket: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  transactionStatus: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  merchantRef: PropTypes.string.isRequired,
};

export default TicketLog;
