import {forwardRef} from "react";
import { IMaskInput} from "react-imask";

function CustomTextMask (props , ref){
    const {refChild , onChange , ...other} = props;

    return(
        <IMaskInput
            {...other}
            mask={props.mask}
            inputRef={ref}
            ref={refChild}
            pattern={props.pattern}
            blocks={props.blocks}
            parse={props.parse}
            format={props.format}
            prepare={props.prepare}
            autofix={props.autofix}
            onAccept={(value, maskRef) => onChange({target: {unmaskedValue: maskRef._unmaskedValue, value}})}
        />
    );
};

export default forwardRef(CustomTextMask)