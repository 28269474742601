import * as React from 'react';
import Typography from "@mui/material/Typography";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    FormGroup, formGroupClasses,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar
} from "@mui/material";
import { Box } from "@mui/system";
import CustomSwitch from "../../components/custom-switch/customSwitch";
import { CustomTextField } from "../../components/custom-text-field/customTextField";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { createWebhookAuth, deleteWebhookAuth, getWebhookAuth, updateProfile, updateWebhookAuth } from "../../services/profileServices";
import { validateUrl } from "../../utils/validator";
import { SaveOutlined } from '@mui/icons-material';
import ComponentLoader from '../../components/component-loader/componentLoader';
import GoogleAnalytics from '../../utils/googleAnalyticsHelper';


function EndPointConfig() {

    const [profile, setProfile, setChangesMade] = useOutletContext();
    const [properties, setProperties] = useState({
        redirectSuccessUrl: "",
        redirectFailedUrl: "",
        webhookUrl: ""
    });

    const [webhookAuth, setWebhookAuth] = useState({
        authType: "",
        authUrl: "",
        clientId: "",
        clientSecret: "",
        grantType: "",
        password: "",
        userName: "",
        webhookAuthConfigId: ""
    });

    const [validation, setValidation] = useState({
        authUrlValid: "",
        successUrlValid: "",
        failureUrlValid: "",
        webhookUrlValid: "",
        clientId: "",
        password: "",
        userName: "",
        clientSecret: ""
    })

    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });

    const [isLoading, setIsLoading] = useState(false);
    const [hasConfig, setHasConfig] = useState(false);
    const [hasAuth, setHasAuth] = useState(false);
    const [authConfigExists, setAuthConfigExists] = useState(false);

    const successUrlChange = (value) => {
        setChangesMade(true);
        setProperties((prevState) => ({
            ...prevState,
            redirectSuccessUrl: value
        }));
    };

    useEffect(() => {
        isSuccessUrlValid()
    }, [properties.redirectSuccessUrl])

    const isSuccessUrlValid = () => {
        const valid = validateUrl(properties.redirectSuccessUrl);
        setValidation((prevState) => ({
            ...prevState,
            successUrlValid: validateUrl(properties.redirectSuccessUrl)
        }));
        return valid;
    }

    const failureUrlChange = (value) => {
        setProperties((prevState) => ({
            ...prevState,
            redirectFailedUrl: value
        }));
        setChangesMade(true);
    };

    useEffect(() => {
        isFailureUrlValid()
    }, [properties.redirectFailedUrl])

    const isFailureUrlValid = () => {
        const valid = validateUrl(properties.redirectFailedUrl);
        setValidation((prevState) => ({
            ...prevState,
            failureUrlValid: valid
        }));
        return valid;
    }

    const webHookUrlChange = (value) => {
        setChangesMade(true);
        setProperties((prevState) => ({
            ...prevState,
            webhookUrl: value
        }));
    };

    useEffect(() => {
        isWebhookUrlValid()
    }, [properties.webhookUrl])

    const isWebhookUrlValid = () => {
        if (properties.webhookUrl) {
            const valid = validateUrl(properties.webhookUrl);
            setValidation((prevState) => ({
                ...prevState,
                webhookUrlValid: valid
            }));
            return valid;
        } else {
            setValidation((prevState) => ({
                ...prevState,
                webhookUrlValid: true
            }));
            return true;
        }
    }

    const authUrlChange = (value) => {
        setWebhookAuth((prevState) => ({
            ...prevState,
            authUrl: value
        }));
    };

    useEffect(() => {
        isAuthUrlValid()
    }, [webhookAuth.authUrl])

    const isAuthUrlValid = () => {
        const valid = validateUrl(webhookAuth.authUrl);
        setValidation((prevState) => ({
            ...prevState,
            authUrlValid: valid
        }));
        return valid;
    }

    const isFieldsValid = () => {
        let isValid = true;
        if (hasConfig) {
            if (!isSuccessUrlValid()) {
                isValid = false
            }
            if (!isFailureUrlValid()) {
                isValid = false
            }
        }
        if (hasAuth) {
            if (!isAuthUrlValid) {
                isValid = false;
            }
            if (!validateClientId()) {
                isValid = false;
            }
            if (!validateClientSecret()) {
                isValid = false;
            }
            if (!isUsernameValid()) {
                isValid = false;
            }
            if (!isPasswordValid()) {
                isValid = false;
            }
        }

        return isValid;
    }

    useEffect(() => {
        validateClientId()
    }, [webhookAuth.clientId])

    const validateClientId = () => {
        if (hasAuth && webhookAuth.authType === "OAUTH") {
            if (webhookAuth.clientId) {
                if (webhookAuth.clientId.length > 0) {
                    setValidation((prevState) => ({
                        ...prevState,
                        clientId: true
                    }));
                    return true
                } else {
                    setValidation((prevState) => ({
                        ...prevState,
                        clientId: false
                    }));
                    return false
                }
            } else {
                setValidation((prevState) => ({
                    ...prevState,
                    clientId: false
                }));
                return false
            }
        } else {
            setValidation((prevState) => ({
                ...prevState,
                clientId: true
            }));
            return true
        }
    }

    useEffect(() => {
        validateClientSecret()
    }, [webhookAuth.clientSecret])

    const validateClientSecret = () => {
        if (hasAuth && webhookAuth.authType === "OAUTH") {
            if (webhookAuth.clientSecret) {
                if (webhookAuth.clientSecret.length > 0) {
                    setValidation((prevState) => ({
                        ...prevState,
                        clientSecret: true
                    }));
                    return true
                } else {
                    setValidation((prevState) => ({
                        ...prevState,
                        clientSecret: false
                    }));
                    return false;
                }
            } else {
                setValidation((prevState) => ({
                    ...prevState,
                    clientSecret: false
                }));
                return false;
            }
        } else {
            setValidation((prevState) => ({
                ...prevState,
                clientSecret: true
            }));
            return true
        }
    }

    useEffect(() => {
        isUsernameValid();
    }, [webhookAuth.userName])

    const isUsernameValid = () => {
        if (hasAuth && webhookAuth.authType === "BASIC_AUTH") {
            if (webhookAuth.userName) {
                if (webhookAuth.userName.length > 1) {
                    setValidation((prevState) => ({
                        ...prevState,
                        userName: true
                    }));
                    return true
                } else {
                    setValidation((prevState) => ({
                        ...prevState,
                        userName: false
                    }));
                    return false
                }
            } else {
                setValidation((prevState) => ({
                    ...prevState,
                    userName: false
                }));
                return false
            }
        } else {
            setValidation((prevState) => ({
                ...prevState,
                userName: true
            }));
            return true
        }
    }

    useEffect(() => {
        isPasswordValid();
    }, [webhookAuth.password])

    const isPasswordValid = () => {
        if (hasAuth && webhookAuth.authType === "BASIC_AUTH") {
            if (webhookAuth.password) {
                if (webhookAuth.password.length > 1) {
                    setValidation((prevState) => ({
                        ...prevState,
                        password: true
                    }));
                    return true
                } else {
                    setValidation((prevState) => ({
                        ...prevState,
                        password: false
                    }));
                    return false
                }
            } else {
                setValidation((prevState) => ({
                    ...prevState,
                    password: false
                }));
                return false
            }
        } else {
            setValidation((prevState) => ({
                ...prevState,
                password: true
            }));
            return true
        }
    }

    useEffect(() => {
        if (profile?.redirectSuccessUrl !== "" || profile?.redirectFailedUrl !== "" || profile?.webhookUrl !== "") {
            setHasConfig(true);
        }
        setProperties({
            redirectSuccessUrl: profile?.redirectSuccessUrl ? profile?.redirectSuccessUrl : "",
            redirectFailedUrl: profile?.redirectFailedUrl ? profile?.redirectFailedUrl : "",
            webhookUrl: profile?.webhookUrl ? profile?.webhookUrl : "",
        });
        setIsLoading(true);
        getWebhookAuth(profile?.profileId).then(response => {
            if (response.data != null && response.data !== "") {
                setHasAuth(true);
                setWebhookAuth(response.data);
                setAuthConfigExists(false);
            }
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        })
    }, [profile]);

    const propertyChanged = (property, value) => {
        setChangesMade(true);
        setProperties({
            ...properties,
            [property]: value
        });
    }

    const authConfigChanged = (property, value) => {
        setChangesMade(true);
        setWebhookAuth({
            ...webhookAuth,
            [property]: value
        });
    }

    const saveConfig = () => {
        if (isFieldsValid()) {
            setIsLoading(true);
            if (!hasConfig) {
                updateProfile(profile?.profileId, {
                    ...profile,
                    redirectSuccessUrl: "",
                    redirectFailedUrl: "",
                    webhookUrl: "",
                }).then((response) => {
                    setProfile(response.data);
                    GoogleAnalytics.savePaymentPageConfig(profile?.profileId);
                    setChangesMade(false);
                    setIsLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                })
            } else {
                updateProfile(profile?.profileId, {
                    ...profile,
                    ...properties
                })
                    .then((response) => {
                        setProfile(response.data);
                        setChangesMade(false);
                        if (hasAuth) {
                            if (authConfigExists) {
                                updateWebhookAuth(webhookAuth.webhookAuthConfigId, webhookAuth).then(response => {
                                    setWebhookAuth(response.data);
                                    setIsLoading(false);
                                    setResponse({
                                        severity: "success",
                                        isOpen: true,
                                        message: "Profile URL's and authentication successfully updated"
                                    })
                                }).catch(error => {
                                    console.log(error);
                                    setIsLoading(false);
                                    setResponse({
                                        severity: "error",
                                        isOpen: true,
                                        message: "Unable to update profile URL's and authentication"
                                    })
                                })
                            } else {
                                createWebhookAuth(profile?.profileId, webhookAuth).then(response => {
                                    setWebhookAuth(response.data);
                                    setAuthConfigExists(true);
                                    setIsLoading(false);
                                    setResponse({
                                        severity: "success",
                                        isOpen: true,
                                        message: "Profile URL's and authentication successfully updated"
                                    })
                                }).catch(error => {
                                    console.log(error);
                                    setIsLoading(false);
                                    setResponse({
                                        severity: "error",
                                        isOpen: true,
                                        message: "Unable to update profile URL's and authentication"
                                    })
                                })
                            }
                        } else {
                            deleteWebhookAuth(profile?.profileId).then(response => {
                                setIsLoading(false);
                                setWebhookAuth({
                                    authType: "",
                                    authUrl: "",
                                    clientId: "",
                                    clientSecret: "",
                                    grantType: "",
                                    password: "",
                                    userName: "",
                                    webhookAuthConfigId: ""
                                });
                                setResponse({
                                    severity: "success",
                                    isOpen: true,
                                    message: "Profile URL's and authentication successfully updated"
                                })
                            }).catch(error => {
                                setResponse({
                                    severity: "error",
                                    isOpen: true,
                                    message: "Unable to update profile URL's and authentication"
                                })
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Unable to update profile URL's and authentication"
                        })
                    })

            }
        }
    }

    return (
        <Box component="form" sx={{ mt: 2, mb: 4, ml: 3, mr: 3, minHeight: "calc(100vh - 345px)" }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasConfig} onChange={(event) => setHasConfig(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Payment
                                    Pages URL Config</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Setup your payment
                                    pages URL's</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <FormGroup sx={{ mt: 3 }}>
                        <CustomTextField
                            id="demo-helper-text-aligned"
                            error={!validation.successUrlValid}
                            helperText={validation.successUrlValid ? "" : "URL not valid"}
                            name={properties.successUrl}
                            label="Success URL"
                            size="small"
                            sx={{ mb: 3 }}
                            value={properties.redirectSuccessUrl}
                            disabled={!hasConfig}
                            onChange={e => successUrlChange(e.target.value)}

                        />
                        <CustomTextField
                            id="demo-helper-text-aligned"
                            error={!validation.failureUrlValid}
                            label="Failure URL"
                            helperText={validation.failureUrlValid ? "" : "URL not valid"}
                            size="small"
                            sx={{ mb: 3 }}
                            value={properties.redirectFailedUrl}
                            disabled={!hasConfig}
                            onChange={e => failureUrlChange(e.target.value)}
                            inputProps={{}}
                        />
                        <CustomTextField
                            id="demo-helper-text-aligned"
                            error={!validation.webhookUrlValid}
                            label="Webhook URL"
                            helperText={validation.webhookUrlValid ? "" : "URL not valid"}
                            size="small"
                            sx={{ mb: 3 }}
                            value={properties.webhookUrl}
                            disabled={!hasConfig}
                            onChange={e => webHookUrlChange(e.target.value)}
                            inputProps={{}}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasAuth} onChange={(event) => setHasAuth(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Webhook
                                    Authentication Config</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Setup your webhook
                                    authentication config</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <FormGroup sx={{ mt: 3 }}>
                        <FormControl sx={{ mb: 3, borderColor: "#000" }} size="small" disabled={!hasAuth}
                            className="dashboard-select">
                            <InputLabel id="auth-type-label">Authentication type</InputLabel>
                            <Select
                                labelId="auth-type-label"
                                id="auth-type"
                                value={webhookAuth.authType}
                                label="Authentication type"
                                onChange={(event) => authConfigChanged("authType", event.target.value)}
                            >
                                <MenuItem value="BASIC_AUTH">Basic</MenuItem>
                                <MenuItem value="OAUTH">OAuth2</MenuItem>
                            </Select>
                        </FormControl>
                        {webhookAuth.authType === "BASIC_AUTH" &&
                            <FormGroup>
                                <CustomTextField
                                    id="username"
                                    label="Username"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.userName}
                                    required
                                    helperText={!validation.userName ? 'Username is required' : ''}
                                    error={!validation.userName}
                                    disabled={!hasAuth}
                                    onChange={(event) => authConfigChanged("userName", event.target.value)}
                                    inputProps={{
                                        minlength: 1
                                    }}
                                />
                                <CustomTextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.password}
                                    required
                                    error={!validation.password}
                                    helperText={!validation.password ? 'Password is required' : ''}
                                    disabled={!hasAuth}
                                    onChange={(event) => authConfigChanged("password", event.target.value)}
                                    inputProps={{
                                        minlength: 6
                                    }}
                                />
                            </FormGroup>
                        }

                        {webhookAuth.authType === "OAUTH" &&
                            <FormGroup>
                                <CustomTextField
                                    id="auth-url"
                                    error={!validation.authUrlValid}
                                    helperText={validation.authUrlValid ? "" : "URL not valid"}
                                    label="Auth URL"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.authUrl}
                                    disabled={!hasAuth}
                                    onChange={e => authUrlChange(e.target.value)}
                                    inputProps={{}}

                                />
                                <CustomTextField
                                    id="client-id"
                                    label="Client ID"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.clientId}
                                    helperText={!validation.clientId ? 'Client ID is required' : ''}
                                    error={!validation.clientId}
                                    required
                                    disabled={!hasAuth}
                                    onChange={(event) => authConfigChanged("clientId", event.target.value)}
                                    inputProps={{
                                        minLength: 1
                                    }}
                                />
                                <CustomTextField
                                    id="client-secret"
                                    label="Client Secret"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.clientSecret}
                                    helperText={!validation.clientSecret ? 'Client Secret is required' : ''}
                                    error={!validation.clientSecret}
                                    required
                                    disabled={!hasAuth}
                                    onChange={(event) => authConfigChanged("clientSecret", event.target.value)}
                                    inputProps={{
                                        minLength: 1
                                    }}
                                />
                                <CustomTextField
                                    id="grsant-type"
                                    label="Grant type"
                                    size="small"
                                    sx={{ mb: 3 }}
                                    value={webhookAuth.grantType}
                                    disabled={!hasAuth}
                                    onChange={(event) => authConfigChanged("grantType", event.target.value)}
                                />
                            </FormGroup>
                        }
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Button onClick={() => saveConfig()} type="button" variant="contained"
                    sx={{ textTransform: "none", background: "#00C1FF", width: "200px", color: "#FFFFFF" }}
                    startIcon={<SaveOutlined />}>
                    Save Changes
                </Button>
                <Typography fontSize={14} letterSpacing={0} sx={{ ml: 3, width: "300px", color: "#404047" }}>
                    Please ensure all information is correct before saving
                </Typography>
            </Grid>
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen}
                autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default EndPointConfig;