import * as React from "react";
import Box from "@mui/system/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProfileSelect from "../../components/profile-select/profileSelect";
import DashboardCard1 from "./dashboard-cards/dashboardCard1";
import DashboardCard2 from "./dashboard-cards/dashboardCard2";
import DashboardCard3 from "./dashboard-cards/dashboardCard3";
import DashboardCard4 from "./dashboard-cards/dashboardCard4";
import TimelineSelect from "./dashboard-selects/timelineSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchMerchantProfiles } from "../../stores/merchantProfiles";
import { Divider } from "@mui/material";
import DashboardTimelineChart from "./dashboard-charts/dashboardTimelineChart";
import DashboardFailedReasons from "./dashboard-cards/dashboardFailedReasons";
import { useOutletContext } from "react-router-dom";
import { Timeline } from "../../constants/timeline";
import CurrencySelect from "../../components/currency-select/currencySelect";

function Dashboard() {

    const dispatch = useDispatch();
    const [selectedMerchants] = useOutletContext();
    const [currencySymbol, setCurrencySymbol] = useState("R");
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [selectedProfileDisplayName, setSelectedProfileDisplayName] = useState("");
    const [selectedTimeline, setSelectedTimeline] = useState(Timeline.TWENTYFOURHOURS);
    const [profileIdsMerchantIds, setProfileIdsMerchantIds] = useState([]);
    const [request, setRequest] = useState({
        profileIds: [],
        merchantIds: [],
        size: 10000,
        page: 0,
        displayName: "",
        profileId: ""
    })

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);

    useEffect(() => {
        if (selectedMerchants && selectedMerchants.length > 0) {
            setIsLoading(true);
            setProfileIdsMerchantIds({...profileIdsMerchantIds, merchantIds: selectedMerchants})
            setRequest({...request, merchantIds: selectedMerchants})
        } else {
            setProfileIdsMerchantIds({...profileIdsMerchantIds, profileIds: [], merchantIds: []})
            setRequest({...request, profileIds: [], merchantIds: []})
        }
    }, [selectedMerchants])

    useEffect(() =>{
        dispatch(fetchMerchantProfiles(request));
    }, [profileIdsMerchantIds?.merchantIds])

    useEffect(() => {
        setIsLoading(false);
    }, [merchantProfiles])

    useEffect(() => {
        setProfileIdsMerchantIds({...profileIdsMerchantIds, profileIds: selectedProfile})
        setRequest({...request, profileIds: selectedProfile})
    }, [selectedProfile])

    useEffect(() => {
        if (profileIdsMerchantIds?.profileIds?.length > 0) {
            const currency = merchantProfiles?.content?.find(profile => profile.profileId === profileIdsMerchantIds?.profileIds?.[0])?.profileCurrencies[0]?.currency
            setCurrencySymbol(currency?.symbol)
            setSelectedProfileDisplayName(merchantProfiles?.content?.find(profile => profile.profileId === profileIdsMerchantIds?.profileIds?.[0])?.displayName)
        }
    }, [profileIdsMerchantIds?.profileIds, merchantProfiles])

    return (
        <Box sx={{ mt: 2, ml: 3, mr: 3, minHeight: "calc(100vh - 206px)" }}>
            <Grid container sx={{ direction: 'row', height: '100%', alignItems: 'center' }}>
                <Grid item sx={{ display: 'flex', flexGrow: 1 }}>
                    <Typography className="font-32">Welcome Back</Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', mr: 2 }}>
                    <Typography className="font-16">Viewing dashboard for:</Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', minWidth: 350}}>
                    <ProfileSelect sx={{ width: "105%" }} profiles={merchantProfiles?.content ? merchantProfiles?.content : []} selectedProfile={profileIdsMerchantIds?.profileIds} handleChange={setSelectedProfile}  />
                    <CurrencySelect selectedCurrencies={selectedCurrencies} setSelectedCurrencies={setSelectedCurrencies}/>
                </Grid>
            </Grid>
            <Grid container sx={{ direction: 'row', alignItems: 'stretch', mt: 2 }}>
                <Grid item xs={3} sx={{ pr: 2 }}>
                    <DashboardCard1
                        profileIdsMerchantIds={profileIdsMerchantIds}
                        displayName={selectedProfileDisplayName}
                        isLoading={isLoading}
                        selectedCurrencies={selectedCurrencies}
                    />
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                    <DashboardCard2
                        profileIdsMerchantIds={profileIdsMerchantIds}
                        displayName={selectedProfileDisplayName}
                        currencySymbol={currencySymbol ? currencySymbol : "R"}
                        isLoading={isLoading}
                        selectedCurrencies={selectedCurrencies}
                    />
                </Grid>
                <Grid item xs={3} sx={{ pl: 1 }}>
                    <DashboardCard3
                        profileIdsMerchantIds={profileIdsMerchantIds}
                        displayName={selectedProfileDisplayName}
                        isLoading={isLoading}
                        selectedCurrencies={selectedCurrencies}
                    />
                </Grid>
                <Grid item xs={3} sx={{ pl: 2 }}>
                    <DashboardCard4
                        profileIdsMerchantIds={profileIdsMerchantIds}
                        selectedCurrencies={selectedCurrencies}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, borderColor: "#00000029", borderBottomWidth: "unset" }} />
            <Grid container spacing={1} sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Typography fontSize={22} fontWeight="bold" letterSpacing={0.31}></Typography>
                </Grid>
                <Grid item sx={{ flexGrow: 0, width: 200 }}>
                    <TimelineSelect selectedTimeline={selectedTimeline} setSelectedTimeline={setSelectedTimeline} />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ direction: 'column', mt: 1, mb: 4 }}>
                <Grid item xs={5}>
                    <Card className="dashboard-card-large" sx={{ height: "100%" }}>
                        <Box sx={{ height: 50, background: "#00C1FF", display: "flex", alignItems: "center", pl: "20px" }}>
                            <Typography fontSize={16} fontWeight="bold" color="#FFFFFF">
                                % Failed Transactions - Top 5 Reasons
                            </Typography>
                        </Box>
                        <Box>
                            <DashboardFailedReasons profileIdsMerchantIds={profileIdsMerchantIds} selectedTimeline={selectedTimeline} isLoading={isLoading}/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={7}>
                    <Card className="dashboard-card-large">
                        <Box sx={{ height: 50, background: "#00C1FF", display: "flex", alignItems: "center", pl: "20px" }}>
                            <Typography fontSize={16} fontWeight="bold" color="#FFFFFF">
                                Transaction Timeline
                            </Typography>
                        </Box>
                        <Box sx={{ m: 2 }}>
                            <DashboardTimelineChart profileIdsMerchantIds={profileIdsMerchantIds} selectedTimeline={selectedTimeline} isLoading={isLoading} selectedCurrencies={selectedCurrencies}/>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;