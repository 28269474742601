import { BrokenImage, DesktopWindows, PhoneIphone, SaveOutlined } from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Popover,
    Snackbar,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ColorSwitch from "../../components/color-switch/colorSwitch";
import CustomSwitch from "../../components/custom-switch/customSwitch";
import { CustomTextField } from "../../components/custom-text-field/customTextField";
import { uploadFile } from "../../services/filesService";
import { updateProfile } from "../../services/profileServices";
import "../../styles.scss";
import ComponentLoader from "../../components/component-loader/componentLoader";
import ColorPicker from "../../components/color-picker/colorPicker";
import GoogleAnalytics from "../../utils/googleAnalyticsHelper"

function BrandingConfig() {

    const [profile, setProfile, setChangesMade] = useOutletContext();
    const [url, setUrl] = useState("");
    const [isDesktop, setIsDesktop] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessagePps, setErrorMessagePpS] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const isUploadContainerOpen = Boolean(anchorEl);

    const [isOpen, setIsOpen] = useState({
        primaryColor: false,
        secondaryColor: false,
        headerFooterColor: false
    });

    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });

    const [hasPaymentSupport, setHasPaymentSupport] = useState(false);
    const [hasPaymentStyling, setHasPaymentStyling] = useState(false);

    const [values, setValues] = useState({
        supportHtml: "<b>Need Help?</b> Call 082 123 1234",
        primaryColor: "#0098D8",
        secondaryColor: "#034EA2",
        headerFooterColor: "#0098D8",
        primaryFontColorBlack: false,
        headerFooterFontColorBlack: false,
        displayName: "Demo preview",
        useLogoOnly: false,
        hideHeader: false,
        useTextOnly: false,
        logoUrl: "https://www.nicepng.com/png/full/610-6100872_f-logo-orange-png-f-logo-design.png",
        isThreeDSecureEmbedded: true,
        allowDarkMode: false
    });

    useEffect(() => {
        if (values.supportHtml) {
            if (values.supportHtml.length < 200) {
                setErrorMessagePpS([]);
            } else {
                setErrorMessagePpS(["Can't exceed 200 characters"])
            }
        }
    }, [values.supportHtml])


    const [image, setImage] = useState({
        name: "",
        selectedFile: null
    });


    useEffect(() => {
        updateFrame();
    })

    const handleChange = () => {
        updateFrame();
    }

    const handleClose = (value) => {
        setIsOpen({
            ...isOpen,
            [value]: false,
            anchor: null
        })
    }

    const handleSave = (prop, value) => {
        setChangesMade(true);
        setValues({ ...values, [prop]: value })
    }

    const updateFrame = useCallback(() => {
        let urlString = `${process.env.REACT_APP_HPP_URL}/preview?support=${encodeURIComponent(values.supportHtml)}&displayName=${encodeURIComponent(values.displayName)}` +
            `&headerFooterColor=${encodeURIComponent(values.headerFooterColor)}&headerFooterFontColorBlack=${encodeURIComponent(values.headerFooterFontColorBlack)}` +
            `&primaryColor=${encodeURIComponent(values.primaryColor)}&primaryFontColorBlack=${encodeURIComponent(values.primaryFontColorBlack)}` +
            `&secondaryColor=${encodeURIComponent(values.secondaryColor)}&useLogoOnly=${encodeURIComponent(values.useLogoOnly)}&useTextOnly=${encodeURIComponent(values.useTextOnly)}` +
            `&logoUrl=${encodeURIComponent(values.logoUrl)}&hideHeader=${encodeURIComponent(values.hideHeader)}&allowDarkMode=${encodeURIComponent(values.allowDarkMode)}`;
        setUrl(urlString);
    }, [values])

    useEffect(() => {
        updateFrame();
    }, [values, updateFrame])

    const uploadImage = (files) => {
        const MAX_FILE_SIZE = 2024

        if (files && files.length > 0) {
            setIsLoading(true);
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                setImage({
                    name: files[0].name,
                    selectedFile: e.target.result,
                })
                const formData = new FormData();
                formData.append('file', files[0]);

                const fileSizeKiloBytes = e.target.result.size / 1024

                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setResponse({
                        severity: "error",
                        isOpen: true,
                        message: "The image needs to be smaller than 2MB"
                    });
                } else {
                    uploadFile(formData).then((response) => {
                        handleSave("logoUrl", response.data.url);
                        setIsLoading(false);
                        setResponse({
                            severity: "success",
                            isOpen: true,
                            message: "Image successfuly uploaded"
                        });
                        setAnchorEl(null);
                    }).catch(error => {
                        setIsLoading(false);
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Unable to upload image"
                        });
                        console.log(error)
                        setAnchorEl(null);
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (profile?.supportHtml || profile?.supportHtml !== "") {
            setHasPaymentSupport(true);
        }
        if (profile?.hostedPaymentStyling && profile?.hostedPaymentStyling?.isActive) {
            setHasPaymentStyling(true);
        }
        if (profile?.hostedPaymentStyling) {
            setValues({
                supportHtml: profile?.supportHtml ? profile?.supportHtml : "",
                headerFooterColor: profile?.hostedPaymentStyling.headerFooterColor,
                headerFooterFontColorBlack: profile?.hostedPaymentStyling.headerFooterFontColorBlack,
                hostedPaymentStylingId: profile?.hostedPaymentStyling.hostedPaymentStylingId,
                logoUrl: profile?.hostedPaymentStyling.logoUrl,
                primaryColor: profile?.hostedPaymentStyling.primaryColor,
                primaryFontColorBlack: profile?.hostedPaymentStyling.primaryFontColorBlack,
                secondaryColor: profile?.hostedPaymentStyling.secondaryColor,
                useLogoOnly: profile?.hostedPaymentStyling.useLogoOnly,
                useTextOnly: profile?.hostedPaymentStyling.useTextOnly,
                hideHeader: profile?.hostedPaymentStyling.hideHeader,
                displayName: profile?.displayName,
                isThreeDSecureEmbedded: profile?.isThreeDSecureEmbedded,
                allowDarkMode: profile?.hostedPaymentStyling?.allowDarkMode
            })
        }
    }, [profile]);

    const saveConfig = () => {
        setIsLoading(true);
        let tempConfig = {
            ...profile,
            supportHtml: hasPaymentSupport ? values.supportHtml : "",
            displayName: values.displayName,
            isThreeDSecureEmbedded: values.isThreeDSecureEmbedded,
            hostedPaymentStyling: hasPaymentStyling ? {
                isActive: true,
                hostedPaymentStylingId: profile?.hostedPaymentStyling?.hostedPaymentStylingId,
                primaryColor: values.primaryColor,
                secondaryColor: values.secondaryColor,
                headerFooterColor: values.headerFooterColor,
                primaryFontColorBlack: values.primaryFontColorBlack,
                headerFooterFontColorBlack: values.headerFooterFontColorBlack,
                useLogoOnly: values.useLogoOnly,
                useTextOnly: values.useTextOnly,
                logoUrl: values.logoUrl,
                hideHeader: values.hideHeader,
                allowDarkMode: values.allowDarkMode
            } : profile?.hostedPaymentStyling ? {
                isActive: false,
                hostedPaymentStylingId: profile?.hostedPaymentStyling?.hostedPaymentStylingId,
                primaryColor: values.primaryColor,
                secondaryColor: values.secondaryColor,
                headerFooterColor: values.headerFooterColor,
                primaryFontColorBlack: values.primaryFontColorBlack,
                headerFooterFontColorBlack: values.headerFooterFontColorBlack,
                useLogoOnly: values.useLogoOnly,
                useTextOnly: values.useTextOnly,
                logoUrl: values.logoUrl,
                hideHeader: values.hideHeader,
                allowDarkMode: values.allowDarkMode
            } : null
        }

        updateProfile(profile?.profileId, tempConfig).then(response => {
            setProfile(response.data);
            setChangesMade(false);
            setIsLoading(false);
            GoogleAnalytics.updatedBrandingConfig(profile?.profileId);
            setResponse({
                severity: "success",
                isOpen: true,
                message: "Profile branding successfully updated"
            })
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
            setResponse({
                severity: "error",
                isOpen: true,
                message: "Unable to update profile branding"
            })
        })
    }

    return (
        <Container maxWidth="100%" sx={{ mt: 2, minHeight: "calc(100vh - 337px)" }}>
            <Grid container>
                <Grid item xs={6} sx={{ mb: 4, pr: 3 }}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasPaymentSupport}
                                onChange={(event) => setHasPaymentSupport(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Support & Help</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Add support, help
                                    URL or number on the payment pages</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormGroup sx={{ mt: 3, mb: 2 }}>
                        <CustomTextField
                            disabled={!hasPaymentSupport}
                            id="outlined-multiline-static"
                            placeholder="Add text/html here"
                            multiline
                            inputProps={{
                                maxLength: 200
                            }}
                            rows={4}
                            value={values.supportHtml}
                            error={Boolean(errorMessagePps.length)}
                            helperText={errorMessagePps[0]}
                            onChange={(event) => handleSave("supportHtml", event.target.value)}
                            sx={{ mb: 3 }}
                        />
                        <Divider />
                    </FormGroup>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasPaymentStyling}
                                onChange={(event) => setHasPaymentStyling(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Branding</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Customise payment
                                    pages with your branding</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormGroup sx={{ mt: 3 }}>
                        <Typography fontSize={16} fontWeight="bold" color="#404047" sx={{ mb: 1 }}>General
                            Colour</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!hasPaymentStyling}
                                            checked={values.allowDarkMode}
                                            onChange={(event) => handleSave("allowDarkMode", event.target.checked)}
                                            sx={{
                                                '&.MuiCheckbox-root': { color: !hasPaymentStyling ? 'grey' : "#00C1FF" },
                                                '& .MuiSvgIcon-root': { fontSize: 35 }
                                            }}
                                        />
                                    }
                                    label={<Typography fontSize={14} letterSpacing={0} sx={{ ml: 1, color: "#9898A2" }}>Enable Dark Mode &#40;if available&#41;</Typography>} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="primaryColor"
                                    placeholder=''
                                    type="text"
                                    label="Primary Colour"
                                    disabled={!hasPaymentStyling}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Box sx={{
                                                    '&:hover': {
                                                        cursor: "pointer"
                                                    },
                                                    background: values.primaryColor,
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: "5px",
                                                    border: "3px solid #fff",
                                                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)"
                                                }} onClick={() => {
                                                    setIsOpen({
                                                        primaryColor: !isOpen.primaryColor,
                                                        secondaryColor: false
                                                    });
                                                }} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={values.primaryColor}
                                    helperText="Changes the Button, Scroll Bar & Progress Bar"
                                    size="small"
                                    onClick={(e) => {
                                        setIsOpen({
                                            primaryColor: !isOpen.primaryColor,
                                            secondaryColor: false,
                                            anchor: e.currentTarget
                                        });
                                    }} />
                                <ColorPicker disabled={!hasPaymentStyling} open={isOpen.primaryColor}
                                    anchor={isOpen.anchor}
                                    prop="primaryColor" handleSave={handleSave}
                                    handleClose={() => handleClose("primaryColor")} color={values.primaryColor}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="secondaryColor"
                                    placeholder=''
                                    type="text"
                                    label="Secondary Colour"
                                    disabled={!hasPaymentStyling}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Box sx={{
                                                    '&:hover': {
                                                        cursor: "pointer"
                                                    },
                                                    background: values.secondaryColor,
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: "5px",
                                                    border: "3px solid #fff",
                                                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)"
                                                }}
                                                    onClick={() => {
                                                        setIsOpen({
                                                            primaryColor: false,
                                                            secondaryColor: !isOpen.secondaryColor
                                                        });
                                                    }} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={values.secondaryColor}
                                    helperText="Changes the Input fields Selected State & Icon Colour"
                                    size="small"
                                    onClick={(e) => {
                                        setIsOpen({
                                            primaryColor: false,
                                            secondaryColor: !isOpen.secondaryColor,
                                            anchor: e.currentTarget
                                        });
                                    }} />
                                <ColorPicker disabled={!hasPaymentStyling} open={isOpen.secondaryColor}
                                    prop="secondaryColor" handleSave={handleSave}
                                    anchor={isOpen.anchor}
                                    handleClose={() => handleClose("secondaryColor")}
                                    color={values.secondaryColor} onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <FormGroup sx={{ mt: 2 }}>
                        <Typography fontSize={16} fontWeight="bold" color="#404047" sx={{ mb: 3 }}>Header & Footer
                            Colour</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="headerFooterColor"
                                    placeholder=''
                                    type="text"
                                    label="Primary Colour"
                                    disabled={!hasPaymentStyling}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Box sx={{
                                                    '&:hover': {
                                                        cursor: "pointer"
                                                    },
                                                    background: values.headerFooterColor,
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: "5px",
                                                    border: "3px solid #fff",
                                                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)"
                                                }} onClick={() => {
                                                    setIsOpen({
                                                        primaryColor: false,
                                                        secondaryColor: false,
                                                        headerFooterColor: !isOpen.headerFooterColor
                                                    });
                                                }} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={values.headerFooterColor}
                                    helperText="Changes the Header & Footer Colour"
                                    size="small"
                                    onClick={((e) => {
                                        setIsOpen({
                                            primaryColor: false,
                                            secondaryColor: false,
                                            headerFooterColor: !isOpen.headerFooterColor,
                                            anchor: e.currentTarget
                                        });
                                    })} />
                                <ColorPicker disabled={!hasPaymentStyling} open={isOpen.headerFooterColor}
                                    anchor={isOpen.anchor}
                                    prop="headerFooterColor" handleSave={handleSave}
                                    handleClose={() => handleClose("headerFooterColor")}
                                    color={values.headerFooterColor} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </FormGroup>
                    <FormGroup sx={{ mt: 3 }}>
                        <Typography fontSize={16} fontWeight="bold" color="#404047" sx={{ mb: 3 }}>Font
                            Colour</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ColorSwitch disabled={!hasPaymentStyling} label="Changes the Button Colour"
                                    checked={values.primaryFontColorBlack}
                                    onChange={(event) => handleSave("primaryFontColorBlack", event.target.checked)} />
                            </Grid>
                            <Grid item xs={6}>
                                <ColorSwitch disabled={!hasPaymentStyling} label="Changes the Header & Footer Colour"
                                    checked={values.headerFooterFontColorBlack}
                                    onChange={(event) => handleSave("headerFooterFontColorBlack", event.target.checked)} />
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <FormGroup sx={{ mt: 3, pr: 2 }}>
                        <Typography fontSize={16} fontWeight="bold" color="#404047" sx={{ mb: 3 }}>Header
                            Logo</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="displayName"
                                    placeholder=''
                                    type="text"
                                    label="Company name"
                                    inputProps={{
                                        maxLength: 23
                                    }}
                                    sx={{ width: "100%" }}
                                    value={values.displayName}
                                    onChange={(event) => handleSave("displayName", event.target.value)}
                                    size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <Button disabled={!hasPaymentStyling}
                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                    type="button" variant="contained" sx={{
                                        textTransform: "none",
                                        background: "#00C1FF",
                                        width: "100%",
                                        height: "100%",
                                        color: "#FFFFFF"
                                    }} startIcon={<BrokenImage />}>
                                    Profile Logo
                                </Button>
                                <Popover
                                    open={isUploadContainerOpen}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    sx={{
                                        "& .MuiPopover-paper": {
                                            p: 2,
                                            width: "300px",
                                            background: "#FFFFFF",
                                            borderRadius: "5px",
                                            boxShadow: "0px 3px 6px #00000029"
                                        }
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}>
                                    <Box>
                                        <Typography sx={{ mb: 2 }} fontSize={12} letterSpacing={0}><b>File
                                            Name: </b>{image.name ? image.name : "None"}</Typography>
                                        <Box sx={{ height: "200px", position: "relative", mb: 2 }}>
                                            <Box component="img"
                                                src={image.selectedFile ? image.selectedFile : values.logoUrl}
                                                sx={{ height: 150, mb: 2, flexGrow: 1, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} alt="logo" />
                                        </Box>
                                        <Grid container>
                                            <Button onClick={() => setAnchorEl(null)} sx={{
                                                flexGrow: 1,
                                                color: "#0098D8",
                                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                boxShadow: "0px 3px 6px #00000029",
                                                borderRadius: "5px",
                                                mr: 1,
                                                "&:hover": {
                                                    background: "#EBFAFF"
                                                }
                                            }} variant="contained">Close</Button>
                                            <Button component="label" sx={{
                                                flexGrow: 1,
                                                color: "#FFFFFF",
                                                background: "#00C1FF 0% 0% no-repeat padding-box",
                                                boxShadow: "0px 3px 6px #00000029",
                                                borderRadius: "5px",
                                                ml: 1
                                            }} variant="contained">
                                                <input hidden accept="image/*" type="file"
                                                    onChange={(event) => uploadImage(event.target.files)}
                                                />
                                                Upload
                                            </Button>
                                            <ComponentLoader isOpen={isLoading} />
                                        </Grid>
                                    </Box>
                                </Popover>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <FormGroup sx={{ mt: 3 }}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!hasPaymentStyling}
                                        checked={!values.hideHeader}
                                        onChange={(event) => handleSave("hideHeader", !event.target.checked)}
                                        sx={{
                                            '&.MuiCheckbox-root': { color: !hasPaymentStyling ? 'grey' : "#00C1FF" },
                                            '& .MuiSvgIcon-root': { fontSize: 35 }
                                        }}
                                    />
                                }
                                label={<Typography fontSize={14} letterSpacing={0} sx={{ ml: 1, color: "#9898A2" }}>Enable Header</Typography>} />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!hasPaymentStyling || values.hideHeader}
                                            checked={values.hideHeader ? false : !values.useLogoOnly}
                                            onChange={(event) => handleSave("useLogoOnly", !event.target.checked)}
                                            sx={{
                                                '&.MuiCheckbox-root': { color: !hasPaymentStyling || values.hideHeader ? 'grey' : "#00C1FF" },
                                                '& .MuiSvgIcon-root': { fontSize: 35 }
                                            }}
                                        />
                                    }
                                    label={<Typography fontSize={14} letterSpacing={0} sx={{ ml: 1, color: "#9898A2" }}>Enable Logo Text on Header</Typography>} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!hasPaymentStyling || values.hideHeader}
                                            checked={values.hideHeader ? false : !values.useTextOnly}
                                            onChange={(event) => handleSave("useTextOnly", !event.target.checked)}
                                            color="primary"
                                            sx={{
                                                '&.MuiCheckbox-root': { color: !hasPaymentStyling || values.hideHeader ? 'grey' : "#00C1FF" },
                                                '& .MuiSvgIcon-root': { fontSize: 35 }
                                            }}
                                        />
                                    }
                                    label={<Typography fontSize={14} letterSpacing={0} sx={{ ml: 1, color: "#9898A2" }}>Enable Logo Image</Typography>} />
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <Typography fontSize={14} letterSpacing={0} color="#515960" fontWeight="bold" sx={{ mt: 3 }}>Logo
                        Requirements
                    </Typography>
                    <Typography fontSize={12} letterSpacing={0} color="#515960" sx={{ mt: 1 }}>1. Image formats accepted:
                        JPG, JPEG, PNG, SVG and WEBP
                    </Typography>
                    <Typography fontSize={12} letterSpacing={0} color="#515960" sx={{ mt: 1 }}>2. Image Size should not
                        exceed 2mb
                    </Typography>
                    <FormGroup sx={{ mt: 3, pr: 2 }}>
                        <Typography fontSize={16} fontWeight="bold" color="#404047" sx={{ mb: 2 }}>3DS Rendering</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!hasPaymentStyling}
                                            checked={values.isThreeDSecureEmbedded}
                                            onChange={(event) => handleSave("isThreeDSecureEmbedded", event.target.checked)}
                                            sx={{
                                                '&.MuiCheckbox-root': { color: "#00C1FF" },
                                                '& .MuiSvgIcon-root': { fontSize: 35 }
                                            }}
                                        />
                                    }
                                    label={<Typography fontSize={14} letterSpacing={0} sx={{ ml: 1, color: "#9898A2" }}>Embed 3DS page</Typography>} />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Live
                                    Preview</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Preview desktop or
                                    mobile hosted payment pages before saving changes to the live version.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Card sx={{
                        p: 3,
                        mt: 3,
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 10px #00000029",
                        borderRadius: "10px"
                    }}>
                        <Grid container alignItems="center" justifyContent="center">
                            <Button startIcon={<PhoneIphone />} sx={{
                                textTransform: "none",
                                width: 150,
                                mr: 2,
                                background: isDesktop ? "#FFFFFF" : "#0098D8",
                                color: isDesktop ? "#0098D8" : "#FFFFFF"
                            }} variant="contained" onClick={() => setIsDesktop(false)}>Preview</Button>
                            <Button startIcon={<DesktopWindows />}
                                sx={{ textTransform: "none", width: 150, background: !isDesktop ? "#FFFFFF" : "#0098D8", color: !isDesktop ? "#0098D8" : "#FFFFFF" }}
                                variant="contained" onClick={() => setIsDesktop(true)}>Preview</Button>
                        </Grid>
                        <Grid sx={{ mt: 3 }} display={!isDesktop ? "flex" : "block"} className="iframe-container"
                            justifyContent="center">
                            <Grid
                                className={isDesktop ? "iframe-first-container-desktop" : "iframe-first-container-mobile"}>
                                <Grid container className="iframe-top-container">
                                    <Grid className="iframe-speaker"></Grid>
                                    <Grid className="iframe-camera"></Grid>

                                </Grid>
                                <Box component="iframe" src={url}
                                    className={isDesktop ? "iframe-desktop" : "iframe-mobile"} title="preview" />
                            </Grid>
                            {isDesktop &&
                                <Grid container direction="column" alignItems="center">
                                    <Grid className="iframe-bottom-stand-container"></Grid>
                                    <Grid className="iframe-bottom-stand-foot-container"></Grid>
                                </Grid>
                            }
                        </Grid>
                    </Card>
                    <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2 }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ ml: 3, width: "215px", color: "#404047" }}>
                            Please ensure all information is correct before saving
                        </Typography>
                        <Button type="button" onClick={saveConfig} variant="contained" sx={{
                            textTransform: "none",
                            background: "#00C1FF",
                            width: "200px",
                            height: "100%",
                            color: "#FFFFFF"
                        }} startIcon={<SaveOutlined />}>
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen}
                autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity ? response.severity : ""} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default BrandingConfig;