import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const CustomTextField = styled(TextField)(({ theme }) => ({
    'label + &': {
        color: "#9898A2"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "1px solid #9898A2",
            borderRadius: "5px",
            boxShadow: "0px 3px 6px #00000029"
        },
        '&:hover fieldset': {
            
        },
        '&.Mui-focused fieldset': {
            
        },
    }, 
    '& :-webkit-autofill': {
        WebkitBoxShadow: "0 0 0 1000px white inset"
    },
}));