import React, { useEffect, useState, useCallback } from 'react';
import { Card, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { Box, color } from "@mui/system";
import { Info } from "@mui/icons-material";
import { fetchProfileAllTransactions, updateProfileAllTransactions } from "../../../stores/profileAllTransactions";
import { TransactionStatus } from "../../../constants/transactionStatus";
import Paginator from '../../../components/paginator/paginator';
import { getFormattedAmount, numberWithCommas } from '../../../utils/amountFormatter';
import { capitalizeFirstLetters } from '../../../utils/stringFormatter'
import CardTransactionMoreInfo from '../../../components/transaction-more-info/more-info/cardTransactionMoreInfo';
import RcsTransactionMoreInfo from '../../../components/transaction-more-info/more-info/rcsTransactionMoreInfo';
import { PaymentType } from '../../../constants/paymentType';
import TransactionMoreInfoOzow from '../../../components/transaction-more-info/more-info/transactionMoreInfoOzow';
import TransactionMoreInfo from '../../../components/transaction-more-info/transactionMoreInfo';
import PageRefresh from '../../../components/page-refresh/page-refresh';
import OverflowTip from '../../../components/overflow-tooltip/overflowTooltip';
import { Tooltip } from '@mui/material';

function TransactionTable(props) {

    const dispatch = useDispatch();
    const { transactions } = useSelector((state) => state.transaction);
    const [status, setStatus] = useState("");

    const changeSort = (sortValues) => {
        if (sortValues.length > 0) {
            props.setRequest({
                ...props.request,
                sortBy: sortValues[0].field,
                sortOrder: sortValues[0].sort
            })
        } else {
            props.setRequest({
                ...props.request,
                sortBy: "createdAt",
                sortOrder: "desc",
            })
        }
    }

    useEffect(() => {
        props.setIsLoading(false);
    }, [transactions])

    useEffect(() => {
        if (props.request?.profileIds && props.request?.profileIds.length > 0) {
            fetchAllTransaction()
        } else {
            dispatch(updateProfileAllTransactions([]))
        }
    }, [props.request, dispatch])

    const fetchAllTransaction = useCallback(() => {
        if (props.request?.profileIds && props.request?.profileIds?.length > 0) {
            let transactionStatuses = [];
            props.request?.transactionStatuses?.forEach(transactionStatus => {
                let index = Object.values(TransactionStatus).indexOf(transactionStatus);
                if (Object.keys(TransactionStatus)[index] === "_3DS_INITIATED") {
                    transactionStatuses.push("3DS_INITIATED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_AUTHORISED") {
                    transactionStatuses.push("3DS_AUTHORISED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_FAILED") {
                    transactionStatuses.push("3DS_FAILED")
                } else if (Object.keys(TransactionStatus)[index] === "_3DS_ABANDONED") {
                    transactionStatuses.push("3DS_ABANDONED")
                } else {
                    transactionStatuses.push(Object.keys(TransactionStatus)[index])
                }
            })
            props.setIsLoading(true);
            console.log(moment(new Date).format("YYYY-MM-DDTHH:mm:ss.SSS") + ": Fetching all transaction");
            dispatch(fetchProfileAllTransactions({
                ...props.request,
                transactionStatuses: transactionStatuses
            }))
        }
    })

    const handleTableUpdate = (status) => {
        console.log(status);
        setMoreInfoRequest({
            ...moreInfoRequest,
            transactionStatus: status
        })

        const newTransactions = transactions?.content.map(transaction => {
            if (transaction.transactionId === moreInfoRequest.transactionId) {
                console.log({ ...transaction, transactionStatus: status });
                return { ...transaction, transactionStatus: { name: status } };
            }
            return transaction;
        });

        const newTransactionsPaginated = {
            ...transactions,
            content: newTransactions
        }

        dispatch(updateProfileAllTransactions(newTransactionsPaginated));
    }

    const [moreInfoRequest, setMoreInfoRequest] = useState({
        isOpen: false,
        transactionId: "",
        symbol: "",
        amount: 0,
        transactionStatus: "",
        displayName: "",
        createdAt: "",
        lastModified: "",
        transactionMoreDetails: null,
        transactionCardDetails: null,
        merchantRef: "",
        currencyCode: "ZAR",
        selectedprofile: "",
        merchantId: "",
        paymentType: "Card"
    });

    const handleMoreInfoBackClick = () => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            isOpen: false
        })
    }

    const openMoreInfo = (row) => {
        var merchantId = props.request?.profileIds?.find(profile => profile.profileId === row.profileId)?.merchantId;
        setMoreInfoRequest({
            isOpen: true,
            transactionId: row.transactionId,
            symbol: row.currency.symbol,
            amount: row.amount,
            transactionStatus: row.transactionStatus.name,
            displayName: row.displayName,
            createdAt: row.createdAt,
            lastModified: row.lastModified,
            transactionMoreDetails: row.transactionMoreDetails,
            transactionCardDetails: row.cardDetails,
            merchantRef: row.merchantRef,
            currencyCode: row.currency.isoCode,
            selectedprofile: row.profileId,
            merchantId: merchantId,
            paymentType: row.paymentType
        })
    }

    const handleChangePage = (newPage) => {
        props.setRequest({
            ...props.request,
            page: newPage
        })
    };

    const handleChangeRowsPerPage = (value) => {
        props.setRequest({
            ...props.request,
            limit: value
        })
    };

    return (
        <Card>
            <DataGrid
                loading={props.isLoading}
                sx={{
                    height: "calc(100vh - 342px)",
                    "& .MuiDataGrid-columnHeadersInner": {
                        background: "#EBFAFF",
                        fontSize: 12
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        boxShadow: "0px 0px 10px #00000029",
                        display: "block"
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        overflowY: "overlay"
                    },
                    "& .amount-cell": {
                        fontWeight: "bold"
                    },
                    "& .cell-font": {
                        fontSize: 12
                    },
                    "& .status-cell": {
                        fontSize: 12,
                    }
                }}
                getRowId={(row) => row.transactionId}
                rows={transactions?.content ? transactions?.content : []}
                columns={[
                    {
                        align: "center", headerAlign: "center", field: 'createdAt', headerName: "Date", flex: 1, cellClassName: 'cell-font', valueFormatter: params => moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} text={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} />
                            )
                        }
                    },
                    {
                        align: "center", headerAlign: "center", field: 'displayName', headerName: "Merchant Profile", flex: 1, cellClassName: 'cell-font',
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={params?.value} text={params?.value} />
                            )
                        }
                    },
                    { align: "center", headerAlign: "center", field: 'paymentType', headerName: "Payment Method", flex: 1, cellClassName: 'cell-font', valueFormatter: params => params?.value ? PaymentType[params?.value] : "Unselected" },
                    {
                        align: "center", headerAlign: "center", field: 'merchantRef', headerName: "Merchant Ref", flex: 1, cellClassName: 'cell-font',
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={params?.value} text={params?.value} />
                            )
                        }
                    },
                    {
                        align: "center", headerAlign: "center", field: 'transactionId', headerName: "Transaction ID", flex: 1, cellClassName: 'cell-font',
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={params?.value} text={params?.value} />
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'transactionStatus', headerName: 'Status', width: 200, cellClassName: 'status-cell',
                        renderCell: (params) => {
                            return (
                                <>
                                    {(params.row.transactionStatus.name === "PAYMENT_REFUNDED" || params.row.transactionStatus.name === "PAYMENT_PARTIALLY_REFUNDED") &&
                                        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <Tooltip
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    opacity: "0%",
                                                }}
                                                align="center"
                                                title={
                                                    <div>
                                                        <Typography
                                                            sx={{ fontSize: 12 }}
                                                            style={{ color: "white" }}
                                                        >
                                                            Refunded Amount
                                                        </Typography>
                                                        <Typography
                                                            sx={{ fontSize: 12 }}
                                                            style={{
                                                                textAlign: "center",
                                                                marginTop: "3px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            R{((params.row.amount - params.row.expectedAmount)).toFixed(2)}
                                                        </Typography>
                                                    </div>
                                                }
                                                arrow
                                            >
                                                <Typography sx={{
                                                    flexGrow: 1, textAlign: "center", fontSize: 12, color: (params.row.transactionStatus.name === "PAYMENT_AUTHORISED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_INITIATED" ? "blue" :
                                                        params.row.transactionStatus.name === "PAYMENT_SETTLED" ? "green" : params.row.transactionStatus.name === "3DS_INITIATED" ? "blue" : params.row.transactionStatus.name === "3DS_AUTHORISED" ? "blue" :
                                                            params.row.transactionStatus.name === "3DS_FAILED" ? "red" : params.row.transactionStatus.name === "PAYMENT_CANCELLED" ? "red" : params.row.transactionStatus.name === "3DS_ABANDONED" ? "red" : params.row.transactionStatus.name === "PAYMENT_FAILED" ? "red" :
                                                                params.row.transactionStatus.name === "3DS_ABANDONED" ? "red" :
                                                                    params.row.transactionStatus.name === "PAYMENT_REVERSED" ? "orange" : params.row.transactionStatus.name === "PAYMENT_REFUNDED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_DECLINED" ? "red" :
                                                                        params.row.transactionStatus.name === "PAYMENT_PARTIALLY_REFUNDED" || params.row.transactionStatus.name === "PAYMENT_TIMED_OUT" ? "blue" : params.row.transactionStatus.name === "PAYMENT_COMPLETED" ? "green" : params.row.transactionStatus.name === "PAYMENT_PENDING" ? "blue" : params.row.transactionStatus.name === "OZOW_INITIATED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_PENDING_INVESTIGATION" ? "blue" : "black")
                                                }}>
                                                    {params.row.transactionStatus.name === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] : params.row.transactionStatus.name === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] : params.row.transactionStatus.name === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] : params.row.transactionStatus.name === "3DS_ABANDONED" ? TransactionStatus["_3DS_ABANDONED"] : TransactionStatus[params.row.transactionStatus.name]}
                                                </Typography>
                                            </Tooltip>
                                        </Box>

                                    }
                                    {params.row.transactionStatus.name !== "PAYMENT_REFUNDED" && params.row.transactionStatus.name !== "PAYMENT_PARTIALLY_REFUNDED" &&
                                        <Typography sx={{
                                            flexGrow: 1, textAlign: "center", fontSize: 12, color: (params.row.transactionStatus.name === "PAYMENT_AUTHORISED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_INITIATED" ? "blue" :
                                                params.row.transactionStatus.name === "PAYMENT_SETTLED" ? "green" : params.row.transactionStatus.name === "3DS_INITIATED" ? "blue" : params.row.transactionStatus.name === "3DS_AUTHORISED" ? "blue" :
                                                    params.row.transactionStatus.name === "3DS_FAILED" ? "red" : params.row.transactionStatus.name === "PAYMENT_CANCELLED" ? "red" : params.row.transactionStatus.name === "3DS_ABANDONED" ? "red" : params.row.transactionStatus.name === "PAYMENT_FAILED" ? "red" :
                                                        params.row.transactionStatus.name === "3DS_ABANDONED" ? "red" :
                                                            params.row.transactionStatus.name === "PAYMENT_REVERSED" ? "orange" : params.row.transactionStatus.name === "PAYMENT_REFUNDED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_DECLINED" ? "red" :
                                                                params.row.transactionStatus.name === "PAYMENT_PARTIALLY_REFUNDED" || params.row.transactionStatus.name === "PAYMENT_TIMED_OUT" ? "blue" : params.row.transactionStatus.name === "PAYMENT_COMPLETED" ? "green" : params.row.transactionStatus.name === "PAYMENT_PENDING" ? "blue" : params.row.transactionStatus.name === "OZOW_INITIATED" ? "blue" : params.row.transactionStatus.name === "PAYMENT_PENDING_INVESTIGATION" ? "blue" : "black")
                                        }}>
                                            {params.row.transactionStatus.name === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] : params.row.transactionStatus.name === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] : params.row.transactionStatus.name === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] : params.row.transactionStatus.name === "3DS_ABANDONED" ? TransactionStatus["_3DS_ABANDONED"] : TransactionStatus[params.row.transactionStatus.name]}
                                        </Typography>
                                    }
                                </>
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'amount', headerName: "Amount", width: 200, cellClassName: 'amount-cell',
                        renderCell: (params) => {
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px", fontSize: 12 }} fontWeight="bold">
                                        {params.row.currency.symbol}{numberWithCommas(getFormattedAmount(params.row.amount))}
                                    </Typography>
                                </Box>
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'expectedBankAmount', headerName: "Expected bank amount", width: 200, cellClassName: 'amount-cell',
                        renderCell: (params) => {
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px", fontSize: 12 }} fontWeight="bold">
                                        {params.row.currency.symbol}{numberWithCommas(getFormattedAmount( params.row.expectedAmount))}
                                    </Typography>
                                    <IconButton disabled={params.row.transactionStatus.name === "PAYMENT_INITIATED" || params.row.transactionStatus.name === "PAYMENT_CANCELLED" || params.row.transactionStatus.name === "PAYMENT_TIMED_OUT" || params.row.transactionStatus.name === "OZOW_INITIATED" || params.row.transactionStatus.name === "3DS_ABANDONED"} sx={{ color: "#034EA2" }} onClick={() => openMoreInfo(params?.row)}>
                                        <Info />
                                    </IconButton>
                                </Box>
                            )
                        }
                    }
                ]}
                rowsPerPageOptions={[5, 10, 25]}
                pageSize={props.request.limit}
                rowCount={transactions?.totalElements ? transactions.totalElements : 0}
                page={props.request.page}
                pagination
                paginationMode="server"
                components={{ Footer: PageRefresh }}
                componentsProps={{
                    footer: {
                        refreshFunction: fetchAllTransaction
                    },
                    pagination: {
                        ActionsComponent: Paginator
                    }
                }}
                onPageSizeChange={(newLimit) => handleChangeRowsPerPage(newLimit)}
                onPageChange={(newPage) => handleChangePage(newPage)}
                onSortModelChange={changeSort}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
            />
            <TransactionMoreInfo
                handleMoreInfoBackClick={handleMoreInfoBackClick}
                moreInfoRequest={moreInfoRequest}
                handleTableUpdate={handleTableUpdate}
                setMoreInfoRequest={setMoreInfoRequest} />
        </Card>
    )
}

TransactionTable.propTypes = {
    profiles: PropTypes.array.isRequired,
    request: PropTypes.object.isRequired,
    setRequest: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
};

export default TransactionTable;