import { Backdrop, Button, Grid, Typography } from "@mui/material";
import { CheckCircleOutline, HighlightOff, PendingOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import "../../styles.scss";

export default function ConfirmationBackdropLogTicket(props) {
  return (
    <Box>
      <Backdrop
        sx={{
          color: "#FFFFFF",
          zIndex: (theme) => theme.zIndex.drawer + 2,
          backdropFilter: "blur(40px)",
          opacity: 1,
          background: "inherit",
        }}
        open={props.isOpen}
      >
        <Grid
          container
          direction="column"
          width="433px"
          sx={{
            boxShadow: "0px 8px 19px #00000029",
            background: "#FFFFFF",
            borderRadius: "5px",
          }}
        >
          <Grid display="flex" justifyContent="center" sx={{ mb: 1, mt: 4 }}>
            {props.isLoading ? <PendingOutlined sx={{ fontSize: "44px", color: "#0098D8" }} />:(props.isSuccessfull ? (
              <CheckCircleOutline sx={{ fontSize: "44px", color: "#0098D8" }} />
            ) : (
              <HighlightOff sx={{ fontSize: "44px", color: "#DF372D" }} />
            ))}
          </Grid>
          <Grid>
            <Typography
              color="#404047"
              fontSize={16}
              fontWeight="bold"
              textAlign="center"
            >
              {props.isLoading ? "Loading":(props.isSuccessfull ? "Ticket Submitted Successfully"
                : "The ticket has failed to submit.")}
            </Typography>
          </Grid>{props.isLoading ? null:(
            props.isSuccessfull ? (
              <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                <Button
                  onClick={props.handleCancel}
                  variant="contained"
                  sx={{
                    "&:hover": { background: "#EBFAFF" },
                    boxShadow: "0px 3px 6px #00000029",
                    textTransform: "none",
                    background: "#FFFFFF",
                    color: "#0098D8",
                    borderRadius: "5px",
                    width: "180px",
                    mr: 1,
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            ) : (
              <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                <Button
                  onClick={props.handleCancel}
                  variant="contained"
                  sx={{
                    "&:hover": { background: "#EBFAFF" },
                    boxShadow: "0px 3px 6px #00000029",
                    textTransform: "none",
                    background: "#FFFFFF",
                    color: "#0098D8",
                    borderRadius: "5px",
                    width: "180px",
                    mr: 1,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            ))}
        </Grid>
      </Backdrop>
    </Box>
  );
}

ConfirmationBackdropLogTicket.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isSuccessfull: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};
