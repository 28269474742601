import * as React from "react";
import Box from "@mui/system/Box";
import Info from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TransactionStatus } from "../../../../constants/transactionStatus";
import Paginator from "../../../../components/paginator/paginator";
import { getFormattedAmount, numberWithCommas } from "../../../../utils/amountFormatter";
import { fetchRcsTransactions, updateRcsTransactions } from "../../../../stores/rcsTransactions";
import RcsTransactionMoreInfo from "../../../../components/transaction-more-info/more-info/rcsTransactionMoreInfo";
import PageRefresh from "../../../../components/page-refresh/page-refresh";
import OverflowTip from "../../../../components/overflow-tooltip/overflowTooltip";
import { Tooltip } from "@mui/material";

function RcsTransactions(props) {

    const dispatch = useDispatch();

    const { rcsTransactions } = useSelector(state => state.rcsTransactions);

    const changeSort = (sortValues) => {
        if (sortValues.length > 0) {
            props.setRequest({
                ...props.request,
                sortBy: sortValues[0].field,
                sortOrder: sortValues[0].sort
            })
        } else {
            props.setRequest({
                ...props.request,
                sortBy: "createdAt",
                sortOrder: "desc",
            })
        }
    }

    useEffect(() => {
        if (props.request?.profileIds && props.request?.profileIds.length > 0) {
            fetchAllTransaction();
        } else {
            dispatch(updateRcsTransactions([]));
        }
    }, [props.request, dispatch])

    const fetchAllTransaction = useCallback(() => {
        if (props.request?.profileIds && props.request?.profileIds?.length > 0) {
            props.setIsLoading(true);
            console.log(moment(new Date).format("YYYY-MM-DDTHH:mm:ss.SSS") + ": Fetching RCS transaction");
            dispatch(fetchRcsTransactions(props.request))
        }
    })

    const [moreInfoRequest, setMoreInfoRequest] = useState({
        isOpen: false,
        transactionId: "",
        symbol: "",
        amount: 0,
        transactionStatus: "",
        displayName: "",
        createdAt: "",
        lastModified: "",
        transactionMoreDetails: null,
        merchantRef: "",
        pan: "",
        nameOnCard: "",
        currencyCode: "ZAR",
        selectedprofile: "",
        merchantId: ""
    });

    const handleMoreInfoBackClick = () => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            isOpen: false
        })
    }

    const openMoreInfo = (row) => {

        setMoreInfoRequest({
            isOpen: true,
            transactionId: row?.transactionId,
            symbol:  row?.currencySymbol??"R",
            amount: row?.amount ? row?.amount : 0,
            transactionStatus: row?.transactionStatus,
            displayName: row?.displayName,
            createdAt: row?.createdAt,
            lastModified: row?.lastModified,
            transactionMoreDetails: row?.transactionMoreDetails,
            merchantRef: row?.merchantRef,
            currencyCode:  row?.currencyCode??"ZAR",
            selectedprofile: row?.profileId,
            merchantId: row?.merchantId
        })
    }

    const handleChangePage = (newPage) => {
        props.setRequest({
            ...props.request,
            page: newPage
        })
    };

    const handleChangeRowsPerPage = (value) => {
        props.setRequest({
            ...props.request,
            limit: value
        })
    };

    useEffect(() => {
        props.setIsLoading(false);
    }, [rcsTransactions])


    const handleTableUpdate = (status) => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            transactionStatus: status
        })

        const newTransactions = rcsTransactions?.content.map(transaction => {
            if (transaction.transactionId === moreInfoRequest.transactionId) {
                console.log({ ...transaction, transactionStatus: status });
                return { ...transaction, transactionStatus: status };
            }
            return transaction;
        });

        const newTransactionsPaginated = {
            ...rcsTransactions,
            content: newTransactions
        }

        dispatch(updateRcsTransactions(newTransactionsPaginated));
    }

    return (
        <Card>
            <DataGrid
                loading={props.isLoading}
                sx={{
                    height: "calc(100vh - 342px)",
                    "& .MuiDataGrid-columnHeadersInner": {
                        background: "#EBFAFF",
                        fontSize: 12
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        boxShadow: "0px 0px 10px #00000029",
                        display: "block"
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        overflowY: "overlay"
                    },
                    "& .amount-cell": {
                        fontWeight: "bold"
                    },
                    "& .cell-font": {
                        fontSize: 12
                    },
                    "& .cardstatus-cell": {
                        fontSize: 12
                    }
                }}
                getRowId={(row) => row?.transactionId}
                rows={rcsTransactions?.content ? rcsTransactions?.content : []}
                columns={[
                    {
                        align: "center", headerAlign: "center", field: 'createdAt', headerName: "Date", flex: 1, cellClassName: 'cell-font', valueFormatter: params => moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} text={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} />
                            )
                        }
                    },
                    {
                        align: "center", headerAlign: "center", field: 'merchantRef', headerName: "Merchant Ref", flex: 1, cellClassName: 'cell-font',
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={params?.value} text={params?.value} />
                            )
                        }
                    },
                    {
                        align: "center", headerAlign: "center", field: 'cardNumber', valueGetter: (params) => {
                            return params.row.cardNumber;
                        }, headerName: "Card Number", flex: 1, cellClassName: 'cell-font'
                    },
                    {
                        align: "center", headerAlign: "center", field: 'transactionId', headerName: "Transaction ID", flex: 1, cellClassName: 'cell-font',
                        renderCell: (params) => {
                            return (
                                <OverflowTip value={params?.value} text={params?.value} />
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'transactionStatus', headerName: 'Status', width: 200, cellClassName: 'cardstatus-cell',
                        renderCell: (params) => {
                            return (
                                <>
                                    {(params.row.transactionStatus === "PAYMENT_REFUNDED" || params.row.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED") &&
                                        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <Tooltip
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    opacity: "0%",
                                                }}
                                                align="center"
                                                title={
                                                    <div>
                                                        <Typography
                                                            sx={{ fontSize: 12 }}
                                                            style={{ color: "white" }}
                                                        >
                                                            Refunded Amount
                                                        </Typography>
                                                        <Typography
                                                            sx={{ fontSize: 12 }}
                                                            style={{
                                                                textAlign: "center",
                                                                marginTop: "3px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {params?.row?.currencySymbol??"R"}{((params.row.amount - params.row.expectedAmount)).toFixed(2)}
                                                        </Typography>
                                                    </div>
                                                }
                                                arrow
                                            >
                                                <Typography sx={{
                                                    flexGrow: 1, textAlign: "center", fontSize: 12, color: (params.row.transactionStatus === "PAYMENT_AUTHORISED" ? "blue" : params.row.transactionStatus === "PAYMENT_INITIATED" ? "blue" :
                                                        params.row.transactionStatus === "PAYMENT_SETTLED" ? "green" : params.row.transactionStatus === "3DS_INITIATED" ? "blue" : params.row.transactionStatus === "3DS_AUTHORISED" ? "blue" :
                                                            params.row.transactionStatus === "3DS_FAILED" ? "red" : params.row.transactionStatus === "PAYMENT_CANCELLED" ? "red" : params.row.transactionStatus === "PAYMENT_FAILED" ? "red" :
                                                                params.row.transactionStatus === "PAYMENT_REVERSED" ? "orange" : params.row.transactionStatus === "PAYMENT_REFUNDED" ? "blue" : params.row.transactionStatus === "PAYMENT_DECLINED" ? "red" : params.row.transactionStatus === "PAYMENT_COMPLETED" ? "green" : params.row.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED" ? "blue" : "black")
                                                }}>
                                                    {params.row.transactionStatus === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] : params.row.transactionStatus === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] : params.row.transactionStatus === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] : TransactionStatus[params.row.transactionStatus]}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    }
                                    {params.row.transactionStatus !== "PAYMENT_REFUNDED" && params.row.transactionStatus !== "PAYMENT_PARTIALLY_REFUNDED" &&
                                        <Typography sx={{
                                            flexGrow: 1, textAlign: "center", fontSize: 12, color: (params.row.transactionStatus === "PAYMENT_AUTHORISED" ? "blue" : params.row.transactionStatus === "PAYMENT_INITIATED" ? "blue" :
                                                params.row.transactionStatus === "PAYMENT_SETTLED" ? "green" : params.row.transactionStatus === "3DS_INITIATED" ? "blue" : params.row.transactionStatus === "3DS_AUTHORISED" ? "blue" :
                                                    params.row.transactionStatus === "3DS_FAILED" ? "red" : params.row.transactionStatus === "PAYMENT_CANCELLED" ? "red" : params.row.transactionStatus === "PAYMENT_FAILED" ? "red" :
                                                        params.row.transactionStatus === "PAYMENT_REVERSED" ? "orange" : params.row.transactionStatus === "PAYMENT_REFUNDED" ? "blue" : params.row.transactionStatus === "PAYMENT_DECLINED" ? "red" : params.row.transactionStatus === "PAYMENT_COMPLETED" ? "green" : params.row.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED" ? "blue" : "black")
                                        }}>
                                            {params.row.transactionStatus === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] : params.row.transactionStatus === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] : params.row.transactionStatus === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] : TransactionStatus[params.row.transactionStatus]}
                                        </Typography>
                                    }
                                </>
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'amount', headerName: "Amount", flex: 1, cellClassName: 'amount-cell',
                        renderCell: (params) => {
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", fontSize: 12 }} fontWeight="bold">
                                        {params?.row?.currencySymbol??"R"}{params.row.amount ? numberWithCommas(getFormattedAmount(params.row.amount)) : 0}
                                    </Typography>
                                </Box>
                            )
                        }
                    },
                    {
                        headerAlign: "center", field: 'expectedAmount', headerName: "Expected Banked Amount", flex: 1, cellClassName: 'amount-cell',
                        renderCell: (params) => {
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px", fontSize: 12 }} fontWeight="bold">
                                        {params?.row?.currencySymbol??"R"}{params.row.expectedAmount ? numberWithCommas(getFormattedAmount(params.row.expectedAmount)) : 0}
                                    </Typography>
                                    <IconButton disabled={params.row.transactionStatus === "PAYMENT_INITIATED"} sx={{ color: "#034EA2" }} onClick={() => openMoreInfo(params.row)}>
                                       <Info/>
                                    </IconButton>
                                </Box>
                            )
                        }
                    }
                ]}
                rowsPerPageOptions={[5, 10, 25]}
                pageSize={props.request.limit}
                rowCount={rcsTransactions?.totalElements ? rcsTransactions.totalElements : 0}
                page={props.request.page}
                pagination
                paginationMode="server"
                components={{ Footer: PageRefresh }}
                componentsProps={{
                    footer: {
                        refreshFunction: fetchAllTransaction
                    },
                    pagination: {
                        ActionsComponent: Paginator
                    }
                }}
                onPageSizeChange={(newLimit) => handleChangeRowsPerPage(newLimit)}
                onPageChange={(newPage) => handleChangePage(newPage)}
                onSortModelChange={changeSort}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
            />
            <RcsTransactionMoreInfo
                isOpen={moreInfoRequest.isOpen}
                transactionId={moreInfoRequest.transactionId}
                handleBack={handleMoreInfoBackClick}
                displayName={moreInfoRequest.displayName}
                symbol={moreInfoRequest.symbol}
                amount={moreInfoRequest.amount}
                createdAt={moreInfoRequest.createdAt}
                lastModified={moreInfoRequest.lastModified}
                transactionStatus={
                    TransactionStatus[moreInfoRequest.transactionStatus] ? TransactionStatus[moreInfoRequest.transactionStatus] : ""}
                transactionMoreDetails={moreInfoRequest.transactionMoreDetails}
                profileId={moreInfoRequest.selectedprofile ? moreInfoRequest.selectedprofile : ""}
                merchantRef={moreInfoRequest.merchantRef}
                currencyCode={moreInfoRequest.currencyCode}
                merchantId={moreInfoRequest.merchantId ? moreInfoRequest.merchantId : ""}
                handleTableUpdate={handleTableUpdate}
                setMoreInfoRequest={setMoreInfoRequest} />
        </Card>
    )
}

RcsTransactions.propType = {
    profiles: PropTypes.array.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    merchantRef: PropTypes.array.isRequired,
    handleChange: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    selectedRange: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
}

export default RcsTransactions;