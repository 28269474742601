import { createSlice } from "@reduxjs/toolkit";
import { getMerchantProfiles } from "../services/profileServices";

const slice = createSlice({
    name: 'merchantProfiles',
    initialState: {
        merchantProfiles: []
    },
    reducers: {
        success: (state, action) => {
            state.merchantProfiles = action.payload;
            state.isLoading = false;
        },
        notFound: (state, action) => {
            state.merchantProfiles = action.payload;
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

const { success, notFound } = slice.actions;

export const fetchMerchantProfiles = (request) => async dispatch => {
    try {
        await getMerchantProfiles(request)
            .then((response) => dispatch(success(response?.data)));
    } catch(e) {
        dispatch(notFound([]));
    }
}

export const updateMerchantProfiles = (profiles) => async dispatch => {
    dispatch(success(profiles));
}