import { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import PropTypes from 'prop-types';
import CustomDateBulk from '../custom-date-range-picker/customDateRangeBulk';
import Popover from '@mui/material/Popover';
import { CustomAutoComplete } from '../custom-autocomplete/customAutoComplete';
import { BulkTransactionCardStatus } from '../../constants/bulkTransactionCardStatus';
import number2 from '../../assets/images/2.svg';

function BulkFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [action, setAction] = useState('');
  const [values, setValues] = useState({
    fromDate: '',
    toDate: '',
    transactionStatuses: [],
    merchantRef: '',
    acquiringBank: '',
    scheme: '',
    transactionId: '',
    pan: '',
  });

  const [openFilter, setOpenFilter] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  useEffect(() => {
    if (props.request) {
      setValues({
        ...values,
        fromDate: moment(props.request.fromDate),
        toDate: moment(props.request.toDate),
        // transactionStatuses: props.request.transactionStatuses
        //   ? props.request.transactionStatuses
        //   : [],
        merchantRef: props.request.merchantRef,
        scheme: props.request.scheme,
        acquiringBank: props.request.acquiringBank,
        pan: props.request.pan,
      });
    }
  }, [props.request]);

  useEffect(() => {
    if (values.transactionStatuses.length > 0) {
      handleApply();
    }
  }, [values.transactionStatuses]);

  const handleDateSelectionChange = (newRange) => {
    setValues({
      ...values,
      fromDate: moment(newRange.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment(newRange.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });
  };

  const handleApply = (transactionStatus) => {
    props.setRequest({
      ...props.request,
      transactionStatuses: transactionStatus
        ? transactionStatus
        : values.transactionStatuses,
    });
    props.setFilterApplied(true);
    handleClose();
  };

  const handleCalendarApply = () => {
    props.setRequest({
      ...props.request,
      fromDate: moment(values.fromDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment(values.toDate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });
    props.setFilterApplied(true);
    handleClose();
  };

  const handleClear = () => {
    props.setRequest({
      ...props.request,
      transactionStatuses: [],
      fromDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });

    props.setFilterApplied(false);
    handleClose();
  };

  const handleStatusChange = (event, newValue) => {
    setAction(newValue[0].action);
    setValues({
      ...values,
      transactionStatuses: [newValue[0].value],
    });
    props.setRequest({
      ...props.request,
      action: newValue[0].action,
    });
  };

  return (
    <Box style={{ display: 'flex' }}>
      <div style={{ paddingRight: '10%' }}>
        <Tooltip title="Filter">
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              height: 40,
              color: 'white',
              boxShadow: 2,
              width: '150px',
            }}
            onClick={openFilter ? handleClose : handleOpen}
          >
            Select Date
          </Button>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ zIndex: 999 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableEnforceFocus={true}
        >
          <Box
            sx={{
              background: '#FFFFFF',
              p: 3,
              maxWidth: '750px',
              minWidth: '500px',
            }}
          >
            <Stack spacing={3}>
              <CustomDateBulk
                selectedRange={{
                  startDate: values.fromDate,
                  endDate: values.toDate,
                }}
                setSelectedRange={handleDateSelectionChange}
              />

              <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
                <Button
                  onClick={handleClear}
                  variant="contained"
                  sx={{
                    '&:hover': { background: '#EBFAFF' },
                    boxShadow: '0px 3px 6px #00000029',
                    textTransform: 'none',
                    background: '#FFFFFF',
                    color: '#0098D8',
                    borderRadius: '5px',
                    width: '160px',
                    mr: 1,
                  }}
                >
                  Clear Filter
                </Button>
                <Button
                  type="button"
                  onClick={handleCalendarApply}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    background: '#00C1FF',
                    width: '160px',
                    height: '100%',
                    color: '#FFFFFF',
                    mr: 1,
                  }}
                >
                  Apply Filter
                </Button>
              </Grid>
            </Stack>
          </Box>
        </Popover>
      </div>
      <img src={number2} />
      <div style={{ paddingLeft: '20px', display: 'flex' }}>
        <CustomAutoComplete
          sx={{ width: '220px' }}
          size="small"
          options={BulkTransactionCardStatus}
          onChange={(event, newValue) => {
            let transfromedValue = newValue ? [newValue] : [];
            handleStatusChange(event, transfromedValue);
          }}
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label="Bulk Action" />
          )}
        />
      </div>
    </Box>
  );
}

BulkFilter.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  setFilterApplied: PropTypes.func.isRequired,
};

export default BulkFilter;
