import {Fragment, useState} from "react";
import { Backdrop, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import ComponentLoader from "../../components/component-loader/componentLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CustomTextField } from '../custom-text-field/customTextField';
import Button from "@mui/material/Button/Button";
import PaymentLinkCreatedDialog from "../../components/paymentLink-Create/PaymentLinkCreatedDialog";
import { createPaymentLink } from "../../services/paymentLinkService";
import { CustomAutoComplete } from "../custom-autocomplete/customAutoComplete";
import TextField from '@mui/material/TextField';
import CustomTextMask from "../mask/customTextMask";
import { Alert, Snackbar } from "@mui/material";
import { HelpRounded } from "@mui/icons-material";
import { CustomTooltip } from "../custom-tooltip/customTooltip";

function CreatePaymentLink(props) {
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const dispatch = useDispatch();
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openCopyLink, setOpenCopyLink] = useState(false);
    const [paymentLinkUrl, setPaymentLinkUrl] = useState("");
    const [currencyInfo, setCurrencyInfo] = useState({"isoCode":"ZAR","symbol":"R"});

    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });

    const [requestforPaymentLink, setRequestforPaymentLink] = useState({
        expiresInDays: "7",
        transaction: {
            merchantId: "",
            profileId: "",
            merchantRef: "",
            amount: "",
            currency: "ZAR"
        }
    });

    const handleCloseCreateLink = (value) => {
        setRequestforPaymentLink({
            expiresInDays: "7",
            transaction: {
                merchantId: "",
                profileId: "",
                merchantRef: "",
                amount: "",
                currency: "ZAR",
            }
        })
        setSelectedProfile(null)
        setValidation({
            amountValid: true,
            merchantRefValid: true,
            expiresInDaysValid: true,
            profileValid: true
        })
        props.handleClose();
    };

    const handleProfileChange = (event, newValue) => {
        const activeCurrency = newValue?.profileCurrencies?.find(currency => currency.active);
        if (activeCurrency) {
            setCurrencyInfo({
                "isoCode": activeCurrency.currency.isoCode,
                "symbol": activeCurrency.currency.symbol
            });
        }
        setSelectedProfile(newValue)
        setRequestforPaymentLink({
            ...requestforPaymentLink,
            transaction: {
                ...requestforPaymentLink.transaction,
                profileId: newValue?.profileId,
                merchantId: newValue?.merchantId,
                currency: activeCurrency ? activeCurrency.currency.isoCode : currencyInfo.isoCode,
            }
        })
    }

    const filterActiveMerchants = merchantProfiles?.content?.filter(profile => profile.active === true);

    const changeAmount = (value) => {
        setRequestforPaymentLink({
            ...requestforPaymentLink,
            transaction: {
                ...requestforPaymentLink.transaction,
                amount: value
            }
        })
    }

    const changeExpiryDate = (value) => {
        setRequestforPaymentLink({
            ...requestforPaymentLink,
            expiresInDays: value
        })
    }

    const changeReference = (value) => {
        setRequestforPaymentLink({
            ...requestforPaymentLink,
            transaction: {
                ...requestforPaymentLink.transaction,
                merchantRef: value
            }
        })
    }

    const handleCloseCopyLink = () => {
        setOpenCopyLink(false);
        setRequestforPaymentLink({
            expiresInDays: "7",
            transaction: {
                merchantId: "",
                profileId: "",
                merchantRef: "",
                amount: "",
                currency: "ZAR",
            }
        })
        setSelectedProfile(null)
        setValidation({
            amountValid: null,
            merchantRefValid: null,
            expiresInDaysValid: null
        })
        props.handleClose();
    };

    const generateLink = () => {
        if (isFormValid()) {
            setIsLoading(true);
            console.log(requestforPaymentLink);
            let request = {
                ...requestforPaymentLink,
                transaction: {
                    ...requestforPaymentLink?.transaction,
                    amount: requestforPaymentLink?.transaction?.amount?.replaceAll(/\s/g, ""),
                    currency: currencyInfo.isoCode,
                }
            };

            createPaymentLink(request)
                .then(response => {
                    setPaymentLinkUrl(response?.data?.paymentLinkUrl);
                    setOpenCopyLink(true);
                    setIsLoading(false);
                    setResponse({
                        severity: "success",
                        isOpen: true,
                        message: "Successfully Created the Payment Link"
                    });
                })
                .catch(error => {
                    setIsLoading(false);
                    setResponse({
                        severity: "error",
                        isOpen: true,
                        message: "Failed to create the Payment Link"
                    });
                });
        }
    }

    const [validation, setValidation] = useState({
        amountValid: true,
        merchantRefValid: true,
        expiresInDaysValid: true,
        profileValid:true
    })

    const isFormValid = () =>{
        let isFormValid = true;
        if (requestforPaymentLink?.transaction?.amount?.length <= 0) {
            setValidation((prevState) => ({
                ...prevState,
                amountValid: false
            }));
            isFormValid = false;
        } else {
            setValidation((prevState) => ({
                ...prevState,
                amountValid: true
            }))
        }
        if (requestforPaymentLink?.transaction?.merchantRef?.length <= 0) {
            setValidation((prevState) => ({
                ...prevState,
                merchantRefValid: false
            }));
            isFormValid = false;
        }
        if (requestforPaymentLink?.expiresInDays < 1 || requestforPaymentLink?.expiresInDays > 60) {
            setValidation((prevState) => ({
                ...prevState,
                expiresInDaysValid: false
            }));
            isFormValid = false;
        }
        if (!selectedProfile) {
            setValidation((prevState) => ({
                ...prevState,
                profileValid: false
            }));
            isFormValid = false;
        }
        return isFormValid;
    }

    const amountBlur = () => {
        setValidation((prevState) => ({
            ...prevState,
            amountValid: (requestforPaymentLink?.transaction?.amount)
        }))
    }

    const referenceBlur = () => {
        setValidation((prevState) => ({
            ...prevState,
            merchantRefValid: (requestforPaymentLink?.transaction?.merchantRef)
        }))
    }

    const expiredDateBlur = () => {
        setValidation((prevState) => ({
            ...prevState,
            expiresInDaysValid: (requestforPaymentLink?.expiresInDays)
        }))
    }
    const profileBlur = () => {
        setValidation((prevState) => ({
            ...prevState,
            profileValid: (selectedProfile)
        }))
    }

    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="20%"
                    minWidth="500px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{ overflow: "hidden", boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}
                >
                    <Box width="100%" sx={{ p: 2 }}>
                        <Typography align="center" fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Create New Link</Typography>
                        <Box sx={{ mb: 2 }}>
                            <Grid item sx={{ flexGrow: 1, marginBottom: "5%" }}>
                                <CustomAutoComplete
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    size="small"
                                    options={filterActiveMerchants ?? []}
                                    getOptionLabel={(option) => option?.displayName || "Select a Profile..."}
                                    onChange={(event, newValue) => handleProfileChange(event, newValue)}
                                    onBlur={profileBlur}
                                    value={selectedProfile}
                                    renderInput={(params) => <TextField {...params} label="Profile"
                                    error={!validation.profileValid}
                                    helperText={!validation.profileValid ? "Please select a profile" :""}/>}
                                    
                                />
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, marginBottom: "5%" }}>
                                <CustomTextField
                                    error={validation.amountValid === false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{currencyInfo.symbol}</InputAdornment>
                                        ),
                                        inputComponent: CustomTextMask,
                                        inputProps: {
                                            mask: `num`,
                                            overwrite: false,
                                            blocks: {
                                                num: {
                                                    mask: Number,
                                                    thousandsSeparator: ' ',
                                                    min: 0,
                                                    scale: 2,
                                                    radix: '.',
                                                    normalizeZeros: true,
                                                    padFractionalZeros: true,
                                                    mapToRadix: [',']
                                                }
                                            }
                                        }
                                    }}
                                    value={requestforPaymentLink?.transaction?.amount}
                                    onChange={(event) => { changeAmount(event.target.value) }}
                                    onBlur={amountBlur}
                                    helperText={validation.amountValid === false ? "Please enter an amount" : ""}
                                    label="Amount"
                                    size="small"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, marginBottom: "5%" }}>
                                <CustomTextField
                                    error={validation.merchantRefValid === false}
                                    label="Reference"
                                    type="text"
                                    size="small"
                                    placeholder="Enter Reference"
                                    value={requestforPaymentLink?.transaction?.merchantRef}
                                    onChange={(event) => {
                                        changeReference(event?.target?.value)
                                    }}
                                    onBlur={referenceBlur}
                                    helperText={validation.merchantRefValid === false ? "Please enter a reference" : ""}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        min: 0,
                                        max: 200,
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, marginBottom: "5%" }}>
                                <CustomTextField
                                    label="Expires In Days"
                                    error={validation.expiresInDaysValid === false}
                                    type="tel"
                                    placeholder="1 Day"
                                    value={requestforPaymentLink?.expiresInDays}
                                    onChange={(event) => {
                                        changeExpiryDate(event?.target?.value)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomTooltip
                                                    title={
                                                        <Fragment>
                                                            <Typography sx={{ padding: 1 }} fontSize={11}
                                                                letterSpacing={0}>
                                                                Expires in days, can only be between 1 and 60. This is the number of days before the link expires and cannot be used anymore.
                                                            </Typography>
                                                        </Fragment>
                                                    }
                                                    arrow
                                                    disableInteractive
                                                    disableFocusListener
                                                    disableTouchListener
                                                >
                                                    <HelpRounded
                                                        fontSize='small'
                                                        sx={{ color: (theme) => theme.palette.primary.main, ":hover": { cursor: "pointer"} }}
                                                    />
                                                </CustomTooltip>
                                        </InputAdornment>
                                        ),
                                        inputComponent: CustomTextMask,
                                        inputProps: {
                                            mask: `num`,
                                            blocks: {
                                                num: {
                                                    mask: Number,
                                                    min: 1,
                                                    max: 60,
                                                }

                                            }
                                        }
                                    }}
                                    onBlur={expiredDateBlur}
                                    size="small"
                                    helperText={validation.expiresInDaysValid === false ? "Please enter an amount of days before expiry" : ""}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                            <Button
                                type="button"
                                variant="text"
                                className="Cancel"
                                onClick={handleCloseCreateLink}
                                sx={{
                                    textTransform: 'none',
                                    width: '45%',
                                    height: 40,
                                    color: 'contained',
                                    fontSize: 12,
                                    boxShadow: 2,
                                    marginRight: "10%",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="Create a Link"
                                onClick={generateLink}
                                sx={{
                                    textTransform: 'none',
                                    width: '45%',
                                    height: 40,
                                    color: 'white',
                                    fontSize: 12,
                                    boxShadow: 2,
                                }}

                            >
                                Create Link
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Backdrop>
            <PaymentLinkCreatedDialog
                isOpen={openCopyLink}
                onClose={handleCloseCopyLink}
                paymentLinkUrl={paymentLinkUrl}
            />
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen}
                autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity ? response.severity : ""} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

CreatePaymentLink.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    transaction: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    profileId: PropTypes.array.isRequired,
    merchantId: PropTypes.string.isRequired,
    merchantRef: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired
};

export default CreatePaymentLink;
