import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import PropTypes from 'prop-types';
import CustomDateRangePicker from '../custom-date-range-picker/customDateRangePicker';
import Popover from '@mui/material/Popover';
import { CustomAutoComplete } from '../custom-autocomplete/customAutoComplete';
import { CustomTextField } from '../custom-text-field/customTextField';
import SaveFilterBackdrop from '../save-filter-backdrop/saveFilterBackdrop';
import { SaveOutlined } from '@mui/icons-material';
import { Scheme } from '../../constants/scheme';
import { TransactionCardStatus } from '../../constants/transactionCardStatus';

function CardFilter(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState({
    fromDate: '',
    toDate: '',
    transactionStatuses: [],
    merchantRef: '',
    acquiringBank: '',
    scheme: '',
    transactionId: '',
    pan: '',
  });

  const [saveFilterOpen, setSaveFilterOpen] = useState(false);
  const [saveFilterName, setSaveFilterName] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  useEffect(() => {
    if (props.request) {
      setValues({
        ...values,
        fromDate: moment(props.request.fromDate),
        toDate: moment(props.request.toDate),
        transactionStatuses: props.request.transactionStatuses,
        merchantRef: props.request.merchantRef,
        scheme: props.request.scheme,
        acquiringBank: props.request.acquiringBank,
        pan: props.request.pan,
      });
    }
  }, [props.request]);

  const handleStatusChange = (event, newValue) => {
    setValues({
      ...values,
      transactionStatuses: newValue,
    });
  };

  const handleSchemeChange = (newValue) => {
    setValues({
      ...values,
      scheme: newValue,
    });
  };

  const handleDateSelectionChange = (newRange) => {
    setValues({
      ...values,
      fromDate: moment(newRange.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment(newRange.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });
  };

  const handleApply = () => {
    props.setRequest({
      ...props.request,
      transactionStatuses: values.transactionStatuses,
      fromDate: moment(values.fromDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment(values.toDate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      merchantRef: values.merchantRef,
      scheme: values.scheme,
      acquiringBank: values.acquiringBank,
      pan: values.pan,
    });

    props.setFilterApplied(true);
    handleClose();
  };

  const handleClear = () => {
    props.setRequest({
      ...props.request,
      transactionStatuses: [],
      fromDate: moment()
        .subtract(1, 'month')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      merchantRef: '',
      scheme: '',
      acquiringBank: '',
      pan: '',
    });

    props.setFilterApplied(false);
    handleClose();
  };

  const handleSave = () => {
    setSaveFilterOpen(true);
  };

  const handleCancelSave = () => {
    setSaveFilterOpen(false);
  };

  const checkAndSave = () => {
    const savedFilters = window.localStorage.getItem('cardFilter');
    if (savedFilters) {
      const tempList = JSON.parse(savedFilters);
      if (tempList.find((f) => f.name === saveFilterName)) {
        setDialogOpen(true);
      } else {
        handleSaveFilter(false);
      }
    } else {
      handleSaveFilter(false);
    }
  };

  const handleSaveFilter = (overwrite) => {
    props.setRequest({
      ...props.request,
      transactionStatuses: values.transactionStatuses,
      fromDate: moment(values.fromDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment(values.toDate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS'),
      merchantRef: values.merchantRef,
      scheme: values.scheme,
      acquiringBank: values.acquiringBank,
      pan: values.pan,
    });

    let filters = window.localStorage.getItem('cardFilter');

    if (!filters) {
      window.localStorage.setItem(
        'cardFilter',
        JSON.stringify([
          {
            name: saveFilterName,
            request: {
              ...props.request,
              transactionStatuses: values.transactionStatuses,
              fromDate: moment(values.fromDate)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSS'),
              toDate: moment(values.toDate)
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSS'),
              merchantRef: values.merchantRef,
              scheme: values.scheme,
              acquiringBank: values.acquiringBank,
            },
          },
        ])
      );
    } else {
      if (overwrite) {
        var tempList = JSON.parse(filters);
        tempList.splice(
          tempList.findIndex((l) => l.name === saveFilterName),
          1
        );
        window.localStorage.setItem(
          'cardFilter',
          JSON.stringify([
            ...tempList,
            {
              name: saveFilterName,
              request: {
                ...props.request,
                transactionStatuses: values.transactionStatuses,
                fromDate: moment(values.fromDate)
                  .startOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                toDate: moment(values.toDate)
                  .endOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                merchantRef: values.merchantRef,
                scheme: values.scheme,
                acquiringBank: values.acquiringBank,
              },
            },
          ])
        );
      } else {
        window.localStorage.setItem(
          'cardFilter',
          JSON.stringify([
            ...JSON.parse(filters),
            {
              name: saveFilterName,
              request: {
                ...props.request,
                transactionStatuses: values.transactionStatuses,
                fromDate: moment(values.fromDate)
                  .startOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                toDate: moment(values.toDate)
                  .endOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss.SSS'),
                merchantRef: values.merchantRef,
                scheme: values.scheme,
                acquiringBank: values.acquiringBank,
              },
            },
          ])
        );
      }
    }

    props.setFilterApplied(true);
    setSaveFilterOpen(false);
    setDialogOpen(false);
    handleClose();
  };

  const handlePanChange = (event) => {
    setValues({
      ...values,
      pan: event.target.value,
    });
  };

  const handleAcquiringBankChange = (event) => {
    setValues({
      ...values,
      acquiringBank: event.target.value,
    });
  };

  const handleSaveFilterChange = (event) => {
    setSaveFilterName(event.target.value);
  };

  return (
    <Box>
      <Tooltip title="Filter">
        <Button
          variant="contained"
          startIcon={<FilterListIcon />}
          sx={{
            textTransform: 'none',
            height: 40,
            color: 'white',
            fontSize: 12,
            boxShadow: 2,
            width: '150px',
          }}
          onClick={openFilter ? handleClose : handleOpen}
        >
          Filter
        </Button>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: 999 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus={true}
      >
        <Box
          sx={{
            background: '#FFFFFF',
            p: 3,
            maxWidth: '750px',
            minWidth: '500px',
          }}
        >
          <Stack spacing={3}>
            <CustomDateRangePicker
              selectedRange={{
                startDate: values.fromDate,
                endDate: values.toDate,
              }}
              setSelectedRange={handleDateSelectionChange}
            />
            <FormControl fullWidth size="small" className="dashboard-select">
              <InputLabel>Scheme</InputLabel>
              <Select
                label="Select Profile"
                value={values.scheme}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
                onChange={(event) => handleSchemeChange(event.target.value)}
              >
                {props.profiles?.length === 0 && (
                  <MenuItem key={1} value="" disabled>
                    <em>None</em>
                  </MenuItem>
                )}
                {Object.keys(Scheme).map((scheme, index) => (
                  <MenuItem key={index} value={scheme}>
                    {Scheme[scheme]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomTextField
              size="small"
              label="Acquiring Bank"
              value={values.acquiringBank}
              onChange={handleAcquiringBankChange}
            />
            <CustomAutoComplete
              ListboxProps={{ style: { maxHeight: '10rem' } }}
              multiple
              size="small"
              options={Object.values(TransactionCardStatus)}
              onChange={(event, newValue) =>
                handleStatusChange(event, newValue)
              }
              value={values.transactionStatuses}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
            <CustomTextField
              size="small"
              label="Card Number"
              value={values.pan}
              onChange={handlePanChange}
            />
            <Grid container justifyContent="center" sx={{ mb: 4, mt: 3 }}>
              <Button
                onClick={handleClear}
                variant="contained"
                sx={{
                  '&:hover': { background: '#EBFAFF' },
                  boxShadow: '0px 3px 6px #00000029',
                  textTransform: 'none',
                  background: '#FFFFFF',
                  color: '#0098D8',
                  borderRadius: '5px',
                  width: '160px',
                  mr: 1,
                }}
              >
                Clear Filter
              </Button>
              <Button
                type="button"
                onClick={handleApply}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  background: '#00C1FF',
                  width: '160px',
                  height: '100%',
                  color: '#FFFFFF',
                  mr: 1,
                }}
              >
                Apply Filter
              </Button>
              <Button
                type="button"
                onClick={handleSave}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  background: '#00C1FF',
                  width: '160px',
                  height: '100%',
                  color: '#FFFFFF',
                }}
                startIcon={<SaveOutlined />}
              >
                Save Filter
              </Button>
            </Grid>
          </Stack>
        </Box>
      </Popover>
      <SaveFilterBackdrop
        isOpen={saveFilterOpen}
        handleCancel={handleCancelSave}
        handleSave={checkAndSave}
        name={saveFilterName}
        handleNameChange={handleSaveFilterChange}
      />
      <Dialog
        sx={{ borderRadius: '5px' }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Overwrite Filter'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You already have a filter saved named {saveFilterName}. Would you
            like to overwite it?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            type="button"
            variant="contained"
            sx={{
              textTransform: 'none',
              '&:hover': { background: '#EBFAFF' },
              background: '#FFFFFF',
              width: '100px',
              height: '100%',
              color: '#00C1FF',
            }}
          >
            No
          </Button>
          <Button
            onClick={() => handleSaveFilter(true)}
            autoFocus
            type="button"
            variant="contained"
            sx={{
              textTransform: 'none',
              background: '#00C1FF',
              width: '100px',
              height: '100%',
              color: '#FFFFFF',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

CardFilter.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  setFilterApplied: PropTypes.func.isRequired,
};

export default CardFilter;
