import { getZapperTransactions } from "../services/zapperTransactionService";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'zapperTransactions',
    initialState: {
        zapperTransactions: []
    },
    reducers: {
        success: (state, action) => {
            state.zapperTransactions = action.payload;
            state.isLoading = false;
        },
        notFound: (state, action) => {
            state.zapperTransactions = action;
            state.isLoading = false;
        },
        internalServerError: (state, action) => {
            state.zapperTransactions = action;
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

const { success, notFound, internalServerError } = slice.actions;

export const fetchZapperTransactions = (request) => async dispatch => {
    try {
        await getZapperTransactions(request)
            .then((response) => dispatch(success(response.data)));
    } catch(e) {
        console.log(e);
        switch (e.response.status) {
            case 404:
                dispatch(notFound(e.response.data));
                break;
            case 500:
                dispatch(internalServerError(e.response.data));
                break;
            default:
                dispatch(internalServerError(e.response.data));
                break;
        }
    }
}

export const updateZapperTransactions = (transactions) => async dispatch => {
    dispatch(success(transactions));
}