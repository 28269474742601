import * as React from "react";
import Typography from "@mui/material/Typography";
import {Outlet, useOutletContext} from "react-router-dom";
import TransactionNav from "./transaction-nav/transactionNav";
import PropTypes from "prop-types";
import Box from "@mui/system/Box";

function Transactions() {

    // ToDo: Keep for later
    // const [currentPage, setCurrentPage] = useState("");
    // const location = useLocation();

    const [selectedMerchants] = useOutletContext();

    // ToDo: Keep for later
    // useEffect(() => {
    //     if (location.pathname.includes("all")) {
    //         setCurrentPage("All");
    //     }
    //     if(location.pathname.includes("card")){
    //         setCurrentPage("Card")
    //     }
    // }, [location.pathname])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`transaction-${index}`}
                aria-labelledby={`transaction-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ pt: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return(
        <Box sx={{ mt: 2, mb: 2, ml: 3, mr: 3 }}>
            {/* ToDo: Keep for later
            <Breadcrumbs
                sx={{ mt: 2, color: "#0098D8" }}
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Typography fontSize={12} sx={{ color: (theme) => theme.palette.secondary.contrastText }} key="1">
                    Transactions
                </Typography>
                <Typography fontSize={12} fontWeight="bold" sx={{ color: (theme) => theme.palette.primary.dark }} key="2">
                    {currentPage}
                </Typography>
            </Breadcrumbs> */}
            <Box sx={{ mt: 2 }}>
                <TransactionNav/>
                <Outlet context={[selectedMerchants]} />
            </Box>
        </Box>
    )
}

export default Transactions;