import { Backdrop, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import "../../../styles.scss";
import DetailedView from "../detailed-view/detailedView";
import PaymentStepStatus from "../payment-step-status/paymentStepStatus";
import Actions from "../actions/actions";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { getCardNotPresentTransaction } from "../../../services/cnpTransactionService";
import { getTdsTransaction } from "../../../services/tdsService";
import AuthorizedElement from "../../../keycloak/permissionsGate";
import ComponentLoader from "../../component-loader/componentLoader";
import TransactionLogs from "../transaction-logs/transactionLogs";
import { getSessionUrls } from "../../../services/sessionService";
import { getWebhookDetails } from "../../../services/profileServices";
import HeaderInfo from "../headerInfo";
import { getTransactions } from "../../../services/transactionService";
import { getTransactionStatus } from "../../../utils/statusFormatter";
import { PaymentType } from "../../../constants/paymentType";
import ZapperTransactionLogs from "../transaction-logs/zapperTransactionLogs";
import ZapperDetailedView from "../detailed-view/zapperDetailedView";
import { getZapperTransaction } from "../../../services/zapperTransactionService";
import ZapperPaymentStepStatus from "../payment-step-status/paymentStepStatusZapper";
import ZapperActions from "../actions/zapperActions";
import { ZapperTransactionStatus } from "../../../constants/zapperTransactionStatus";

function ZapperTransactionMoreInfo(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [transactionDetails, setTransactionDetails] = useState();
    const [transactionStatus, setTransactionStatus] = useState();
    const [webhookDetails, setWebhookDetails] = useState();
    const [refundedAmount, setRefundedAmount] = useState();
    const [isActionPlaced, setIsActionPlaced] = useState(false);
    const [redirectUrls, setRedirectUrls] = useState();

    const updateTransactionDetails = (() => {
        getTransactions(props.transactionId).then(response => {
            setIsLoading(false);
            setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
            setTransactionDetails({
                responseCode: response.data?.zapperTransactionDetails?.[0]?.iso8583ResponseCode,
                responseText: response.data?.zapperTransactionDetails?.[0]?.responseText,
                amount: response.data?.amount,
                currencyCode: response.data?.currency?.[0]?.isoCode,
                merchantRef: response.data?.merchantRef,
                merchantId: props.profiles?.find(profile => profile.profileId === response.data?.profileId)?.merchantId,
                profileId: response.data?.profileId,
                createdAt: response.data?.createdAt,
                transactionStatus: response.data?.transactionStatus,
                transactionId: response.data?.transactionId,
                token: response.data?.cardDetails?.token,
                scheme: response.data?.cardDetails?.cardScheme,
                cardDetails: {
                    nameOnCard: response.data?.cardDetails?.nameOnCard,
                    pan: response.data?.cardDetails?.maskedPan,
                    expiryDate: response.data?.cardDetails?.expiryDate
                }
            })
        }).catch(error => {
            setIsLoading(false);
        })
    })

    useEffect(() => {
        getZapperInfo();
    }, [props.transactionId])

    const getZapperInfo=(() => {
        if (props.transactionId && props.transactionId !== "") {
            setIsLoading(true);
            getZapperTransaction(props.transactionId).then(response => {
                setRefundedAmount(response.data.refundedAmount);
                setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
                setTransactionDetails(response.data)
                setIsLoading(false);
            }).catch(error => {
                updateTransactionDetails();
                console.log(error)
            })
        }
    })

    useEffect(()=>{
        if(isActionPlaced){
            setIsLoading(true);
            getZapperTransaction(props.transactionId).then(response => {
                setRefundedAmount(response.data.refundedAmount);
                setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
                setTransactionDetails(response.data)
                setIsLoading(false);
            }).catch(error => {
                updateTransactionDetails();
                console.log(error)
            })
            setIsActionPlaced(false);
        }
    },[isActionPlaced])

    const getRedirectUrls = useCallback(() => {
        if (props.transactionId) {
            getSessionUrls(props.transactionId).then(response => {
                setRedirectUrls(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    })

    useEffect(()=>{
        getRedirectUrls();
        if(isActionPlaced){
            setIsLoading(true);
            getZapperTransaction(props.transactionId).then(response => {
                setRefundedAmount(response.data.refundedAmount);
                setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
                setTransactionDetails(response.data)
                setIsLoading(false);
            }).catch(error => {
                updateTransactionDetails();
                console.log(error)
            })
            setIsActionPlaced(false);
        }
    },[isActionPlaced])
    const tableUpdate = (()=>
    {
        setIsActionPlaced(true)
    })

    useEffect(() => {
        if (props.isOpen) {
            switch (transactionStatus) {
                case TransactionStatus.PAYMENT_INITIATED:

                    setStatus("Initiated");

                    break;

                case TransactionStatus.PAYMENT_FAILED:

                    setStatus("Failed");

                    break;

                case TransactionStatus.PAYMENT_COMPLETED:

                    setStatus("Complete");

                    break;

                case TransactionStatus.PAYMENT_PENDING_INVESTIGATION:

                    setStatus("Pending investigation");

                    break;

                case TransactionStatus.PAYMENT_PENDING:

                    setStatus("Pending");

                    break;

                case TransactionStatus.PAYMENT_REFUNDED:

                    setStatus("Refund");

                    break;

                case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:

                    setStatus("Partially Refunded");

                    break;

                case TransactionStatus.PAYMENT_CANCELLED:

                    setStatus("Cancelled");

                    break;

                case TransactionStatus.PAYMENT_TIMED_OUT:

                    setStatus("Timed Out");

                    break;

                default:

                    break;
            }
        }
    }, [transactionStatus, transactionDetails])

    const handleClose = () => {
        setIsLoading(true)
        setTransactionDetails(null);
        setTransactionStatus(null);
        props.setMoreInfoRequest({
            isOpen: false,
            transactionId: "",
            symbol: "",
            amount: 0,
            transactionStatus: "",
            displayName: "",
            createdAt: "",
            lastModified: "",
            cardTransactionDetails: null,
            merchantRef: "",
            currencyCode: "ZAR",
            selectedprofile: ""
        });
        setIsLoading(false)
    }


    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="80%"
                    minWidth="800px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{overflow:"hidden",height:"100vh",   boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <HeaderInfo isOpen={props.isOpen} amount={props.amount} createdAt={props.createdAt} displayName={props.displayName} handleClose={handleClose} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} symbol={props.symbol} transactionId={props.transactionId} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} status={status} />
                    <Grid container direction="column" sx={{ p: 3 ,maxHeight: "calc(100vh - 150px)",flexWrap: "nowrap", overflow: "auto", overflowY: "auto", overflowX:"hidden"}} width="100%">
                        <ZapperPaymentStepStatus transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} displayName={props.displayName} transactionDetails={transactionDetails} createdAt={props.createdAt} lastModified={props.lastModified} transactionMoreDetails={props.transactionMoreDetails} />
                        {ZapperTransactionStatus[transactionDetails?.transactionStatus] !== ZapperTransactionStatus.PAYMENT_INITIATED ?
                            <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                <ZapperActions transactionId={props.transactionId} merchantId={transactionDetails?.merchantId} profileId={transactionDetails?.profileId} currencyCode={props.currencyCode} symbol={props.symbol} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} refundedAmount={transactionDetails?.refundedAmount} amount={props.amount} refundAmount={props.refundAmount} handleTableUpdate={tableUpdate} zapperInfo={props.getZapperInfo}/>
                            </AuthorizedElement> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_INITIATED ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <ZapperDetailedView symbol={props.symbol} transactionDetails={transactionDetails}/>
                            </Box> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_INITIATED ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <ZapperTransactionLogs transactionDetails={transactionDetails} webhookDetails={webhookDetails} symbol={props.symbol}/>
                            </Box> : null
                        }
                    </Grid>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={isLoading} />
        </Box>
    );
}

ZapperTransactionMoreInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    expectedAmount: PropTypes.number.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    merchantRef: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired,
    profiles:  PropTypes.object.isRequired
};

export default ZapperTransactionMoreInfo;