import { createSlice } from "@reduxjs/toolkit";
import { getMerchant } from "../services/merchantService";

const slice = createSlice({
    name: 'merchant',
    initialState: {
        merchant: null
    },
    reducers: {
        success: (state, action) => {
            state.merchant = action.payload;
            state.isLoading = false;
        },
        notFound: (state, action) => {
            state.merchant = action;
            state.isLoading = false;
        },
        internalServerError: (state, action) => {
            state.merchant = action;
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

const { success, notFound, internalServerError } = slice.actions;

export const fetchMerchant = (request) => async dispatch => {
    try {
        await getMerchant(request)
            .then((response) => dispatch(success(response.data)));
    } catch(e) {
        console.log(e);
        switch (e.response.status) {
            case 404:
                dispatch(notFound(e.response.data));
                break;
            case 500:
                dispatch(internalServerError(e.response.data));
                break;
            default:
                dispatch(internalServerError(e.response.data));
                break;
        }
    }
}