import Typography from "@mui/material/Typography";
import { Alert, Backdrop, Button, CircularProgress, FormGroup, Grid, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import { useOutletContext } from "react-router-dom";
import CustomSwitch from "../../components/custom-switch/customSwitch";
import { useEffect, useState } from "react";
import { updateProfile } from "../../services/profileServices";
import { CustomTextField } from "../../components/custom-text-field/customTextField";
import { SaveOutlined } from "@mui/icons-material";
import ComponentLoader from "../../components/component-loader/componentLoader";
import GoogleAnalytics from "../../utils/googleAnalyticsHelper";


function GeneralConfig() {

    const [profile, setProfile, setChangesMade] = useOutletContext();

    const [hasTerms, setHasTerms] = useState(false);
    const [hasPrivacy, setHasPrivacy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageTerms  , setErrorMessageTerms] = useState([])
    const [errorMessagePrivacy  , setErrorMessagePrivacy] = useState([])

    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });

    const [legal, setLegal] = useState({
        termsAndConditions: "",
        privacyPolicy: ""
    });

    useEffect(() => {
        if(legal.privacyPolicy){
            if (legal.privacyPolicy.length < 10000){
                setErrorMessagePrivacy([]);
            }else {
                setErrorMessagePrivacy(["Can't exceed 10 000 characters"])
            }
        }
    }, [legal.privacyPolicy])

    useEffect(() => {
        if (legal.termsAndConditions){
            if (legal.termsAndConditions.length < 10000 ){
                setErrorMessageTerms([]);
            }else {
                setErrorMessageTerms(["Can't exceed 10 000 characters"])
            }
        }
    } , [legal.termsAndConditions])

    const legalChanged = (property, value) => {
        setChangesMade(true);
        setLegal({
            ...legal,
            [property]: value
        });
    }

    const saveConfig = () => {
        setIsLoading(true);
        updateProfile(profile?.profileId, {
            ...profile,
            termsAndConditions:  legal.termsAndConditions,
            privacyPolicy:legal.privacyPolicy ,
            termsConditionsActive:hasTerms,
            privacyPolicyActive:hasPrivacy
        }).then(response => {
            setProfile(response.data);
            setChangesMade(false);
            setIsLoading(false);
            GoogleAnalytics.saveGeneralConfigSettings(profile?.profileId);
            setResponse({
                severity: "success",
                isOpen: true,
                message: "Legal information successfully updated"
            })
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
            setResponse({
                severity: "error",
                isOpen: true,
                message: "Unable to update legal information"
            })
        })
    }

    useEffect(() => {
        if (profile) {
            setLegal({termsAndConditions: profile?.termsAndConditions, privacyPolicy: profile?.privacyPolicy})
            setHasTerms(profile?.termsConditionsActive);
            setHasPrivacy(profile?.privacyPolicyActive);
        }
    }, [profile])

    return (
        <Box sx={{ mt: 2, mb: 4, ml: 3, mr: 3, minHeight: "calc(100vh - 345px)" }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasTerms} onChange={(event) => setHasTerms(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Terms & Conditions</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Setup your payment pages terms & conditions</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <FormGroup sx={{ mt: 3, mb: 2 }}>
                        <CustomTextField
                            disabled={!hasTerms}
                            required
                            error={Boolean(errorMessageTerms.length)}
                            helperText = {errorMessageTerms[0]}
                            id="outlined-multiline-static"
                            placeholder="Add text/html here"
                            multiline
                            rows={4}
                            value={legal.termsAndConditions}
                            onChange={(event) => legalChanged("termsAndConditions", event.target.value)}
                            sx={{ mb: 3 }}
                            inputProps ={{
                                maxLength : 10000
                            }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item>
                            <CustomSwitch checked={hasPrivacy} onChange={(event) => setHasPrivacy(event.target.checked)} />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Privacy Policy</Typography>
                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Setup your payment pages privacy policy</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <FormGroup sx={{ mt: 3, mb: 2 }}>
                        <CustomTextField
                            disabled={!hasPrivacy}
                            id="outlined-multiline-static"
                            placeholder="Add text/html here"
                            multiline
                            rows={4}
                            value={legal.privacyPolicy}
                            helperText = {errorMessagePrivacy[0]}
                            error={Boolean(errorMessagePrivacy.length)}
                            onChange={(event) => legalChanged("privacyPolicy", event.target.value)}
                            sx={{ mb: 3 }}
                            inputProps ={{
                                maxLength: 10000
                            }}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Button onClick={() => saveConfig()} type="button" variant="contained" sx={{ textTransform: "none", background: "#00C1FF", width: "200px", color: "#FFFFFF" }} startIcon={<SaveOutlined />}>
                    Save Changes
                </Button>
                <Typography fontSize={14} letterSpacing={0} sx={{ ml: 3, width: "300px", color: "#404047" }}>
                    Please ensure all information is correct before saving
                </Typography>
            </Grid>
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen} autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default GeneralConfig;