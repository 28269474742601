import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider } from "@mui/material"
import KeycloakService from "../../keycloak/keycloakService";
import PropTypes from 'prop-types';
import { Theme } from "../../assets/styles/theme";

function InactivityDialog(props) {

    const theme = Theme();

    const logout = () => {
        window.sessionStorage.removeItem("merchantId");
        KeycloakService.doLogout();
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                id="inactivity-dialog"
                open={props.dialogOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Signed Out"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {props.inactivity?"We have signed you out due to inactivity to protect your account. Please sign in again."
                        :KeycloakService.getDisplayName()+" does not have permission to view this web site"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={logout} autoFocus type="button" variant="contained" sx={{
                        textTransform: "none",
                        background: "#00C1FF",
                        width: "100px",
                        height: "100%",
                        color: "#FFFFFF"
                    }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

InactivityDialog.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    inactivity: PropTypes.bool.isRequired
};

export default InactivityDialog