import styled from "@emotion/styled";
import { CheckCircleOutline, HighlightOff, PendingOutlined } from "@mui/icons-material";
import { Card, Grid, Popover, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { TransactionStatus } from "../../../constants/transactionStatus";

const SuccessTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#00C1FF",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#00C1FF",
        color: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029"
    },
}));

function ZapperPaymentStepStatus(props){

    const [anchorElTransaction, setAnchorElTransaction] = useState(null);
    const [anchorEl3DS, setAnchorEl3DS] = useState(null);

    const handleClick = (event) => {
        switch (event.target.id) {
            case "3DSecure":
                if (step3DSecure) {
                    if (props.transactionStatus !== TransactionStatus._3DS_INITIATED) {
                        setAnchorEl3DS(event.currentTarget);
                    }
                }
                break;
            case "transaction":
                if (stepPayment) {
                    setAnchorElTransaction(event.currentTarget);
                }
                break;
            default:
                break;
        }
    };

    const handleCloseTransaction = () => {
        setAnchorElTransaction(null);
    };

    const openTransaction = Boolean(anchorElTransaction);
    const open3DS = Boolean(anchorEl3DS);

    const [step3DSecure, setStep3DSecure] = useState(false);
    const [stepPayment, setStepPayment] = useState(false);


    const [paymentSuccessful, setPaymentSuccessful] = useState(false);

    const [transactionMoreDetails, setTransactionMoreDetails] = useState();

    useEffect(() => {
        switch (props.transactionStatus) {
            case TransactionStatus.PAYMENT_CANCELLED:
                setStepPayment(false);
                setStep3DSecure(false);
                setPaymentSuccessful(false);
                break;
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStepPayment(true);
                setPaymentSuccessful(false);
                setStep3DSecure(true);
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:    
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStepPayment(true);
                setPaymentSuccessful(true);
                setStep3DSecure(true);
                break;
            case TransactionStatus.PAYMENT_TIMED_OUT:
            case TransactionStatus.PAYMENT_INITIATED:
                setStepPayment(false);
                setStep3DSecure(false);
                setPaymentSuccessful(false);
                break;
            case TransactionStatus._3DS_AUTHORISED:
            case TransactionStatus._3DS_INITIATED:
                setStepPayment(false);
                setStep3DSecure(true);
                setPaymentSuccessful(false);
                break;
            case TransactionStatus._3DS_FAILED:
                setStepPayment(false);
                setStep3DSecure(true);
                setPaymentSuccessful(false);
                break;
            default:
                setStepPayment(false);
                setPaymentSuccessful(false);
                setStep3DSecure(false);
                break;
        }

        let mostRecentDate = new Date(Math.max.apply(null, props.transactionMoreDetails?.map(moreDetail => {
            return new Date(moreDetail.lastModified);
        })));
        setTransactionMoreDetails(props.transactionMoreDetails?.filter(moreDetail => {
            var d = new Date(moreDetail.lastModified);
            return d.getTime() === mostRecentDate.getTime();
        })[0]);
    }, [props])

    const getTransactionName = () => {
        let transactionName = ""
        Object.keys(TransactionStatus).forEach(item => {
            if(TransactionStatus[item] === props.transactionStatus){
                transactionName = item
            } 
        })
        return transactionName
    }

    return(
        <Box width="100%">
            <Grid container columns={11} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <SuccessTooltip disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? true : false} arrow title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">Request Sent</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                            </Box>
                        } placement="top">
                            <Card sx={{ "&:hover": { cursor: "pointer" }, p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="initial">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: "#404047" }} id="initial">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "Payment Initiated" : "Client sent transaction to provider"}
                                </Typography>
                            </Card>
                        </SuccessTooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.displayName}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        <Box className="pointer" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>
                            <Box className="success-check">
                                {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ?
                                    <PendingOutlined /> : <CheckCircleOutline />
                                }
                            </Box>
                        </Box>
                        {stepPayment ?
                            <Box className="pointer pointer-inverted" sx={{ top: "46px", display: "flex", justifyContent: "center" }}>
                                <Box className={paymentSuccessful ? "success-check" : "failed-check"}>
                                    {paymentSuccessful ? <CheckCircleOutline sx={{ transform: "rotate(180deg)" }} /> :
                                        <HighlightOff sx={{ transform: "rotate(180deg)" }} />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    "& .MuiTooltip-arrow": {
                                        color: (paymentSuccessful ? "#00C1FF" : "#DF372D")
                                    },
                                    "&.MuiTooltip-tooltip": {
                                        backgroundColor: (paymentSuccessful ? "#00C1FF" : "#DF372D"),
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 6px #00000029"
                                    }
                                }
                            }
                        }}  disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus.PAYMENT_FAILED || props.transactionStatus === TransactionStatus.PAYMENT_DECLINED ? true : false} title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">{paymentSuccessful ? "Success" : "Failed"}</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.lastModified).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                {!paymentSuccessful &&
                                    <Typography fontSize={10} fontWeight="bold" textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning}</Typography>
                                }
                            </Box>
                         } placement="top">
                            <Card onClick={handleClick} sx={{ "&:hover": { cursor: "pointer" }, background: (props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED || props.transactionStatus === TransactionStatus._3DS_INITIATED || props.transactionStatus === TransactionStatus._3DS_FAILED || props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "#FFFFFF" : paymentSuccessful ? "#08F9BA" : "#DF372D"), p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="transaction">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: paymentSuccessful ? "#404047": "#FFFFFF" }} id="transaction">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED|| props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED ||props.transactionStatus === TransactionStatus._3DS_INITIATED ||props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "" : TransactionStatus[getTransactionName()]}
                                    {props.transactionStatus === TransactionStatus.PAYMENT_FAILED || props.transactionStatus === TransactionStatus.PAYMENT_DECLINED ? 
                                    <Typography fontSize={14}  textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning ? transactionMoreDetails?.iso8583ResponseCodeMeaning : ""  }</Typography>
                                : ""}
                                </Typography>
                            </Card>
                        </Tooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.transactionDetails?.merchantBankDisplayName ? props.transactionDetails?.merchantBankDisplayName : "Zapper"}
                        </Typography>
                    </Card>
                </Grid>
            </Grid >
        </Box >
    );

}

ZapperPaymentStepStatus.propTypes = {
    displayName: PropTypes.string.isRequired,
    transactionDetails: PropTypes.object,
    transactionStatus: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array
};

export default ZapperPaymentStepStatus;