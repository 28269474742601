import * as React from 'react';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

function PageNotFound() {
    return (
        <Box className="center" sx={{ width: '100%' }}>
            <Grid container sx={{ direction: 'row', color: '#404047', justifyContent: 'center' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsSuggestOutlinedIcon sx={{ fontSize: 115 }}/>
                </Grid>
                <Grid item sx={{ direction: 'column', ml: 2 }}>
                    <Grid>
                        <Typography sx={{ fontSize: 48, fontWeight: 'bold' }}>
                            Under Construction
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={{ fontSize: 28 }}>
                            The Tech Wizards are busy with the magic
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PageNotFound;