import { SearchOutlined } from "@mui/icons-material";
import { InputAdornment, List, ListSubheader } from "@mui/material";
import { useRef } from "react";
import { CustomTextField } from "../custom-text-field/customTextField";

function CustomSearchListbox(props) {

    const { filterValue, setFilterValue, setNegateClose, ...listboxProps } = props
    const textFieldRef = useRef(null);

    const handleSearchInput = () => {
        setNegateClose(true);
        setTimeout(() => {
            textFieldRef.current.focus()
        }, 50)
    }

    return (
        <List
            sx={{
                "&.MuiAutocomplete-listbox": {
                    pt: 0
                }
            }}
            {...listboxProps}
            subheader={
                <ListSubheader>
                    <CustomTextField
                        inputRef={textFieldRef}
                        onClick={() => handleSearchInput()}
                        placeholder="Type to search..."
                        fullWidth
                        size="small"
                        sx={{
                            mt: 1,
                            mb: 1
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            )
                        }}
                        value={filterValue}
                        onChange={(event) => setFilterValue(event.target.value)} />
                </ListSubheader>
            }
        />
    );
}

export default CustomSearchListbox;