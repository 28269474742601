import { Button, Grid, Popover, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful"
import "../../styles.scss";
import { validateHexColor } from "../../utils/validator";
import { CustomTextField } from "../custom-text-field/customTextField";
import PropTypes from 'prop-types';

function ColorPicker(props) {

    const [currentColor, setCurrentColor] = useState("");
    const [newColor, setNewColor] = useState("");
    const [newColorError, setNewColorError] = useState(false);

    useEffect(() => {
        setNewColor(props.color)
    }, [props.open, props.color])

    const handleSave = () => {
        props.handleSave(props.prop, currentColor);
        handleClose();
    }

    const handleClose = () => {
        props.handleClose()
    }

    useEffect(() => {
        if (validateHexColor(newColor)) {
            setCurrentColor(newColor);
            setNewColorError(false);
        } else {
            setNewColorError(true);
        }
    }, [newColor])

    const handleColorPicked = (value) => {
        setCurrentColor(value);
        setNewColor(value);
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={!props.disabled && props.open}
            anchorEl={props.anchor}
            onClose={handleClose}>
            <Box className="ps-0 pe-0 ms-0 me-0">
                <HexColorPicker className="color-picker" color={currentColor} onChange={handleColorPicked} />
                <Grid sx={{ p: "25px" }}>
                    <CustomTextField sx={{ width: "100%" }} size="small" value={newColor} onChange={(event) => setNewColor(event.target.value)} error={newColorError} helperText={newColorError ? "Please enter a valid hex colour" : ""} />
                    <Grid className="mt-3" container justifyContent="center">
                        <Button onClick={handleClose} sx={{ textTransform: "none", background: "#FFF", color: "#404047", width: "100px", "&:hover": { background: "#EBFAFF" } }} variant="contained" className="me-2 color-picker-button">Close</Button>
                        <Button onClick={handleSave} sx={{ textTransform: "none", width: "100px", color: "#FFFFFF", "&:hover": { background: "#0098D8" } }} variant="contained" className="color-picker-button">Apply</Button>
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    )
}

ColorPicker.propTypes = {
    open: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    anchor: PropTypes.object,
    handleClose: PropTypes.func.isRequired
};

export default ColorPicker;