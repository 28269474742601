
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import moment from "moment";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { ReactComponent as ZapperLogo } from '../../../assets/images/FrameZapper.svg';
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function ZapperDetailedView(props) {

    const {transactionDetails} = props;
    const [status , setStatus] = useState("");

    useEffect(() => {
        switch (TransactionStatus[transactionDetails?.transactionStatus]) {
            case TransactionStatus.PAYMENT_CANCELLED:
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStatus("Failed");
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:    
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStatus("Successful");
                break;
            case TransactionStatus.PAYMENT_INITIATED:
                setStatus("Initiated");
                break;
            default:
                break;
        }
    }, [props])
    return (
        <Box width="100%">
            <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Details</Typography>
            <Box>
                <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <ZapperLogo height={40} />
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} sx={{ color: "#404047" }}>{props.symbol}{numberWithCommas(getFormattedAmount(transactionDetails?.amount))}</Typography>
                        </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Reference:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{transactionDetails?.reference}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Date:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(transactionDetails?.createdAt).format("YYYY/MM/DD")}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Time:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(transactionDetails?.createdAt).format("HH:mm:ss")}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Result:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{status}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Verified Transaction:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{transactionDetails?.validTransaction?.toString()}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

ZapperDetailedView.propTypes = {
    symbol: PropTypes.string.isRequired,
    transactionDetails: PropTypes.object.isRequired,
};

export default ZapperDetailedView;