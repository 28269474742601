import axios from 'axios';
import ReactGA from "react-ga4";
import GoogleAnalytics from '../utils/googleAnalyticsHelper';

export const getMerchantTransaction = (request) => {                
    GoogleAnalytics.getMerchantTransactions(request);
    return axios.get(`${process.env.REACT_APP_SESSION_PATH}/${request.merchantId}?fromDate=${request.fromDate}&toDate=${request.toDate}&page=${request.page}&limit=${request.limit}`);
}

export const getProfileTransactions = (request) => {    
    GoogleAnalytics.getProfileTransaction(request);
    return axios.get(`${process.env.REACT_APP_TRANSACTIONS_PROFILE_PATH}?profileId=${request.profileId}&fromDate=${request.fromDate}&toDate=${request.toDate}&sortBy=${request.sortBy}&sortOrder=${request.sortOrder}&page=${request.page}&limit=${request.limit}`);
}

export const getTransactions = (transactionId) => {
  return axios.get(`${process.env.REACT_APP_TRANSACTION_PATH}/${transactionId}`);
}

export const getProfileFailedTransactions = (request) => {
    GoogleAnalytics.getProfileFailedTransaction(request);
    return axios.get(`${process.env.REACT_APP_TRANSACTIONS_PROFILE_PATH}/failed`, request);
}

export const getProfileAllTransactions = (request) => {
    GoogleAnalytics.getProfileAllTransaction();
    return axios.post(`${process.env.REACT_APP_ALL_TRANSACTIONS_PROFILE_PATH}`, request);
}

export const getProfileCardTransactions = (request) => {
    GoogleAnalytics.getProfileCardTransaction();
    return axios.post(`${process.env.REACT_APP_CARD_TRANSACTIONS_PROFILE_PATH}`, request);
}

export const getProfileCSVAllTransactions = (request,transactionStatuses) => {
    GoogleAnalytics.getProfileAllCsvTransaction();

    return axios({
        url: `${process.env.REACT_APP_CSV_ALL_TRANSACTIONS_PROFILE_PATH}`,
        method: 'POST',
        responseType: 'blob',
        data: request
    });
}

export const getCardProfileCSVAllTransactions = (request,transactionStatuses) => {
    GoogleAnalytics.getCardProfileAllCsvTransaction();
    return axios({
        url: `${process.env.REACT_APP_CSV_CARD_TRANSACTIONS_PATH}`,
        method: 'POST',
        responseType: 'blob',
        data: request
    });
}