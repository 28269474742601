import axios from 'axios';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';

export const getCardNotPresentTransaction = (transactionId) => {
  GoogleAnalytics.getCardNotPresentTransactionID(transactionId);
    return axios.get(`${process.env.REACT_APP_TRANSACTIONCNP_PATH}/${transactionId}`)
}

export const getCardNotPresentTransactions = (request) => {
    GoogleAnalytics.getCardNotPresentTransactionsRequest(request);
    return axios.get(`${process.env.REACT_APP_TRANSACTIONCNP_PATH}?from=${request.from}&to=${request.to}&sortBy=${request.sortBy}&sortOrder=${request.sortOrder}&page=${request.page}&size=${request.size}&merchantRef=${request.merchantRef}&transactionStatuses=${request.transactionStatuses}&route=${request.route}&scheme=${request.scheme}&profileId=${request.profileId}&transactionId=${request.transactionId}&pan=${request.pan}`);
}