export const OzowTransactionStatus = {

    PAYMENT_INITIATED :"Initiated",
    PAYMENT_FAILED: "Failed",
    PAYMENT_COMPLETED : "Complete",
    PAYMENT_PENDING_INVESTIGATION: "Pending investigation",
    PAYMENT_PENDING:"Pending",
    PAYMENT_REFUNDED: "Refunded",
    PAYMENT_PARTIALLY_REFUNDED:"Partially Refunded",
    PAYMENT_CANCELLED: "Cancelled",
    OZOW_INITIATED: "Ozow Initiated"
}