import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import { getTransactionsFailedReasons } from '../../../services/statisticsService';
import moment from 'moment';
import { CircularProgress, Divider } from '@mui/material';
import { Box } from '@mui/system';
import ComponentLoader from '../../../components/component-loader/componentLoader';
import { Timeline } from '../../../constants/timeline';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    PieChart
} from 'echarts/charts';
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    // DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer, LegendComponent]
);

function DashboardFailedReasons(props) {

    const [statistics, setStatistics] = useState({});
    const [request, setRequest] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props?.profileIdsMerchantIds?.profileIds?.length> 0 && props.selectedTimeline) {
            let fromDate = "";
            let toDate = moment().endOf("day");

            switch (props.selectedTimeline) {
                case Timeline.TWENTYFOURHOURS:
                    fromDate = moment().startOf("day");
                    break;
                case Timeline.ONEWEEK:
                    fromDate = moment().startOf("day").subtract(1, "week");
                    break;
                case Timeline.ONEMONTH:
                    fromDate = moment().startOf("day").subtract(1, "month");
                    break;
                default:
                    break;
            }

            setRequest({
                profileIds: props.profileIdsMerchantIds?.profileIds,
                merchantIds: props.profileIdsMerchantIds?.merchantIds,
                fromDate: fromDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: toDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
            });
            console.log(request)
        } else {
            setStatistics({});
        }
    }, [props.profileIdsMerchantIds, props.selectedTimeline])

    useEffect(() => {
        if (request) {
            setIsLoading(true);
            getTransactionsFailedReasons(request).then(response => {
                // Get top 5 items from results
                let failedReasons = response.data
                if (failedReasons && failedReasons.length > 0) {
                    function compare( a, b ) {
                        if ( a.amount > b.amount ){
                            return -1;
                        }
                        if ( a.amount < b.amount ){
                            return 1;
                        }
                        return 0;
                    }
                    failedReasons.sort( compare );
                    let failedReasonsTopFive = [];
                    for (let i = 0; i < 5; i++) {
                        if (failedReasons[i] != null && failedReasons[i] != undefined) {
                            failedReasonsTopFive.push(failedReasons[i]);
                        }
                    }
                }
                setStatistics(response.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })
        }
    }, [request])

    return (
        <Grid sx={{ pl: 2, pr: 2 }}>
            {(statistics && statistics.length > 0) ?
                <ReactEChartsCore
                    echarts={echarts}
                    option={
                        {
                            title: {
                                show: false
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {c} ({d}%)',
                                confine: true,
                            },
                            legend: {
                                icon: 'circle',
                                bottom: 0,
                                right: 0,
                                padding: [0, 10],
                                textStyle: {
                                    color: "#9898A2",
                                    fontFamily: "montserrat",
                                    fontSize: "12"
                                }
                            },
                            series: [
                                {
                                    type: 'pie',
                                    radius: '60%',
                                    data: [
                                        { value: statistics[0] ? statistics[0].amount : null, name: statistics[0] ? statistics[0].reason : "" },
                                        { value: statistics[1] ? statistics[1].amount : null, name: statistics[1] ? statistics[1].reason : "" },
                                        { value: statistics[2] ? statistics[2].amount : null, name: statistics[2] ? statistics[2].reason : "" },
                                        { value: statistics[3] ? statistics[3].amount : null, name: statistics[3] ? statistics[3].reason : "" },
                                        { value: statistics[4] ? statistics[4].amount : null, name: statistics[4] ? statistics[4].reason : "" }
                                    ],
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ],
                            color: ['#00C1FF', '#034EA2', '#0098D8', '#266798', '#9898A2'],
                            responsive: true,
                            maintainAspectRatio: false
                        }
                    }
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"theme_name"}
                />
                // <Box key={index}>
                //     <Grid container sx={{ mb: 3, mt: 3 }}>
                //         <Grid item sx={{ width: 20 }}>
                //             <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                //                 {index + 1}.
                //             </Typography>
                //         </Grid>
                //         <Grid item sx={{ flexGrow: 1 }}>
                //             <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                //                 {stat.reason}
                //             </Typography>
                //         </Grid>
                //         <Grid item sx={{ flexGrow: 0 }}>
                //             <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                //                 {stat.percentage}%
                //             </Typography>
                //         </Grid>
                //     </Grid>
                //     {index < statistics.length - 1 &&
                //         <Divider />
                //     }
                // </Box>
                :
                <>
                {!props.isLoading || isLoading?
                <Typography sx={{ fontSize: 14, fontWeight: 'bold', mb: 3, mt: 3 }}>
                    No Failed Reasons
                </Typography>
                : <Grid container width="100%" height="300px" alignItems="center" justifyContent="center">
                <CircularProgress />
                </Grid>
                }
                </>
            }
        </Grid>
    )
}

DashboardFailedReasons.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    selectedTimeline: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};

export default DashboardFailedReasons;