import * as React from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CustomTextField } from "../../../components/custom-text-field/customTextField";
import Grid from "@mui/material/Grid";
import { InputAdornment,Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Regex } from '../../../constants/regex';

function SearchBySelect(props) {

    const [values, setValues] = useState({
        selectedSearchBy: 0,
        textSearch: "",
    })

    const performTextSearch = (newValue) => {
        setValues({
            textSearch: newValue,
            selectedSearchBy: (newValue.match(new RegExp(Regex.UUID_PATTERN)))? 0 : 1
        })
    }

    useEffect(() => {
        if (values.textSearch) {
            const timeOut = setTimeout(() => {
                switch (values.selectedSearchBy) {
                    case 0:
                        props.setRequest({
                            ...props.request,
                            transactionId: values.textSearch,
                            merchantRef: "",
                            paymentType: ""
                        })
                        break;
                    case 1:
                        props.setRequest({
                            ...props.request,
                            transactionId: "",
                            merchantRef: values.textSearch,
                            paymentType: ""
                        })
                        break;
                    case 2:
                        props.setRequest({
                            ...props.request,
                            transactionId: "",
                            merchantRef: "",
                            paymentType: values.textSearch
                        })
                        break;
                    default:
                        break;
                }
            }, 2000);

            return () => clearTimeout(timeOut);
        }else {
            props.setRequest({
                ...props.request,
                transactionId: "",
                merchantRef: "",
            })
        }
    }, [values])

    const searchBySelect = [
        "Transaction ID",
        "Merchant Ref",
    ]

    const performSearchBy = (newValue) => {
        setValues({
            ...values,
            selectedSearchBy: newValue,
            textSearch: ""
        })
    }

    return (
        <Grid container columnSpacing={2} alignItems="center" sx={{ mt: 0 }}>
            {/* <Grid item>
                <FormControl size="small" className="transaction-select" sx={{ color: "#FFF", minWidth: "225px", borderColor: "#00C1FF" }}>
                    <InputLabel sx={{ color: "#FFF", fontSize: "14px", letterSpacing: "0" }}>{values.selectedSearchBy === "" ? "Search By" : ""}</InputLabel>
                    <Select
                        label={values.selectedSearchBy === "" ? "Search By" : ""}
                        value={values.selectedSearchBy}
                        sx={{
                            "&.MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#00C1FF"
                                },
                                "&:hover fieldset": {
                                    borderColor: "#0098D8"
                                }
                            },
                            "& .MuiSelect-select": {
                                "&:hover": {
                                    background: "#0098D8",
                                    borderColor: "#0098D8"
                                },
                                color: "#FFF",
                                background: "#00C1FF",
                                borderColor: "#00C1FF",
                                fontSize: "14px",
                                letterSpacing: "0"
                            },
                            "& .MuiSelect-icon": {
                                color: "#FFF"
                            }
                        }}
                        MenuProps={{
                            style: {
                                maxHeight: 400
                            }
                        }}
                        onChange={(event) => performSearchBy(event.target.value)}
                    >
                        {
                            searchBySelect.map((searchBy, index) => (
                                <MenuItem key={index} value={index}
                                >{searchBy}</MenuItem>

                            ))
                        }
                    </Select>
                </FormControl>
            </Grid> */}
            <Grid item>
                <Tooltip title="Search by Transaction ID or Merchant Ref">
                    <CustomTextField
                        size="small"
                        className="transaction-select"
                        label={values.textSearch?values.selectedSearchBy === 0? "Searching by Transaction ID":"Searching by Merchant Ref":"Search"}
                        disabled={values.selectedSearchBy === ""}
                        value={values.textSearch}
                        inputProps={{
                            maxLength: 36
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={event => performTextSearch(event.target.value)}
                    />
                </Tooltip>
            </Grid>
        </Grid>
    );
};

SearchBySelect.propTypes = {
    request: PropTypes.object.isRequired,
    setRequest: PropTypes.func.isRequired
};

export default SearchBySelect;