import {Regex} from "../constants/regex";

export const validateUrl = (url) => {
    if (url.match(new RegExp(Regex.URL_PATTERN))){
        return true;
    }
    return false;
}

export const validateHexColor = (color) => {
    if (color.match(new RegExp(Regex.COLOR_PATTERN))) {
        return true
    }
    return false;
}