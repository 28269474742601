import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import { getTransactionsAmount } from '../../../services/statisticsService';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { AmountFormatter } from '../../../utils/amountFormatter';

const CurrencyAmountInfo = ({ currency, stat, fontSize = 10 }) => {
    if (!currency || !stat) {
        return (
            <Grid container alignItems="center" sx={{ mb: 1, background: '#0098d8', padding: 1, borderRadius: 1 }}>
                <Grid item sx={{ fontSize: 10, color: '#FFF', ml: 1 }}>
                    <Typography className={"loading"}>{currency.symbol} loading...</Typography>
                </Grid>
            </Grid>
        );
    }

    const { symbol, percentageChange, transactionsAmountToday, transactionsAmountLastWeek } = stat;

    return (
        <Grid container alignItems="center" sx={{ mb: 1, background: '#0098d8', paddingLeft: 1, paddingRight: 1, borderRadius: 1 }}>
            <Box sx={{ width: 50, height: '100%', background: '#00c1ff', color: '#FFF', fontSize: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', mr: 1, borderRadius: 1 }}>
                {currency.isoCode}
            </Box>
            <Grid item sx={{ display: 'flex', alignItems: 'center', fontSize, fontWeight: 'bold', color: '#FFF', mr: 1 }}>
                {percentageChange !== 0 && (
                    percentageChange > 0 ? <ArrowUpward sx={{ fontSize: fontSize }} /> : <ArrowDownward sx={{ fontSize: fontSize }} />
                )}
                {percentageChange}%
            </Grid>
            <Box sx={{ height: '24px', width: '1px', background: '#8B929A', mx: 1 }}></Box>
            <Grid item sx={{ display: 'flex', fontSize: 14, color: '#FFF' }}>
                {currency.symbol}: {AmountFormatter(transactionsAmountToday, 2)}
            </Grid>
            <Grid item sx={{ fontSize: 10, color: '#FFF', ml: 'auto' }}>
                Last week: {currency.symbol}: {AmountFormatter(transactionsAmountLastWeek, 2)}
            </Grid>
        </Grid>
    );
};

function DashboardCard2(props) {
    const [statistics, setStatistics] = useState([]);
    const [statisticsChange, setStatisticsChange] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (props?.profileIdsMerchantIds?.profileIds?.length > 0 && props?.selectedCurrencies?.length > 0) {
                const currencyIds = props.selectedCurrencies.map(currency => currency.id);

                try {
                    const response = await getTransactionsAmount(props.profileIdsMerchantIds, currencyIds);

                    const data = props.selectedCurrencies.map(currency => {
                        const currencyData = response?.data?.find(curr => curr.symbol === currency.symbol) || {
                            transactionsAmountToday: 0,
                            transactionsAmountLastWeek: 0,
                            percentageChange: 0,
                            symbol: currency.symbol,
                            isoCode: currency.isoCode
                        };
                        if (!response.data?.[0]?.symbol && props?.selectedCurrencies?.[0]?.id === 0) {
                            return {
                                transactionsAmountToday: response.data?.[0]?.transactionsAmountToday || 0,
                                transactionsAmountLastWeek: response.data?.[0]?.transactionsAmountLastWeek || 0,
                                percentageChange: response.data?.[0]?.percentageChange || 0,
                                symbol: 'All',
                                label: 'ALL',
                                isoCode: 'ALL'
                            };
                        }
                        return {
                            percentageChange: Math.abs(currencyData.percentageChange),
                            transactionsAmountToday: currencyData.transactionsAmountToday,
                            transactionsAmountLastWeek: currencyData.transactionsAmountLastWeek,
                            symbol: currencyData.symbol,
                            label: currencyData.isoCode
                        };
                    });

                    setStatistics(data);

                    const changeStatus = data.map(curr => {
                        if (curr.percentageChange === 0) return "equal";
                        return curr.percentageChange > 0 ? "up" : "down";
                    });
                    setStatisticsChange(changeStatus);
                } catch (error) {
                    console.log('Error fetching transaction amounts:', error);
                    setStatistics([]);
                    setStatisticsChange([]);
                }
            } else {
                console.log('No profile IDs or currencies selected.');
                setStatistics([]);
                setStatisticsChange([]);
            }
        };
        fetchData();
    }, [props.profileIdsMerchantIds?.profileIds, props.selectedCurrencies]);


    const getHeight = () => {
        if (props.selectedCurrencies.length === 1) return '100%';
        if (props.selectedCurrencies.length === 2) return '50%';
        return '33%';
    };

    return (
        <Card sx={{ background: '#00c1ff', height: 'auto', overflowY: 'hidden', minHeight:'140px' }}>
            <Grid sx={{ m: 1 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#FFF' }}>
                    Transaction Volume
                </Typography>
                <Typography sx={{ fontSize: 12, color: '#FFF' }}>
                    today
                </Typography>
                {!props.isLoading ? (
                    <Grid container>
                        {props.selectedCurrencies.map((currency, index) => (
                            <Grid item xs={12} key={currency.isoCode} sx={{ height: getHeight() }}>
                                <CurrencyAmountInfo currency={currency} stat={statistics[index]} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <Grid item sx={{ display: 'flex', flexGrow: 1 }}>
                            <Typography className="loading" fontSize={10} fontWeight='bold'>Loading...</Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
}

DashboardCard2.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    selectedCurrencies: PropTypes.array.isRequired,
    displayName: PropTypes.string,
    isLoading: PropTypes.bool.isRequired
};

export default DashboardCard2;
