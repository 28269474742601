import {
    Alert,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { TransactionActions } from "../../../constants/transactionActions";
import "../../../styles.scss";
import { CustomTextField } from '../../custom-text-field/customTextField';
import { refundTransaction, reverseTransaction, settleTransaction } from "../../../services/cardNotPresentService";
import ComponentLoader from "../../component-loader/componentLoader";
import { TransactionStatus } from "../../../constants/transactionStatus";

import * as React from "react";
import CustomTextMask from "../../mask/customTextMask";
import { SaveOutlined } from "@mui/icons-material";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

export default function Actions(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedAction, setSelectedAction] = useState("");
    const [amount, setAmount] = useState("");
    const [isActionPlaced, setIsActionPlaced] = useState(false);
    const [isPaddedZeros, setIsPaddedZeros] = useState(false);
    const transactionActions = Object.values(TransactionActions);
    const [selectedActionSubmit, setSelectedActionSubmit] = useState("");
    const [selectedActionAmount, setSelectedActionAmount] = useState("");

    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });

    const [allowedActions, setAllowedActions] = useState({
        refund: false,
        reverse: false,
        settle: false
    });

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const performAction = () => {
        setDialogOpen(false);
        switch (selectedAction) {
            case TransactionActions.SETTLE:
                setIsLoading(true);
                settleTransaction({
                    profileId: props.profileId,
                    merchantId: props.merchantId,
                    transactionId: props.transactionId
                }).then(result => {
                    if (result.data.transactionStatus === "PAYMENT_SETTLED") {
                        props.handleTableUpdate(result.data.transactionStatus);
                    }
                    setIsLoading(false);
                    setResponse({
                        severity: "success",
                        isOpen: true,
                        message: "Transaction Settled"
                    });
                    setIsActionPlaced(true);
                }).catch(error => {
                    setIsLoading(false);
                    setResponse({
                        severity: "error",
                        isOpen: true,
                        message: "Unable to settle transaction"
                    });
                    console.log(error)
                })
                break;
            case TransactionActions.REVERSE:
                setIsLoading(true);
                reverseTransaction({
                    profileId: props.profileId,
                    merchantId: props.merchantId,
                    transactionId: props.transactionId
                }).then(result => {
                    if (result.data.transactionStatus === "PAYMENT_REVERSED") {
                        props.handleTableUpdate(result.data.transactionStatus);
                    }
                    setIsLoading(false);
                    setResponse({
                        severity: "success",
                        isOpen: true,
                        message: "Transaction Reversed"
                    });
                    setIsActionPlaced(true);
                }).catch(error => {
                    setIsLoading(false);
                    setResponse({
                        severity: "error",
                        isOpen: true,
                        message: "Unable to reverse transaction"
                    });
                    console.log(error)
                })
                break;
            case TransactionActions.REFUND:
                setIsLoading(true);
                refundTransaction({
                    profileId: props.profileId,
                    merchantId: props.merchantId,
                    transactionId: props.transactionId,
                    amount: amount.replace(props.symbol, ""),
                    currencyCode: props.currencyCode
                }).then(result => {
                    setIsLoading(false);
                    if(result?.data?.transactionStatus !== "PAYMENT_FAILED"){
                    props.handleTableUpdate(result.data.transactionStatus);
                    if (result.data.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED") {
                        setResponse({
                            severity: "success",
                            isOpen: true,
                            message: `Transaction partially refunded for ${props.symbol}${numberWithCommas(getFormattedAmount(amount.replace(props.symbol, "")))}`
                        });
                        setIsActionPlaced(true);
                    } else {
                        setResponse({
                            severity: "success",
                            isOpen: true,
                            message: `Transaction refunded for ${props.symbol}${numberWithCommas(getFormattedAmount(amount.replace(props.symbol, "")))}`
                        });
                        setIsActionPlaced(true);
                    }
                }else{
                    setResponse({
                        severity: "error",
                        isOpen: true,
                        message: result.data.providerResponseDescription
                    });
                }
                }).catch(error => {
                    setIsLoading(false);
                    if (error.response.data.message.includes("Refund attempted for more than the original amount")) {
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Refund attempted for more than the original amount"
                        });
                    } else {
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Unable to refund transaction"
                        });
                    }
                    console.log(error)
                })
                setIsActionPlaced(true);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (props.transactionStatus === TransactionStatus.PAYMENT_AUTHORISED) {
            setSelectedAction("");
            setAllowedActions({
                refund: false,
                reverse: true,
                settle: true
            });
        } else if (props.transactionStatus === TransactionStatus.PAYMENT_SETTLED) {
            setSelectedAction("");
            // let modifiedDate = moment(props.lastModified)
            // if (moment().endOf("day").isAfter(modifiedDate)) {
                setAllowedActions({
                    refund: true,
                    reverse: false,
                    settle: false
                });
            // } else {
            //     setAllowedActions({
            //         refund: false,
            //         reverse: false,
            //         settle: false
            //     });
            // }
            setAmount(props.amount.toString())
        } else if (props.transactionStatus === TransactionStatus.PAYMENT_PARTIALLY_REFUNDED) {
            setSelectedAction("");
            setAllowedActions({
                refund: true,
                reverse: false,
                settle: false
            });
            setAmount((props.amount - props.refundedAmount).toString())
        } else {
            setSelectedAction("");
            setAllowedActions({
                refund: false,
                reverse: false,
                settle: false
            });
        }
    }, [props])

    useEffect(() =>{
        if(selectedAction === "Settle"){
            setSelectedActionSubmit("Settle");
            setSelectedActionAmount(numberWithCommas(getFormattedAmount(props.amount)));
        }
        else
        if(selectedAction === "Reverse"){
            setSelectedActionSubmit("Reversal");
            setSelectedActionAmount(numberWithCommas(getFormattedAmount(props.amount)))
        }
        if(selectedAction === "Refund"){
            setSelectedActionSubmit("Refund");
            setSelectedActionAmount((numberWithCommas(getFormattedAmount(amount.replace(props.symbol, "")))))
        }
    },[selectedAction,amount])

    return (
        <Box width="100%" sx={{ mt: 3 }}>
            <Typography fontSize={16} fontWeight="bold" sx={{ color: "#000000" }}>Perform an Action</Typography>
            <Grid container alignItems="center" sx={{ mt: 3 }}>
                <FormControl fullWidth size="small" className="app-bar-select" sx={{ maxWidth: "300px", mr: 2 }}>
                    <InputLabel>Select Action</InputLabel>
                    <Select
                        label="Select Action"
                        value={selectedAction}
                        onChange={(event) => setSelectedAction(event.target.value)}
                    >
                        {transactionActions.map((action, index) => (
                            <MenuItem key={index} value={action} disabled={
                                (action === TransactionActions.REFUND && !allowedActions.refund) ||
                                (action === TransactionActions.REVERSE && !allowedActions.reverse) ||
                                (action === TransactionActions.SETTLE && !allowedActions.settle) ||
                                (action === TransactionActions.SETTLE && props.transactionStatus === TransactionActions.SETTLE)
                            }>{action}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedAction === TransactionActions.REFUND ?
                    <CustomTextField
                        InputProps={{
                            inputComponent: CustomTextMask,
                            inputProps: {
                                mask: `{${props.symbol}}num`,
                                overwrite: true,
                                blocks: {
                                    num: {
                                        mask: Number,
                                        thousandsSeparator: ',',
                                        max: props.refundedAmount ? props.amount - props.refundedAmount : props.amount,
                                        min: 0,
                                        scale: 2,
                                        radix: '.',
                                        normalizeZeros: true,
                                        padFractionalZeros: isPaddedZeros,
                                        mapToRadix: ['.']
                                    }
                                }
                            }
                        }}
                        value={amount}
                        onChange={(event) => setAmount(event.target.unmaskedValue)}
                        onBlur={()=>{setIsPaddedZeros(true)}}
                        onClick={()=>{setIsPaddedZeros(false)}}
                        label="Refund Amount"
                        size="small" sx={{ width: "300px" }}
                        error={!amount}
                    /> : null
                }
            </Grid>
            <Grid container sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Button
                    disabled={selectedAction === "" || (props.transactionStatus === TransactionStatus.PAYMENT_SETTLED && selectedAction === TransactionActions.SETTLE)}
                    type="button" onClick={handleDialogOpen} variant="contained" sx={{
                        textTransform: "none",
                        background: "#00C1FF",
                        width: "200px",
                        height: "100%",
                        color: "#FFFFFF"
                    }} startIcon={<SaveOutlined />}>
                    Submit
                </Button>
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        You are about to perform an action that will have a financial impact and cannot be reversed. Please confirm that you would like to submit a {selectedActionSubmit} for {props.symbol}{(selectedActionAmount)}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button autoFocus onClick={handleDialogClose} type="button" variant="contained" sx={{
                            textTransform: "none",
                            "&:hover": { background: "#EBFAFF" },
                            background: "#FFFFFF",
                            width: "100px",
                            height: "100%",
                            color: "#00C1FF"
                        }}>
                            Cancel
                        </Button>
                        <Button onClick={performAction} autoFocus type="button" variant="contained" sx={{
                            textTransform: "none",
                            background: "#00C1FF",
                            width: "100px",
                            height: "100%",
                            color: "#FFFFFF"
                        }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography fontSize={14} letterSpacing={0} sx={{ ml: 3, width: "215px", color: "#404047" }}>
                    Please ensure all information is correct before saving
                </Typography>
            </Grid>
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen}
                autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity ? response.severity : ""} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

Actions.propTypes = {
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    refundedAmount: PropTypes.number,
    amount: PropTypes.number.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    lastModified: PropTypes.string.isRequired
};