import axios from 'axios';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';

export const getTransactionsPerformed = (profileIdsMerchantIds, currencyIds) => {
    GoogleAnalytics.getTransactionsPerformed();
    const payload = {
        ...profileIdsMerchantIds,
        currencyId: currencyIds
    };
    return axios.post(`${process.env.REACT_APP_STATISTICS_PERFORMED_PATH}`, payload);
};

export const getTransactionsSuccessful = (profileIdsMerchantIds,currencyIds) => {
    GoogleAnalytics.getTransactionsSuccessful();
    const payload = {
        ...profileIdsMerchantIds,
        currencyId: currencyIds
    };
    return axios.post(`${process.env.REACT_APP_STATISTICS_SUCCESSFUL_PATH}`, payload);
}

export const getTransactionsFailedReasons = (request) => {
    GoogleAnalytics.getTransactionsFailed();
    return axios.post(`${process.env.REACT_APP_STATISTICS_FAILED_REASONS_PATH}`, request);
}

export const getTransactionsAmount = (profileIdsMerchantIds, currencyIds) => {
    GoogleAnalytics.getTransactionsAmount();
    const payload = {
        ...profileIdsMerchantIds,
        currencyId: currencyIds
    };
    return axios.post(`${process.env.REACT_APP_STATISTICS_AMOUNT_PATH}`, payload);
}

export const getTransactionsTimeline = (request) => {
    GoogleAnalytics.getTransactionTimeline();
    return axios.post(`${process.env.REACT_APP_STATISTICS_TIMELINE_PATH}`, request);
}

export const getTransactionsTotalAmount = (request) => {
    GoogleAnalytics.getTransactionsTotalAmount();
    return axios.post(`${process.env.REACT_APP_STATISTICS_TOTAL_AMOUNT_PATH}`, request);
}

export const getTransactionsAmountData = (request) => {
    GoogleAnalytics.getTransactionDataAmount();
    return axios.post(`${process.env.REACT_APP_STATISTICS_AMOUNT_DATA_PATH}`, request);
}

export const getTransactionsHourlyAvg = (request) => {
    GoogleAnalytics.getTransactionHourlyAvg();
    return axios.post(`${process.env.REACT_APP_STATISTICS_HOURLY_AVG_PATH}`, request);
}