import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/system";

const StyledSwitch = styled(Switch, { shouldForwardProp: (prop) => prop !== 'checked' })(({ theme, checked }) => ({
    padding: 0,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "40px",
    width: 83,
    height: 30,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 3,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(54px)',
            color: '#FFFFFF',
            '& + .MuiSwitch-track': {
                backgroundColor: '#000000',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#EBFAFF',
        borderRadius: 22 / 2,
        color: checked ? "#FFFFFF" : "#000000",
        fontFamily: "Montserrat",
        fontSize: 12,
        letterSpacing: 0,
        opacity: 1,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            content: checked ? '"Black"' : '""',
            left: 8,
        },
        '&:after': {
            content: checked ? '""' : '"White"',
            right: 28,
        },
    },
    '& .MuiSwitch-thumb': {
        width: 23,
        height: 23,
        margin: 2,
        marginLeft: 0,
        marginTop: 0.5
    },
}));

export default function ColorSwitch(props) {
    return (
        <FormControlLabel
            control={<StyledSwitch disabled={props.disabled} onChange={props.onChange} checked={props.checked} sx={{ mr: 1 }} />}
            label={props.label}
            sx={{
                ml: 0, color: "#9898A2", "& .MuiFormControlLabel-label": {
                    fontSize: "14px", color: "#9898A2", letterSpacing: 0
                }
            }}
        />
    );
}