import axios from 'axios';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';

export const refundTransaction = (request) => {
  GoogleAnalytics.ApiCallRefund(refundTransaction);
    return axios.post(`${process.env.REACT_APP_REFUND_PATH}`, request);
}

export const settleTransaction = (request) => {
  GoogleAnalytics.ApiCallSettle(settleTransaction);
    return axios.post(`${process.env.REACT_APP_SETTLE_PATH}`, request);
}

export const reverseTransaction = (request) => {
    GoogleAnalytics.ApiCallReverse(reverseTransaction);
    return axios.post(`${process.env.REACT_APP_REVERSAL_PATH}`, request)
}

export const bulkActionRequest = (request) => {
    GoogleAnalytics.bulkActionRequests()
    return axios.post(`${process.env.REACT_APP_BULK_ACTION_PATH}`, request);
};