import { React, StrictMode } from 'react';
import App from './components/app';
import KeycloakService from './keycloak/keycloakService';
import { render } from 'react-dom';
import InactivityDialog from './components/inactivity-dialog/inactivityDialog';
import axios from 'axios';
import { ROLES } from './keycloak/permissionsGate';
import GoogleAnalytics from './utils/googleAnalyticsHelper'

GoogleAnalytics.initializeAnalytics();

const renderApp = () => render(
	<StrictMode>
		<App />
	</StrictMode>,
	document.getElementById('root')
);

axios.interceptors.request.use(function (config) {
	
	KeycloakService.keycloakInstance.updateToken(300)
	.then(refreshed => {
		if (refreshed) {
			console.log(KeycloakService.keycloakInstance.tokenParsed)
			if (process.env.NODE_ENV === "development") {
				console.log(KeycloakService.keycloakInstance.tokenParsed)
			}
		}
	}).catch(() => {
		if (process.env.NODE_ENV === "development") {
			console.log('Failed to refresh the token, or the session has expired');
		}
	});
    const token = KeycloakService.keycloakInstance.token;
    config.headers.Authorization =  "Bearer " + token;
    return config;
});

KeycloakService.keycloakInstance.onTokenExpired = () => {
	render(
		<InactivityDialog dialogOpen={true}  inactivity={true}/>,
		document.getElementById('inactivity-dialog')
	)
}

KeycloakService.initKeycloak(renderApp);


KeycloakService.keycloakInstance.onAuthSuccess = () => {
	if (KeycloakService.isLoggedIn) {
		if (!KeycloakService.hasResourceRole(ROLES.administrator) && !KeycloakService.hasResourceRole(ROLES.readonly)){
			setTimeout(()=>{
				render(
					<InactivityDialog dialogOpen={true}  inactivity={false}/>,
					document.getElementById('inactivity-dialog')
				)
			},3000)
		};
	}
}
