import { getPaymentLinkTransactions } from "../services/paymentLinkService";

import { createSlice } from "@reduxjs/toolkit";




const slice = createSlice({

    name: 'paymentLinkTransactions',

    initialState: {

        paymentLinkTransactions: []

    },

    reducers: {

        success: (state, action) => {
            state.paymentLinkTransactions = action.payload;
            state.isLoading = false;

        },

        notFound: (state, action) => {
            state.paymentLinkTransactions = action;
            state.isLoading = false;
        },

        internalServerError: (state, action) => {

            state.paymentLinkTransactions = action;

            state.isLoading = false;

        }

    }

});


export default slice.reducer;
const { success, notFound, internalServerError } = slice.actions;


export const fetchPaymentLinkTransactions = (request) => async dispatch => {

    try {

        await getPaymentLinkTransactions(request)

            .then((response) => dispatch(success(response.data)));

    } catch(e) {

        console.log(e);

        switch (e.response.status) {

            case 404:

                dispatch(notFound(e.response.data));

                break;

            case 500:

                dispatch(internalServerError(e.response.data));

                break;

            default:

                dispatch(internalServerError(e.response.data));

                break;

        }

    }

}




export const updatePaymentLinkTransactions = (transactions) => async dispatch => {

    dispatch(success(transactions));

}