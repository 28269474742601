import styled from "@emotion/styled";
import { CheckCircleOutline, HighlightOff, PendingOutlined } from "@mui/icons-material";
import { Card, Grid, Popover, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { RcsTransactionStatus } from "../../../constants/rcsTransactionStatus";
import { TransactionStatus } from "../../../constants/transactionStatus";
import rcsTransactions from "../../../stores/rcsTransactions";

const SuccessTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#00C1FF",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#00C1FF",
        color: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029"
    },
}));

function RcsTransactionPaymentStepStatus(props) {

    const [anchorElTransaction, setAnchorElTransaction] = useState(null);
    const [anchorElProviderTransaction, setAnchorElProviderTransaction] = useState(
        {
            anchorElTransaction:null,
            cardVerify:true
        }
    );

    const handleClick = (event) => {
                if (paymentOtp&&paymentCardVerify) {
                    setAnchorElTransaction(event.currentTarget);
                }
    };

    const handleCardVerifyClick = (event) => {
        if(props.transactionDetails?.cardVerification?.code || props.transactionDetails?.cardVerification?.description){
            setAnchorElProviderTransaction({anchorElTransaction:event.currentTarget,cardVerify:true});
        }else
        if(props.transactionDetails?.otpValidation?.code || props.transactionDetails?.otpValidation?.description){
            setAnchorElProviderTransaction({anchorElTransaction:event.currentTarget,cardVerify:false});
        }
};

    const handleCloseTransaction = () => {
        setAnchorElTransaction(null);
    };


    const handleCloseCardTransaction = () => {
        setAnchorElProviderTransaction({anchorElTransaction:null,cardVerify:true});
    };

    const openTransaction = Boolean(anchorElTransaction);
    const openCardVerify = Boolean(anchorElProviderTransaction?.anchorElTransaction);

    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [paymentOtp, setPaymentOtp] = useState(true);
    const [paymentCardVerify, setPaymentCardVerify] = useState(false);

    const [transactionMoreDetails, setTransactionMoreDetails] = useState();

    useEffect(() => {
        setPaymentCardVerify(props.transactionDetails?.cardVerification?.svc=="P");
       if (props.transactionDetails?.cardVerification?.requireOtp){
        setPaymentOtp(props.transactionDetails?.otpValidation?.svc=='P');
       }else{
        setPaymentOtp(true);
       }
       setPaymentSuccessful(props.transactionStatus !== TransactionStatus.PAYMENT_FAILED && props.transactionStatus !== TransactionStatus.PAYMENT_DECLINED);
        let mostRecentDate = new Date(Math.max.apply(null, props.transactionMoreDetails?.map(moreDetail => {
            return new Date(moreDetail.lastModified);
        })));
        setTransactionMoreDetails(props.transactionMoreDetails?.filter(moreDetail => {
            var d = new Date(moreDetail.lastModified);
            return d.getTime() === mostRecentDate.getTime();
        })[0]);
    }, [props])

    const getTransactionName = () => {
        let transactionName = ""
        Object.keys(TransactionStatus).forEach(item => {
            if(TransactionStatus[item] === props.transactionStatus){
                transactionName = item
            } 
        })
        return transactionName
    }



    return (
        <Box width="100%">
            <Grid container columns={11}>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <SuccessTooltip disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? true : false} arrow title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">Request Sent</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                            </Box>
                        } placement="top">
                            <Card sx={{ "&:hover": { cursor: "pointer" }, p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="initial">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: "#404047" }} id="initial">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "Payment Initiated" : "Client sent transaction to provider"}
                                </Typography>
                            </Card>
                        </SuccessTooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.displayName}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        <Box className="pointer" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>
                            <Box className="success-check">
                                {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ?
                                    <PendingOutlined /> : <CheckCircleOutline />
                                }
                            </Box>
                        </Box>
                        {props.transactionStatus != RcsTransactionStatus.PAYMENT_INITIATED?
                            <Box className="pointer pointer-inverted" sx={{ top: "46px", display: "flex", justifyContent: "center" }}>
                                <Box className={paymentSuccessful ? "success-check" : "failed-check"}>
                                    {paymentSuccessful ? <CheckCircleOutline sx={{ transform: "rotate(180deg)" }} /> :
                                        <HighlightOff sx={{ transform: "rotate(180deg)" }} />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    "& .MuiTooltip-arrow": {
                                        color: (props.transactionStatus === RcsTransactionStatus.PAYMENT_COMPLETED || props.transactionStatus ===RcsTransactionStatus.PAYMENT_PARTIALLY_REFUNDED || props.transactionStatus ===RcsTransactionStatus.PAYMENT_PAYMENT_REFUNDED? "#00C1FF" : "#DF372D")
                                    },
                                    "&.MuiTooltip-tooltip": {
                                        backgroundColor: (props.transactionStatus === RcsTransactionStatus.PAYMENT_COMPLETED || props.transactionStatus ===RcsTransactionStatus.PAYMENT_PARTIALLY_REFUNDED || props.transactionStatus ===RcsTransactionStatus.PAYMENT_REFUNDED ? "#00C1FF" : "#DF372D"),
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 6px #00000029"
                                    }
                                }
                            }
                        }} disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? true : false} title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">
                                    {props.transactionStatus === RcsTransactionStatus.COMPLETED ||props.transactionStatus === RcsTransactionStatus.PAYMENT_PARTIALLY_REFUNDED || props.transactionStatus ===RcsTransactionStatus.PAYMENT_REFUNDED ? "Request Sent" : "Failed"}
                                </Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.threeDSecureDetails?.record?.dateLogged).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                            </Box>
                        } placement="top">
                            <Card onClick={handleCardVerifyClick} sx={{ "&:hover": { cursor: "pointer" }, p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="3DSecure">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: "#404047" }} id="3DSecure">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "Initiated" : "Provider sent response"}
                                </Typography>
                            </Card>
                        </Tooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "None" : "Card Verification"}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        {props.transactionStatus != RcsTransactionStatus.PAYMENT_INITIATED?
                            <Box className="pointer" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>
                                <Box className={(paymentCardVerify && paymentOtp ? "success-check":"failed-check" )}>
                                    {props.transactionStatus === RcsTransactionStatus.PAYMENT_INITIATED ? <PendingOutlined /> :
                                        paymentCardVerify && paymentOtp ?  <CheckCircleOutline />:
                                            <HighlightOff />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                        {props.transactionStatus != RcsTransactionStatus.PAYMENT_INITIATED?
                            <Box className="pointer pointer-inverted" sx={{ top: "46px", display: "flex", justifyContent: "center" }}>
                                <Box className={paymentSuccessful ? "success-check" : "failed-check"}>
                                    {paymentSuccessful ? <CheckCircleOutline sx={{ transform: "rotate(180deg)" }} /> :
                                        <HighlightOff sx={{ transform: "rotate(180deg)" }} />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    "& .MuiTooltip-arrow": {
                                        color: (paymentSuccessful ? "#00C1FF" : "#DF372D")
                                    },
                                    "&.MuiTooltip-tooltip": {
                                        backgroundColor: (paymentSuccessful ? "#00C1FF" : "#DF372D"),
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 6px #00000029"
                                    }
                                }
                            }
                        }}  disableHoverListener={props.transactionStatus === RcsTransactionStatus.PAYMENT_INITIATED || RcsTransactionStatus.DECLINED } title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">{paymentSuccessful ? "Success" : "Failed"}</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.lastModified).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                {!paymentSuccessful &&
                                    <Typography fontSize={10} fontWeight="bold" textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning}</Typography>
                                }
                            </Box>
                         } placement="top">
                            <Card onClick={handleClick} sx={{ "&:hover": { cursor: "pointer" }, background: (props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED || props.transactionStatus === TransactionStatus._3DS_INITIATED || props.transactionStatus === TransactionStatus._3DS_FAILED || props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "#FFFFFF" : paymentSuccessful ? "#08F9BA" : "#DF372D"), p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="transaction">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: paymentSuccessful ? "#404047": "#FFFFFF" }} id="transaction">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED|| props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED ||props.transactionStatus === TransactionStatus._3DS_INITIATED ||props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "" : TransactionStatus[getTransactionName()]}
                                    {props.transactionStatus === TransactionStatus.PAYMENT_FAILED ? 
                                    <Typography fontSize={14}  textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning ? transactionMoreDetails?.iso8583ResponseCodeMeaning : ""  }</Typography>
                                : ""}
                                </Typography>
                            </Card>
                        </Tooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            RCS
                        </Typography>
                    </Card>
                </Grid>
            </Grid >
            <Popover
                open={openTransaction}
                anchorEl={anchorElTransaction}
                onClose={handleCloseTransaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ background: "#EBFAFF", p: 2 }}>
                    <Typography fontSize={22} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>
                        {paymentSuccessful ? "Successful" : "Failed"}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>RRN: </b>{props.transactionDetails?.rrn}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response Code: </b>{props.transactionDetails?.responseCode}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response Code Meaning: </b>{props.transactionDetails?.responseText}
                    </Typography>
                </Box>
            </Popover>
            
            <Popover
                open={openCardVerify}
                anchorEl={anchorElProviderTransaction?.anchorElTransaction}
                onClose={handleCloseCardTransaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ background: "#EBFAFF", p: 2 }}>
                    <Typography fontSize={22} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>
                        {paymentSuccessful ? "Successful" : "Failed"}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response Code: </b>{anchorElProviderTransaction?.cardVerify? props.transactionDetails?.cardVerification?.code :props.transactionDetails?.otpValidation?.code?props.transactionDetails?.otpValidation?.code:"None" }
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response description: </b>{anchorElProviderTransaction?.cardVerify? props.transactionDetails?.cardVerification?.description :props.transactionDetails?.otpValidation?.description?props.transactionDetails?.otpValidation?.description:"None"}
                    </Typography>
                </Box>
            </Popover>
        </Box >
    );
}

RcsTransactionPaymentStepStatus.propTypes = {
    displayName: PropTypes.string.isRequired,
    transactionDetails: PropTypes.object,
    transactionStatus: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array
};

export default RcsTransactionPaymentStepStatus;