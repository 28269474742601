import * as React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { PaymentType } from '../../../constants/paymentType';

function TransactionNav() {

    const navigate = useNavigate();

    const [selected, setSelected] = useState("all");
    
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [navSelects, setNavSelects] = useState({
        isRcsEnabled:false,
        isOzowEnabled:false,
        isZapperEnabled:false
    })

    const switchPage = (page) => {
        setSelected(page);
        navigate(page, {replace : true});
    }

    useEffect(() => {
        merchantProfiles?.content?.forEach(p => {
            p?.profileProducts?.forEach(product =>{
                if(product?.productCode === "RCS"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isRcsEnabled: true
                    }));
                }else
                if(product?.productCode === "OZOW"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isOzowEnabled: true
                    }));
                }
                if(product?.productCode === "ZAPPER"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isZapperEnabled: true
                    }));
                }
            })
        })
    }, [merchantProfiles])

    useEffect(() => {
        if (window.location.pathname === "/transactions") {
            switchPage("all");
        }
    },[navigate])

    useEffect(() => {
        if (window.location.href.includes("transactions/all")) {
            setSelected("all")
        }
        if (window.location.href.includes("transactions/card")) {
            setSelected("card")
        } 
        if(window.location.href.includes("transactions/rcs")){
            setSelected("rcs")
        }
        if(window.location.href.includes("transactions/ozow")){
            setSelected("ozow")
        }
        if(window.location.href.includes("transactions/zapper")){
            setSelected("zapper")
        }
    },[selected])

    return (
        <Grid sx={{ mb: 1, position: "sticky", zIndex: 1004 }} className="nav-tab">
            <Button sx={{ borderRadius: 0 }} variant="contained" className={selected === "all" ? "nav-selected-button nav-button" : "nav-button"} onClick={() => switchPage("all")}>All</Button>
            <Button sx={{ borderRadius: 0 }} variant="contained" className={selected === "card" ? "nav-selected-button nav-button" : "nav-button"} onClick={() => switchPage("card")}>Card</Button>
            {navSelects?.isOzowEnabled?<Button sx={{ borderRadius: 0 }} variant="contained" className={selected === "ozow" ? "nav-selected-button nav-button" : "nav-button"} onClick={() => switchPage("ozow")}>Ozow</Button>:null}
            {navSelects?.isRcsEnabled?<Button sx={{ borderRadius: 0 }} variant="contained" className={selected === "rcs" ? "nav-selected-button nav-button" : "nav-button"} onClick={() => switchPage("rcs")}>RCS</Button>:null}
            {navSelects?.isRcsEnabled?<Button sx={{ borderRadius: 0 }} variant="contained" className={selected === "zapper" ? "nav-selected-button nav-button" : "nav-button"} onClick={() => switchPage("zapper")}>Zapper</Button>:null}
        </Grid>
    )
}

export default TransactionNav;