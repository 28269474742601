import * as React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Card, Link, List, ListItem } from "@mui/material";
import { Email, SupportAgent } from "@mui/icons-material";

function HelpAndSupport() {

    return (
        <Box sx={{ mt: 2, mb: 2, ml: 3, mr: 3 }}>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid container direction="column">
                    <Typography fontSize={22} letterSpacing={0} fontWeight="bold" sx={{ color: "#404047" }}>Need help? We have your back.</Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Support might seem simple, but there is a lot that happens behind the scenes to ensure that your questions are answered quickly, and any issues you might have are resolved as soon as possible.</Typography>
                </Grid>
                <Grid container direction="column" sx={{ mt: 4 }}>
                    <Typography fontSize={16} fontWeight={700} sx={{ color: "#404047" }}>How to get in touch:</Typography>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item>
                            <Card sx={{ p: 4, minWidth: 300, display: "flex", height: "100%" }}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Email />
                                    </Grid>
                                    <Grid item>
                                        <Typography fontSize={16} fontWeight={700} sx={{ color: "#404047" }}>Email</Typography>
                                        <Link fontSize={14} fontWeight={400} sx={{ color: "#404047", textDecorationColor: "#404047" }} href="mailto:support@switch.tj">support@switch.tj</Link>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card sx={{ p: 4, minWidth: 300, display: "flex" }}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <SupportAgent />
                                    </Grid>
                                    <Grid item>
                                        <Typography fontSize={16} fontWeight={700} sx={{ color: "#404047" }}>Call</Typography>
                                        <Link fontSize={14} fontWeight={400} sx={{ color: "#404047", textDecorationColor: "#404047" }} href="tel:086-111-4958">086 111 4958</Link>
                                        <Typography fontSize={12} fontWeight={400} sx={{ color: "#404047" }}>Extended hours: 7am to 10pm</Typography>
                                        <Typography fontSize={12} fontWeight={400} sx={{ color: "#404047" }}>Priority 1 issues: 10pm to 7am</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{ mt: 4 }}>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>When you get in touch, please ensure that you provide us with the below information, so that our teams can get right to work resolving your query:</Typography>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Your company details</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Company name, your name and contact number.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>What went wrong?</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Describe what happened.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>How can we make it right?</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Describe what we can do to help. The more specific you are, the quicker we can resolve the problem for you.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Details of specific transactions</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>If the problem is related to a transaction, let us know the transaction reference and date, so that we can quickly find what we are looking for.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{ mt: 4 }}>
                    <Typography fontSize={16} fontWeight={700} sx={{ color: "#404047" }}>How do we prioritise your support request?</Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#9898A2" }}>Every query is important, we understand that. In order to give you the best service possible we prioritize calls according to the impact they have on your business. The priorities of calls and associated response and resolution times are fully defined below.</Typography>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Priority</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Description</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>High (P1)</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Loss of core functionality, the system is down, you are not able to process any transactions.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Medium (P2)</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Degraded or intermittent functionality affecting some functions (for example, some card types or transaction types are not working). It does not prevent you from accepting payment.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Low (P3)</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Single, isolated transactions impacted; some investigation is required to identify the issue.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Information Request (P4)</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Request for information, additional reporting, configuration changes, little to no impact to business-as-usual functionality.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 2, ml: 2 }}>
                    <List sx={{ listStyleType: "disc"}}>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Any issue that has been handed over to an external 3rd party for additional support, will be placed on hold and will follow their SLA timeframes</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Junction provides 24x7 support for P1 issues</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item", p: 0 }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>P2 - P4 are worked on during business hours</Typography>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HelpAndSupport;