import React, { useEffect, useState } from 'react';
import { Card, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { Box, color } from "@mui/system";
import CardTransactionMoreInfo from "./more-info/cardTransactionMoreInfo";
import RcsTransactionMoreInfo from "./more-info/rcsTransactionMoreInfo";
import ZapperTransactionMoreInfo from './more-info/zapperTransactionMoreInfo';
import { PaymentType } from '../../constants/paymentType';
import { TransactionStatus } from '../../constants/transactionStatus';
import TransactionMoreInfoOzow from './more-info/transactionMoreInfoOzow';

function TransactionMoreInfo(props) {
    return (
        <Box>
            {
                props.moreInfoRequest?.paymentType == PaymentType.ZAPPER.toUpperCase() ?
                    <ZapperTransactionMoreInfo
                    isOpen={props.moreInfoRequest?.isOpen}
                    handleBack={props.handleMoreInfoBackClick}
                    transactionId={props.moreInfoRequest?.transactionId}
                    symbol={props.moreInfoRequest?.symbol}
                    amount={props.moreInfoRequest?.amount}
                    expectedAmount={props.moreInfoRequest?.expectedAmount}
                    transactionStatus={TransactionStatus[props.moreInfoRequest?.transactionStatus] ? TransactionStatus[props.moreInfoRequest?.transactionStatus] : ""}
                    displayName={props.moreInfoRequest?.displayName}
                    createdAt={props.moreInfoRequest?.createdAt}
                    lastModified={props.moreInfoRequest?.lastModified}
                    transactionMoreDetails={props.moreInfoRequest?.transactionMoreDetails}
                    merchantRef={props.moreInfoRequest?.merchantRef}
                    currencyCode={props.moreInfoRequest?.currencyCode}
                    merchantId={props.moreInfoRequest?.merchantId ? props.moreInfoRequest?.merchantId : ""}
                    profileId={props.moreInfoRequest?.selectedprofile ? props.moreInfoRequest?.selectedprofile : ""}
                    setMoreInfoRequest={props.setMoreInfoRequest}
                    />
                :
                props.moreInfoRequest?.paymentType == PaymentType.RCS ?
                    <RcsTransactionMoreInfo
                        isOpen={props.moreInfoRequest?.isOpen}
                        transactionId={props.moreInfoRequest?.transactionId}
                        handleBack={props.handleMoreInfoBackClick}
                        displayName={props.moreInfoRequest?.displayName}
                        symbol={props.moreInfoRequest?.symbol}
                        amount={props.moreInfoRequest?.amount}
                        createdAt={props.moreInfoRequest?.createdAt}
                        lastModified={props.moreInfoRequest?.lastModified}
                        transactionStatus={
                            TransactionStatus[props.moreInfoRequest?.transactionStatus] ? TransactionStatus[props.moreInfoRequest?.transactionStatus] : ""}
                        transactionMoreDetails={props.moreInfoRequest?.transactionMoreDetails}
                        merchantRef={props.moreInfoRequest?.merchantRef}
                        currencyCode={props.moreInfoRequest?.currencyCode}
                        profileId={props.moreInfoRequest?.selectedprofile ? props.moreInfoRequest?.selectedprofile : ""}
                        merchantId={props.moreInfoRequest?.merchantId ? props.moreInfoRequest?.merchantId : ""}
                        handleTableUpdate={props.handleTableUpdate}
                        setMoreInfoRequest={props.setMoreInfoRequest} />
                    :
                    props.moreInfoRequest?.paymentType == PaymentType.OZOW.toUpperCase() ?
                    <TransactionMoreInfoOzow
                    isOpen={props.moreInfoRequest?.isOpen}
                    transactionId={props.moreInfoRequest?.transactionId}
                    handleBack={props.handleMoreInfoBackClick}
                    displayName={props.moreInfoRequest?.displayName}
                    symbol={props.moreInfoRequest?.symbol}
                    amount={props.moreInfoRequest?.amount}
                    createdAt={props.moreInfoRequest?.createdAt}
                    lastModified={props.moreInfoRequest?.lastModified}
                    transactionStatus={
                                    TransactionStatus[props.moreInfoRequest?.transactionStatus] ? TransactionStatus[props.moreInfoRequest?.transactionStatus] : ""}
                    transactionMoreInfoOzow={props.moreInfoRequest?.transactionMoreInfoOzow}
                    profileId={props.moreInfoRequest?.selectedprofile ? props.moreInfoRequest?.selectedprofile : ""}
                    merchantRef={props.moreInfoRequest?.merchantRef}
                    currencyCode={props.moreInfoRequest?.currencyCode}
                    maskedAccountNumber= {props.moreInfoRequest?.maskedAccountNumber}
                    provider={props.moreInfoRequest?.provider}
                    response={props.moreInfoRequest?.response}
                    successUrl={props.moreInfoRequest?.successUrl}
                    errorUrl={props.moreInfoRequest?.errorUrl}
                    merchantId={props.moreInfoRequest?.merchantId ? props.moreInfoRequest?.merchantId : ""}
                    handleTableUpdate={props.handleTableUpdate}
                    setMoreInfoRequest={props.setMoreInfoRequest}
                    bankName={props.moreInfoRequest?.bankName}
                    toBankName={props.moreInfoRequest?.toBankName}/>
                    :
                    <CardTransactionMoreInfo
                        isOpen={props.moreInfoRequest?.isOpen}
                        transactionId={props.moreInfoRequest?.transactionId}
                        handleBack={props.handleMoreInfoBackClick}
                        displayName={props.moreInfoRequest?.displayName}
                        symbol={props.moreInfoRequest?.symbol}
                        amount={props.moreInfoRequest?.amount}
                        createdAt={props.moreInfoRequest?.createdAt}
                        lastModified={props.moreInfoRequest?.lastModified}
                        transactionStatus={
                            props.moreInfoRequest?.transactionStatus === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] :
                                props.moreInfoRequest?.transactionStatus === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] :
                                props.moreInfoRequest?.transactionStatus === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] :
                                props.moreInfoRequest?.transactionStatus === "3DS_ABANDONED" ? TransactionStatus["_3DS_ABANDONED"] :
                                        TransactionStatus[props.moreInfoRequest?.transactionStatus] ? TransactionStatus[props.moreInfoRequest?.transactionStatus] : ""}
                        transactionMoreDetails={props.moreInfoRequest?.transactionMoreDetails}
                        transactionCardDetails={props.moreInfoRequest?.transactionCardDetails}
                        merchantRef={props.moreInfoRequest?.merchantRef}
                        currencyCode={props.moreInfoRequest?.currencyCode}
                        profileId={props.moreInfoRequest?.selectedprofile ? props.moreInfoRequest?.selectedprofile : ""}
                        merchantId={props.moreInfoRequest?.merchantId ? props.moreInfoRequest?.merchantId : ""}
                        handleTableUpdate={props.handleTableUpdate}
                        setMoreInfoRequest={props.setMoreInfoRequest} />
            }
        </Box>
    );
}

TransactionMoreInfo.propTypes = {
    moreInfoRequest: PropTypes.array,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired,
    handleMoreInfoBackClick: PropTypes.func.isRequired
};

export default TransactionMoreInfo;