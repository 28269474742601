import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    BarChart
} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import moment from "moment";
import { useEffect, useState } from 'react';
import { getTransactionsHourlyAvg } from '../../../services/statisticsService';
import { CircularProgress, Grid, Typography } from '@mui/material';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, LegendComponent]
);

const colorMapping = ['#034EA2', '#df372d', '#00cf3a', '#f1c40f', '#e67e22', '#9b59b6', '#1abc9c'];

function PaymentsByTimeCharts(props) {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        if (props.profileIdsMerchantIds?.profileIds && props.selectedRange) {
            const currencyIds = props.selectedCurrencies ? props.selectedCurrencies.map(currency => currency.id) : [0];
            getTransactionsHourlyAvg({
                profileIds: props.profileIdsMerchantIds?.profileIds,
                fromDate: moment(props.selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(props.selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                currencyId: currencyIds
            }).then(response => {
                let tempData = {};
                response.data.forEach(data => {
                    const currencySymbol = data.symbol || 'All';
                    if (!tempData[currencySymbol]) {
                        tempData[currencySymbol] = [];
                    }
                    tempData[currencySymbol].push([moment().hours(data.hour).minutes(0).format("YYYY-MM-DDTHH:mm"), data.amount]);
                });
                setTransactions(Object.keys(tempData).map((symbol, index) => ({
                    name: symbol,
                    data: tempData[symbol],
                    color: colorMapping[index % colorMapping.length]
                })));
            }).catch(error => {
                console.log(error)
            })
        } else {
            setTransactions([]);
        }
    }, [props.selectedRange, props.selectedCurrencies, props.profileIdsMerchantIds?.profileIds]);

    const legendOption = {
        data: transactions.map(series => ({
            name: series.name,
            itemStyle: {
                color: series.color
            },
            symbol: 'circle'
        })),
        bottom: 0,
        right: 10,
        textStyle: {
            color: "#9898A2",
            fontFamily: "montserrat",
            fontSize: "10"
        }
    };

    const seriesData = transactions.map(series => ({
        name: series.name,
        type: 'bar',
        data: series.data.map(item => ({
            value: item[1],
            name: series.name,
            itemStyle: { color: series.color }
        })),
    }));

    return (
        <>
            {transactions.length > 0 ? (
                <ReactEChartsCore
                    echarts={echarts}
                    option={{
                        grid: {
                            right: 80,
                            top: 20,
                            left: 80
                        },
                        xAxis: {
                            type: 'category',
                            data: transactions[0].data.map(item => item[0]),
                            axisLabel: {
                                formatter: axisValue => moment(axisValue).format("HH:mm")
                            },
                            name: "Date/Time",
                            nameLocation: "start",
                            nameGap: 10,
                            nameTextStyle: {
                                align: 'right',
                                verticalAlign: 'top',
                                color: "#9898A2",
                                fontFamily: "montserrat",
                                fontSize: "12",
                                fontWeight: "bold",
                                padding: [52, -85, 0, 0],
                            },
                            axisLine: {
                                symbol: 'none',
                                lineStyle: {
                                    type: 'solid'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisPointer: {
                                show: false
                            }
                        },
                        yAxis: {
                            alignTicks: true,
                            type: 'value',
                            name: "Transactions",
                            nameRotate: "90",
                            nameLocation: "center",
                            nameTextStyle: {
                                color: "#9898A2",
                                fontFamily: "montserrat",
                                fontSize: "12",
                                fontWeight: "bold",
                                padding: [0, 0, 50, 0]
                            },
                            axisLine: {
                                show: true,
                                symbol: "none",
                                lineStyle: {
                                    type: 'solid',
                                    width: 1,
                                    opacity: 1
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    width: 1,
                                    opacity: 1
                                }
                            },
                            axisPointer: {
                                show: true
                            },
                            axisLabel: {
                                zlevel: 2,
                                hideOverlap: true
                            }
                        },
                        legend: legendOption,
                        series: seriesData,
                        color: colorMapping,
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer: {
                                type: 'cross'
                            }
                        }
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"theme_name"}
                />
            ) : (
                <Grid container width="100%" height="300px" alignItems="center" justifyContent="center">
                    {!props.isLoading ? (
                        <Typography>No Data Available</Typography>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            )}
        </>
    );
}

PaymentsByTimeCharts.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    selectedRange: PropTypes.object,
    selectedCurrencies: PropTypes.array
};

export default PaymentsByTimeCharts;
