import React, { useEffect, useRef, useState } from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from 'prop-types';
import profile from '../../stores/profile';
import { Chip, createFilterOptions, OutlinedInput, TextField, Typography, Stack, CircularProgress } from '@mui/material';
import GoogleAnalytics from '../../utils/googleAnalyticsHelper'
import { CustomAutoComplete } from '../custom-autocomplete/customAutoComplete';

function ProfileSelect(props) {

    const [tempSelectedProfile, setTempSelectedProfile] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const textFieldRef = useRef(null);
    const [toggle, setToggle] = useState(true);

    const filter = createFilterOptions();

    const handleClick = () => {
        setToggle(!toggle)
      };

    useEffect(() => {
        if (props.profiles.length > 0) {
            setIsLoading(true)
            if (window.localStorage.getItem("selectedProfile") && window.localStorage.getItem("selectedProfile") !== "") {
                const selectedProfile = window.localStorage.getItem("selectedProfile").split(',');
                let selectedProfiles = [];
                if (selectedProfile?.length > 0) {
                    selectedProfile.forEach(profilesId => {
                        if (props.profiles.find(p => p.profileId === profilesId)) {
                            selectedProfiles.push(props.profiles.find(p => p.profileId === profilesId));

                        }
                    });
                    GoogleAnalytics.profilesSelected()
                    if (selectedProfiles?.length < 1) {
                        handleChange("", [...props.profiles]);
                    }
                }
                if (selectedProfiles?.length > 0) {
                    handleChange("", selectedProfiles)
                }
            } else {
                if (!props.selectedProfile || props.selectedProfile?.length === 0) {
                    handleChange("", [...props.profiles]);
                }
            }
        } else {
            setTempSelectedProfile([]);
        }
    }, [props.profiles])

    const handleDelete = (profileId) => {
        let unselectedProfile = tempSelectedProfile.filter((profile) => profile.profileId !== profileId);
        handleChange("", unselectedProfile);
    }
    

    const getTagLimit = (values) => {
        let textFieldWidth = textFieldRef.current ? textFieldRef.current.offsetWidth : 0
        if (textFieldWidth > 0) {
            textFieldWidth -= 62
        }
        let selectedValueWidth = 0;
        let limitCount = 0;
        values.forEach(value => {
            if (selectedValueWidth + (value?.displayName?.length * 7) + 78 <= textFieldWidth) {
                selectedValueWidth += ((value?.displayName?.length * 7) + 78)
                limitCount++
            }
        });
        return limitCount
    }

    const handleChange = (event, value) => {
        let selectedProfilesId = [];
        let tempProfiles = [];
        if (value.find(p => p.profileId === "0")) {
            props.profiles.forEach(element => {
                selectedProfilesId.push(element.profileId);
                tempProfiles.push(element);
            });
        } else {
            value.forEach(element => {
                selectedProfilesId.push(element.profileId);
                tempProfiles.push(element);
            });
        };
        window.localStorage.setItem("selectedProfile", selectedProfilesId);
        setTempSelectedProfile(tempProfiles);
        setIsLoading(false)
        props.handleChange(selectedProfilesId);
    }

    
        if(toggle){
            return(
            <div>

                <FormControl sx={props.sx} size="small" className="dashboard-select">
                    <CustomAutoComplete ListboxProps={{ style: { maxHeight: '15rem' } }}
                        multiple
                        size="small"
                        disableListWrap={true}
                        options={props.profiles}
                        noOptionsText={"none"}
                        getOptionLabel={(option) => option.displayName}
                        onChange={(event, newValue) => handleChange(event, newValue)}
                        value={tempSelectedProfile}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            return [{ displayName: "Select All", profileId: "0" }, ...filtered];
                        }}
                        renderTags={(value, getTagProps) => {
                            const numTags = value.length;
                            const limitTags = getTagLimit(value)

                            return (
                                <>
                                    <Stack direction="row" spacing={0.5}>{value.slice(0, limitTags).map((option) =>
                                        <Chip size='small'
                                            key={option.profileId}
                                            label={option.displayName}
                                            onDelete={() => { handleDelete(option.profileId) }} />)}
                                    </Stack> {numTags > limitTags && `  +${numTags - limitTags}`}
                                </>
                            );

                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                ref={textFieldRef}
                                label="Select Profile"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {props.isLoading || isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />)
                        }
                    />
            </FormControl>
          </div>)}else {
    return <div style={{width:"0px"}}></div>
  }
       
    
}

ProfileSelect.propTypes = {
    profiles: PropTypes.array.isRequired,
    selectedProfile: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default ProfileSelect;