import {
    Alert,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Typography,
    Tooltip
  } from "@mui/material";
  import { Box } from "@mui/system";
  import PropTypes from 'prop-types';
  import { useEffect, useState } from "react";
  import { TransactionActions } from "../../../constants/transactionActions";
  import "../../../styles.scss";
  import { CustomTextField } from '../../custom-text-field/customTextField';
  import ComponentLoader from "../../component-loader/componentLoader";
  import { TransactionStatus } from "../../../constants/transactionStatus";
  
  import * as React from "react";
  import CustomTextMask from "../../mask/customTextMask";
  import { SaveOutlined } from "@mui/icons-material";
  import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";
  import { OzowTransactionActions } from '../../../constants/ozowTransactionActions';
  import { OzowTransactionStatus } from '../../../constants/ozowTransactionStatus';
  import { refundOzowTransaction } from "../../../services/ozowTransactionService";
  import { ZapperTransactionStatus } from "../../../constants/zapperTransactionStatus";
import { ZapperTransactionActions } from "../../../constants/zapperTransactionAction";
import { refundZapperTransaction } from "../../../services/zapperTransactionService";
  //line above must change once refund functoinality is complete

  export default function ZapperActions(props) {
  
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAction, setSelectedAction] = useState("");
    const [amount, setAmount] = useState("");
  
    const transactionActions = Object.values(ZapperTransactionActions);
    const [selectedActionSubmit, setSelectedActionSubmit] = useState("");
      const [isPaddedZeros, setIsPaddedZeros] = useState(false);
    const [selectedActionAmount, setSelectedActionAmount] = useState("");
  
    const [response, setResponse] = useState({
        severity: "success",
        isOpen: false,
        message: ""
    });
  
    const [allowedActions, setAllowedActions] = useState({
        refund: false
    });
  
    const [dialogOpen, setDialogOpen] = useState(false);
  
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
  
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
  
    const performAction = () => {
        setDialogOpen(false);
        switch (selectedAction) {
            case ZapperTransactionActions.REFUND:
                setIsLoading(true);
                refundZapperTransaction({

                    profileId: props.profileId,
                    merchantId: props.merchantId,
                    transactionId: props.transactionId,
                    amount: amount.replace(props.symbol, ""),
                    currencyCode: props.currencyCode
                }).then(result => {
                    setIsLoading(false);
                    if(result.data?.ZapperTransactionStatus == "PAYMENT_FAILED"){
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Unable to refund transaction"
                        });
                    }else
                    if (result.data?.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED") {
                        props.handleTableUpdate(result.data?.transactionStatus);
                        setResponse({
                            severity: "success",
                            isOpen: true,
                            message: `Transaction partially refunded for ${amount}`
                        });
                    } else {
                        props.handleTableUpdate(result.data?.transactionStatus);
                        setResponse({
                            severity: "success",
                            isOpen: true,
                            message: `Transaction refunded for ${amount}`
                        });
                    }
                }).catch(error => {
                    setIsLoading(false);
                    if (error.response?.data?.message?.includes("Refund attempted for more than the original amount")) {
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Refund attempted for more than the original amount"
                        });
                    } else {
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Unable to refund transaction"
                        });
                    }
                    if (error?.response?.data?.message?.includes("The amount cannot be less than 1 Rand. Please enter valid amount to refund")) {
                        setResponse({
                            severity: "error",
                            isOpen: true,
                            message: "Refund cant be less than 1 rand"
                        });
                    }
                    console.log(error)
                })
                break;
            default:
                break;
        }
    }
  
    

    useEffect(() => {
        if (props.transactionStatus === ZapperTransactionStatus.PAYMENT_COMPLETED ) {
            setSelectedAction("");
            setAllowedActions({
                refund: true
            });
            console.log("1")
            setAmount(props.amount.toString())
        } else if (props.transactionStatus === ZapperTransactionStatus.PAYMENT_PARTIALLY_REFUNDED) {
            setSelectedAction("");
            setAllowedActions({
                refund: true
            });
            setAmount((props.amount - (props?.refundedAmount ?? 0)).toString())
        } else {
            setSelectedAction("");
            setAllowedActions({
                refund: false
            });
        }
    }, [props])
  
    useEffect(() =>{
        if(selectedAction === "Refund"){
            setSelectedActionSubmit("Refund");
            setSelectedActionAmount((numberWithCommas(getFormattedAmount(amount.replace(props.symbol, "")))))
        }
    },[selectedAction,amount])
  
    return (
        <Box width="100%" sx={{ mt: 3 }}>
            <Typography fontSize={16} fontWeight="bold" sx={{ color: "#000000" }}>Perform an Action</Typography>
            <Grid container alignItems="center" sx={{ mt: 3 }}>
                <FormControl fullWidth size="small" className="app-bar-select" sx={{ maxWidth: "300px", mr: 2 }}>
                    <InputLabel>Select Action</InputLabel>
                    <Select
                        label="Select Action"
                        value={selectedAction}
                        onChange={(event) => setSelectedAction(event.target.value)}
                    >
                        {transactionActions.map((action, index) => (
                            <MenuItem key={index} value={action} disabled={
                                (action === TransactionActions.REFUND && !allowedActions.refund)
                            }>{action}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
  
                {selectedAction === TransactionActions.REFUND ?
                <Tooltip title="Refunds for amounts less than R1.00 will be rounded up to R1">
                    <CustomTextField
                        InputProps={{
                            inputComponent: CustomTextMask,
                            inputProps: {
                                mask: `{${props.symbol}}num`,
                                blocks: {
                                    num: {
                                        mask: Number,
                                        thousandsSeparator: ',',
                                        max: props.refundedAmount ? props.amount - props.refundedAmount : props.amount,
                                        min: 1,
                                        scale: 2,
                                        radix: '.',
                                        normalizeZeros: true,
                                        padFractionalZeros: isPaddedZeros,
                                        mapToRadix: ['.']
                                    }
                                }
                            }
                        }}
                        value={amount}
                        onChange={(event) => setAmount(event.target.unmaskedValue)}
                        onBlur={()=>{setIsPaddedZeros(true)}}
                        onClick={()=>{setIsPaddedZeros(false)}}
                        label="Refund Amount"
                        size="small" sx={{ width: "300px" }}
                    /> 
                    </Tooltip>: null
                }
            </Grid>
            <Grid container sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Button
                    disabled={selectedAction === "" || (props.transactionStatus === TransactionStatus.PAYMENT_SETTLED && selectedAction === TransactionActions.SETTLE)||!amount}
                    type="button" onClick={handleDialogOpen} variant="contained" sx={{
                        textTransform: "none",
                        background: "#00C1FF",
                        width: "200px",
                        height: "100%",
                        color: "#FFFFFF"
                    }} startIcon={<SaveOutlined />}>
                    Submit
                </Button>
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Are you sure?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        You are about to perform an action that will have a financial impact and cannot be reversed. Please confirm that you would like to submit a {selectedActionSubmit} for {props.symbol}{(selectedActionAmount)}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button autoFocus onClick={handleDialogClose} type="button" variant="contained" sx={{
                            textTransform: "none",
                            "&:hover": { background: "#EBFAFF" },
                            background: "#FFFFFF",
                            width: "100px",
                            height: "100%",
                            color: "#00C1FF"
                        }}>
                            Cancel
                        </Button>
                        <Button onClick={performAction} autoFocus type="button" variant="contained" sx={{
                            textTransform: "none",
                            background: "#00C1FF",
                            width: "100px",
                            height: "100%",
                            color: "#FFFFFF"
                        }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography fontSize={14} letterSpacing={0} sx={{ ml: 3, width: "215px", color: "#404047" }}>
                    Please ensure all information is correct before saving
                </Typography>
            </Grid>
            <ComponentLoader isOpen={isLoading} />
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={response.isOpen}
                autoHideDuration={2000} onClose={() => setResponse({ ...response, isOpen: false })}>
                <Alert severity={response.severity ? response.severity : ""} sx={{ width: '100%' }}>
                    {response.message}
                </Alert>
            </Snackbar>
        </Box>
    );
  }
  
  ZapperActions.propTypes = {
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    refundedAmount: PropTypes.number,
    amount: PropTypes.number.isRequired,
    handleTableUpdate: PropTypes.func.isRequired
  };