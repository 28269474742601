import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import { getTransactionsFailedReasons } from '../../../services/statisticsService';
import moment from 'moment';
import { Hidden } from '@mui/material';

function DashboardCard4(props) {

    const [statistics, setStatistics] = useState({});

    const [request, setRequest] = useState();

    useEffect(() => {
        if (props?.profileIdsMerchantIds?.profileIds?.length > 0) {
            setRequest({
                profileIds: props.profileIdsMerchantIds?.profileIds,
                merchantIds: props.profileIdsMerchantIds?.merchantIds,
                fromDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS")
            });
        } else {
            setStatistics({});
        }
    }, [props.profileIdsMerchantIds])

    useEffect(() => {
        if (request) {
            getTransactionsFailedReasons(request).then(response => {
                let responseList = response?.data
                if(responseList?.length>3){
                    responseList = responseList.slice(0,3)
                }
                setStatistics(responseList);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [request])

    return (
        <Card className="dashboard-card-content dark-blue" sx={{height:'100%', bgColor:'#00c1ff !important'}}>
            <Grid sx={{ mt: 2, mb: 2, ml: 2, mr: 1 }}>
                <Typography sx={{ fontSize: 18 }}>
                    eComm Top 3 Failure Reasons
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 'bold', mb: 1 }}>
                    in the last 24 Hours
                </Typography>
                {statistics && statistics.length > 0 ? statistics?.map((stat, index) => {
                    return index <= 3 ?
                        <Grid container key={index}>
                            <Grid item sx={{ flexGrow: 1,width:'10%'}}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                {index + 1}. {stat.reason}
                                </Typography>
                            </Grid>
                        </Grid> : null
                }) :
                !props.isLoading ?
                    <Typography sx={{ fontSize: 12, fontWeight: 'bold', mt: 2 }}>
                        No Failed Reasons
                    </Typography>
                : 
                <Grid container sx={{ display: 'flex', direction: 'row', alignItems: 'center', mt: 4}}>
                    <Grid item sx={{ display: 'flex', flexGrow: 1 }}>
                        <Typography className="loading" fontSize={20} fontWeight='bold'>Loading...</Typography>
                    </Grid>
                </Grid>
                }
                
            </Grid>
        </Card>
    )
}

DashboardCard4.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default DashboardCard4;