import { createSlice } from "@reduxjs/toolkit";
import {getProfileCardTransactions} from "../services/transactionService";

const slice = createSlice({
    name: 'cnpTransactions',
    initialState: {
        cnpTransactions: []
    },
    reducers: {
        success: (state, action) => {
            state.cnpTransactions = action.payload;
            state.isLoading = false;
        },
        notFound: (state, action) => {
            state.cnpTransactions = action;
            state.isLoading = false;
        },
        internalServerError: (state, action) => {
            state.cnpTransactions = action;
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

const { success, notFound, internalServerError } = slice.actions;

export const fetchProfileCardTransactions = (request) => async dispatch => {
    try {
        await getProfileCardTransactions(request)
            .then((response) => dispatch(success(response.data)));
    } catch(e) {
        console.log(e);
        switch (e.response.status) {
            case 404:
                dispatch(notFound(e.response.data));
                break;
            case 500:
                dispatch(internalServerError(e.response.data));
                break;
            default:
                dispatch(internalServerError(e.response.data));
                break;
        }
    }
}

export const updateProfileCardTransactions = (cnpTransactions) => async dispatch => {
    dispatch(success(cnpTransactions));
}