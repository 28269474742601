export const TransactionStatus = {
    PAYMENT_INITIATED: "Initiated",
    PAYMENT_TIMED_OUT: "Timed Out",
    PAYMENT_CANCELLED: "Cancelled", 
    PAYMENT_FAILED: "Failed", 
    PAYMENT_AUTHORISED: "Authorised",
    PAYMENT_SETTLED: "Settled", 
    PAYMENT_REVERSED: "Reversed",
    PAYMENT_REFUNDED: "Refunded",
    PAYMENT_DECLINED: "Declined",
    PAYMENT_PARTIALLY_REFUNDED: "Partially Refunded",
    _3DS_INITIATED: "3DS Initiated",
    _3DS_AUTHORISED: "3DS Authorised",
    _3DS_FAILED: "3DS Failed",
    _3DS_ABANDONED: "3DS Abandoned",
    PAYMENT_COMPLETED: "Complete",
    PAYMENT_PENDING_INVESTIGATION: "Pending Investigation",
    PAYMENT_PENDING:"Pending",
    OZOW_INITIATED: "Ozow Initiated",
    SENT: "Sent",
    PAID: "Paid",
    EXPIRED: "Expired"
}