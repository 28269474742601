import styled from "@emotion/styled";
import { Tooltip, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes = {{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.contrastText
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.contrastText
    }
}))