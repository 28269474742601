import { Backdrop, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import "../../../styles.scss";
import DetailedView from "../detailed-view/detailedView";
import { TransactionStatus } from "../../../constants/transactionStatus";
import AuthorizedElement from "../../../keycloak/permissionsGate";
import ComponentLoader from "../../component-loader/componentLoader";
import { getSessionUrls } from "../../../services/sessionService";
import HeaderInfo from "../headerInfo";
import { getRcsTransaction } from "../../../services/rcsTransactionService";
import RcsTransactionPaymentStepStatus from "../payment-step-status/rcsPaymentStepStatus";
import RcsActions from "../actions/rcsActions";
import { getTransactions } from "../../../services/transactionService";
import { getTransactionStatus } from "../../../utils/statusFormatter";
import { RcsTransactionStatus } from "../../../constants/rcsTransactionStatus";
import RcsTransactionLogs from "../transaction-logs/rcsTransactionLogs";

function RcsTransactionMoreInfo(props) {


const [isLoading, setIsLoading] = useState(false);
const [openTicket, setOpenTicket] = useState(false);
const [transactionStatus, setTransactionStatus] = useState();
const [status, setStatus] = useState("");
const [transactionDetails, setTransactionDetails] = useState();
const [transactionDetailView, setTransactionDetailView] = useState();
const [redirectUrls, setRedirectUrls] = useState();
const [isActionPlaced, setIsActionPlaced] = useState(false);
const [webhookDetails, setWebhookDetails] = useState();
const [refundedAmount, setRefundedAmount] = useState();

const updateTransactionDetails = ((data) => {
    getTransactions(props.transactionId).then(response => {
        setIsLoading(false);
        setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
        setTransactionDetails({
            responseCode: response.data?.TransactionDetails?.[0]?.iso8583ResponseCode ?? response.data?.rcsTransactionDetails?.[0]?.providerResponseCode,
            responseText: response.data?.TransactionDetails?.[0]?.responseText ?? response.data?.rcsTransactionDetails?.[0]?.providerResponseDescription,
            amount: response.data?.amount,
            currencyCode: response.data?.currency?.isoCode,
            merchantRef: response.data?.merchantRef,
            merchantId: response.data?.merchantId,
            profileId: response.data?.profileId,
            createdAt: response.data?.createdAt,
            transactionDateTime: response.data?.createdAt,
            transactionStatus: response.data?.transactionStatus,
            transactionId: response.data?.transactionId,
            token: response.data?.cardDetails?.token,
            scheme: response.data?.cardDetails?.cardScheme,
            cardDetails: {
                nameOnCard: response.data?.cardDetails?.nameOnCard,
                pan: response.data?.cardDetails?.maskedPan,
                expiryDate: response.data?.cardDetails?.expiryDate
            },
            cardVerification : data?.cardVerification,
            otpValidation: data?.otpValidation
        })
    }).catch(error => {
        setIsLoading(false);
    })
})

useEffect(() => {
    getRcsInfo();
}, [props.transactionId])

const getRcsInfo=(() => {
    if (props.transactionId && props.transactionId !== "") {
        setIsLoading(true);
        getRcsTransaction(props.transactionId).then(response => {
            setRefundedAmount(response.data.refundedAmount);
            setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
            if (response.data?.transactionId) {
                setTransactionDetails(response.data)
            }
            else {
                updateTransactionDetails(response.data);
            }
            setTransactionDetails(response.data)
            setIsLoading(false);
        }).catch(error => {
             updateTransactionDetails(null);
            console.log(error)
        })
    }
})

useEffect(()=>{
    if(isActionPlaced){
        setIsLoading(true);
        getRcsTransaction(props.transactionId).then(response => {
            setRefundedAmount(response.data.refundedAmount);
            setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
            setTransactionDetails(response.data)
            setIsLoading(false);
        }).catch(error => {
            updateTransactionDetails();
            console.log(error)
        })
        setIsActionPlaced(false);
    }
},[isActionPlaced])
const tableUpdate = (()=>
{
    setIsActionPlaced(true)
})

const getRedirectUrls = useCallback(() => {
    if (props.transactionId) {
        getSessionUrls(props.transactionId).then(response => {
            setRedirectUrls(response.data)
        }).catch(error => {
            console.log(error)
        })
    }
}, [props.transactionId])

useEffect(() => {
    getRedirectUrls();
    if (props.isOpen) {
        switch (TransactionStatus[transactionDetails?.transactionStatus]) {
            case TransactionStatus.PAYMENT_CANCELLED:
                setStatus("Cancelled")
                break;
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStatus("Failed");
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStatus("Successful");
                break;
            case TransactionStatus.PAYMENT_INITIATED:
                setStatus("Initiated");
                break;
        }

    }
}, [transactionDetails])

useEffect(()=>{
    setTransactionDetailView({
        route:"RCS",
        scheme:"",
        responseCode:transactionDetails?.responseCode,
        responseText:transactionDetails?.responseText,
        rrn:transactionDetails?.rrn,
        transactionDateTime: transactionDetails?.transactionDateTime,
        token:transactionDetails?.token
    })
},[transactionDetails])

const handleClose = () => {
    props.setMoreInfoRequest({
        isOpen: false,
        transactionId: "",
        symbol: "",
        amount: 0,
        transactionStatus: "",
        displayName: "",
        createdAt: "",
        lastModified: "",
        transactionMoreDetails: null,
        merchantRef: "",
        currencyCode: "ZAR",
        selectedprofile: "",
        transactionDateTime: "",
        profileId: []
    });
    setTransactionDetails(null);
}

    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="80%"
                    minWidth="800px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{ overflow:"hidden",height:"100vh", boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <HeaderInfo isOpen={props.isOpen} amount={props.amount} createdAt={props.createdAt} displayName={props.displayName} handleClose={handleClose} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} symbol={props.symbol} transactionId={props.transactionId} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} status={status} />
                    <Grid container direction="column" sx={{ p: 3 ,maxHeight: "calc(100vh - 150px)",flexWrap: "nowrap", overflow: "auto", overflowY: "auto", overflowX:"hidden"}} width="100%">
                        <RcsTransactionPaymentStepStatus transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} displayName={props.displayName} transactionDetails={transactionDetails}  createdAt={props.createdAt} lastModified={props.lastModified} transactionMoreDetails={props.transactionMoreDetails} />
                        {status === "Successful" && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_REVERSED ?
                            <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                <RcsActions transactionId={props.transactionId}  merchantId={transactionDetails?.merchantId} profileId={transactionDetails?.profileId} currencyCode={props.currencyCode} symbol={props.symbol} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} refundedAmount={transactionDetails?.refundedAmount} amount={props.amount} handleTableUpdate={tableUpdate} getRcsInfo={getRcsInfo} />
                            </AuthorizedElement> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_INITIATED  ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <DetailedView amount={props.amount} symbol={props.symbol} transactionDetails={transactionDetailView} merchantRef={props.merchantRef} lastModified={props.lastModified} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} transactionMoreDetails={props.transactionMoreDetails} threeDSecureDetails={""} redirectUrls={redirectUrls} paymentType={"RCS"} status={status}/>
                            </Box> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== RcsTransactionStatus.PAYMENT_INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== RcsTransactionStatus.DECLINED && transactionDetails?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <RcsTransactionLogs transactionDetails={transactionDetails}  symbol={props.symbol}/>
                            </Box> : null
                        }
                    </Grid>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={isLoading} />
        </Box>
    );
}

RcsTransactionMoreInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    merchantRef: PropTypes.string.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired
};

export default RcsTransactionMoreInfo;