import * as React from 'react';
import Box from '@mui/system/Box';
import moment from 'moment';
import { Grid, Button } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { fetchMerchantProfiles } from '../../../../stores/merchantProfiles';
import ComponentLoader from '../../../../components/component-loader/componentLoader';
import PropTypes from 'prop-types';
import BulkFilter from '../../../../components/filters/bulkFilter';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { bulkActionRequest } from '../../../../services/cardNotPresentService';
import KeycloakService from '../../../../keycloak/keycloakService';
import TextField from '@mui/material/TextField';
import number1 from '../../../../assets/images/1.svg';
import number3 from '../../../../assets/images/3.svg';
import number4 from '../../../../assets/images/4.svg';

function CardBulkFilterDash(props) {
  const dispatch = useDispatch();
  const { merchantProfiles } = useSelector((state) => state.merchantProfiles);
  const [date, setDate] = useState('');
  const [selectedMerchant] = useOutletContext();
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [openAnother, setOpenAnother] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [batchReference, setBatchReference] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [request, setRequest] = useState({
    profileIds: [],
    fromDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    toDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    page: 0,
    limit: 25,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    merchantRef: '',
    transactionStatuses: [],
    acquiringBank: '',
    pan: '',
    scheme: '',
    transactionId: '',
    selectedTransactions: [],
  });

  const [values, setValues] = useState({
    fromDate: '',
    toDate: '',
    transactionStatuses: [],
    merchantRef: '',
    acquiringBank: '',
    scheme: '',
    transactionId: '',
    pan: '',
  });

  const handleOpen = () => {
    if (
      props.request.selectedTransactions.length > 0 &&
      props.request.action !== undefined
    ) {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const handleNotifierOpen = () => {
    setOpenAnother(true);
    setOpen(false);
  };

  const handleCloseNotifier = () => {
    setOpenAnother(false);
    handleClear();
    handleRedirect();
  };

  const handleErrorOpen = () => {
    setOpenError(true);
  };
  const handleErrorClose = () => {
    setOpenError(false);
  };

  const total = props.request.selectedTransactions.reduce(
    (total, currentItem) => (total = total + currentItem.amount),
    0
  );

  const handleConfirm = () => {
    const bulkTransactionsList = props.request.selectedTransactions.map(
      (trx) => {
        return {
          amount: trx.amount,
          merchantId: trx.merchantId,
          profileId: trx.profileId,
          transactionId: trx.transactionId,
          action: props.request.action,
        };
      }
    );
    const data = {
      userName: KeycloakService.getDisplayName(),
      email: KeycloakService.getEmail(),
      batchReference: batchReference,
      bulkTransactionsList,
    };
    setIsLoading(true);
    bulkActionRequest(data)
      .then((result) => {
        setDate(result.data.createdDateTime);
        if (result.status === 200) {
          handleNotifierOpen();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        handleErrorOpen();
        setIsLoading(false);
      });
  };

  const popUpHeader = () => {
    if (props.request.action === 'SETTLED') return 'Settle';
    else if (props.request.action === 'REFUND') return 'Refund';
    else if (props.request.action === 'REVERSE') return 'Reverse';
  };

  const handleClear = () => {
    props.setRequest({
      ...props.request,
      transactionStatuses: [],
      fromDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      toDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      selectedTransactions: [],
    });
    setFilterApplied(false);
  };

  const handleRedirect = () => {
    props.setBulkSelect(false);
  };

  useEffect(() => {
    if (request) {
      setValues({
        ...values,
        fromDate: moment(request.fromDate),
        toDate: moment(request.toDate),
        transactionStatuses: request.transactionStatuses,
        merchantRef: request.merchantRef,
        scheme: request.scheme,
        acquiringBank: request.acquiringBank,
        pan: request.pan,
      });
    }
  }, [request]);

  useEffect(() => {
    if (selectedMerchant) {
      setIsProfilesLoading(true);
      dispatch(fetchMerchantProfiles(selectedMerchant));
    }
  }, [selectedMerchant]);

  useEffect(() => {
    setIsProfilesLoading(false);
    if (merchantProfiles?.content && merchantProfiles?.content?.length > 0) {
      setIsLoading(false);
    }
  }, [merchantProfiles]);

  useEffect(() => {
    if (props.request.action !== null) {
      setBatchReference(popUpHeader() + ' - ' + moment().format('L'));
    }
  }, [props.request.action]);
  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Grid
          container
          columns={15}
          columnSpacing={2}
          sx={{
            position: 'sticky',
            zIndex: 1004,
            background: '#FFFFFF',
            mb: 2,
          }}
        >
          <Grid item sx={{ minWidth: '250px' }} style={{ display: 'flex' }}>
            <img src={number1} />

            <div style={{ paddingLeft: '20px', zIndex: 1 }}>
              <BulkFilter
                request={props.request}
                setRequest={props.setRequest}
                setFilterApplied={setFilterApplied}
              />
            </div>
            <div style={{ paddingLeft: '90px', zIndex: 0 }}>
              <img src={number3} />
            </div>
            <div style={{ paddingLeft: '10px', zIndex: 0, paddingTop: '10px' }}>
              select transactions
            </div>
            <div style={{ paddingLeft: '90px', zIndex: 0, display: 'flex' }}>
              <img src={number4} />
              <div style={{ paddingLeft: '20px', zIndex: 1 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOpen();
                  }}
                  sx={{ color: 'white' }}
                >
                  confirm
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Bulk {popUpHeader()}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      You are about to <b>{popUpHeader()}</b> the following
                      transactions, please make sure that the correct
                      transactions are in this list before proceeding.
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '12px',
                      }}
                    >
                      <b>Details</b>
                      <br />
                      <TextField
                        label="Batch Reference"
                        sx={{ width: '20%' }}
                        value={batchReference}
                        size="small"
                        required={true}
                        onChange={(event) => {
                          setBatchReference(event.target.value);
                        }}
                      />
                      <div>
                        <p>
                          <b>Number of Transactions: </b>
                          {props.request.selectedTransactions.length}
                        </p>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        border: '1px solid #000',
                        borderRadius: '5px',
                        padding: '10px',
                        height: '200px',
                        overflowY: 'auto',
                      }}
                    >
                      <TableBody>
                        {props.request.selectedTransactions.map((index) => (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th">
                              <b>Date:</b>
                              {'  ' +
                                moment(index?.createdAt).format(
                                  'YYYY/MM/DD HH:mm:ss'
                                )}
                            </TableCell>
                            <TableCell>
                              | <b>Transaction ID:</b>
                              {'  ' + index.transactionId}
                            </TableCell>
                            <TableCell>
                              | <b>Status:</b>
                              {'  ' + index.transactionStatus}
                            </TableCell>
                            <TableCell>
                              | <b>Amount:</b>
                              {'  R' + index.amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </div>

                    <div style={{ paddingTop: '10px' }}>
                      <Button
                        sx={{ color: 'white' }}
                        variant="contained"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        cancel
                      </Button>{' '}
                      <Button
                        sx={{ color: 'white' }}
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={batchReference.length == 0}
                      >
                        {popUpHeader()}
                      </Button>
                    </div>
                    <ComponentLoader isOpen={isLoading} />
                  </Box>
                </Modal>
                <React.Fragment>
                  <Modal
                    open={openError}
                    onClose={() => handleErrorClose()}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box
                      sx={{
                        ...stylePopUp,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <h2 id="child-modal-title">Bulk Action failed</h2>
                      <p id="child-modal-description">
                        Your bulk {popUpHeader()} has been failed
                      </p>
                      <p>please try again</p>

                      <br />
                      <Button
                        sx={{
                          color: '#0098D8',
                          bgcolor: '#fff',

                          '&:hover': {
                            color: '#0098D8',
                            backgroundColor: '#fff',
                          },
                        }}
                        variant="contained"
                        onClick={() => handleErrorClose()}
                      >
                        OK
                      </Button>
                    </Box>
                  </Modal>
                </React.Fragment>

                <React.Fragment>
                  <Modal
                    open={openAnother}
                    onClose={() => handleCloseNotifier()}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box
                      sx={{
                        ...stylePopUp,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <h2 id="child-modal-title">Bulk Action submitted</h2>
                      <p id="child-modal-description">
                        Your bulk {popUpHeader()} has been submitted, you will
                        receive an email soon with the Bulk Action Report.
                      </p>
                      <br />

                      <b>
                        {` ${popUpHeader()}  ${moment(date).format(
                          'YYYY/MM/DD HH:mm:ss'
                        )}`}
                      </b>
                      <br />
                      <Button
                        sx={{
                          color: '#0098D8',
                          bgcolor: '#fff',

                          '&:hover': {
                            color: '#0098D8',
                            backgroundColor: '#fff',
                          },
                        }}
                        variant="contained"
                        onClick={() => handleCloseNotifier()}
                      >
                        OK
                      </Button>
                    </Box>
                  </Modal>
                </React.Fragment>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CardBulkFilterDash.propType = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  setBulkSelect: PropTypes.func,
  setChecked: PropTypes.func.isRequired,
};

export default CardBulkFilterDash;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: 'auto',
  bgcolor: '#fff',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  fontSize: '14px',
};

const stylePopUp = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ' 400px',
  height: 'auto',
  bgcolor: '#fff',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '10px',
  fontSize: '14px',
  pt: 2,
  px: 4,
  pb: 3,
};
