import axios from 'axios';


export const createPaymentLink = (request) => {
    return axios.post(`${process.env.REACT_APP_PAYMENT_LINK_CREATE_PATH}`, request);
}
export const getPaymentLinkTransactions = (request) => {

    return axios.post(`${process.env.REACT_APP_PAYMENT_LINK_TRANSACTIONS_PATH}`, request);

}
//For the download csv

export const getAllCsvPaymentLinks = (request) => {

    return axios({
        url: `${process.env.REACT_APP_PAYMENT_LINK_TRANSACTIONS_PATH_CSV}`,
        method: 'POST',
        responseType: 'blob',
        data: request
    });

}