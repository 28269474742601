import * as React from 'react';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Download from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { fetchMerchantProfiles } from '../../../../stores/merchantProfiles';
import { getAllCsvPaymentLinks, getPaymentLinkTransactions } from '../../../../services/paymentLinkService';
import { getZapperCsvTransactions } from '../../../../services/zapperTransactionService';
import ProfileSelect from '../../../../components/profile-select/profileSelect'; 
import ReactGA from 'react-ga4';
import ZapperTransactions from './zapperTransactions';
import ZapperSearchBySelect from '../../transaction-selects/zapperSearchBySelect';
import ZapperFilterApplied from '../../transaction-buttons/filter-applied/zapperFilterApplied';

function ZapperProducts() {
    
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvLoading, setIsCsvLoading] = useState(false);
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [selectedMerchant] = useOutletContext();
    const [selectedDisplayName, setSelectedDisplayName] = useState("");

    const [isProfilesLoading, setIsProfilesLoading] = useState(false);

    const [request, setRequest] = useState({
        merchantIds: [],
        profileIds: [],
        fromDate: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        page: 0,
        limit: 25,
        sortBy: "createdAt",
        sortOrder: "desc",
        merchantRef: "",
        transactionStatuses: [],
        transactionId: ""
    });

    const [requestProfile, setRequestProfile] = useState({
        profileIds: [],
        merchantIds: [],
        page :0,
        size: 10000,
        displayName: "",
        profileId: ""
    })

    useEffect(() => {
        if (selectedMerchant) {
            setIsProfilesLoading(true)
            setRequest({...request, merchantIds: selectedMerchant})
            setRequestProfile({...requestProfile, merchantIds: selectedMerchant})
        }
    }, [selectedMerchant])

    useEffect(() =>{
        dispatch(fetchMerchantProfiles(requestProfile));
    }, [requestProfile?.merchantIds])

    useEffect(() => {
        setIsProfilesLoading(false)
        if (merchantProfiles?.content && merchantProfiles?.content?.length > 0) {
            setIsLoading(false);
        }
    }, [merchantProfiles])

    const setSelectedProfile = (profileIds) => {
        setRequest({
            ...request,
            profileIds: profileIds
        })
    }

    const downloadZapperCSV = () => {
        setIsCsvLoading(true);
        getZapperCsvTransactions(request).then(response => {
            setIsCsvLoading(false);
            if (!window.navigator.msSaveOrOpenBlob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Zapper Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
                document.body.appendChild(link);
                link.click();
                ReactGA.event({
                    category: "transaction",
                    action: "download zapper csv",
                  });
            } else {
                const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `Zapper Transactions - ${moment().format("YYYY-MM-DD HHmmss")}.csv`);
            }
        }).catch(error => {
            console.log(error);
            setIsCsvLoading(false);
        })
    }

    useEffect(() => {
        let displayNames = [];
        request?.profileIds.forEach(profileId =>{
            const foundProfile = merchantProfiles?.content?.find(p => p.profileId === profileId);
            if(foundProfile)
            displayNames.push(foundProfile?.displayName)
        })
        setSelectedDisplayName(displayNames)
        
    
    }, [request?.profileIds])

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Box sx={{ mt: 2 , ml:3, mr:3}}>
                <Grid container columns={15} columnSpacing={2} sx={{ position: "sticky", zIndex: 1004, background: "#FFFFFF", mb: 2 }}>
                    <Grid item sx={{ minWidth: "250px" }}>
                        <ProfileSelect sx={{ width: "100%" }} profiles={merchantProfiles?.content ? merchantProfiles?.content : []} handleChange={setSelectedProfile} selectedProfile={request?.profileIds} isLoading={isProfilesLoading} />
                    </Grid>
                    <Grid item>
                        <ZapperSearchBySelect request={request} setRequest={setRequest}/>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            className="transaction-button"
                            startIcon={isCsvLoading ?<CircularProgress color='inherit' size={"25px"}/>:<Download />}
                            onClick={isCsvLoading ?null:downloadZapperCSV}
                            sx={{ textTransform: 'none', width: '175px', height: 40, color: 'white', fontSize: 12, boxShadow: 2 }}
                        >
                            Download CSV
                        </Button>
                    </Grid>
                    <Grid item>
                        <ZapperFilterApplied request={request} setRequest={setRequest} displayName={selectedDisplayName}/>
                    </Grid>
                </Grid>
                <Grid>
                    <ZapperTransactions profiles={merchantProfiles?.content ? merchantProfiles?.content : []} request={request} isLoading={isLoading} setIsLoading={setIsLoading} setRequest={setRequest} merchantId={selectedMerchant} profileIds={request.profileIds} />
                </Grid>
            </Box>
        </Box>
    )
    // }
}

export default ZapperProducts;