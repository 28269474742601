
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { ExpandMoreButton } from "../../expand-more/expandMore";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import moment from "moment";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function RcsTransactionLogs(props) {

    const [expanded, setExpanded] = useState(false);


    return (
        <Box width="100%">
            <Grid container>
                <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Logs</Typography>
                <ExpandMoreButton expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    <ExpandMore />
                </ExpandMoreButton>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.transactionDetails &&
                    <Box sx={{ mt:  2 }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Details</Typography>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.transactionDetails?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.amount))}` : 'R0.00'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Currency Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.currencyCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.dmsIndicator?.toString()}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant Ref:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantRef}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Profile ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.profileId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Provider Junction Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>RCS</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.responseCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.responseText}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>RRN:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.rrn}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Store:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.store}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.token}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Date Time:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {props.transactionDetails?.authorisationLeg &&
                            <Grid container sx={{ mt: 4 }}>
                                <Grid item xs={1.97}>
                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Payment Leg:</Typography>
                                </Grid>
                                <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                {props.transactionDetails?.authorisationLeg?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.authorisationLeg?.amount/100))}` : 'R0.00'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Payment Leg ID:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.authorisationLegId}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.dmsIndicator?.toString()}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Expiry Date:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.expiryDate}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>PAN:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.hashedPan}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>MCC:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.mcc}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ISO Currency Code:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.numericIsoCurrencyCode}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Store ID:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.storeId}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.token}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        }
                        {props.transactionDetails?.refundLegs || props.transactionDetails?.cardVerification || props.transactionDetails?.otpValidation ?
                            <Grid container columnSpacing={2} rowSpacing={0.5}>
                                {props.transactionDetails?.cardVerification &&
                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={1.97}>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Card Verification leg:</Typography>
                                            </Grid>
                                            <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Grid container wrap="nowrap" key={"cardVerify"}>
                                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.cardVerification?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Last Modified Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.cardVerification?.lastModified).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Hashed Pan:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.pan}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Require OTP:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.requireOtp?.toString()}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Resend OTP:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.resendOtp?.toString()}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                        {props.transactionDetails?.cardVerification?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.cardVerification?.amount))}` : 'R0.00'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {props.transactionDetails?.cardVerification?.description ?
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.description }</Typography>
                                                                </Grid>
                                                            </Grid>
                                                                :""}
                                                                { props.transactionDetails?.cardVerification?.code ?
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.code }</Typography>
                                                                </Grid>
                                                            </Grid>
                                                                :""}
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Unique ID:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.uniqueId}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Unique Reference:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.uniqueReference}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>SVC:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardVerification?.svc}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {props.transactionDetails?.otpValidation &&
                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={1.97}>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>OTP Verification leg:</Typography>
                                            </Grid>
                                            <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Grid container wrap="nowrap" key={"otpVerify"}>
                                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.otpValidation?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Last Modified Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.otpValidation?.lastModified).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {props.transactionDetails?.otpValidation?.description ?
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.otpValidation?.description }</Typography>
                                                                </Grid>
                                                            </Grid>
                                                                :""}
                                                             { props.transactionDetails?.otpValidation?.code?
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.otpValidation?.code }</Typography>
                                                                </Grid>
                                                            </Grid>
                                                                :""}
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Unique ID:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.otpValidation?.uniqueId}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>SVC:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.otpValidation?.svc}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {props.transactionDetails?.refundLegs && props.transactionDetails?.refundLegs?.length > 0 &&
                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={1.97}>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg:</Typography>
                                            </Grid>
                                            <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                                {props.transactionDetails?.refundLegs?.map((refundLeg, index) => (
                                                    <Grid container wrap="nowrap" key={index}>
                                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>{index + 1}:</Typography>
                                                        </Grid>
                                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                        {refundLeg.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(refundLeg.amount/100))}` : 'R0.00'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.dmsIndicator?.toString()}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ISO Currency Code:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.numericIsoCurrencyCode}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg Id:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.refundLegId}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.responseCode}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.responseText}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Sequence:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.sequence}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Status:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.status}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.token}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>RRN:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.rrn}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            :""}
                    </Box>
                }
            </Collapse >
        </Box >
    );
}

RcsTransactionLogs.propTypes = {
    transactionDetails: PropTypes.object,
    webhookDetails: PropTypes.object,
    symbol: PropTypes.string,

};

export default RcsTransactionLogs;