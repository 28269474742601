import styled from "@emotion/styled";
import { CheckCircleOutline, HighlightOff, PendingOutlined } from "@mui/icons-material";
import { Card, Grid, Popover, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { TransactionStatus } from "../../../constants/transactionStatus";

const SuccessTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#00C1FF",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#00C1FF",
        color: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029"
    },
}));

function TransactionPaymentStepStatus(props) {

    const [anchorElTransaction, setAnchorElTransaction] = useState(null);
    const [anchorEl3DS, setAnchorEl3DS] = useState(null);

    const handleClick = (event) => {
        switch (event.target.id) {
            case "3DSecure":
                if (step3DSecure) {
                    if (props.transactionStatus !== TransactionStatus._3DS_INITIATED) {
                        setAnchorEl3DS(event.currentTarget);
                    }
                }
                break;
            case "transaction":
                if (stepPayment) {
                    setAnchorElTransaction(event.currentTarget);
                }
                break;
            default:
                break;
        }
    };

    const handleCloseTransaction = () => {
        setAnchorElTransaction(null);
    };

    const handleClose3DS = () => {
        setAnchorEl3DS(null);
    };

    const openTransaction = Boolean(anchorElTransaction);
    const open3DS = Boolean(anchorEl3DS);

    const [step3DSecure, setStep3DSecure] = useState(false);
    const [stepPayment, setStepPayment] = useState(false);


    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [threeDSSuccessful, setThreeDSSuccessful] = useState(false);

    const [transactionMoreDetails, setTransactionMoreDetails] = useState();

    useEffect(() => {
        switch (props.transactionStatus) {
            case TransactionStatus.PAYMENT_CANCELLED:
                setStepPayment(false);
                setStep3DSecure(false);
                setPaymentSuccessful(false);
                setThreeDSSuccessful(false);
                break;
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStepPayment(true);
                setPaymentSuccessful(false);
                setThreeDSSuccessful(true);
                setStep3DSecure(true);
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:    
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStepPayment(true);
                setPaymentSuccessful(true);
                setThreeDSSuccessful(true);
                setStep3DSecure(true);
                break;
            case TransactionStatus.PAYMENT_INITIATED:
                setStepPayment(false);
                setStep3DSecure(false);
                setPaymentSuccessful(false);
                setThreeDSSuccessful(false);
                break;
            case TransactionStatus._3DS_AUTHORISED:
            case TransactionStatus._3DS_INITIATED:
                setStepPayment(false);
                setStep3DSecure(true);
                setThreeDSSuccessful(true);
                setPaymentSuccessful(false);
                break;
            case TransactionStatus._3DS_FAILED:
                setStepPayment(false);
                setStep3DSecure(true);
                setThreeDSSuccessful(true);
                setPaymentSuccessful(false);
                break;
            default:
                setThreeDSSuccessful(false);
                setStepPayment(false);
                setPaymentSuccessful(false);
                setStep3DSecure(false);
                break;
        }

        let mostRecentDate = new Date(Math.max.apply(null, props.transactionMoreDetails?.map(moreDetail => {
            return new Date(moreDetail.lastModified);
        })));
        setTransactionMoreDetails(props.transactionMoreDetails?.filter(moreDetail => {
            var d = new Date(moreDetail.lastModified);
            return d.getTime() === mostRecentDate.getTime();
        })[0]);
    }, [props])
  

    const getTransactionName = () => {
        let transactionName = ""
        Object.keys(TransactionStatus).forEach(item => {
            if(TransactionStatus[item] === props.transactionStatus){
                transactionName = item
            } 
        })
        return transactionName
    }



    return (
        <Box width="100%">
            <Grid container columns={11}>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <SuccessTooltip disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? true : false} arrow title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">Request Sent</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                            </Box>
                        } placement="top">
                            <Card sx={{ "&:hover": { cursor: "pointer" }, p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="initial">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: "#404047" }} id="initial">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "Payment Initiated" : "Client sent transaction to provider"}
                                </Typography>
                            </Card>
                        </SuccessTooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.displayName}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        <Box className="pointer" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>
                            <Box className="success-check">
                                {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ?
                                    <PendingOutlined /> : <CheckCircleOutline />
                                }
                            </Box>
                        </Box>
                        {stepPayment ?
                            <Box className="pointer pointer-inverted" sx={{ top: "46px", display: "flex", justifyContent: "center" }}>
                                <Box className={paymentSuccessful ? "success-check" : "failed-check"}>
                                    {paymentSuccessful ? <CheckCircleOutline sx={{ transform: "rotate(180deg)" }} /> :
                                        <HighlightOff sx={{ transform: "rotate(180deg)" }} />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    "& .MuiTooltip-arrow": {
                                        color: (props.transactionStatus === TransactionStatus._3DS_AUTHORISED || threeDSSuccessful ? "#00C1FF" : "#DF372D")
                                    },
                                    "&.MuiTooltip-tooltip": {
                                        backgroundColor: (props.transactionStatus === TransactionStatus._3DS_AUTHORISED || threeDSSuccessful ? "#00C1FF" : "#DF372D"),
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 6px #00000029"
                                    }
                                }
                            }
                        }} disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus._3DS_INITIATED ? true : false} title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">
                                    {props.transactionStatus === TransactionStatus._3DS_AUTHORISED || threeDSSuccessful ? "Request Sent" : "Failed"}
                                </Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.threeDSecureDetails?.record?.dateLogged).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                            </Box>
                        } placement="top">
                            <Card onClick={handleClick} sx={{ "&:hover": { cursor: "pointer" }, p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="3DSecure">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: "#404047" }} id="3DSecure">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "" : props.transactionStatus === TransactionStatus._3DS_INITIATED ? "3DS Initiated" : "Provider sent response"}
                                </Typography>
                            </Card>
                        </Tooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED ? "None" : "3DS"}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={1}>
                    <Grid container>
                        {stepPayment || step3DSecure ?
                            <Box className="pointer" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>
                                <Box className={(props.transactionStatus === TransactionStatus._3DS_FAILED ? "failed-check" : "success-check")}>
                                    {props.transactionStatus === TransactionStatus._3DS_INITIATED ? <PendingOutlined /> :
                                        props.transactionStatus === TransactionStatus._3DS_FAILED ? <HighlightOff /> :
                                            <CheckCircleOutline />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                        {stepPayment ?
                            <Box className="pointer pointer-inverted" sx={{ top: "46px", display: "flex", justifyContent: "center" }}>
                                <Box className={paymentSuccessful ? "success-check" : "failed-check"}>
                                    {paymentSuccessful ? <CheckCircleOutline sx={{ transform: "rotate(180deg)" }} /> :
                                        <HighlightOff sx={{ transform: "rotate(180deg)" }} />
                                    }
                                </Box>
                            </Box> :
                            <Box className="pointer pointer-hidden" sx={{ top: "27px", display: "flex", justifyContent: "center" }}>

                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ p: "6px", background: "#EBFAFF", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "145px" }}>
                        <Tooltip arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    "& .MuiTooltip-arrow": {
                                        color: (paymentSuccessful ? "#00C1FF" : "#DF372D")
                                    },
                                    "&.MuiTooltip-tooltip": {
                                        backgroundColor: (paymentSuccessful ? "#00C1FF" : "#DF372D"),
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 6px #00000029"
                                    }
                                }
                            }
                        }}  disableHoverListener={props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus._3DS_INITIATED || props.transactionStatus === TransactionStatus._3DS_FAILED || props.transactionStatus === TransactionStatus._3DS_AUTHORISED || props.transactionStatus === TransactionStatus.PAYMENT_FAILED ? true : false} title={
                            <Box>
                                <Typography fontSize={12} fontWeight="bold" textAlign="center">{paymentSuccessful ? "Success" : "Failed"}</Typography>
                                <Typography fontSize={10} textAlign="center">{moment(props.lastModified).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                {!paymentSuccessful &&
                                    <Typography fontSize={10} fontWeight="bold" textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning}</Typography>
                                }
                            </Box>
                         } placement="top">
                            <Card onClick={handleClick} sx={{ "&:hover": { cursor: "pointer" }, background: (props.transactionStatus === TransactionStatus.PAYMENT_INITIATED || props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED || props.transactionStatus === TransactionStatus._3DS_INITIATED || props.transactionStatus === TransactionStatus._3DS_FAILED || props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "#FFFFFF" : paymentSuccessful ? "#08F9BA" : "#DF372D"), p: "35px", borderRadius: "5px", boxShadow: "0px 3px 6px #00000029", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} id="transaction">
                                <Typography fontSize={14} letterSpacing={0} textAlign="center" sx={{ color: paymentSuccessful ? "#404047": "#FFFFFF" }} id="transaction">
                                    {props.transactionStatus === TransactionStatus.PAYMENT_INITIATED|| props.transactionStatus === TransactionStatus.PAYMENT_CANCELLED ||props.transactionStatus === TransactionStatus._3DS_INITIATED ||props.transactionStatus === TransactionStatus._3DS_AUTHORISED ? "" : TransactionStatus[getTransactionName()]}
                                    {props.transactionStatus === TransactionStatus.PAYMENT_FAILED ? 
                                    <Typography fontSize={14}  textAlign="center">{transactionMoreDetails?.iso8583ResponseCodeMeaning ? transactionMoreDetails?.iso8583ResponseCodeMeaning : ""  }</Typography>
                                : ""}
                                </Typography>
                            </Card>
                        </Tooltip>
                        <Typography fontSize={16} letterSpacing={0} textAlign="center" fontWeight="bold" sx={{ mt: "8px", mb: "2px", textAlign: "center" }}>
                            {props.transactionDetails?.merchantBankDisplayName ? props.transactionDetails?.merchantBankDisplayName : "None"}
                        </Typography>
                    </Card>
                </Grid>
            </Grid >
            <Popover
                open={open3DS}
                anchorEl={anchorEl3DS}
                onClose={handleClose3DS}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {props.threeDSecureDetails !== undefined ?
                    <Box sx={{ background: "#EBFAFF", p: 2, maxWidth: "500px" }}>
                        <Typography fontSize={22} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>
                            {threeDSSuccessful ? "Successful" : "Failed"}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>ECI: </b>{props.threeDSecureDetails?.record?.eci}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>MD Status: </b>{props.threeDSecureDetails?.record?.mdStatus}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>Message: </b>{props.threeDSecureDetails?.record?.mdErrorMsg}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>Protocol: </b>{props.threeDSecureDetails?.record?.protocol}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>CAVV: </b>{props.threeDSecureDetails?.record?.cavv}
                        </Typography>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                            <b>Card Type: </b>{props.threeDSecureDetails?.record?.cardType}
                        </Typography>
                    </Box> :
                    <Typography fontSize={12} textAlign="center" sx={{ p: 2, background: "#EBFAFF" }}>No 3DS details available</Typography>
                }
            </Popover>
            <Popover
                open={openTransaction}
                anchorEl={anchorElTransaction}
                onClose={handleCloseTransaction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ background: "#EBFAFF", p: 2 }}>
                    <Typography fontSize={22} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>
                        {paymentSuccessful ? "Successful" : "Failed"}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Aquiring Bank: </b>{props.transactionDetails?.route}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>RRN: </b>{props.transactionDetails?.rrn}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Scheme: </b>{props.transactionDetails?.scheme}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Auth Code: </b>{transactionMoreDetails?.authCode}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response Code: </b>{props.transactionDetails?.responseCode}
                    </Typography>
                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>
                        <b>Response Code Meaning: </b>{props.transactionDetails?.responseText}
                    </Typography>
                </Box>
            </Popover>
        </Box >
    );
}

TransactionPaymentStepStatus.propTypes = {
    displayName: PropTypes.string.isRequired,
    transactionDetails: PropTypes.object,
    transactionStatus: PropTypes.string,
    threeDSecureDetails: PropTypes.object,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array
};

export default TransactionPaymentStepStatus;