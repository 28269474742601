import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import moment from "moment";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function DetailedViewOzow(props){

    const [status , setStatus] = useState("");

    const [transactionMoreDetails, setTransactionMoreDetails] = useState();

    useEffect(() => {
        switch (props.transactionStatus) {
            case TransactionStatus.PAYMENT_CANCELLED:
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStatus("Failed");
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:    
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStatus("Successful");
                break;
            case TransactionStatus.PAYMENT_INITIATED:
                setStatus("Initiated");
                break;
            case TransactionStatus._3DS_INITIATED:
                setStatus("3DS Initiated");
                break;
            case TransactionStatus._3DS_AUTHORISED:
                setStatus("3DS Authorised");
                break;
            case TransactionStatus._3DS_FAILED:
                setStatus("3DS Failed");
                break;
            default:
                break;
        }
    }, [props])
    return(
        <Box width="100%">
            <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Details</Typography>
            {status === "Failed" || status === "Successful" ?
                <Box>
                    <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Box sx={{ height: 20 }} component="img" src={require("../../../assets/images/ozow.png")} alt="scheme" />
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} sx={{ color: "#404047" }}>{props.symbol}{numberWithCommas(getFormattedAmount(props.amount))}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Reference:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.merchantRef}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Date:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.transactionDetails?.transactionDateTime).format("YYYY/MM/DD")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Time:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.transactionDetails?.transactionDateTime).format("HH:mm:ss")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Result:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{status}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Response:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.transactionDetails?.response}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Provider:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.provider}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Masked Account Nr:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}> {props.transactionDetails?.maskedAccountNumber} </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Bank From:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}> {props.transactionDetails?.bankName} </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Bank To:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}> {props.transactionDetails?.toBankName} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                                    <Grid item xs={12} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Success Redirect URL:</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.successUrl}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Failed Redirect URL:</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.errorUrl}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            </Grid>
                    </Grid>
                </Box> :null  
            }
        </Box>
    );
}

DetailedViewOzow.propTypes = {
    amount: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    threeDSecureDetails: PropTypes.object,
    transactionDetails: PropTypes.object,
    redirectUrls: PropTypes.object,
    merchantRef: PropTypes.string.isRequired
};

export default DetailedViewOzow;