import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import profile from './profile';
import merchant from './merchants';
import transaction from './transaction';
import paymentIntent from "./paymentIntent";
import merchantProfiles from "./merchantProfiles";
import tdsTransaction from "./tdsTransaction";
import profileTransactions from "./profileTransactions";
import profileAllTransactions from "./profileAllTransactions";
import cnpTransactions from "./CardNotPresentTransactions";
import ozowTransactions from "./ozowTransactions";
import rcsTransactions from "./rcsTransactions";
import paymentLinkTransactions from "./paymentLinkTransactions";
import zapperTransactions from "./zapperTransactions";

const reducer = combineReducers({
    transaction,
    profile,
    profileTransactions,
    merchant,
    merchantProfiles,
    paymentIntent,
    tdsTransaction,
    profileAllTransactions,
    cnpTransactions,
    ozowTransactions,
    rcsTransactions,
    paymentLinkTransactions,
    zapperTransactions
});

const store = configureStore({
    reducer
});

export default store;