import axios from 'axios';
import React from 'react';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';


export const getRcsTransactions = (request) => {
    GoogleAnalytics.getRcsTransactionRequest();
    return axios.post(`${process.env.REACT_APP_RCS_TRANSACTIONS_PATH}`, request);
}


export const getRcsCsvTransactions = (request) => {
    GoogleAnalytics.getRcsCsvTransaction();
    return axios({
      url: `${process.env.REACT_APP_RCS_TRANSACTIONS_CSV_PATH}`,
      method: 'POST',
      responseType: 'blob',
      data: request
    });
}

export const getRcsTransaction = (transactionId) => {
  GoogleAnalytics.getRcsTransactionId(transactionId);

return axios.get(`${process.env.REACT_APP_RCS_TRANSACTION_PATH}/${transactionId}`)}

export const refundRcsTransaction = (request) => {
  GoogleAnalytics.refundRcsTransactionRequest(request);

return axios.post(`${process.env.REACT_APP_RCS_REFUND_PATH}`,request)}