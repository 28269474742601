import FormControl from "@mui/material/FormControl";
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from "react";
import { CustomAutoComplete } from '../../custom-autocomplete/customAutoComplete';
import { CustomTextField } from '../../custom-text-field/customTextField';
import { LocalStorage } from '../../../constants/localStorage';
import { Chip, ClickAwayListener, Grid, lighten, ListItemButton } from '@mui/material';
import { CheckOutlined, RemoveOutlined } from '@mui/icons-material';
import { Theme } from '../../../assets/styles/theme';
import { Box } from "@mui/system";
import CustomSearchListbox from "../../custom-search-listbox/customSearchListbox";
import { useNavigate } from "react-router-dom";

const theme = Theme();

function MerchantSelect(props) {

    const navigate = useNavigate();

    const { selectedMerchants, setSelectedMerchants, merchant } = props;

    const [filteredMerchantList, setFilteredMerchantList] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    const [localSelectedMerchants, setLocalSelectedMerchants] = useState([]);

    const [groupStates, setGroupStates] = useState([]);

    const textFieldRef = useRef(null);

    const [filterValue, setFilterValue] = useState("");
    const [open, setOpen] = useState(false);
    const [negateClose, setNegateClose] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        const traverseChildren = (childMerchants, children, parentName) => {
            childMerchants?.map(child => {
                if (child.childMerchants !== null && child.childMerchants.length) {
                    child.childMerchants = traverseChildren(child.childMerchants, children, child.name);
                }
                children.push({
                    merchantId: child.merchantId,
                    name: child.name,
                    parentId: child.parentId,
                    parentName: parentName
                });

                return null;
            });

            return children;
        }

        if (merchant) {
            var temp = [];
            window.sessionStorage.setItem("merchantName", merchant.name)

            temp.push({
                merchantId: merchant.merchantId,
                name: merchant.name,
                parentId: merchant.parentId,
                parentName: "Main Merchant"
            })

            let newMerchantList = traverseChildren(merchant.childMerchants, temp, merchant.name);
            var tempGroupStates = [];

            newMerchantList.forEach(merchant => {
                let group = tempGroupStates.find(group => group.name === merchant.parentName);
                if (!group) {
                    tempGroupStates.push({
                        name: merchant.parentName,
                        state: 0
                    })
                }
            });

            setGroupStates(tempGroupStates);
            setMerchantList(newMerchantList);
            setFilteredMerchantList(newMerchantList)
        }
    }, [merchant, navigate])

    useEffect(() => {
        if (merchantList && merchantList?.length > 0) {
            let previousSelectedMerchants = window.localStorage.getItem(LocalStorage.SELECTED_MERCHANTS);
            var merchantFound = false;

            if (previousSelectedMerchants && firstLoad) {
                setFirstLoad(false);
                previousSelectedMerchants = previousSelectedMerchants?.split(',');
                previousSelectedMerchants.forEach((previousSelectedMerchant) => {
                    var foundMerchant = merchantList.find((merchant) => merchant.merchantId === previousSelectedMerchant);
                    if (foundMerchant) {
                        merchantFound = true;
                        return false;
                    }
                });
            }
            

            if (merchantFound) {
                window.localStorage.removeItem(LocalStorage.SELECTED_MERCHANTS);
                window.localStorage.removeItem(LocalStorage.SELECTED_PROFILE);
                previousSelectedMerchants = null;
            }
            
            if (previousSelectedMerchants) {
                setSelectedMerchants(previousSelectedMerchants?.split(','));
            } else {
                setSelectedMerchants([merchantList?.[0].merchantId]);
            }
        }
    }, [merchantList, setSelectedMerchants])

    useEffect(() => {
        var merchants = []
        selectedMerchants?.forEach(selectedMerchant => {
            let foundMerchant = merchantList.find(merchant => merchant.merchantId === selectedMerchant);
            foundMerchant && merchants.push(foundMerchant);
        });
        setLocalSelectedMerchants(merchants)
    }, [selectedMerchants, merchantList])

    useEffect(() => {
        var tempGroupStates = []

        merchantList.forEach(merchant => {
            let group = tempGroupStates.find(group => group.name === merchant.parentName);
            if (!group) {
                tempGroupStates.push({
                    name: merchant.parentName,
                    state: 0
                })
            }
        });

        tempGroupStates.forEach((state, index) => {
            let merchants = merchantList.filter(merchant => merchant.parentName === state.name);
            let tempSelectedMerchants = localSelectedMerchants?.filter(selectedMerchant => selectedMerchant.parentName === state.name);
            if (merchants?.length === tempSelectedMerchants?.length) {
                tempGroupStates[index].state = 2
            } else if (merchants?.length > tempSelectedMerchants?.length && tempSelectedMerchants?.length !== 0) {
                tempGroupStates[index].state = 1
            } else {
                tempGroupStates[index].state = 0
            }
        })

        setGroupStates(tempGroupStates);
    }, [localSelectedMerchants, merchantList])

    const updateSelectedMerchants = (newSelectedMerchants, userUpdated) => {
        if (userUpdated) {
            var i;
            var found = newSelectedMerchants.some((selectedMerchant, index) => {
                i = index; return selectedMerchant === "0";
            });

            if (found) {
                newSelectedMerchants.splice(i, 1);
                merchantList.forEach(merchant => {
                    !newSelectedMerchants.includes(merchant.merchantId) && newSelectedMerchants.push(merchant.merchantId);
                })
            }
            window.localStorage.setItem(LocalStorage.SELECTED_MERCHANTS, newSelectedMerchants)

            setSelectedMerchants(newSelectedMerchants)
        }
    }

    const getTagLimit = (values) => {
        let textFieldWidth = textFieldRef.current ? textFieldRef.current.offsetWidth : 0
        if (textFieldWidth > 0) {
            textFieldWidth -= 62
        }
        let selectedValueWidth = 0;
        let limitCount = 0;
        values.forEach(value => {
            if (selectedValueWidth + (value.name?.length * 7) + 110  <= textFieldWidth) {
                selectedValueWidth += ((value.name?.length * 7) + 110 )
                limitCount++
            }
        });
        return limitCount
    }

    const groupClicked = (groupName) => {
        let groupState = groupStates.find(state => state.name === groupName);
        if (groupState) {
            let tempSelectedMerchants = []
            if (groupState?.state === 0) {
                tempSelectedMerchants = [...selectedMerchants];
                let tempMerchants = merchantList.filter(merchant => merchant.parentName === groupState.name);
                tempMerchants.forEach(merchant => {
                    tempSelectedMerchants.push(merchant.merchantId);
                })
            } else if (groupState?.state === 1) {
                tempSelectedMerchants = [...selectedMerchants];
                let tempMerchants = merchantList.filter(merchant => merchant.parentName === groupState.name);
                tempMerchants.forEach(merchant => {
                    !tempSelectedMerchants.includes(merchant.merchantId) && tempSelectedMerchants.push(merchant.merchantId);
                })
            } else if (groupState?.state === 2) {
                tempSelectedMerchants = [...selectedMerchants];
                let tempMerchants = merchantList.filter(merchant => merchant.parentName === groupState.name);
                tempMerchants.forEach(merchant => {
                    var i;
                    var found = tempSelectedMerchants.some((selectedMerchant, index) => {
                        i = index; return selectedMerchant === merchant.merchantId;
                    });

                    if (found) {
                        tempSelectedMerchants.splice(i, 1);
                    }
                })
            }
            updateSelectedMerchants(tempSelectedMerchants, true)
        }
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (filterValue === "") {
            if (merchantList?.length > 0) {
                setFilteredMerchantList(merchantList);
            }
        } else {
            var filteredItems = [];
            filteredItems = merchantList.filter(merchant => merchant.name.toLowerCase().search(filterValue.toLowerCase()) !== -1);
            
            setFilteredMerchantList(filteredItems)
        }
    }, [filterValue])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <FormControl fullWidth size="small" className="app-bar-select">
                <CustomAutoComplete
                    ListboxComponent={CustomSearchListbox}
                    ListboxProps={{
                        style: { maxHeight: '20rem', overflow: "auto" },
                        filterValue: filterValue,
                        setFilterValue: setFilterValue,
                        negateClose: negateClose,
                        setNegateClose: setNegateClose
                    }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        if (!negateClose) {
                            setOpen(false);
                        } else {
                            setNegateClose(false)
                        }
                    }}
                    multiple
                    size="small"
                    disableListWrap={true}
                    label="Select Merchant"
                    options={merchantList}
                    disableCloseOnSelect={false}
                    groupBy={(option) => option.parentName}
                    getOptionLabel={(merchant) => merchant?.name}
                    selectOnFocus={true}
                    noOptionsText={"none"}
                    renderInput={({ inputProps, ...rest }) =>
                        <CustomTextField
                            {...rest}
                            autoFocus={false}
                            role="listbox"
                            ref={textFieldRef}
                            label="Select Merchant"
                            inputProps={{ ...inputProps, readOnly: true }}
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    maxHeight: "40px"
                                }
                            }} />
                    }
                    renderTags={(value, getTagProps) => {
                        const limitTags = getTagLimit(value);

                        return (
                            <>
                                {value.slice(0, limitTags).map((option, index) => (
                                    <Chip size='small'
                                        {...getTagProps({ index })}
                                        key={index}
                                        label={option.name}
                                    />
                                ))}

                                {value.length > limitTags && ` +${value.length - limitTags}`}
                            </>
                        );
                    }}
                    renderOption={(props, option, state) => (
                        <ListItemButton {...props} key={option.merchantId}
                            autoFocus={false}
                            sx={{
                                "&.MuiAutocomplete-option[aria-selected='true']": {
                                    backgroundColor: 'transparent'
                                },
                                "&.MuiAutocomplete-option.Mui-focused": {
                                    cursor: "pointer",
                                    backgroundColor: "rgba(0, 193, 255, 0.12)"
                                },
                                "&:hover": {
                                    cursor: "pointer",
                                    backgroundColor: "rgba(0, 193, 255, 0.12) !important"
                                }
                            }}>
                            {state.selected ?
                                <Grid container>
                                    <Grid item sx={{ flexGrow: 1 }}>
                                        {option.name}
                                    </Grid>
                                    <Grid item display="flex" alignItems="center">
                                        <CheckOutlined fontSize="small" />
                                    </Grid>
                                </Grid> :
                                option.name
                            }
                        </ListItemButton>
                    )}
                    renderGroup={(params) => (
                        <>
                            {params.group &&
                                <Box component={"li"} key={params.key}>
                                    <Grid container
                                        sx={{
                                            pl: "14px",
                                            pt: "10px",
                                            pr: "10px",
                                            pb: "10px",
                                            color: lighten(theme?.palette?.text?.primary, 0.50),
                                            fontSize: 14,
                                            "&:hover": {
                                                cursor: "pointer",
                                                backgroundColor: "rgba(0, 193, 255, 0.12)"
                                            }
                                        }}
                                        onClick={() => groupClicked(params.group)}>
                                        <Grid item display="flex" flexGrow={1}>
                                            {params.group}
                                        </Grid>
                                        <Grid item display="flex" alignItems="center">
                                            {groupStates.find(state => state.name === params.group)?.state === 2 ?
                                                <CheckOutlined fontSize="small" /> :
                                                groupStates.find(state => state.name === params.group)?.state === 1 ?
                                                    <RemoveOutlined fontSize="small" /> : null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Box component={"ul"} key={params.key}
                                        sx={{
                                            p: 0,
                                            "& .MuiAutocomplete-option": {
                                                pl: 3,
                                                pr: "10px"
                                            }
                                        }}>
                                        {params.children}
                                    </Box>
                                </Box>
                            }
                            {!params.group &&
                                <Box component={"ul"} key={params.key}
                                    sx={{
                                        p: 0,
                                        "& .MuiAutocomplete-option": {
                                            pl: 3,
                                            pr: "10px"
                                        }
                                    }}>
                                    {params.children}
                                </Box>
                            }
                        </>
                    )}
                    filterOptions={(options, params) => {
                        var list = []
                        list.push({ name: "Select All", merchantId: "0" })
                        list.push(...filteredMerchantList)
                        return list;
                    }}
                    value={localSelectedMerchants}
                    onChange={(_, selectedMerchants) => {
                        var merchantIds = selectedMerchants.map(merchant => {
                            return merchant?.merchantId;
                        });
                        updateSelectedMerchants(merchantIds, true)
                    }}
                />
            </FormControl>
        </ClickAwayListener>
    )
}

MerchantSelect.propTypes = {
    selectedMerchants: PropTypes.array.isRequired,
    setSelectedMerchants: PropTypes.func.isRequired,
    merchant: PropTypes.object
};

export default MerchantSelect;