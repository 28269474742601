import KeycloakService from "./keycloakService";

// Only administrator & reamRole used currently
export const ROLES = {
    viewer: "VIEWER",
    editor: "EDITOR",
    owner: "OWNER",
    administrator: "MerchantPortal_AdministratorRole",
    readonly: "MerchantPortal_ReadOnly",
    realmRole: "default-roles-merchantportal"
};

// Below not used, can be used if scopes are included
export const SCOPES = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view"
};

// Below not used, can be used when scopes included
export const PERMISSIONS = {
    [ROLES.viewer]: [SCOPES.canView],
    [ROLES.editor]: [SCOPES.canView, SCOPES.canEdit],
    [ROLES.owner]: [
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ]
};

export default function AuthorizedElement({roles, scopes, children}) {
    
    if (KeycloakService.isLoggedIn) {
        const hasRole = KeycloakService.hasResourceRole(roles);

        if (hasRole) {
            return ( children );
        }
    }

    return <></>;
}

