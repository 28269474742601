import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import { Container } from '@mui/system';
import { Card, Grid } from '@mui/material';
import ProfileSelect from '../../components/profile-select/profileSelect';
import { useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMerchantProfiles } from '../../stores/merchantProfiles';
import CustomDateRangePicker from '../../components/custom-date-range-picker/customDateRangePicker';
import DashboardTimelineChart from '../dashboard/dashboard-charts/dashboardTimelineChart';
import { Timeline } from '../../constants/timeline';
import { getTransactionsTotalAmount } from '../../services/statisticsService';
import moment from 'moment';
import PaymentsThisYearChart from './payments-this-year-chart/paymentsThisYearChart';
import PaymentsByTimeChart from './payments-by-time-chart/paymentsByTimeChart';
import ComponentLoader from '../../components/component-loader/componentLoader';
import { getFormattedAmount, numberWithCommas } from '../../utils/amountFormatter';
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PaymentChart from './payment-chart/paymentChart';
import CurrencySelect from "../../components/currency-select/currencySelect";

function Reporting() {

    const dispatch = useDispatch();

    const [selectedMerchants] = useOutletContext();

    const { merchantProfiles } = useSelector(state => state.merchantProfiles);
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [profileIdsMerchantIds, setProfileIdsMerchantIds] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [navSelects, setNavSelects] = useState({
        isRcsEnabled:false,
        isOzowEnabled:false,
        isZapperEnabled:false
    })

    const [totalAmountCard, setTotalAmountCard] = useState([]);
    const [totalAmountOzow, setTotalAmountOzow] = useState([]);
    const [totalAmountRCS, setTotalAmountRCS] = useState([]);
    const [totalAmountZapper, setTotalAmountZapper] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);


    const [interval, setInterval] = useState('hour');

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [request, setRequest] = useState({
        profileIds: [],
        merchantIds: [],
        size: 10000,
        page: 0,
        displayName: "",
        profileId: ""
    })

    const handleChange = (value) => {
        console.log(value);
        setInterval(value)
    }

    useEffect(() => {
        if (selectedMerchants && selectedMerchants?.length > 0) {
            setSelectedProfile([])
            setIsLoading(true);
            setIsProfileLoading(true);
            setProfileIdsMerchantIds({...profileIdsMerchantIds, merchantIds: selectedMerchants})
            setRequest({...request, merchantIds: selectedMerchants})
        }
    }, [selectedMerchants, dispatch])

    useEffect(() =>{
        dispatch(fetchMerchantProfiles(request));
    }, [profileIdsMerchantIds?.merchantIds, request])

    useEffect(() => {
        setIsLoading(false);
        setIsProfileLoading(false);
        merchantProfiles?.content?.forEach(p => {
            p?.profileProducts?.forEach(product =>{
                if(product?.productCode === "RCS"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isRcsEnabled: true
                    }));
                }else
                if(product?.productCode === "OZOW"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isOzowEnabled: true
                    }));
                }else
                if(product?.productCode === "ZAPPER"){
                    setNavSelects((prevState) => ({
                        ...prevState,
                        isZapperEnabled: true
                    }));
                }
            })
        })
    }, [merchantProfiles])


    useEffect(() => {
        if (selectedProfile && selectedProfile?.length > 0 && selectedRange) {
            setProfileIdsMerchantIds({...profileIdsMerchantIds, profileIds: selectedProfile})
            getTransactionsTotalAmount({
                profileIds: selectedProfile,
                fromDate: moment(selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType : "CARD",
                currencyId: selectedCurrencies ? selectedCurrencies.map(currency => currency.id) : [0]
            }).then(response => {
                setTotalAmountCard(response?.data)
            }).catch(error => {
                console.log(error)
            })
            getTransactionsTotalAmount({
                profileIds: selectedProfile,
                fromDate: moment(selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType : "OZOW",
                currencyId: selectedCurrencies ? selectedCurrencies.map(currency => currency.id) : [0]
            }).then(response => {
                setTotalAmountOzow(response?.data)
            }).catch(error => {
                console.log(error)
            })
            getTransactionsTotalAmount({
                profileIds: selectedProfile,
                fromDate: moment(selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType : "RCS",
                currencyId: selectedCurrencies ? selectedCurrencies.map(currency => currency.id) : [0]
            }).then(response => {
                setTotalAmountRCS(response?.data)
            }).catch(error => {
                console.log(error)
            })
            getTransactionsTotalAmount({
                // profileIdsMerchantIds: profileIdsMerchantIds,
                profileIds: selectedProfile,
                fromDate: moment(selectedRange.startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment(selectedRange.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType : "ZAPPER",
                currencyId: selectedCurrencies ? selectedCurrencies.map(currency => currency.id) : [0]
            }).then(response => {
                setTotalAmountZapper(response?.data)
            }).catch(error => {
                console.log(error)
            })
        } else {
            setTotalAmountCard(0)
            setTotalAmountOzow(0)
            setTotalAmountRCS(0)
            setTotalAmountZapper(0)
        }
    }, [selectedRange, selectedProfile, selectedCurrencies])
    
    return (
        <Container maxWidth="100%" sx={{ mb: 4 }}>
            <Grid container sx={{ mt: 2 }} spacing={2}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <ProfileSelect sx={{ maxWidth: "500px", minWidth: "300px", width: "fit-content" }} profiles={merchantProfiles?.content ? merchantProfiles?.content : []} isLoading={isProfileLoading} selectedProfile={selectedProfile} handleChange={setSelectedProfile} />
                </Grid>
                <Grid item sx={{ flexGrow: 1, maxWidth: "300px", minWidth: "150px" }}>
                    <CurrencySelect selectedCurrencies={selectedCurrencies} setSelectedCurrencies={setSelectedCurrencies} />
                </Grid>
                <Grid item sx={{ flexGrow: 1, maxWidth: "300px", minWidth: "250px" }} >
                    <CustomDateRangePicker selectedRange={selectedRange} setSelectedRange={setSelectedRange}/>
                </Grid>
            </Grid>
            <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px" }}>
                <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4 }}>Transaction Timeline</Typography>
                <DashboardTimelineChart
                    profileIdsMerchantIds={profileIdsMerchantIds}
                    selectedTimeline={Timeline.CUSTOM}
                    isLoading={isLoading}
                    selectedRange={selectedRange}
                    selectedCurrencies={selectedCurrencies}/>
            </Card>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4 }}>Card</Typography>
                            </Grid>
                        </Grid>
                        <PaymentChart
                            profileIdsMerchantIds={profileIdsMerchantIds}
                            isLoading={isLoading}
                            selectedRange={selectedRange}
                            paymentType="CARD"
                            selectedCurrencies={selectedCurrencies}
                        />
                        <hr />
                        <Grid container direction="row" sx={{ width: "fit-content" }}>
                            {totalAmountCard?.length > 0 ? (
                                totalAmountCard.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <Typography fontSize={20} sx={{ mr: 2 }}>
                                    {
                                        selectedCurrencies.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Chip
                                                    label={`${item.symbol ? item.symbol + ' ' : ''}0`}
                                                    sx={{ ml: 1 }}
                                                />
                                            </React.Fragment>
                                        ))}
                                </Typography>
                            )}
                        </Grid>
                    </Card>
                    {navSelects?.isOzowEnabled || navSelects?.isRcsEnabled || navSelects?.isZapperEnabled?
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4 }}>{navSelects?.isOzowEnabled?"Ozow": navSelects?.isRcsEnabled?"RCS":"Zapper"}</Typography>
                            </Grid>
                        </Grid>
                        <PaymentChart profileIdsMerchantIds={profileIdsMerchantIds}
                                      isLoading={isLoading}
                                      selectedRange={selectedRange}
                                      paymentType={navSelects?.isOzowEnabled?"OZOW": navSelects?.isRcsEnabled?"RCS":"ZAPPER"}
                                      selectedCurrencies={selectedCurrencies}/>
                        <hr />
                        <Grid container direction="column" sx={{ width: "fit-content" }}>
                            {navSelects?.isOzowEnabled && totalAmountOzow?.length > 0 ? (
                                totalAmountOzow.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : navSelects?.isRcsEnabled && totalAmountRCS?.length > 0 ? (
                                totalAmountRCS.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Typography fontSize={20} sx={{ mr: 2 }}>
                                            {item.symbol ? item.symbol + ' ' : ''}{numberWithCommas(getFormattedAmount(item.amount))}
                                        </Typography>
                                    </React.Fragment>
                                ))
                            ) : navSelects?.isZapperEnabled && totalAmountZapper?.length > 0 ? (
                                totalAmountZapper.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <Typography fontSize={20} sx={{ mr: 2 }}>
                                    {
                                        selectedCurrencies.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Chip
                                                    label={`${item.symbol ? item.symbol + ' ' : ''}0`}
                                                    sx={{ ml: 1 }}
                                                />
                                            </React.Fragment>
                                        ))}
                                </Typography>
                            )}
                        </Grid>
                    </Card>
                    :null}
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4, height: "45px", alignItems: "center", display: "flex" }}>Payments This Year</Typography>
                        <PaymentsThisYearChart profileIdsMerchantIds={profileIdsMerchantIds} isLoading={isLoading} selectedCurrencies={selectedCurrencies} />
                    </Card>
                    {navSelects?.isOzowEnabled&&navSelects?.isRcsEnabled || navSelects?.isOzowEnabled&&navSelects?.isZapperEnabled || navSelects?.isRcsEnabled&&navSelects?.isZapperEnabled?
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4 }}>
                                    {navSelects?.isOzowEnabled && navSelects?.isRcsEnabled ? "RCS" : "Zapper"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <PaymentChart
                            profileIdsMerchantIds={profileIdsMerchantIds}
                            isLoading={isLoading}
                            selectedRange={selectedRange}
                            paymentType={navSelects?.isOzowEnabled && navSelects?.isRcsEnabled ? "RCS" : "ZAPPER"}
                            selectedCurrencies={selectedCurrencies}
                        />
                        <hr />
                        <Grid container direction="column" sx={{ width: "fit-content" }}>
                            {navSelects?.isRcsEnabled && totalAmountRCS?.length > 0 ? (
                                totalAmountRCS.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : navSelects?.isZapperEnabled && totalAmountZapper?.length > 0 ? (
                                totalAmountZapper.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <Typography fontSize={20} sx={{ mr: 2 }}>
                                    {
                                        selectedCurrencies.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Chip
                                                    label={`${item.symbol ? item.symbol + ' ' : ''}0`}
                                                    sx={{ ml: 1 }}
                                                />
                                            </React.Fragment>
                                        ))}
                                </Typography>
                            )}
                        </Grid>
                    </Card>
                    :null}
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4, height: "45px", alignItems: "center", display: "flex" }}>Payment by Time</Typography>
                        <PaymentsByTimeChart profileIdsMerchantIds={profileIdsMerchantIds} isLoading={isLoading} selectedRange={selectedRange}  selectedCurrencies={selectedCurrencies}/>
                    </Card>
                    {navSelects?.isOzowEnabled&&navSelects?.isRcsEnabled&&navSelects?.isZapperEnabled?
                    <Card sx={{ p: 2, mt: 2, boxShadow: "0px 3px 6px #00000029", borderRadius: "5px", height:"420px" }}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography fontWeight="bold" fontSize={20} sx={{ ml: 4 }}>Zapper</Typography>
                            </Grid>
                        </Grid>
                        <PaymentChart
                            profileIdsMerchantIds={profileIdsMerchantIds}
                            isLoading={isLoading}
                            selectedRange={selectedRange}
                            paymentType="ZAPPER"
                            selectedCurrencies={selectedCurrencies}
                        />
                        <hr />
                        <Grid container direction="row" sx={{ width: "fit-content" }}>
                            {totalAmountZapper?.length > 0 ? (
                                totalAmountZapper.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            label={`${item.symbol ? item.symbol + ' ' : ''}${numberWithCommas(getFormattedAmount(item.amount))}`}
                                            sx={{ ml: 1 }}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <Grid container direction="row" sx={{ width: "fit-content" }}>
                                    {
                                        selectedCurrencies.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Chip
                                                    label={`${item.symbol ? item.symbol + ' ' : ''}0`}
                                                    sx={{ ml: 1 }}
                                                />
                                            </React.Fragment>
                                        ))}
                                </Grid>
                            )}
                        {/*
                        a) Use Selected Currencies to map results against 
                        b)
                        */}
                        </Grid>
                    </Card>
                    :null}
                </Grid>
                
            {/* <ComponentLoader isOpen={isLoading} /> */}
            </Grid>
        </Container>
    )
}

export default Reporting;