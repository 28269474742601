import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    LineChart,
    //   BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    // DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import moment from "moment";
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { getTransactionsTimeline } from '../../../services/statisticsService';
import { Timeline } from '../../../constants/timeline';
import { CircularProgress, Grid, Typography } from '@mui/material';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, LegendComponent]
);

//Lighten Colors of previously Months Lines
const colorMapping = ['#034EA2', '#df372d', '#00cf3a'];
function lightenColor(color, percent) {
    const num = parseInt(color.slice(1), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = (num >> 8 & 0x00FF) + amt,
        B = (num & 0x0000FF) + amt;
    return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1).toUpperCase()}`;
}

function DashboardTimelineChart(props) {

    const [previousLines, setPreviousLines] = useState([]);
    const [nowLines, setNowLines] = useState([]);
    const [request, setRequest] = useState();
    const [requestPrevious, setRequestPrevious] = useState();
    const [xAxisDateTime, setxAxisDateTime] = useState();
    const currencyIds = props.selectedCurrencies.map(currency => currency.id);

    useEffect(() => {
        if (props?.profileIdsMerchantIds?.profileIds?.length > 0 && props.selectedTimeline) {
            let previousFromDate = "";
            let previousToDate = "";
            let nowFromDate = "";
            let nowToDate = moment().endOf("day");
            let diff = 0;
            let interval = "hour";

            switch (props.selectedTimeline) {
                case Timeline.TWENTYFOURHOURS:
                    nowFromDate = moment().startOf("day");
                    previousFromDate = moment().startOf("day").subtract(1, "week");
                    setxAxisDateTime(0);
                    previousToDate = moment().endOf("day").subtract(1, "week");
                    interval = "hour";
                    break;
                case Timeline.ONEWEEK:
                    nowFromDate = moment().startOf("week").add(1, "day");
                    previousFromDate = moment().startOf("week").add(1, "day").subtract(1, "week");
                    setxAxisDateTime(-1);
                    previousToDate = moment().endOf("week").add(1, "day").subtract(1, "week");
                    interval = "day";
                    break;
                case Timeline.ONEMONTH:
                    nowFromDate = moment().startOf("month");
                    previousFromDate = moment().subtract(1, "month").startOf("month");
                    setxAxisDateTime(2);
                    previousToDate = moment().subtract(1, "month").endOf("month");
                    interval = "day";
                    break;
                case Timeline.CUSTOM:
                    nowFromDate = moment(props.selectedRange?.startDate).startOf("day");
                    nowToDate = moment(props.selectedRange?.endDate).endOf("day");

                    const diff = nowToDate.diff(nowFromDate, "days");
                    if (diff <= 6) {
                        previousFromDate = moment(nowFromDate).startOf("day").subtract(1, "week");
                        previousToDate = moment(nowToDate).endOf("day").subtract(1, "week");
                    } else if (diff < nowFromDate.daysInMonth()) {
                        previousFromDate = moment(nowFromDate).startOf("day").subtract(1, "month");
                        previousToDate = moment(nowToDate).endOf("day").subtract(1, "month");
                    } else {
                        previousFromDate = moment(nowFromDate).startOf("day").subtract(1, "year");
                        previousToDate = moment(nowToDate).endOf("day").subtract(1, "year");

                    }
                    if (diff <= 2) {
                        interval = "hour";
                    } else {
                        interval = "day";
                    }
                    setxAxisDateTime(interval === "hour" ? 0 :
                        props === "day" ? -1 : 1)
                    break;
                default:
                    break;
            }

            setRequest((prevState)=>({
                ...prevState,
                profileIds: props.profileIdsMerchantIds?.profileIds,
                merchantIds: props.profileIdsMerchantIds?.merchantIds,
                fromDate: nowFromDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: nowToDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                paymentType : "ALL",
                currencyId: currencyIds.length > 0 ? currencyIds : [1]
            }))
            setRequestPrevious((prevState)=>({
                ...prevState,
                profileIds: props.profileIdsMerchantIds?.profileIds,
                merchantIds: props.profileIdsMerchantIds?.merchantIds,
                fromDate: previousFromDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: previousToDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                daysDiff: diff,
                paymentType : "ALL",
                currencyId: currencyIds.length > 0 ? currencyIds : [1]
            }))
        } else {
            setNowLines([]);
            setPreviousLines([]);
        }
    }, [props.profileIdsMerchantIds, props.selectedTimeline, props.selectedRange, props.selectedCurrencies])

    useEffect(() => {
        if (request) {
            getTransactionsTimeline(request).then(response => {
                let allDates = new Set(response.data.map(data => data.date));
                let tempData = {};
                props.selectedCurrencies.forEach(currency => {
                    tempData[currency.symbol] = Array.from(allDates).map(date => ({ date, amount: 0 }));
                });
                response.data.forEach(data => {
                    const currencySymbol = data.symbol || 'All';
                    const entry = tempData[currencySymbol]?.find(e => e.date === data.date);
                    if (entry) entry.amount = data.amount;
                });
                setNowLines(Object.keys(tempData).map((symbol, index) => ({
                    name: `Current ${symbol}`,
                    data: tempData[symbol].map(e => [e.date, e.amount]),
                    color: colorMapping[index % colorMapping.length]
                })));
            }).catch(error => {
                console.log(error);
                setNowLines([]);
            });
        }
    }, [request]);

    useEffect(() => {
        if (requestPrevious) {
            getTransactionsTimeline(requestPrevious).then(response => {
                let allDates = new Set(response.data.map(data => data.date));
                let tempData = {};
                props.selectedCurrencies.forEach(currency => {
                    tempData[currency.symbol] = Array.from(allDates).map(date => ({ date, amount: 0 }));
                });
                response.data.forEach(data => {
                    const currencySymbol = data.symbol || 'All';
                    const entry = tempData[currencySymbol]?.find(e => e.date === data.date);
                    if (entry) entry.amount = data.amount;
                });
                const fromCurrentDate = moment(request.fromDate).startOf("day");
                const fromPreviousDate = moment(requestPrevious.fromDate).startOf("day");
                const diff = fromCurrentDate.diff(fromPreviousDate, "days");
                setPreviousLines(Object.keys(tempData).map((symbol, index) => ({
                    name: `Previous ${symbol}`,
                    data: tempData[symbol].map(e => [moment(e.date).add(diff, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZZ"), e.amount]),
                    color: lightenColor(colorMapping[index % colorMapping.length], 40)
                })));
            }).catch(error => {
                console.log(error);
                setPreviousLines([]);
            });
        }
    }, [requestPrevious]);

    let legendOption = {
        data: [...(previousLines || []).map(line => ({
            name: line.name,
            lineStyle: {
                color: line.color
            },
            itemStyle: {
                color: line.color
            },
            symbol: 'triangle'
        })),
            ...(nowLines || []).map(line => ({
                name: line.name,
                lineStyle: {
                    color: line.color
                },
                itemStyle: {
                    color: line.color
                },
                symbol: 'circle'
            }))
        ]
    };
    legendOption = {
        ...legendOption,
        bottom: 0,
        right: 10,
        itemGap: 25,
        textStyle: {
            color: "#9898A2",
            fontFamily: "montserrat",
            fontSize: "10"
        },
        lineStyle:{
            opacity:0
        }

    }
    const seriesData = [
        ...previousLines.map(line => ({
            name: line.name,
            data: line.data,
            type: 'line',
            smooth: true,
            lineStyle: {
                color: line.color
            },
            itemStyle: {
                color: line.color
            },
            symbol: 'triangle',
            symbolSize: 8
        })),
        ...nowLines.map(line => ({
            name: line.name,
            data: line.data,
            type: 'line',
            smooth: true,
            lineStyle: {
                color: line.color
            },
            itemStyle: {
                color: line.color
            },
            symbol: 'circle',
            symbolSize: 8
        }))
    ];

    return (
        <>{previousLines?.length > 0&& nowLines?.length >0?
        <ReactEChartsCore
            echarts={echarts}
            showLoading={props.isLoading}
            option={
                {
                    grid: {
                        right: 5,
                        top: 20,
                        left: 80
                    },
                    xAxis: {
                        type: 'time',
                        axisLabel: {
                            rotate: 45,
                            formatter: axisValue => {
                                return  (xAxisDateTime === 0) ? moment(axisValue).format("HH:mm") :
                                        (xAxisDateTime === -1) ? moment(axisValue).format("dddd") :
                                        (xAxisDateTime >= 1 & xAxisDateTime <= 99) ? moment(axisValue).format("DD") :
                                        moment(axisValue).format("MMM")
                            }
                        },
                        name: "Date/Time",
                        nameLocation: "start",
                        nameGap: 10,
                        nameTextStyle: {
                            align: 'right',
                            verticalAlign: 'top',
                            color: "#9898A2",
                            fontFamily: "montserrat",
                            fontSize: "12",
                            fontWeight: "bold",
                            padding: [52, -85, 0, 0],
                        },
                        axisLine: {
                            symbol: 'none',
                            lineStyle: {
                                type: 'solid'
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        alignTicks: true,
                        type: 'value',
                        name: "Transactions",
                        nameRotate: "90",
                        minInterval: 1,
                        nameLocation: "center",
                        nameTextStyle: {
                            color: "#9898A2",
                            fontFamily: "montserrat",
                            fontSize: "12",
                            fontWeight: "bold",
                            padding: [0, 0, 50, 0]
                        },
                        axisLine: {
                            show: true,
                            symbol: "none",
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                opacity: 1
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                opacity: 1
                            }
                        },
                        axisPointer: {
                            show: true
                        }
                    },
                    // legend: {
                    //     icon: 'circle',
                    //     bottom: 0,
                    //     right: 0,
                    //     itemGap: 30,
                    //     textStyle: {
                    //         color: "#9898A2",
                    //         fontFamily: "montserrat",
                    //         fontSize: "12"
                    //     }
                    // },
                    // series: [
                    //     {
                    //         data: previousLine,
                    //         type: 'line',
                    //         smooth: true,
                    //         name: props.selectedTimeline === Timeline.TWENTYFOURHOURS ? `Last ${moment().format("dddd")}` :
                    //             props.selectedTimeline === Timeline.ONEWEEK ? "Last week" :
                    //                 props.selectedTimeline === Timeline.ONEMONTH ? "Last month" :
                    //                     `Average from ${moment(requestPrevious?.fromDate).format("YYYY-MM-DD")} to ${moment(requestPrevious?.toDate).format("YYYY-MM-DD")}`
                    //     },
                    //     {
                    //         data: nowLine,
                    //         type: 'line',
                    //         smooth: true,
                    //         name: props.selectedTimeline === Timeline.TWENTYFOURHOURS ? "Today" :
                    //             props.selectedTimeline === Timeline.ONEWEEK ? "This week" :
                    //                 props.selectedTimeline === Timeline.ONEMONTH ? "This month" :
                    //                     `Average from ${moment(request?.fromDate).format("YYYY-MM-DD")} to ${moment(request?.toDate).format("YYYY-MM-DD")}`
                    //     }
                    // ],
                    //
                    //color: ['#9898A2', '#00C1FF'],
                    legend: legendOption,
                    series: seriesData,
                    tooltip: {
                        show: true,
                        trigger: "axis",
                        
                        axisPointer: {
                            type: 'cross',
                        }
                    }
                }
            }
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
        />
        :
        <Grid container width="100%" height="300px" alignItems="center" justifyContent="center">
            {!props.isLoading ?
                <Typography>No Data Available</Typography>
                :
                <CircularProgress />
            }
        </Grid>
        }
        </>
    )

}

DashboardTimelineChart.propTypes = {
    profileIdsMerchantIds: PropTypes.object.isRequired,
    selectedTimeline: PropTypes.string.isRequired,
    selectedRange: PropTypes.object,
    selectedCurrencies: PropTypes.array.isRequired,
    interval: PropTypes.string,
    isLoading: PropTypes.bool
};

export default DashboardTimelineChart;