import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop } from '@mui/material';
import { TJLoader } from 'tj-components';

function ComponentLoader(props) {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.isOpen}
        >
            <TJLoader image="/tj-logo-05.svg" spinner="/loader.svg" />
        </Backdrop>
    )
}

ComponentLoader.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

export default ComponentLoader;