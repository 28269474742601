
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import moment from "moment";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";
import { ReactComponent as RCSLogo } from '../../../assets/images/rcs_logo.svg';
import { PaymentType } from "../../../constants/paymentType";

function DetailedView(props) {

    return (
        <Box width="100%">
            <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Details</Typography>
            {props.status === "Failed" || props.status === "Successful" ?
                <Box>
                    <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            {props.paymentType === PaymentType.RCS.toUpperCase() &&
                                <RCSLogo height={40} />
                            }
                            {props.paymentType === PaymentType.CARD.toUpperCase() &&
                                <Box sx={{ height: 40 }} component="img" src={
                                    (
                                        props.transactionDetails?.scheme === "VISA" ? require("../../../assets/images/visa.png") :
                                            props.transactionDetails?.scheme === "MASTERCARD" ? require("../../../assets/images/mastercard.png") :
                                                props.transactionDetails?.scheme === "DINERS" ? require("../../../assets/images/diners.png") :
                                                    props.transactionDetails?.scheme === "AMEX" ? require("../../../assets/images/amex.png") :
                                                            require("../../../assets/images/transparent.png")
                                    )
                                } alt="scheme" />
                            }
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} sx={{ color: "#404047" }}>{props.symbol}{numberWithCommas(getFormattedAmount(props.amount))}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Reference:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.merchantRef}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Date:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.transactionDetails?.transactionDateTime).format("YYYY/MM/DD")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Time:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.transactionDetails?.transactionDateTime).format("HH:mm:ss")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Result:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.status}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Response:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.transactionDetails?.responseCode} {props.transactionDetails?.responseCode ? "–" : ""} {props.transactionDetails?.responseText}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Provider:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.transactionDetails?.route}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>RRN:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.transactionDetails?.rrn}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.transactionDetails?.token}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid item xs={12} sx={{ alignItems: "center" }} zeroMinWidth>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Success Redirect URL:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.redirectUrls?.successUrl ? props.redirectUrls?.successUrl : "NA"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Failed Redirect URL:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.redirectUrls?.failedUrl ? props.redirectUrls?.failedUrl : "NA"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box> : props.status === "3DS Failed" || props.status === "3DS Authorised" ?
                    <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Date:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.threeDSecureDetails?.record?.dateLogged).format("YYYY/MM/DD")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Time:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.threeDSecureDetails?.record?.dateLogged).format("HH:mm:ss")}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>ECI:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.eci}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>MD Status:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.mdStatus}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Message:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.mdErrorMsg}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Protocol:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.protocol}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>CAVV:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.cavv}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                            <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Card Type:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.cardType}</Typography>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={2} sx={{ display: "flex" }}>
                                <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>URLs:</Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                                <Grid container direction="column">
                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.okUrl}</Typography>
                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.threeDSecureDetails?.record?.failUrl}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> : null
            }
        </Box>
    );
}

DetailedView.propTypes = {
    amount: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    threeDSecureDetails: PropTypes.object,
    transactionDetails: PropTypes.object,
    redirectUrls: PropTypes.object,
    merchantRef: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    paymentType: PropTypes.string
};

export default DetailedView;