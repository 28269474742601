import * as React from "react";
import Box from "@mui/system/Box";
import Info from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TransactionStatus } from "../../../../constants/transactionStatus";
import Paginator from "../../../../components/paginator/paginator";
import {
  getFormattedAmount,
  numberWithCommas,
} from "../../../../utils/amountFormatter";
import { TransactionCardStatus } from "../../../../constants/transactionCardStatus";
import CardTransactionMoreInfo from "../../../../components/transaction-more-info/more-info/cardTransactionMoreInfo";
import BulkPageRefresh from "../../../../components/page-refresh/bulk-page-refresh";
import OverflowTip from "../../../../components/overflow-tooltip/overflowTooltip";
import {
  fetchProfileCardTransactions,
  updateProfileCardTransactions,
} from "../../../../stores/profileCardTransactions";
import { Tooltip } from "@mui/material";

function CardTransactions(props) {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const { cnpTransactions } = useSelector((state) => state.cnpTransactions);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const activateSelect = () => {
    setBulkSelect(true);
  };

  const deactivateSelect = () => {
    setBulkSelect(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked === !true) {
      activateSelect();
      props.onBulkSelect(event.target.checked);
    } else {
      deactivateSelect();
      props.onBulkSelect(event.target.checked);
    }
  };

  useEffect(() => {
    if (props.bulkSelect === false) {
      setChecked(false);
      deactivateSelect();
    }
  }, [props.onBulkSelect]);

  const changeSort = (sortValues) => {
    if (sortValues.length > 0) {
      props.setRequest({
        ...props.request,
        sortBy: sortValues[0].field,
        sortOrder: sortValues[0].sort,
      });
    } else {
      props.setRequest({
        ...props.request,
        sortBy: "createdAt",
        sortOrder: "desc",
      });
    }
  };

  useEffect(() => {
    if (props.request?.profileIds && props.request?.profileIds.length > 0) {
      fetchAllTransaction();
    } else {
      dispatch(updateProfileCardTransactions([]));
    }
  }, [props.request, dispatch]);

  const fetchAllTransaction = useCallback(() => {
    if (props.request?.profileIds && props.request?.profileIds?.length > 0) {
      let transactionStatuses = [];
      props.request?.transactionStatuses?.forEach((transactionStatus) => {
        let index = Object.values(TransactionCardStatus).indexOf(
          transactionStatus
        );
        if (Object.keys(TransactionCardStatus)[index] === "_3DS_INITIATED") {
          transactionStatuses.push("3DS_INITIATED");
        } else if (
          Object.keys(TransactionCardStatus)[index] === "_3DS_AUTHORISED"
        ) {
          transactionStatuses.push("3DS_AUTHORISED");
        } else if (
          Object.keys(TransactionCardStatus)[index] === "_3DS_FAILED"
        ) {
          transactionStatuses.push("3DS_FAILED");
        } else if (
          Object.keys(TransactionCardStatus)[index] === "_3DS_ABANDONED"
        ) {
          transactionStatuses.push("3DS_ABANDONED");
        } else {
          transactionStatuses.push(Object.keys(TransactionCardStatus)[index]);
        }
      });

      props.setIsLoading(true);
      dispatch(
        fetchProfileCardTransactions({
          ...props.request,
          transactionStatuses: transactionStatuses,
          scheme: props.request.scheme.toUpperCase(),
          acquiringBank: props.request.acquiringBank.toUpperCase(),
        })
      );
    }
  });

  const [moreInfoRequest, setMoreInfoRequest] = useState({
    isOpen: false,
    transactionId: "",
    symbol: "",
    amount: 0,
    transactionStatus: "",
    displayName: "",
    createdAt: "",
    lastModified: "",
    cardTransactionDetails: null,
    merchantRef: "",
    pan: "",
    nameOnCard: "",
    currencyCode: "ZAR",
    selectedprofile: "",
    merchantId: "",
  });

  const handleMoreInfoBackClick = () => {
    setMoreInfoRequest({
      ...moreInfoRequest,
      isOpen: false,
    });
  };

  const openMoreInfo = (
    transactionId,
    symbol,
    amount,
    transactionStatus,
    displayName,
    createdAt,
    lastModified,
    cardTransactionDetails,
    merchantRef,
    currencyCode,
    profileId
  ) => {
    let merchantId = props.request?.profiles?.find((profile) => profile.profileId === profileId)?.merchantId;
    var displayNameProfile = props.profiles?.find(profile => profile.profileId === profileId)?.displayName;

    setMoreInfoRequest({
      isOpen: true,
      transactionId: transactionId,
      symbol: symbol,
      amount: amount ? amount : 0,
      transactionStatus: transactionStatus,
      displayName: displayNameProfile,
      createdAt: createdAt,
      lastModified: lastModified,
      cardTransactionDetails: cardTransactionDetails,
      merchantRef: merchantRef,
      currencyCode: currencyCode,
      selectedprofile: profileId,
      merchantId: merchantId,
    });
  };

  const handleChangePage = (newPage) => {
    props.setRequest({
      ...props.request,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    props.setRequest({
      ...props.request,
      limit: value,
    });
  };

  const handleDataTransfer = () => {
    if (cnpTransactions.content) {
      const transactionData = cnpTransactions?.content.filter((trx) =>
        selectedRows.includes(trx.transactionId)
      );
      props.getSelectedBulkTransactions(transactionData);
    }
  };

  useEffect(() => {
    props.setIsLoading(false);
  }, [cnpTransactions]);

  useEffect(() => {
    handleDataTransfer();
  }, [selectedRows]);

  const handleTableUpdate = (status) => {
    setMoreInfoRequest({
      ...moreInfoRequest,
      transactionStatus: status,
    });

    const newTransactions = cnpTransactions?.content.map((transaction) => {
      if (transaction.transactionId === moreInfoRequest.transactionId) {
        return { ...transaction, transactionStatus: status };
      }
      return transaction;
    });

    const newTransactionsPaginated = {
      ...cnpTransactions,
      content: newTransactions,
    };

    dispatch(updateProfileCardTransactions(newTransactionsPaginated));
  };

  return (
    <Card>
      <DataGrid
        loading={props.isLoading}
        sx={{
          height: "calc(100vh - 342px)",
          "& .MuiDataGrid-columnHeadersInner": {
            background: "#EBFAFF",
            fontSize: 12,
          },
          "& .MuiDataGrid-columnHeaders": {
            boxShadow: "0px 0px 10px #00000029",
            display: "block",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowY: "overlay",
          },
          "& .amount-cell": {
            fontWeight: "bold",
          },
          "& .cell-font": {
            fontSize: 12,
          },
          "& .cardstatus-cell": {
            fontSize: 12,
          },
        }}
        checkboxSelection={bulkSelect}
        onSelectionModelChange={(ids) => {
          setSelectedRows(ids);
        }}
        keepNonExistentRowsSelected={true}
        //pre-existing code below
        getRowId={(row) => row.transactionId}
        rows={cnpTransactions?.content ? cnpTransactions?.content : []}
        columns={[
          {
            align: "center",
            headerAlign: "center",
            field: "createdAt",
            headerName: "Date",
            flex: 1,
            cellClassName: "cell-font",
            valueFormatter: (params) =>
              moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),
            renderCell: (params) => {
              return (
                <OverflowTip
                  value={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")}
                  text={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")}
                />
              );
            },
          },
          {
            align: "center",
            headerAlign: "center",
            field: "merchantRef",
            headerName: "Merchant Ref",
            flex: 1,
            cellClassName: "cell-font",
            renderCell: (params) => {
              return <OverflowTip value={params?.value} text={params?.value} />;
            },
          },
          {
            align: "center",
            headerAlign: "center",
            field: "cardHolder",
            valueGetter: (params) => {
              return params.row.cardHolder;
            },
            headerName: "Card Holder",
            flex: 1,
            cellClassName: "cell-font",
          },
          {
            align: "center",
            headerAlign: "center",
            field: "maskedPan",
            valueGetter: (params) => {
              return params.row.cardNumber;
            },
            headerName: "Card Number",
            flex: 1,
            cellClassName: "cell-font",
          },
          {
            align: "center",
            headerAlign: "center",
            field: "transactionId",
            headerName: "Transaction ID",
            flex: 1,
            cellClassName: "cell-font",
            renderCell: (params) => {
              return <OverflowTip value={params?.value} text={params?.value} />;
            },
          },
          {
            headerAlign: "center",
            field: "transactionStatus",
            headerName: "Status",
            width: 200,
            cellClassName: "cardstatus-cell",
            renderCell: (params) => {
              return (
                <>
                  {(params.row.transactionStatus === "PAYMENT_REFUNDED" || params.row.transactionStatus === "PAYMENT_PARTIALLY_REFUNDED") &&
                    <Box
                      sx={{ display: "flex", alignItems: "center", width: "100%" }} >

                      <Tooltip
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          opacity: "0%",
                        }}
                        align="center"
                        title={
                          <div>
                            <Typography
                              sx={{ fontSize: 12 }}
                              style={{ color: "white" }}
                            >
                              Refunded Amount
                            </Typography>
                            <Typography
                              sx={{ fontSize: 12 }}
                              style={{
                                textAlign: "center",
                                marginTop: "3px",
                                color: "white",
                              }}
                            >

                              {params.row.currencySymbol}{((params.row.amount - params.row.expectedAmount)).toFixed(2)}
                            </Typography>
                          </div>
                        }
                        arrow
                      >
                        <Typography
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: 12,
                            color:
                              params.row.transactionStatus === "PAYMENT_AUTHORISED"
                                ? "blue"
                                : params.row.transactionStatus ===
                                  "PAYMENT_INITIATED"
                                  ? "blue"
                                  : params.row.transactionStatus === "PAYMENT_SETTLED"
                                    ? "green"
                                    : params.row.transactionStatus === "3DS_INITIATED"
                                      ? "blue"
                                      : params.row.transactionStatus === "3DS_AUTHORISED"
                                        ? "blue"
                                        : params.row.transactionStatus === "3DS_FAILED"
                                          ? "red"
                                          : params.row.transactionStatus === "3DS_ABANDONED"
                                            ? "red"
                                            : params.row.transactionStatus ===
                                              "PAYMENT_CANCELLED"
                                              ? "red"
                                              : params.row.transactionStatus === "PAYMENT_FAILED"
                                                ? "red"
                                                : params.row.transactionStatus ===
                                                  "PAYMENT_REVERSED"
                                                  ? "orange"
                                                  : params.row.transactionStatus ===
                                                    "PAYMENT_REFUNDED"
                                                    ? "blue"
                                                    : params.row.transactionStatus ===
                                                      "PAYMENT_DECLINED"
                                                      ? "red"
                                                      : params.row.transactionStatus ===
                                                        "PAYMENT_PARTIALLY_REFUNDED"
                                                        ? "blue"
                                                        : "black",
                          }}
                        >
                          {params.row.transactionStatus === "3DS_INITIATED"
                            ? TransactionStatus["_3DS_INITIATED"]
                            : params.row.transactionStatus === "3DS_AUTHORISED"
                              ? TransactionStatus["_3DS_AUTHORISED"]
                              : params.row.transactionStatus === "3DS_FAILED"
                                ? TransactionStatus["_3DS_FAILED"]
                                : params.row.transactionStatus === "3DS_ABANDONED"
                                  ? TransactionStatus["_3DS_ABANDONED"]
                                  : TransactionStatus[params.row.transactionStatus]}
                        </Typography>
                      </Tooltip>
                    </Box>
                  }
                  {params.row.transactionStatus !== "PAYMENT_REFUNDED" && params.row.transactionStatus !== "PAYMENT_PARTIALLY_REFUNDED" &&
                    <Typography
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        fontSize: 12,
                        color:
                          params.row.transactionStatus === "PAYMENT_AUTHORISED"
                            ? "blue"
                            : params.row.transactionStatus ===
                              "PAYMENT_INITIATED"
                              ? "blue"
                              : params.row.transactionStatus === "PAYMENT_SETTLED"
                                ? "green"
                                : params.row.transactionStatus === "3DS_INITIATED"
                                  ? "blue"
                                  : params.row.transactionStatus === "3DS_AUTHORISED"
                                    ? "blue"
                                    : params.row.transactionStatus === "3DS_FAILED"
                                      ? "red"
                                      : params.row.transactionStatus === "3DS_ABANDONED"
                                        ? "red"
                                        : params.row.transactionStatus ===
                                          "PAYMENT_CANCELLED"
                                          ? "red"
                                          : params.row.transactionStatus === "PAYMENT_FAILED"
                                            ? "red"
                                            : params.row.transactionStatus ===
                                              "PAYMENT_REVERSED"
                                              ? "orange"
                                              : params.row.transactionStatus ===
                                                "PAYMENT_REFUNDED"
                                                ? "blue"
                                                : params.row.transactionStatus ===
                                                  "PAYMENT_DECLINED"
                                                  ? "red"
                                                  : params.row.transactionStatus ===
                                                    "PAYMENT_PARTIALLY_REFUNDED"
                                                    ? "blue"
                                                    : "black",
                      }}
                    >
                      {params.row.transactionStatus === "3DS_INITIATED"
                        ? TransactionStatus["_3DS_INITIATED"]
                        : params.row.transactionStatus === "3DS_AUTHORISED"
                          ? TransactionStatus["_3DS_AUTHORISED"]
                          : params.row.transactionStatus === "3DS_FAILED"
                            ? TransactionStatus["_3DS_FAILED"]
                            : params.row.transactionStatus === "3DS_ABANDONED"
                              ? TransactionStatus["_3DS_ABANDONED"]
                              : TransactionStatus[params.row.transactionStatus]}
                    </Typography>
                  }
                </>
              );
            },
          },
          {
            headerAlign: "center",
            field: "amount",
            headerName: "Amount",
            flex: 1,
            cellClassName: "amount-cell",
            renderCell: (params) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "center", fontSize: 12 }}
                    fontWeight="bold"
                  >
                    {params.row.currencySymbol}
                    {params.row.amount
                      ? numberWithCommas(getFormattedAmount(params.row.amount))
                      : 0}
                  </Typography>
                </Box>
              );
            },
          },
          {
            headerAlign: "center",
            field: "expectedAmount",
            headerName: "Expected Banked Amount",
            width: 200,
            cellClassName: "amount-cell",
            renderCell: (params) => {
              return (
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      textAlign: "center",
                      pl: "40px",
                      fontSize: 12,
                    }}
                    fontWeight="bold"
                  >
                    {params.row.currencySymbol}
                    {params.row.expectedAmount
                      ? numberWithCommas(
                        getFormattedAmount(params.row.expectedAmount)
                      )
                      : 0}
                  </Typography>
                  <IconButton
                    disabled={
                      params.row.transactionStatus === "PAYMENT_INITIATED" ||
                      params.row.transactionStatus === "PAYMENT_CANCELLED" ||
                      params.row.transactionStatus === "PAYMENT_TIMED_OUT" ||
                      params.row.transactionStatus === "OZOW_INITIATED" ||
                      params.row.transactionStatus === "3DS_ABANDONED"
                    }
                    sx={{ color: "#034EA2" }}
                    onClick={() =>
                      openMoreInfo(
                        params.row.transactionId,
                        params.row.currencySymbol,
                        params.row.amount,
                        params.row.transactionStatus,
                        "BuyAlot",
                        params.row.createdAt,
                        params.row.createdAt,
                        params.row.transactionMoreDetails,
                        params.row.merchantRef,
                        params.row.currencyCode,
                        params.row.profileId
                      )
                    }
                  >
                    <Info />
                  </IconButton>
                </Box>
              );
            },
          },
        ]}
        rowsPerPageOptions={[5, 10, 25]}
        pageSize={props.request.limit}
        rowCount={
          cnpTransactions?.totalElements ? cnpTransactions.totalElements : 0
        }
        page={props.request.page}
        pagination
        paginationMode="server"
        components={{ Footer: BulkPageRefresh }}
        componentsProps={{
          footer: {
            refreshFunction: fetchAllTransaction,
            setBulkSelect: setBulkSelect,
            handleChange: handleChange,
            checked: checked,
          },
          pagination: {
            ActionsComponent: Paginator,
          },
        }}
        onPageSizeChange={(newLimit) => handleChangeRowsPerPage(newLimit)}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onSortModelChange={changeSort}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
      />
      <CardTransactionMoreInfo
        isOpen={moreInfoRequest.isOpen}
        transactionId={moreInfoRequest.transactionId}
        handleBack={handleMoreInfoBackClick}
        displayName={moreInfoRequest.displayName}
        symbol={moreInfoRequest.symbol}
        amount={moreInfoRequest.amount}
        createdAt={moreInfoRequest.createdAt}
        lastModified={moreInfoRequest.lastModified}
        transactionStatus={
          moreInfoRequest.transactionStatus === "3DS_INITIATED"
            ? TransactionStatus["_3DS_INITIATED"]
            : moreInfoRequest.transactionStatus === "3DS_AUTHORISED"
              ? TransactionStatus["_3DS_AUTHORISED"]
              : moreInfoRequest.transactionStatus === "3DS_FAILED"
                ? TransactionStatus["_3DS_FAILED"]
                : moreInfoRequest.transactionStatus === "3DS_ABANDONED"
                  ? TransactionStatus["_3DS_ABANDONED"]
                  : TransactionStatus[moreInfoRequest.transactionStatus]
                    ? TransactionStatus[moreInfoRequest.transactionStatus]
                    : ""
        }
        transactionMoreDetails={moreInfoRequest.cardTransactionDetails}
        profileId={
          moreInfoRequest.selectedprofile ? moreInfoRequest.selectedprofile : ""
        }
        merchantRef={moreInfoRequest.merchantRef}
        currencyCode={moreInfoRequest.currencyCode}
        merchantId={
          moreInfoRequest.merchantId ? moreInfoRequest.merchantId : ""
        }
        handleTableUpdate={handleTableUpdate}
        setMoreInfoRequest={setMoreInfoRequest}
      />
    </Card>
  );
}

CardTransactions.propType = {
  profiles: PropTypes.array.isRequired,
  profileId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  merchantRef: PropTypes.array.isRequired,
  handleChange: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  selectedRange: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  bulkSelect: PropTypes.bool.isRequired,
  setBulkSelect: PropTypes.func.isRequired,
  onBulkSelect: PropTypes.func,
  getSelectedBulkTransactions: PropTypes.func,
};

export default CardTransactions;
