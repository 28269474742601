import * as React from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/system';

function BulkSwitch(props) {
  const StyledSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '40px',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#00C1FF',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box>
      <Tooltip title="Activate Bulk Selection">
        <FormControlLabel
          control={
            <StyledSwitch
              sx={{ m: 1 }}
              checked={props.checked}
              onChange={props.handleChanges}
              inputProps={{ 'aria-label': 'controlled' }}
              label={props.label}
            />
          }
          label={props.label}
        />
      </Tooltip>
    </Box>
  );
}

BulkSwitch.propTypes = {
  setBulkSelect: PropTypes.func.isRequired,
  cancelBulkSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default BulkSwitch;
