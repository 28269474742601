import ReactGA from "react-ga4";
import KeycloakService from "../keycloak/keycloakService";

export default class GoogleAnalytics {
    static initializeAnalytics = () => {
        ReactGA.initialize(
            process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
            {
                gtagUrl: `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`,
                gtagOptions:{
                    "send_page_view": "false"
                }
            }
        );
    }
    static sendException = (description, fatal) => {
        ReactGA.gtag("event", "exception", {
            description: description,
            fatal: fatal
        })
    }
    static sendPageView = (pageTitle, pageLocation) => {
        ReactGA.gtag("event", "page_view", {
            page_title: pageTitle,
            page_location: pageLocation
        })
    }
    static updatedBrandingConfig = (configType) => {
        ReactGA.gtag("event", "updated_config", {
            type: configType
        })
    }
    static merchantsSelected = (merchantName) => {
        ReactGA.gtag("event", "merchants_selected", {
            merchant_name: merchantName
        })
    }
    static profilesSelected = () => {
        ReactGA.gtag("event", "profile_selected", {
            user: KeycloakService.getUsername()
        })
    }
    static savePaymentPageConfig = (savePaymentConfig) => {
        ReactGA.gtag("event", "saved_payment", {
            save_config: savePaymentConfig
        })
    }
    static saveGeneralConfigSettings = (saveGeneralConfig) => {
        ReactGA.gtag("event", "saved_config", {
            profile_id: saveGeneralConfig
        })
    }
    static csvPaymentDownloaded = (csvDownload) => {
        ReactGA.gtag("event", "csv_download", {
            profile_id: csvDownload
        })
    }
    static csvCardDownloaded = (csvCardDownload) => {
        ReactGA.gtag("event", "csv_card", {
            payment_method: csvCardDownload
        })
    }
    static csvMobicredDownloaded = (csvMobicredDownload) => {
        ReactGA.gtag("event", "csv_mobicred", {
            payment_method: csvMobicredDownload
        })
    }
    static csvOzowDownloaded = (csvOzowDownload) => {
        ReactGA.gtag("event", "csv_ozow", {
            payment_method: csvOzowDownload
        })
    }
    static csvRcsDownloaded = (csvRcsDownload) => {
        ReactGA.gtag("event", "csv_rcs", {
            payment_method: csvRcsDownload
        })
    }
    static ApiCallRefund = (transactionRefund) => {
        ReactGA.gtag("event", "refund_transaction", {
            refund_transaction: transactionRefund
        })
    }
    static ApiCallSettle = (transactionSettle) => {
        ReactGA.gtag("event", "settle_transaction", {
            settle_transaction: transactionSettle
        })
    }
    static ApiCallReverse = (transactionReverse) => {
        ReactGA.gtag("event", "reverse_transaction", {
            reverse_transaction: transactionReverse
        })
    }
    static getCardNotPresentTransactionID = (transactionID) => {
        ReactGA.gtag("event", "transaction_id", {
            transaction_id: transactionID
        })
    }
    static getCardNotPresentTransactionsRequest = (transactionRequest) => {
        ReactGA.gtag("event", "cardNotPresent_request", {
            cardNotPresent_transaction: transactionRequest
        })
    }
    static uploadFile = (uploadFileRequest) => {
        ReactGA.gtag("event", "upload_file", {
            upload_request: uploadFileRequest
        })
    }
    static getMerchant = (merchantId) => {
        ReactGA.gtag("event", "get_merchant", {
            get_merchantid: merchantId
        })
    }
    static getPayment = (paymentIntentId) => {
        ReactGA.gtag("event", "get_payment", {
            get_payment: paymentIntentId
        })
    }
    static getTdsTransaction = (transactionId) => {
        ReactGA.gtag("event", "get_tdstransaction", {
            get_transaction: transactionId
        })
    }
    static getMobicredTransactionId = (mobicredTransactionId) => {
        ReactGA.gtag("event", "get_mobicredtransaction_id", {
            mobicred_id: mobicredTransactionId
        })
    }
    static getMobicredTransactionRequest = () => {
        ReactGA.gtag("event", "get_mobicredtransaction_request", {
            user: KeycloakService.getUsername()
        })
    }
    static refundMobicredTransactionRequest = (mobicredTransactionRefund) => {
        ReactGA.gtag("event", "refund_mobicredtransaction_request", {
            mobicred_refund: mobicredTransactionRefund
        })
    }
    static getOzowTransactionId = (ozowTransactionId) => {
        ReactGA.gtag("event", "get_ozowtransaction_id", {
            ozow_id: ozowTransactionId
        })
    }
    static getOzowTransactionRequest = () => {
        ReactGA.gtag("event", "get_ozowtransaction_request", {
            user: KeycloakService.getUsername()
        })
    }
    static refundOzowTransactionRequest = (ozowTransactionRefund) => {
        ReactGA.gtag("event", "refund_ozowtransaction_request", {
            ozow_refund: ozowTransactionRefund
        })
    }
    static getMerchantProfileIds = (merchantProfileIds) => {
        ReactGA.gtag("event", "merchant_ids", {
            merchant_ids: merchantProfileIds
        })
    }
    static getMerchantProfileRequest = () => {
        ReactGA.gtag("event", "merchant_profile", {
            user: KeycloakService.getUsername()
        })
    }
    static updatedProfile = (profileUpdate) => {
        ReactGA.gtag("event", "update_profile", {
            update_profile: profileUpdate
        })
    }
    static getWebhookAuth = (webhookAuth) => {
        ReactGA.gtag("event", "get_Webhook_Auth", {
            get_webhook: webhookAuth
        })
    }
    static createdWebhookAuth = (createWebhookAuth) => {
        ReactGA.gtag("event", "webhook_auth_created", {
            create_webhook: createWebhookAuth
        })
    }
    static updateWebhookAuth = (updateWebhookAuth) => {
        ReactGA.gtag("event", "webhook_auth_updated", {
            update_webhook: updateWebhookAuth
        })
    }
    static deleteWebhookAuth = (deleteWebhookAuth) => {
        ReactGA.gtag("event", "webhook_auth_deleted", {
            delete_webhook: deleteWebhookAuth
        })
    }
    static getRcsTransactionRequest = () => {
        ReactGA.gtag("event", "rcs_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static getRcsCsvTransaction = () => {
        ReactGA.gtag("event", "rcs_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static getRcsTransactionId = (rcsTransactionId) => {
        ReactGA.gtag("event", "rcs_transactions_id", {
            rcs_id: rcsTransactionId
        })
    }
    static refundRcsTransactionRequest = (refundRcsTransaction) => {
        ReactGA.gtag("event", "refund_rcs_transactions", {
            refund_rcs: refundRcsTransaction
        })
    }
    static getTransactionsPerformed = () => {
        ReactGA.gtag("event", "transactions_performed", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionsSuccessful = () => {
        ReactGA.gtag("event", "transactions_successful", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionsFailed = () => {
        ReactGA.gtag("event", "transactions_failed", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionsAmount = () => {
        ReactGA.gtag("event", "transactions_amount", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionTimeline = () => {
        ReactGA.gtag("event", "transactions_timeline", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionsTotalAmount = () => {
        ReactGA.gtag("event", "transactions_total_amount", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionDataAmount = () => {
        ReactGA.gtag("event", "transactions_data_amount", {
            user: KeycloakService.getUsername()
        })
    }
    static getTransactionHourlyAvg = () => {
        ReactGA.gtag("event", "transactions_hourly_average", {
            user: KeycloakService.getUsername()
        })
    }
    static getMerchantTransactions = (MerchantTransactions) => {
        ReactGA.gtag("event", "merchant_transactions", {
            merchant_transaction: MerchantTransactions
        })
    }
    static getProfileTransaction = (ProfileTransactions) => {
        ReactGA.gtag("event", "profile_transactions", {
            profile_transaction: ProfileTransactions
        })
    }
    static getProfileFailedTransaction = (ProfileFailedTransactions) => {
        ReactGA.gtag("event", "profile_failed_transactions", {
            profile_failed_transaction: ProfileFailedTransactions
        })
    }
    static getProfileAllTransaction = () => {
        ReactGA.gtag("event", "profile_all_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static getProfileCardTransaction = () => {
        ReactGA.gtag("event", "profile_card_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static getProfileAllCsvTransaction = () => {
        ReactGA.gtag("event", "profile_Csv_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static getCardProfileAllCsvTransaction = () => {
        ReactGA.gtag("event", "card_profile_csv_transactions", {
            user: KeycloakService.getUsername()
        })
    }
    static  bulkActionRequests = () => {
        ReactGA.gtag("event", "bulk_action_requests", {
            user: KeycloakService.getUsername()
        })
    }

    static userThemeToggle = () => {
        ReactGA.gtag('event', 'theme_toggle_enabled', {
            user: KeycloakService.getUsername()
        });
    };

}
