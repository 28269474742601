import { styled } from '@mui/material/styles';
import { ArrowBack } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getMerchantProfile } from "../../services/profileServices";
import ConfirmationBackdrop from '../../components/confirmation-backdrop/confirmationBackdrop';
import ComponentLoader from '../../components/component-loader/componentLoader';

const CustomTab = styled(Tab)({
    '&.MuiTab-root.Mui-selected': {
        backgroundColor: '#0098D8',
        color: "#FFFFFF"
    }
});

function ConfigManagerInfo(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState("Legal copy");
    const [isLoading, setIsLoading] = useState(false);

    const [changesMade, setChangesMade] = useState(false);

    const [confirmationProperties, setConfirmationProperties] = useState({
        isOpen: false,
    });

    const handleChange = (event, index) => {
        console.log(index)
        setTabIndex(index);
        var page = "";
        switch (index) {
            case 0:
                setChangesMade(false);
                setCurrentPage("Legal copy");
                page = "general"
                break;
            case 1:
                setChangesMade(false);
                setCurrentPage("End-point");
                page = "end-point"
                break;
            case 2:
                setChangesMade(false);
                setCurrentPage("Payment Page Settings");
                page = "branding"
                break;
            default:
                break;
        }
        navigate(page, { replace: true });
    };

    useEffect(() => {
        if (window.location.href.includes("config-manager/info/general")) {
            setTabIndex(0);
        } else if (window.location.href.includes("config-manager/info/end-point")) {
            setTabIndex(1);
        } else if (window.location.href.includes("config-manager/info/branding")) {
            setTabIndex(2);
        }
    }, [tabIndex])

    useEffect(() => {
        if (location.state?.profileId) {
            setIsLoading(true);
            getMerchantProfile(location.state.profileId).then((profile) => {
                setProfile(profile.data);
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
        } else if (window.sessionStorage.getItem("configId")) {
            getMerchantProfile(window.sessionStorage.getItem("configId")).then((profile) => {
                setProfile(profile.data);
                setIsLoading(false);
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
        }
    }, [location])

    const handleBackClick = () => {
        if (changesMade) {
            setConfirmationProperties({
                ...confirmationProperties,
                isOpen: true
            })
        } else {
            navigate("/config-manager", { replace: true });
        }
    }

    const handleConfirmationCancel = () => {
        setConfirmationProperties({
            ...confirmationProperties,
            isOpen: false
        })
    }

    const handleConfirmationGoBack = () => {
        setConfirmationProperties({
            ...confirmationProperties,
            isOpen: false
        });
        navigate("/config-manager", { replace: true });
    }

    return (
        <Container maxWidth="100%">
            {/* TODO: Keep for later
            <Breadcrumbs
                sx={{ mt: 2, color: "#0098D8" }}
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Typography fontSize={12} sx={{ color: (theme) => theme.palette.secondary.contrastText }} key="1">
                    Settings
                </Typography>
                <Typography fontSize={12} fontWeight="bold" sx={{ color: (theme) => theme.palette.primary.dark }} key="2">
                    {currentPage}
                </Typography>
            </Breadcrumbs> */}
            <Grid container alignItems="center" sx={{ mt: 2 }}>
                <Grid item sx={{ flexGrow: 0 }}>
                    <Button onClick={handleBackClick} variant="outlined" startIcon={<ArrowBack />} color="primary" sx={{ boxShadow: "0px 3px 6px #00000029", color: "#0098D8", fontWeight: "bold", border: "1px solid #0098D8", textTransform: "none", height: "50px", width: "140px" }}>
                        Back
                    </Button>
                </Grid>
                <Grid item sx={{ flexGrow: 0, ml: 2 }}>
                    <Grid container direction="column">
                        <Typography fontSize={20} fontWeight="bold" sx={{ color: (theme) => theme.palette.primary.dark }}>
                            {profile?.displayName}
                        </Typography>
                        <Typography fontSize={14} sx={{ color: "#9898A2" }}>
                            Merchant ID: {profile?.merchantId} | Profile ID: {profile?.profileId}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>

                </Grid>
                <Grid item sx={{ flexGrow: 0, height: "50px" }}>
                    <Box sx={{ height: "100%", borderRadius: "5px", display: "flex", alignItems: "center" }}>
                        <Box sx={{ height: "50%", width: "37px", background: profile?.active ? "#08F9BA" : "#DF372D", mr: 2, borderRadius: 50, boxShadow: "0px 3px 6px #00000029" }}></Box>
                        <Grid container direction="column" sx={{ display: "flex", alignItems: "left", justifyContent: "center" }}>
                            <Typography fontSize={18} fontWeight="bold" lineHeight="1" color="#404047">{profile?.active ? "Active" : "Inactive"}</Typography>
                            <Typography fontSize={10} color="#404047">Profile Status</Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                sx={{
                    boxShadow: "0px 3px 6px #00000029",
                    background: "#00C1FF", mt: 2
                }}
            >
                <CustomTab sx={{ color: "#FFFFFF", textTransform: "none", width: 250, "&:hover": { backgroundColor: '#0098D8' } }} label="Legal copy" />
                <CustomTab sx={{ color: "#FFFFFF", textTransform: "none", width: 250, "&:hover": { backgroundColor: '#0098D8' } }} label="End-point" />
                <CustomTab sx={{ color: "#FFFFFF", textTransform: "none", width: 250, "&:hover": { backgroundColor: '#0098D8' } }} label="Payment Page Settings" />
            </Tabs>
            <Outlet context={[profile, setProfile, setChangesMade]} />
            <ComponentLoader isOpen={isLoading} />
            <ConfirmationBackdrop isOpen={confirmationProperties.isOpen} handleBack={handleConfirmationGoBack} handleCancel={handleConfirmationCancel} />
        </Container >
    )
}

export default ConfigManagerInfo;