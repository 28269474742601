import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Info from "@mui/icons-material/Info";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, List, ListItem, ListItemButton, ListItemText, Popover, Tooltip } from '@mui/material';
import { useState } from "react";
import CardFilter from "../../../../components/filters/cardFilter";
import RcsFilter from "../../../../components/filters/rcsFilter";

function RcsFilterApplied(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState();
    const [filterItems, setFilterItems] = useState([]);
    const [filterApplied, setFilterApplied] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setFilterItems(JSON.parse(window.localStorage.getItem("cardFilter")));
        setAnchorEl(event.currentTarget);
    }

    const handleFilterClick = (index) => {
        setSelectedIndex(index);
        props.setRequest(filterItems[index].request)
        setFilterApplied(true)
        handleClose();
    }

    const deleteItem = (index) => {
        var tempList = JSON.parse(JSON.stringify(filterItems));
        tempList.splice(index, 1);
        window.localStorage.setItem("cardFilter", JSON.stringify(tempList));
        setFilterItems(tempList);
        if (index === selectedIndex) {
            props.setRequest({
                ...props.request,
                fromDate: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                page: 0,
                size: 25,
                sortBy: "",
                sortOrder: "",
                merchantRef: "",
                transactionStatuses: [],
                route: "",
                scheme: "",
                pan: "",
                transactionId: ""
            });
        }
    }

    return (
        <Grid container sx={{ direction: 'row', height: '100%', alignItems: 'center' }}>
            {filterApplied &&
                <Grid container alignItems="center" sx={{ mr: 2, width: "fit-content" }}>
                    <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: '#404047', mr: 2 }}>
                        Filter Applied
                    </Typography>
                    <Tooltip arrow componentsProps={{
                        tooltip: {
                            sx: {
                                "& .MuiTooltip-arrow": {
                                    color: "#FFFFFF"
                                },
                                "&.MuiTooltip-tooltip": {
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                    boxShadow: "0px 3px 6px #00000029"
                                }
                            }
                        }
                    }} title={
                        <Box>
                            <Typography fontSize={10} fontWeight="bold" sx={{ mb: 1 }}>Current Applied Filters</Typography>
                            <Typography fontSize={9}>Profile Name: {props.displayName.length >= 3 && `Profiles selected.....${props.displayName.length} Profiles`}
                                                                    {props.displayName.length <= 2 && `${props.displayName + ""}`}</Typography>
                            <Typography fontSize={9}>Start Date: {moment(props.request.fromDate).format("YYYY-MM-DD")}</Typography>
                            <Typography fontSize={9}>End Date: {moment(props.request.toDate).format("YYYY-MM-DD")}</Typography>
                            {props.request.merchantRef ? <Typography fontSize={9}>Merchant Ref: {props.request.merchantRef}</Typography> : null}
                            {props.request.transactionStatuses.length > 0 ? <Typography fontSize={9}>Transaction Statuses: {props.request.transactionStatuses + " "}</Typography> : null}
                            {props.request.scheme ? <Typography fontSize={9}> Scheme: {props.request.scheme}</Typography> : null}
                            {props.request.route ? <Typography fontSize={9}> Acquiring Bank : {props.request.route}</Typography> : null}
                            {props.request.pan ? <Typography fontSize={9}> Card number: {props.request.pan}</Typography> : null}
                        </Box>
                    }>
                        <Info sx={{ "&:hover": { cursor: "pointer" }, color: "#034EA2" }} fontSize="16px" />
                    </Tooltip>
                </Grid>
            }
            <Grid item sx={{ mr: 2 }}>
                <Button onClick={handleClick} type="button" variant="contained" sx={{ "&:hover": { background: "#EBFAFF" }, textTransform: "none", background: "#FFFFFF", minWidth: "45px", width: "45px", height: "40px" }}>
                    {open ? <ExpandLess sx={{ color: "#034EA2" }} /> : <ExpandMore sx={{ color: "#034EA2" }} />}
                </Button>
            </Grid>
            <Grid item>
                <RcsFilter request={props.request} setRequest={props.setRequest} setFilterApplied={setFilterApplied} />
            </Grid>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ zIndex: 999, "& .MuiPopover-paper": { borderRadius: "10px" } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List sx={{ width: '300px', maxWidth: 360, bgcolor: 'background.paper', pt: 0, pb: 0 }}>
                    {filterItems?.length > 0 ? filterItems.map((item, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(index)}>
                                    <Delete sx={{ color: "#034EA2" }} />
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                selected={selectedIndex === index}
                                onClick={(event) => handleFilterClick(index)}
                            >
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    )) : <Typography sx={{ pt: 2, pb: 2 }} textAlign="center">No filter saved</Typography>}
                </List>
            </Popover>
        </Grid>
    )
}

RcsFilterApplied.propTypes = {
    request: PropTypes.object.isRequired,
    setRequest: PropTypes.func.isRequired,
    displayName: PropTypes.string
};

export default RcsFilterApplied;