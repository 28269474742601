import { Backdrop, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes, { symbol } from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import "../../../styles.scss";
import DetailedView from "../detailed-view/detailedView";
import PaymentStepStatus from "../payment-step-status/paymentStepStatus";
import Actions from "../actions/actions";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { getCardNotPresentTransaction } from "../../../services/cnpTransactionService";
import { getTdsTransaction } from "../../../services/tdsService";
import AuthorizedElement from "../../../keycloak/permissionsGate";
import ComponentLoader from "../../component-loader/componentLoader";
import TransactionLogs from "../transaction-logs/transactionLogs";
import { getSessionUrls } from "../../../services/sessionService";
import { getWebhookDetails } from "../../../services/profileServices";
import HeaderInfo from "../headerInfo";
import { getTransactions } from "../../../services/transactionService";
import { PaymentType } from "../../../constants/paymentType";
import { getTransactionStatus } from "../../../utils/statusFormatter";

function CardTransactionMoreInfo(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [openTicket, setOpenTicket] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState();
    const [status, setStatus] = useState("");
    const [transactionDetails, setTransactionDetails] = useState();
    const [threeDSecureDetails, setThreeDSecureDetails] = useState();
    const [redirectUrls, setRedirectUrls] = useState();
    const [webhookDetails, setWebhookDetails] = useState();
    const [refundedAmount, setRefundedAmount] = useState();
    const [isActionPlaced, setIsActionPlaced] = useState(false);


    const updateTransactionDetails = (() => {
        getTransactions(props.transactionId).then(response => {
            setIsLoading(false);
            setTransactionDetails({
             responseCode:  response.data?.cardTransactionDetails?.[0]?.iso8583ResponseCode,
             responseText:  response.data?.cardTransactionDetails?.[0]?.responseText,
             amount: response.data?.amount,
             currencyCode: response.data?.currency?.[0]?.isoCode,
             merchantRef: props.merchantRef,
             merchantId: props.merchantId,
             profileId: response.data?.profileId,
             transactionDateTime: response.data?.cardTransactionDetails?.[0]?.createdAt,
             transactionStatus: response.data?.transactionStatus,
             transactionId: response.data?.transactionId,
             token: response.data?.cardDetails?.token,
             scheme: response.data?.cardDetails?.cardScheme,
             cardDetails:{
                nameOnCard: response.data?.cardDetails?.nameOnCard,
                pan: response.data?.cardDetails?.maskedPan,
                expiryDate: response.data?.cardDetails?.expiryDate
             }
            })
        }).catch(error => {
            setIsLoading(false);
        })
    })

    useEffect(() => {
        if (props.transactionId && props.transactionId !== "") {
            setIsLoading(true);
            getCardNotPresentTransaction(props.transactionId).then(response => {
                setRefundedAmount(response.data.refundedAmount);
                setTransactionDetails(response.data)
                setIsLoading(false);
            }).catch(error => {
                updateTransactionDetails();
                console.log(error)
                setIsLoading(false);
            })
        }
    }, [props.transactionId])

    useEffect(()=>{
        if(isActionPlaced){
            setIsLoading(true);
            getCardNotPresentTransaction(props.transactionId).then(response => {
                setRefundedAmount(response.data.refundedAmount);
                setTransactionStatus(getTransactionStatus(response.data?.transactionStatus))
                setTransactionDetails(response.data)
                setIsLoading(false);                               
            }).catch(error => {
                updateTransactionDetails();
                console.log(error)
            })
            setIsActionPlaced(false);
        }
    },[isActionPlaced])
    const tableUpdate = (()=>
    {
        setIsActionPlaced(true)
    })

    const getThreeDSecureDetails = useCallback(() => {
        if (props.transactionId) {
            getTdsTransaction(props.transactionId).then(response => {
                setThreeDSecureDetails(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [props.transactionId])

    const getRedirectUrls = useCallback(() => {
        if (props.transactionId) {
            getSessionUrls(props.transactionId).then(response => {
                setRedirectUrls(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [props.transactionId])

    const getWebhooksDetails = useCallback(() => {
        if (props.transactionId) {
            getWebhookDetails(props.transactionId).then(response => {
                setWebhookDetails(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [props.transactionId])

    useEffect(() => {
        setThreeDSecureDetails();
        getRedirectUrls();
        getWebhooksDetails();
        if (props.isOpen) {
            switch (TransactionStatus[transactionDetails?.transactionStatus]) {
                case TransactionStatus.PAYMENT_CANCELLED:
                    setStatus("Cancelled")
                    break;
                case TransactionStatus.PAYMENT_DECLINED:
                case TransactionStatus.PAYMENT_FAILED:
                    setStatus("Failed");
                    break;
                case TransactionStatus.PAYMENT_AUTHORISED:
                case TransactionStatus.PAYMENT_SETTLED:
                case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
                case TransactionStatus.PAYMENT_REFUNDED:
                case TransactionStatus.PAYMENT_REVERSED:
                    setStatus("Successful");
                    break;
                case TransactionStatus.PAYMENT_INITIATED:
                    setStatus("Initiated");
                    break;
                case TransactionStatus._3DS_INITIATED:
                    setStatus("3DS Initiated");
                    break;
                case TransactionStatus._3DS_ABANDONED:
                    setStatus("3DS Abandoned");
                    break;
                case TransactionStatus._3DS_AUTHORISED:
                    setStatus("3DS Authorised");
                    break;
                case TransactionStatus._3DS_FAILED:
                    setStatus("3DS Failed");
                    break;
                default:
                    break;
            }
            getThreeDSecureDetails()
        }
    }, [transactionDetails])

    const handleOpen = () => {
        setOpenTicket(true);
    }
    const handleClose = () => {
        props.setMoreInfoRequest({
            isOpen: false,
            transactionId: "",
            symbol: "",
            amount: 0,
            transactionStatus: "",
            displayName: "",
            createdAt: "",
            lastModified: "",
            cardTransactionDetails: null,
            merchantRef: "",
            currencyCode: "ZAR",
            selectedprofile: "",
            profileId: []
        });
        setTransactionDetails(null);
        setStatus(null)
    }

    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="80%"
                    minWidth="800px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{overflow:"hidden",height:"100vh",   boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <HeaderInfo isOpen={props.isOpen} amount={transactionDetails?.amount} createdAt={props.createdAt} displayName={props.displayName} handleClose={handleClose} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} symbol={props.symbol} transactionId={props.transactionId} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} status={status} />
                    <Grid container direction="column" sx={{ p: 3 ,maxHeight: "calc(100vh - 150px)",flexWrap: "nowrap", overflow: "auto", overflowY: "auto", overflowX:"hidden"}} width="100%">
                        <PaymentStepStatus transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} displayName={props.displayName} transactionDetails={transactionDetails} threeDSecureDetails={threeDSecureDetails} createdAt={props.createdAt} lastModified={props.lastModified} cardTransactionDetails={props.cardTransactionDetails} />
                        {status === "Successful" && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_REVERSED ?
                            <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                <Actions transactionId={props.transactionId} merchantId={transactionDetails?.merchantId} profileId={transactionDetails?.profileId} currencyCode={props.currencyCode} symbol={props.symbol} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} refundedAmount={transactionDetails?.refundedAmount} amount={transactionDetails?.amount} handleTableUpdate={tableUpdate} />
                            </AuthorizedElement> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus._3DS_INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus._3DS_ABANDONED?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <DetailedView amount={transactionDetails?.amount} symbol={props.symbol} transactionDetails={transactionDetails} merchantRef={props.merchantRef} lastModified={props.lastModified} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} cardTransactionDetails={props.cardTransactionDetails} threeDSecureDetails={threeDSecureDetails} redirectUrls={redirectUrls} paymentType={PaymentType.CARD.toUpperCase()} status={status} />
                            </Box> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus._3DS_INITIATED&& TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus._3DS_ABANDONED ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <TransactionLogs transactionDetails={transactionDetails} threeDSecureDetails={threeDSecureDetails} webhookDetails={webhookDetails} symbol={props.symbol} currencyCode={props.currencyCode}/>
                            </Box> : null
                        }
                    </Grid>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={isLoading} />
        </Box>
    );
}

CardTransactionMoreInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    cardTransactionDetails: PropTypes.array,
    transactionCardDetails: PropTypes.array,
    merchantRef: PropTypes.string.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired
};

export default CardTransactionMoreInfo;