import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, FormHelperText } from '@mui/material';
import { getCurrencies } from '../../services/currencyService';
import { CheckOutlined } from '@mui/icons-material';

const CurrencySelect = ({ defaultCurrency = "0", selectedCurrencies, setSelectedCurrencies }) => {
    const [currencies, setCurrencies] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const response = await getCurrencies();
                const currencyData = [{ id: 0, isoCode: 'ALL', symbol: 'All', label: 'All Currencies' }, ...response.data];
                setCurrencies(currencyData);
                const defaultCurrencyData = currencyData.find(currency => currency.id.toString() === defaultCurrency);
                if (defaultCurrencyData) {
                    setSelectedCurrencies([defaultCurrencyData]);
                }
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        };

        fetchCurrencies();
    }, [defaultCurrency, setSelectedCurrencies]);


    const handleChange = (event) => {
        const value = event.target.value;
        const isAllSelected = event.target.value?.[0] === ('ALL');
        const otherCurrenciesSelected = value.filter(currency => currency !== 'ALL');
        const isAllInside = value.includes('ALL');
        if (!isAllSelected && otherCurrenciesSelected && isAllInside) {
            console.log("not all selected");
            setSelectedCurrencies([currencies.find(currency => currency.isoCode === 'ALL')]);
        } else if(otherCurrenciesSelected.length > 0 && otherCurrenciesSelected.length <= 3 ) {
            console.log("Checking Greate 3 Hit");
            setSelectedCurrencies(currencies.filter(currency => otherCurrenciesSelected.includes(currency.isoCode)));
        }
        //  For mapping more than 3 currencies
        // } else if(otherCurrenciesSelected.length < 3 ) {
        //     console.log("Greater than 3 If");
        //     let tempList = []
        //     tempList.push(otherCurrenciesSelected[3])
        //     tempList.push(otherCurrenciesSelected[0])
        //     tempList.push(otherCurrenciesSelected[1])
        //     setSelectedCurrencies(tempList);
        // }

    };

    return (
        <div style={{ margin: '0px 8px 0px 8px',padding: '0px 8px 0px 8px' }}>
            <FormControl fullWidth
                         sx={{
                             backgroundColor: '#FFF',
                             maxWidth: '350px',
                             minWidth: '200px'
            }}
                         error={!!error}>
                <InputLabel id="currency-select-label" >Select currencies</InputLabel>
                <Select
                    labelId="currency-select-label"
                    id="currency-select"
                    label ="Select currencies"
                    multiple
                    size="small"
                    value={selectedCurrencies.map(currency => currency.isoCode)}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    sx={{ backgroundColor: '#FFF' }}
                >
                    {currencies.map((currency) => (
                        <MenuItem key={currency.id} value={currency.isoCode}>
                            <ListItemText primary={`${currency.symbol} - ${currency.isoCode}`} />
                            <Checkbox checked={selectedCurrencies.some(c => c.isoCode === currency.isoCode)} icon={<div></div>} checkedIcon={<CheckOutlined />} />
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default CurrencySelect;