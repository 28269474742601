
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useState } from "react";
import { ExpandMoreButton } from "../../expand-more/expandMore";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import moment from "moment";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function OzowTransactionLogs(props) {

    const [expanded, setExpanded] = useState(false);

    return (
        <Box width="100%">
            <Grid container>
                <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Logs</Typography>
                <ExpandMoreButton expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    <ExpandMore />
                </ExpandMoreButton>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.transactionDetails &&
                    <Box sx={{ mt:  2 }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Details</Typography>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.transactionDetails?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.amount))}` : 'NaN'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Currency Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.currencyCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Ozow Transaction Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.ozowTransactionStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant Ref:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantReference}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Profile ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.profileId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Provider Junction Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>Ozow</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>From Bank Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.bankName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Masked Account Number:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.maskedAccountNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>To Bank Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.toBankName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>To Account Number:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.toAccountNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Ozow Response:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.response}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Date Time:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.transactionDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {props.transactionDetails?.refundLegs && props.transactionDetails?.refundLegs?.length > 0 ?
                            <Grid container columnSpacing={2} rowSpacing={0.5}>
                                {props.transactionDetails?.refundLegs && props.transactionDetails?.refundLegs?.length > 0 &&
                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={1.97}>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg:</Typography>
                                            </Grid>
                                            <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                                {props.transactionDetails?.refundLegs?.map((refundLeg, index) => (
                                                    <Grid container wrap="nowrap" key={index}>
                                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>{index + 1}:</Typography>
                                                        </Grid>
                                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refunded Amount:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                        {refundLeg.refundAmount ? `${props.symbol}${numberWithCommas(getFormattedAmount(refundLeg.refundAmount))}` : 'NaN'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg.createdDate).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Last Modified Date Time:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            </Grid>
                                                            <Grid container wrap="nowrap">
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Calculate Hash:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.hashCheck}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Status:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.transactionStatus}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "6px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>

                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    

                                                                </Grid>
                                                            </Grid>
                                                            
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            :""}
                    </Box>
                }
            </Collapse >
        </Box >
    );
}

OzowTransactionLogs.propTypes = {
    transactionDetails: PropTypes.object,
    webhookDetails: PropTypes.object,
    symbol: PropTypes.string,
};

export default OzowTransactionLogs;