import { createTheme } from "@mui/material";

export const Theme = () => createTheme({
  palette: {
    primary: {
      main: '#00C1FF',
      contrastText: '#404047'
    },
    secondary: {
      main: '#BFEFFF',
      contrastText: '#00C1FF'
    }
  },
  typography: {
    fontFamily: 'Montserrat'
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // background: "#00C1FF",
          scrollbarColor: "#00C1FF",
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            height: "8px",
            width: "8px"
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 6,
            backgroundColor: "#00C1FF"
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: "#00C1FF",
            borderRadius: 6
          }
        }
      }
    }
  }
});