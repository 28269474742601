import Typography from "@mui/material/Typography";
import { TextField, IconButton, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { InputAdornment,Tooltip } from '@mui/material';
import { CustomTextField } from "../../components/custom-text-field/customTextField";
import '../../styles.scss';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { fetchMerchantProfiles, updateMerchantProfiles } from "../../stores/merchantProfiles";
import { Search, Info } from "@mui/icons-material";
import moment from "moment";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { CustomAutoComplete } from "../../components/custom-autocomplete/customAutoComplete";
import { Regex } from "../../constants/regex";

const ConfigManager = () => {

    const [selectedMerchants] = useOutletContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { merchantProfiles } = useSelector(state => state.merchantProfiles);

    const [displayName, setDisplayName] = useState("")
    const [profileIds, setProfileIds] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(10);
    const [request, setRequest] = useState({
        profileIds: [],
        merchantIds: [],
        size: 25,
        page: 0,
        displayName: "",
        profileId: ""
    })

    const [values, setValues] = useState({
        selectedSearchBy: 0,
        textSearch: "",
    })

    const [filter, setFilter] = useState("");


    const [isLoading, setIsLoading] = useState(false);


    const handleChangePage = (newPage) => {
        setRequest({...request, page: newPage})
    };

    const handleChangeRowsPerPage = (newLimit) => {
        setRequest({...request, size: newLimit})
    };

    useEffect(() => {
        if (selectedMerchants && selectedMerchants?.length > 0) {
            setRequest({...request, merchantIds: selectedMerchants})
        }
    }, [selectedMerchants])

    useEffect(() =>{
        if (request?.merchantIds && request?.merchantIds?.length > 0) {
            setIsLoading(true)
            dispatch(fetchMerchantProfiles(request));
        }
    }, [request])

    useEffect(() => {
        setIsLoading(false);
        if (merchantProfiles && merchantProfiles?.content?.length > 0) {
            setTotalElements(merchantProfiles?.totalElements)
        }
    }, [merchantProfiles])

    const goToInfo = (profileId) => {
        window.sessionStorage.setItem("configId", profileId);
        navigate("/config-manager/info/general", { replace: false, state: { profileId: profileId } });
    }

    const performTextSearch = (newValue) => {
        setValues({
            textSearch: newValue,
            selectedSearchBy: (newValue.match(new RegExp(Regex.UUID_PATTERN)))? 0 : 1
        })
    }

    useEffect(() => {
        if (values.textSearch) {
            const timeOut = setTimeout(() => {
                switch (values.selectedSearchBy) {
                    case 0:
                        setRequest({...request, profileId: values.textSearch, displayName: ""})
                        break;
                    case 1:
                        setRequest({...request, displayName: values.textSearch,  profileId: ""})
                        break;
                    default:
                        break;
                }
            }, 2000);
            return () => clearTimeout(timeOut);
        }else {
            setRequest({...request, displayName: "", profileId: ""})
        }
    }, [values])

    return (
        <Box sx={{ mt: 2, mb: 2, ml: 3, mr: 3 }}>
            <Grid container direction="column">
                <Tooltip title="Search by Profile ID or by Profile Name"  sx={{width: 400}}>
                    <CustomTextField
                        size="small"
                        className="transaction-select"
                        label={values.textSearch?values.selectedSearchBy === 0? "Searching by Profile ID":"Searching by Profile Name":"Search"}
                        disabled={values.selectedSearchBy === ""}
                        value={values.textSearch}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={event => performTextSearch(event.target.value)}
                    />
                </Tooltip>
            </Grid>
            <DataGrid
                sx={{
                    height: "calc(100vh - 280px)",
                    "&.MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        boxShadow: "0px 0px 10px #00000029",
                        display: "block"
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        overflowY: "overlay"
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                        background: "#EBFAFF"
                    },
                    "& .MuiDataGrid-cell": {
                        border: "0.5px solid rgba(224, 224, 224, 1)"
                    },
                    "& .MuiDataGrid-footerContainer": {
                        pt: 4
                    },
                    mt: 2
                }}
                getRowId={(row) => row.profileId}
                rows={merchantProfiles?.content ? merchantProfiles?.content : []}
                columns={[
                    { align: "center", headerAlign: "center", field: 'createdAt', headerName: "Date Created", flex: 1, valueFormatter: params => moment(params?.value).format("YYYY/MM/DD HH:mm:ss") },
                    { align: "center", headerAlign: "center", field: 'profileId', headerName: "Profile ID", flex: 1 },
                    { align: "center", headerAlign: "center", field: 'displayName', headerName: "Trading As/Profile", flex: 1 },
                    {
                        headerAlign: "center", field: 'active', headerName: "Status", flex: 1, cellClassName: 'amount-cell',
                        renderCell: (params) => {
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px" }}>
                                        {params?.value ? "Active" : "Inactive"}
                                    </Typography>
                                    <IconButton sx={{ color: "#034EA2" }} onClick={() => goToInfo(params.row.profileId)}>
                                        <Info />
                                    </IconButton>
                                </Box>
                            )
                        }
                    }
                ]}
                rowsPerPageOptions={[5, 10, 25]}
                pageSize={request.size}
                rowCount={totalElements ? totalElements : 0}
                page={request.page}
                pagination
                paginationMode="server"
                onPageSizeChange={(newLimit) => handleChangeRowsPerPage(newLimit)}
                onPageChange={(newPage) => handleChangePage(newPage)}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                loading={isLoading}
            />
        </Box>
    )
}

export default ConfigManager;