
import Box from "@mui/system/Box";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import { ExpandMoreButton } from "../../expand-more/expandMore";
import { ExpandMore, Info } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import moment from "moment";
import CardTransactionMoreInfo from "../more-info/cardTransactionMoreInfo";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { Currency } from "../../../constants/currency";
import { PaymentType } from "../../../constants/paymentType";
import RcsTransactionMoreInfo from "../more-info/rcsTransactionMoreInfo";
import TransactionMoreInfoOzow from "../more-info/transactionMoreInfoOzow";

function PaymentLinkTransactionLog(props) {

    const [expanded, setExpanded] = useState(false);


    const [moreInfoRequest, setMoreInfoRequest] = useState({
        isOpen: false,
        transactionId: "",
        paymentType: "",
        symbol: "",
        amount: 0,
        transactionStatus: "",
        createdAt: "",
        lastModified: "",
        cardTransactionDetails: null,
        pan: "",
        nameOnCard: "",
        currencyCode: "",
        selectedprofile: ""
    });

    const openTransactionInfo = (transaction) => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            isOpen: true,
            paymentType: transaction?.paymentType,
            transactionId: transaction?.transactionId,
            transactionStatus: transaction?.transactionStatus,
            currencyCode: transaction?.currency,
            symbol: Currency[transaction?.currency],
            amount: props?.amount,
            createdAt: transaction?.createdAt
        })
    }

    return (
        <Box width="100%">
            <Grid container>
                <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transactions</Typography>
                <ExpandMoreButton expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    <ExpandMore />
                </ExpandMoreButton>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.transactions?.length > 0 ?
                    props.transactions?.map((transaction, index) => (
                        <Grid container wrap="nowrap" key={index} sx={{ mb: index < props?.transactions?.length - 1 ? 4 : 0 }}>
                            <Grid item xs={11} sx={{ alignItems: "center" }} zeroMinWidth>
                                <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Date:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(transaction.createdAt).format("YYYY/MM/DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transaction.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Payment Type:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transaction.paymentType}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Currency:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.currency}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} display="flex" justifyContent="end" alignItems="center">
                                <Box display="flex">
                                    <IconButton sx={{ color: "#034EA2" }} onClick={() => openTransactionInfo(transaction)}>
                                        <Info />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    )) :
                    <Grid container wrap="nowrap" >
                        <Grid item xs={1} sx={{ alignItems: "center" }} zeroMinWidth>
                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>N/A</Typography>
                        </Grid>
                    </Grid>
                }
            </Collapse >
            {moreInfoRequest?.paymentType === PaymentType.CARD.toUpperCase() &&
                <CardTransactionMoreInfo
                    isOpen={moreInfoRequest.isOpen}
                    transactionId={moreInfoRequest.transactionId}
                    setMoreInfoRequest={setMoreInfoRequest}
                    displayName={props.displayName}
                    transactionStatus={
                        moreInfoRequest.transactionStatus === "3DS_INITIATED" ? TransactionStatus["_3DS_INITIATED"] :
                            moreInfoRequest.transactionStatus === "3DS_AUTHORISED" ? TransactionStatus["_3DS_AUTHORISED"] :
                                moreInfoRequest.transactionStatus === "3DS_FAILED" ? TransactionStatus["_3DS_FAILED"] :
                                    TransactionStatus[moreInfoRequest.transactionStatus] ? TransactionStatus[moreInfoRequest.transactionStatus] : ""}
                    symbol={moreInfoRequest.symbol}
                    amount={moreInfoRequest.amount}
                    merchantId={props.merchantId}
                    profileId={props.profileId}
                    merchantRef={props.merchantRef}
                    currencyCode={moreInfoRequest.currencyCode}
                    handleBack={() => {}} 
                    handleTableUpdate={() => {}} />
            }
            {moreInfoRequest?.paymentType === PaymentType.RCS.toUpperCase() &&
                <RcsTransactionMoreInfo
                    isOpen={moreInfoRequest.isOpen}
                    transactionId={moreInfoRequest.transactionId}
                    setMoreInfoRequest={setMoreInfoRequest}
                    displayName={props.displayName}
                    transactionStatus={
                        TransactionStatus[moreInfoRequest.transactionStatus] ? TransactionStatus[moreInfoRequest.transactionStatus] : ""}
                    symbol={moreInfoRequest.symbol}
                    amount={moreInfoRequest.amount}
                    merchantId={props.merchantId}
                    profileId={props.profileId}
                    merchantRef={props.merchantRef}
                    currencyCode={moreInfoRequest.currencyCode}
                    handleBack={() => {}}
                    handleTableUpdate={() => {}}  />
            }
            {moreInfoRequest?.paymentType === PaymentType.OZOW.toUpperCase() &&
                <TransactionMoreInfoOzow
                    isOpen={moreInfoRequest.isOpen}
                    transactionId={moreInfoRequest.transactionId}
                    setMoreInfoRequest={setMoreInfoRequest}
                    displayName={props.displayName}
                    transactionStatus={
                        TransactionStatus[moreInfoRequest.transactionStatus] ? TransactionStatus[moreInfoRequest.transactionStatus] : ""}
                    symbol={moreInfoRequest.symbol}
                    amount={moreInfoRequest.amount}
                    merchantId={props.merchantId}
                    profileId={props.profileId}
                    merchantRef={props.merchantRef}
                    currencyCode={moreInfoRequest.currencyCode}
                    handleBack={() => {}}
                    handleTableUpdate={() => {}}  />
            }
        </Box >
    );
}

PaymentLinkTransactionLog.propTypes = {
    transactions: PropTypes.array,
    threeDSecureDetails: PropTypes.object,
    transactionDetails: PropTypes.object,
    displayName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    merchantId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantRef: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired
};

export default PaymentLinkTransactionLog;