import { Backdrop, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import "../../../styles.scss";
import ComponentLoader from "../../component-loader/componentLoader";
import HeaderInfo from "../headerInfo";
import PaymentLinkDetailedView from "../detailed-view/paymentLinkDetailedView";
import PaymentLinkTransactionLog from "../transaction-logs/paymentLinkTransactionLogs";
import { PaymentLinkStatus } from "../../../constants/paymentLinkStatus";

function PaymentLinkMoreInfo(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [openTicket, setOpenTicket] = useState(false);

    const [status, setStatus] = useState("");
    const [transactionDetails, setTransactionDetails] = useState();


    const handleOpen = () => {
        setOpenTicket(true);
    }
    const handleClose = () => {
        props.setMoreInfoRequest({
            isOpen: false,
            paymentLinkId: "",
            symbol: "",
            amount: 0,
            paymentLinkStatus: "",
            displayName: "",
            createdAt: "",
            lastModified: "",
            transactionMoreDetails: null,
            merchantRef: "",
            currencyCode: "",
            selectedprofile: "",
            profileId: []
        });
        setTransactionDetails(null);
    }

   

    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="80%"
                    minWidth="800px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{ overflow: "hidden", boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <HeaderInfo isOpen={props.isOpen} amount={props.amount} createdAt={props.createdAt} displayName={props.displayName} handleClose={handleClose} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} symbol={props.symbol} paymentLinkId={props.paymentLinkId} transactionStatus={props.paymentLinkStatus} status={PaymentLinkStatus[props.paymentLinkStatus]} />
                    <Grid container direction="column" sx={{ p: 3, maxHeight: "calc(100vh - 150px)", flexWrap: "nowrap", overflow: "auto", overflowY: "auto", overflowX: "hidden" }} width="100%">
                        <Box>
                            <PaymentLinkDetailedView amount={props.amount} symbol={props.symbol} transactionDetails={transactionDetails} merchantRef={props.merchantRef} lastModified={props.lastModified} paymentLinkStatus={props.paymentLinkStatus} createdAt={props.createdAt} expiredAt={props.expiredAt} transactionMoreDetails={props.transactionMoreDetails} profileId={props.profileId} transactions={props.transactions} redirectUrl={props.redirectUrl} />
                        </Box>
                        <Box>
                            <Divider sx={{ mt: 4, mb: 3 }} />

                            <PaymentLinkTransactionLog transactions={props.transactions} displayName={props.displayName} amount={props.amount} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} currency={props.currencyCode} />
                        </Box>
                    </Grid>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={isLoading} />
        </Box>
    );
}

PaymentLinkMoreInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    paymentLinkId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    paymentLinkStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    expiredAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    merchantRef: PropTypes.string.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired,
    transactions: PropTypes.array.isRequired,
    redirectUrl: PropTypes.string.isRequired

};

export default PaymentLinkMoreInfo;