import { Backdrop, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import "../../../styles.scss";
import DetailedViewOzow from "../detailed-view/detailsOzow";
import { TransactionStatus } from "../../../constants/transactionStatus";
import AuthorizedElement from "../../../keycloak/permissionsGate";
import ComponentLoader from "../../component-loader/componentLoader";
import { getSessionUrls } from "../../../services/sessionService";
import { getOZOWTransaction } from "../../../services/ozowTransactionService";
import OzowPaymentStepStatus from "../payment-step-status/paymentStepStatusOzow";
import OzowTransactionLogs from '../transaction-logs/ozowTransactionLogs';
import { OzowTransactionStatus } from '../../../constants/ozowTransactionStatus';
import OzowActions from '../actions/ozowActions';
import HeaderInfo from "../headerInfo";

function TransactionMoreInfoOzow(props) {


    const [isLoading, setIsLoading] = useState(false);
    const [openTicket, setOpenTicket] = useState(false);
    const [redirectUrls, setRedirectUrls] = useState();
    const [status, setStatus] = useState("");
    const [transactionDetails, setTransactionDetails] = useState();

    useEffect(() => {
        if (props.transactionId && props.transactionId !== "") {
            setIsLoading(true);
            getOZOWTransaction(props.transactionId).then(response => {
                setTransactionDetails(response.data)
                setIsLoading(false);
            }).catch(error => {
                console.log(error)
                setIsLoading(false);
            })
        }
    }, [props.transactionId])

    const getRedirectUrls = useCallback(() => {
        if (props.transactionId) {
            getSessionUrls(props.transactionId).then(response => {
                setRedirectUrls(response.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [props.transactionId])

    useEffect(() => {
        getRedirectUrls();
        if (props.isOpen) {
            switch (TransactionStatus[transactionDetails?.transactionStatus]) {
                case TransactionStatus.PAYMENT_CANCELLED:
                    setStatus("Cancelled")
                    break;
                case TransactionStatus.PAYMENT_DECLINED:
                case TransactionStatus.PAYMENT_FAILED:
                    setStatus("Failed");
                    break;
                case TransactionStatus.PAYMENT_AUTHORISED:
                case TransactionStatus.PAYMENT_SETTLED:
                case TransactionStatus.PAYMENT_COMPLETED:
                case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
                case TransactionStatus.PAYMENT_REFUNDED:
                case TransactionStatus.PAYMENT_REVERSED:
                    setStatus("Successful");
                    break;
                case TransactionStatus.PAYMENT_INITIATED:
                    setStatus("Initiated");
                    break;
                case TransactionStatus._3DS_INITIATED:
                    setStatus("3DS Initiated");
                    break;
                case TransactionStatus._3DS_AUTHORISED:
                    setStatus("3DS Authorised");
                    break;
                case TransactionStatus._3DS_FAILED:
                    setStatus("3DS Failed");
                    break;
                default:
                    break;
            }

        }
    }, [transactionDetails])

    const handleOpen = () => {
        setOpenTicket(true);
    }
    const handleClose = () => {
        props.setMoreInfoRequest({
            isOpen: false,
            transactionId: "",
            symbol: "",
            amount: 0,
            transactionStatus: "",
            displayName: "",
            createdAt: "",
            lastModified: "",
            transactionMoreDetails: null,
            merchantRef: "",
            currencyCode: "ZAR",
            maskedAccountNumber: "",
            selectedprofile: "",
            provider: "",
            response: "",
            errorUrl: "",
            successUrl: "",
            profileId: [],
            bankName: "",
            toBankName: "",
            refundAmount: "",
            refundedAmount: ""

        });
        setTransactionDetails(null);
        props.handleBack();
    }
    useEffect(() => {
        console.log(props.refundAmount)
    })


    useEffect(() => {
        console.log(props.profileId)
    })


    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="80%"
                    minWidth="800px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{ flexWrap: "nowrap", overflow: "hidden", overflowY: "auto", boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}>
                    <HeaderInfo isOpen={props.isOpen} amount={props.amount} createdAt={props.createdAt} displayName={props.displayName} handleClose={handleClose} merchantId={props.merchantId} merchantRef={props.merchantRef} profileId={props.profileId} symbol={props.symbol} transactionId={props.transactionId} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} status={status} />
                    <Grid container direction="column" sx={{ p: 3 }} width="100%">
                        <OzowPaymentStepStatus transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} displayName={props.displayName} transactionDetails={transactionDetails} createdAt={props.createdAt} lastModified={props.lastModified} transactionMoreDetails={props.transactionMoreDetails} />
                        {status === "Successful" && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus.PAYMENT_REVERSED ?
                            <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                <OzowActions transactionId={props.transactionId} merchantId={props.merchantId} profileId={props.profileId} currencyCode={props.currencyCode} symbol={props.symbol} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} refundedAmount={transactionDetails?.refundedAmount} amount={props.amount} handleTableUpdate={props.handleTableUpdate} refundAmount={props.refundAmount} />
                            </AuthorizedElement> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== OzowTransactionStatus.INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== TransactionStatus._3DS_INITIATED ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <DetailedViewOzow amount={props.amount} maskedAccountNumber={props.maskedAccountNumber} symbol={props.symbol} transactionDetails={transactionDetails} merchantRef={props.merchantRef} lastModified={props.lastModified} transactionStatus={TransactionStatus[transactionDetails?.transactionStatus]} transactionMoreDetails={props.transactionMoreDetails} response={props.response} provider={props.provider} redirectUrls={redirectUrls} errorUrl={props.errorUrl} successUrl={props.successUrl} bankName={props.bankName} toBankName={props.toBankName} refundAmount={props.refundAmount} />
                            </Box> : null
                        }
                        {TransactionStatus[transactionDetails?.transactionStatus] !== OzowTransactionStatus.INITIATED && TransactionStatus[transactionDetails?.transactionStatus] !== OzowTransactionStatus.DECLINED && transactionDetails ?
                            <Box>
                                <Divider sx={{ mt: 4, mb: 3 }} />
                                <OzowTransactionLogs transactionDetails={transactionDetails}  symbol={props.symbol} />
                            </Box> : null
                        }
                    </Grid>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={isLoading} />
        </Box>
    )


}

TransactionMoreInfoOzow.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    merchantRef: PropTypes.string.isRequired,
    handleTableUpdate: PropTypes.func.isRequired,
    setMoreInfoRequest: PropTypes.func.isRequired,
    response: PropTypes.string.isRequired,
};

export default TransactionMoreInfoOzow;