import axios from 'axios';
import GoogleAnalytics from '../utils/googleAnalyticsHelper';


export const getZapperTransactions = (request) => {
    return axios.post(`${process.env.REACT_APP_ZAPPER_TRANSACTIONS_PATH}`, request);
}

export const getZapperCsvTransactions = (request) => {
    return axios({
        url: `${process.env.REACT_APP_ZAPPER_TRANSACTIONS_CSV_PATH}`,
        method: 'POST',
        responseType: 'blob',
        data: request
    })
}

export const getZapperTransaction = (transactionId) => {
    return axios.get(`${process.env.REACT_APP_ZAPPER_TRANSACTION_PATH}/${transactionId}`)
}

export const refundZapperTransaction = (request) => {
    return axios.post(`${process.env.REACT_APP_ZAPPER_REFUND_PATH}`,request)
}