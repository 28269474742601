import * as React from 'react';
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";
import { useState } from "react";

function NotificationButton(props) {

    const [anchor, setAnchor] = useState('');
    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

     return(
         <Box {...props}>
             <IconButton className="app-bar-button" onClick={handleClick}>
                 <NotificationsIcon sx={{ color: '#FFFFFF' }}/>
             </IconButton>
             <Popover
                 id={id}
                 open={open}
                 anchorEl={anchor}
                 onClose={handleClose}
                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
             >
                 <Typography sx={{ p: 3 }}>
                     <Grid sx={{ display: 'flex' }}>
                         No new notifications.
                     </Grid>
                 </Typography>
             </Popover>
         </Box>
     )
}

export default NotificationButton;