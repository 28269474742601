 import KeycloakService from "./keycloakService";

const PrivateRoute = ({ children }) => {
    if (KeycloakService.isLoggedIn()) {
        KeycloakService.updateToken()
        return children
    } else {
        return null
    }
};

export default PrivateRoute