import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import CardTransactions from './cardTransactions';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { fetchMerchantProfiles } from '../../../../stores/merchantProfiles';
import { getCardProfileCSVAllTransactions } from '../../../../services/transactionService';
import { TransactionCardStatus } from '../../../../constants/transactionCardStatus';
import CardFilterDash from './cardFilterDash';
import CardBulkFilterDashboard from './cardBulkFilterDashboard';
import GoogleAnalytics from "../../../../utils/googleAnalyticsHelper";
import { PaymentType } from '../../../../constants/paymentType';


function CardProducts() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const { merchantProfiles } = useSelector((state) => state.merchantProfiles);
  const [selectedMerchant] = useOutletContext();
  const [selectedDisplayName, setSelectedDisplayName] = useState("");
  const [bulkSelect, setBulkSelect] = useState(false);
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [request, setRequest] = useState({
    profileIds: [],
    merchantIds: [],
    fromDate: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS'),
    toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    page: 0,
    limit: 25,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    merchantRef: '',
    transactionStatuses: [],
    acquiringBank: '',
    pan: '',
    scheme: '',
    transactionId: '',
    paymentType: '',
    selectedTransactions: [],
  });

  const [requestProfile, setRequestProfile] = useState({
    profileIds: [],
    merchantIds: [],
    page: 0,
    size: 10000,
    displayName: '',
    profileId: '',
  });

  useEffect(() => {
    if (selectedMerchant) {
      setIsProfilesLoading(true);
      setRequest({ ...request, merchantIds: selectedMerchant });
      setRequestProfile({ ...requestProfile, merchantIds: selectedMerchant });
    }
  }, [selectedMerchant]);

  useEffect(() => {
    dispatch(fetchMerchantProfiles(requestProfile));
  }, [requestProfile?.merchantIds]);

  useEffect(() => {
    setIsProfilesLoading(false);
    if (merchantProfiles?.content && merchantProfiles?.content?.length > 0) {
      setIsLoading(false);
    }
  }, [merchantProfiles]);

  const setSelectedProfile = (profileId) => {
    setRequest({
      ...request,
      profileIds: profileId,
    });
  };

  const downloadCardCSV = () => {
    let transactionStatuses = [];
    if (request?.profileIds && request?.profileIds !== '') {
      request?.transactionStatuses?.forEach((transactionStatus) => {
        let index = Object.values(TransactionCardStatus).indexOf(
          transactionStatus
        );
        if (Object.keys(TransactionCardStatus)[index] === '_3DS_INITIATED') {
          transactionStatuses.push('3DS_INITIATED');
        } else if (
          Object.keys(TransactionCardStatus)[index] === '_3DS_AUTHORISED'
        ) {
          transactionStatuses.push('3DS_AUTHORISED');
        } else if (
          Object.keys(TransactionCardStatus)[index] === '_3DS_FAILED'
        ) {
          transactionStatuses.push('3DS_FAILED');
        }else if (
          Object.keys(TransactionCardStatus)[index] === '_3DS_ABANDONED'
        ) {
          transactionStatuses.push('3DS_ABANDONED');
        } else {
          transactionStatuses.push(Object.keys(TransactionCardStatus)[index]);
        }
      });
    }
    setIsCsvLoading(true);
    getCardProfileCSVAllTransactions({
      ...request,
      transactionStatuses: transactionStatuses,
    })
      .then((response) => {
        setIsCsvLoading(false);
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Card Transactions - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
          );
          document.body.appendChild(link);
          link.click();
          GoogleAnalytics.csvCardDownloaded(PaymentType);
        } else {
          const url = window.navigator.msSaveOrOpenBlob(
            new Blob([response.data]),
            `Card Transactions - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setIsCsvLoading(false);
      });
  };

  useEffect(() => {
    let displayNames = [];
    request?.profileIds.forEach(profileId =>{
        const foundProfile = merchantProfiles?.content?.find(p => p.profileId === profileId);
        if(foundProfile)
        displayNames.push(foundProfile?.displayName)
    })
    setSelectedDisplayName(displayNames)
    

}, [request?.profileIds])

  useEffect(() => {
    if (bulkSelect === true) {
      setRequest({
        ...request,
        fromDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        toDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    } else {
      setRequest({
        ...request,
        fromDate: moment()
          .subtract(1, 'month')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS'),
        toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        transactionStatuses: [],
        merchantRef: '',
        paymentType: '',
      });
    }
  }, [bulkSelect]);

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Box sx={{ mt: 2 }}>
        <Grid sx={{ ml: '15px' }}>
          <Grid item sx={{ minWidth: '260px' }}>
            {bulkSelect ? (
              <div style={{ height: '50px' }}>
                <CardBulkFilterDashboard
                  request={request}
                  setRequest={setRequest}
                  setBulkSelect={() => setBulkSelect(false)}
                />
              </div>
            ) : (
              <CardFilterDash
                isCsvLoading={isCsvLoading}
                downloadCardCSV={downloadCardCSV}
                setSelectedProfile={setSelectedProfile}
                request={request}
                setRequest={setRequest}
                selectedDisplayName={selectedDisplayName}
              />
            )}
          </Grid>
        </Grid>
        <Grid>
          <CardTransactions
            onBulkSelect={(checked) => setBulkSelect(checked)}
            bulkSelect={bulkSelect}
            profiles={
              merchantProfiles?.content ? merchantProfiles?.content : []
            }
            request={request}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setRequest={setRequest}
            merchantId={selectedMerchant}
            profileId={request.profileId}
            getSelectedBulkTransactions={(transactionData) => {
              setRequest({ ...request, selectedTransactions: transactionData });
            }}
          />
        </Grid>
      </Box>
    </Box>
  );
}

export default CardProducts;
