
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { ExpandMoreButton } from "../../expand-more/expandMore";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import moment from "moment";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function ZapperTransactionLogs(props) {

    const [expanded, setExpanded] = useState(false);
    const {transactionDetails} = props

    return (
        <Box width="100%">
            <Grid container>
                <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Logs</Typography>
                <ExpandMoreButton expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    <ExpandMore />
                </ExpandMoreButton>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {transactionDetails &&
                    <Box sx={{ mt:  2 }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Details</Typography>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {transactionDetails?.amount ? `${props?.symbol}${numberWithCommas(getFormattedAmount(transactionDetails?.amount))}` : 'NaN'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.merchantId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant Ref:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.reference}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Profile ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.profileId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Provider Junction Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>Zapper</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Zapper ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.zapperId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Date Time:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(transactionDetails?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.transactionStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {transactionDetails?.completedLeg ?
                            <Grid container columnSpacing={2} rowSpacing={0.5}>
                                {transactionDetails?.completedLeg &&
                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={1.97}>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Completed Leg:</Typography>
                                            </Grid>
                                            <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Grid container wrap="nowrap">
                                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Completed Leg ID:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.completedLeg?.completedLegId}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(transactionDetails?.completedLeg?.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Status:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.completedLeg?.transactionStatus}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Zapper Response:</Typography>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{transactionDetails?.completedLeg?.zapperResponse}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container wrap="nowrap" sx={{ mb: "6px" }}>
                                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            :""}
                            </Box>
                        }{props.transactionDetails?.refundLeg && props.transactionDetails?.refundLeg?.length > 0 ?
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid container columnSpacing={2} rowSpacing={0.5}>
                        {props.transactionDetails?.refundLeg && props.transactionDetails?.refundLeg?.length > 0 &&
                            <Grid item xs={12} sx={{ mt: 4 }}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={1.97}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg:</Typography>
                                    </Grid>
                                    <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                        {props.transactionDetails?.refundLeg?.map((refundLeg, index) => (
                                            <Grid container wrap="nowrap" key={index}>
                                                <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>{index + 1}:</Typography>
                                                </Grid>
                                                <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                {refundLeg?.amount ? `${props?.symbol}${numberWithCommas(getFormattedAmount(refundLeg?.amount/100))}` : 'NaN'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refunded Leg ID:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg?.refundLegId}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Zapper ID:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg?.zapperId}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg?.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Origin:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.origin}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Origin Reference:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.originReference}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Status:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg?.status}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Invoice External Reference:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg?.invoiceExternalReference}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Invoice Created UTC Date:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg?.invoiceCreatedUTCDate).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" sx={{ mb: "6px" }}>
                                                        <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>

                                                        </Grid>
                                                        <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            

                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                    </Grid>
                    :""}
            </Collapse >
        </Box >
    );
}

ZapperTransactionLogs.propTypes = {
    transactionDetails: PropTypes.object,
    webhookDetails: PropTypes.object,
    symbol: PropTypes.string,
};

export default ZapperTransactionLogs;