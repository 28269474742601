import React, { useEffect, useState } from 'react';
import AppBar from "../../components/app-bar/appBar";
import { Grid } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { Container } from '@mui/system';
import GoogleAnalytics from '../../utils/googleAnalyticsHelper'

function Home() {

    const [selectedMerchants, setSelectedMerchants] = useState([]);

    const location = useLocation();

    useEffect(() => {
        console.log(location)
        const pathParts = location.pathname.split('/').filter(part => part.trim() !== '');
        const pageTitle = pathParts[pathParts.length - 1];
        GoogleAnalytics.sendPageView(pageTitle, location.pathname);
    }, [location])

    return (
        <Grid height="100vh" sx={{ overflow: "auto" }}>
            <AppBar selectedMerchants={selectedMerchants} setSelectedMerchants={setSelectedMerchants} />
            <Container maxWidth="false" sx={{
                "&.MuiContainer-root": {
                    pl: 0, pr: 0
                },
                height: "calc(100vh - 190px)",
                overflowY: "auto"
            }}>
                <Outlet context={[selectedMerchants ? selectedMerchants : ""]} />
            </Container>
            <Footer />
        </Grid>
    )
}

export default Home;