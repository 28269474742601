import * as React from 'react';
import store from "../stores";
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { Provider } from "react-redux";
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { Theme } from '../assets/styles/theme';
import PrivateRoute from '../keycloak/privateRoute';
import Home from '../pages/home/home';
import Dashboard from '../pages/dashboard/dashboard';
import Transactions from '../pages/transactions/transactions';
import AllProducts from '../pages/transactions/products/all-products/allProducts';
import CardProducts from '../pages/transactions/products/card-transactions/cardProducts';
import Reporting from '../pages/reporting/reporting';
import Reconciliation from '../pages/reconciliation/reconciliation';
import Settlements from '../pages/settlements/settlements';
import ConfigManager from '../pages/config-manager/configManager';
import HelpAndSupport from '../pages/help-and-support/helpAndSupport';
import ConfigManagerInfo from '../pages/config-manager-info/configManagerInfo';
import GeneralConfig from '../pages/config-manager/generalConfig';
import BrandingConfig from '../pages/config-manager/brandingConfig';
import EndpointConfig from '../pages/config-manager/endpointConfig';
import PageNotFound from '../pages/page-not-found/pageNotFound';
import AuthorizedElement from '../keycloak/permissionsGate';
import OzowProducts from "../pages/transactions/products/ozow-transactions/ozowProducts";
import RcsProducts from '../pages/transactions/products/rcs-transactions/rcsProducts';
import PaymentLinkProducts from '../pages/payment-link/paymentLinkProducts';
import ZapperProducts from '../pages/transactions/products/zapper-transactions/zapperProducts';

const theme = Theme();

const App = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />}>
                        <Route path="dashboard" element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        } />
                        <Route path="transactions" element={
                            <PrivateRoute>
                                <Transactions />
                            </PrivateRoute>
                        }>
                            <Route path="all" element={<AllProducts />} />
                            <Route path="card" element={<CardProducts />} />
                            <Route path="rcs" element= {<RcsProducts/>}/>
                            <Route path="ozow" element={<OzowProducts />} />
                            <Route path="zapper" element={<ZapperProducts/>}/>
                        </Route>
                        <Route path="reporting" element={
                            <PrivateRoute>
                                <Reporting />
                            </PrivateRoute>
                        } />
                        <Route path="reconciliation" element={
                            <PrivateRoute>
                                <Reconciliation />
                            </PrivateRoute>
                        } />
                        <Route path="settlements" element={
                            <PrivateRoute>
                                <Settlements />
                            </PrivateRoute>
                        } />
                        <Route path="config-manager" element={
                            <PrivateRoute>
                                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                    <ConfigManager />
                                </AuthorizedElement>
                            </PrivateRoute>
                        } />
                       
                        <Route path="support" element={
                            <PrivateRoute>
                                <HelpAndSupport />
                            </PrivateRoute>
                        } />
                        <Route path="config-manager/info" element={
                            <PrivateRoute>
                                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                    <ConfigManagerInfo />
                                </AuthorizedElement>
                            </PrivateRoute>
                        }>
                            <Route path="general" element={
                                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                    <GeneralConfig />
                                </AuthorizedElement>
                            } />
                            <Route path="branding" element={
                                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                    <BrandingConfig />
                                </AuthorizedElement>
                            } />
                            <Route path="end-point" element={
                                <AuthorizedElement roles="MerchantPortal_AdministratorRole">
                                    <EndpointConfig />
                                </AuthorizedElement>
                            } />
                        </Route>
                        <Route path="payment-link" element={<PaymentLinkProducts/>} />
                    </Route>
                    
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
);

export default App;