import React, { useEffect, useState } from "react";
import { Backdrop, Grid, Box, Button, Typography, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import ComponentLoader from "../component-loader/componentLoader";
import { CopyAllRounded } from "@mui/icons-material";

function PaymentLinkCreatedDialog(props) {
    const textToCopyRef = React.useRef(null);

    const handleCopyText = () => {
        const textToCopy = textToCopyRef.current.innerText;

        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                })
                .catch((error) => {
                    console.error("Copy failed: ", error);
                });
        }
    };
    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: "blur(10px)",
                    opacity: 1,
                    background: "inherit"
                }}
                open={props.isOpen}
            >
                <Grid
                    container
                    direction="column"
                    width="20%"
                    minWidth="500px"
                    maxHeight="calc(100vh - 50px)"
                    sx={{ overflow: "hidden", boxShadow: "0px 8px 19px #00000029", background: "#FFFFFF", borderRadius: "5px" }}
                >
                    <Box width="100%" sx={{ p: 2 }}>
                        <Typography align="center" fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Payment Link Created</Typography>
                        <Box sx={{ mb: 2 }}>
                            <Grid item sx={{ flexGrow: 1, marginBottom: "5%" }}>
                                <Typography align="center" fontSize={16} letterSpacing={0} sx={{ color: "#404047", mb: 2 }} ref={textToCopyRef} > {props.paymentLinkUrl}
                                    <IconButton>
                                        <CopyAllRounded onClick={handleCopyText} />
                                    </IconButton>
                                </Typography>

                            </Grid>
                            <Grid container justifyContent="center" alignItems="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="transaction-button"
                                    onClick={props.onClose}
                                    sx={{ textTransform: 'none', width: '45%', height: 40, color: 'white', fontSize: 12, boxShadow: 2, marginRight: "0%" }}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Backdrop>
            <ComponentLoader isOpen={props.isLoading} />
        </Box>
    );
}

PaymentLinkCreatedDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    paymentLinkUrl: PropTypes.string.isRequired
};

export default PaymentLinkCreatedDialog;