import React from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from 'prop-types';
import { Timeline } from '../../../constants/timeline';

function TimelineSelect(props) {

    return(
        <FormControl fullWidth size="small" className="dashboard-select">
            <InputLabel>Timeline</InputLabel>
            <Select
                label="Timeline"
                value={props.selectedTimeline}
                onChange={(event) => props.setSelectedTimeline(event.target.value)}
            >
                <MenuItem value={Timeline.TWENTYFOURHOURS}>24 Hours</MenuItem>
                <MenuItem value={Timeline.ONEWEEK}>1 Week</MenuItem>
                <MenuItem value={Timeline.ONEMONTH}>1 Month</MenuItem>
            </Select>
        </FormControl>
    )
}

TimelineSelect.propTypes = {
    selectedTimeline: PropTypes.string.isRequired,
    setSelectedTimeline: PropTypes.func.isRequired
};

export default TimelineSelect;