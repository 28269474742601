
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { ExpandMoreButton } from "../../expand-more/expandMore";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import moment from "moment";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function TransactionLogs(props) {

    const [expanded, setExpanded] = useState(false);
     
  
    return (
        <Box width="100%">
            <Grid container>
                <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Logs</Typography>
                <ExpandMoreButton expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    <ExpandMore />
                </ExpandMoreButton>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.threeDSecureDetails &&
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>3D Secure Logs</Typography>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Allowed To Continue:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.allowedToContinue?.toString()}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.threeDSecureDetails?.record?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.threeDSecureDetails?.record?.amount))}` : 'NaN'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Card Type:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.cardType}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>CAVV:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.cavv}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>CAVV Algorithm:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.cavvAlgorithm}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Currency Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.currencyCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Date Logged:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.threeDSecureDetails?.record?.dateLogged).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Description:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.description}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ECI:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.eci}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Expiry:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.expiry}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Exponent:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.exponent}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Fail URL:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.failUrl}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.id}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>MD Error Message:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.mdErrorMsg}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>MD Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.mdStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.merchantId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Modified Date Time:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.threeDSecureDetails?.record?.modifiedDateTime ?? props.threeDSecureDetails?.record?.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Success URL:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.okUrl}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>PAN:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.pan}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Pares Syntax OK:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.paresSyntaxOK}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Pares Tx Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.paresTxStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Pares Verified:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.paresVerified}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Profile ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.profileId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Protocol:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.protocol}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Signature:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.signature}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Veres Enrolled Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.veresEnrolledStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Version:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.version}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6} wrap="nowrap">
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>XID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.threeDSecureDetails?.record?.xid}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {props.transactionDetails &&
                    <Box sx={{ mt: (props.threeDSecureDetails ? 4 : 2) }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Transaction Details</Typography>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.transactionDetails?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.amount))}` : 'NaN'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Card Details:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Expiry Date:</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardDetails?.expiryDate}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Name On Card:</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardDetails?.nameOnCard}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Pan:</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.cardDetails?.pan}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Currency Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.currencyCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.dmsIndicator?.toString()}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Expected Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.transactionDetails?.expectedAmount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.expectedAmount))}` : 'NaN'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant Bank:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantBankDisplayName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Merchant Ref:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.merchantRef}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>POS:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.pos}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Profile ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.profileId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Provider Junction Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.providerJunctionName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Route:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.route}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.responseCode}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.responseText}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>RRN:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.rrn}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Scheme:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.scheme}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Sequence:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.seq}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Store:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.store}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.token}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Date Time:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(props.transactionDetails?.transactionDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionId}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Transaction Status:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.transactionStatus}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Incremental Auth Ref:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.incrementalAuthRef}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Incremental Auth Flag:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.incrementalAuthFlag?.toString()}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: "4px" }}>
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Incremental Auth Amount:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                            {props.transactionDetails?.incrementalAuthAmount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.incrementalAuthAmount/100))}` : `${props.symbol}0`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {props.transactionDetails?.authorisationLeg &&
                            <Grid container sx={{ mt: 4 }}>
                                <Grid item xs={1.97}>
                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Authorisation Leg:</Typography>
                                </Grid>
                                <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                {props.transactionDetails?.authorisationLeg?.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(props.transactionDetails?.authorisationLeg?.amount/100))}` : 'NaN'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Authorisation Leg ID:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.authorisationLegId}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Auto Settle Flag:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.autoSettleFlag?.toString()}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.dmsIndicator?.toString()}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Expiry Date:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.expiryDate}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>PAN:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.hashedPan}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Name On Card:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.nameOnCard}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ISO Currency Code:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.numericIsoCurrencyCode}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Store ID:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.storeId}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.token}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Incremental Auth Flag:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.incrementalAuthFlag?.toString()}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap">
                                        <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Route:</Typography>
                                        </Grid>
                                        <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Code:</Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.route?.code}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ID:</Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.route?.id}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Name:</Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.route?.name}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Provider Junction Name:</Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.route?.providerJunctionName}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Route ID:</Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.authorisationLeg?.route?.routeId}</Typography>
                                                </Grid>
                                            </Grid>                                 
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {props.transactionDetails?.adjustmentLegs && props.transactionDetails?.adjustmentLegs?.length > 0 &&
                                <Grid item xs={12} sx={{ mt: 4 }}>
                                    <Grid container wrap="nowrap">
                                        <Grid item xs={1.97}>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Adjustment Leg:</Typography>
                                        </Grid>
                                        <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                            {props.transactionDetails?.adjustmentLegs?.map((adjustmentLeg, index) => (
                                                <Grid container wrap="nowrap" key={index}>
                                                    <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>{index + 1}:</Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Sequence:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.sequence}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Status:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.status}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.responseCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.responseText}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(adjustmentLeg.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                    {adjustmentLeg.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(adjustmentLeg.amount/100))}` : 'NaN'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.dmsIndicator?.toString()}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Numeric Iso Currency Code:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.numericIsoCurrencyCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{adjustmentLeg.token}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        <Grid container columnSpacing={2} rowSpacing={0.5}>
                            {props.transactionDetails?.settlementLeg &&
                                <Grid item xs={12} sx={{ mt: 4 }}>
                                    <Grid container wrap="nowrap">
                                        <Grid item xs={1.97}>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Settlement Leg:</Typography>
                                        </Grid>
                                        <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                </Grid>
                                                <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.settlementLeg?.responseCode}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Sequence:</Typography>
                                                </Grid>
                                                <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.settlementLeg?.responseCode}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Settlement Leg ID:</Typography>
                                                </Grid>
                                                <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.transactionDetails?.settlementLeg?.settlementLegId}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {props.transactionDetails?.refundLegs && props.transactionDetails?.refundLegs?.length > 0 &&
                                <Grid item xs={12} sx={{ mt: 4 }}>
                                    <Grid container wrap="nowrap">
                                        <Grid item xs={1.97}>
                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg:</Typography>
                                        </Grid>
                                        <Grid item xs={10.03} sx={{ alignItems: "center" }} zeroMinWidth>
                                            {props.transactionDetails?.refundLegs?.map((refundLeg, index) => (
                                                <Grid container wrap="nowrap" key={index}>
                                                    <Grid item xs={3} sx={{ alignItems: "center" }} zeroMinWidth>
                                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>{index + 1}:</Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ alignItems: "center" }} zeroMinWidth>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                            <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>
                                                                    {refundLeg.amount ? `${props.symbol}${numberWithCommas(getFormattedAmount(refundLeg.amount/100))}` : 'NaN'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Creation Date Time:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{moment(refundLeg.creationDateTime).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>DMS Indicator:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.dmsIndicator?.toString()}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>ISO Currency Code:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.numericIsoCurrencyCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Refund Leg Id:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.refundLegId}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Code:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.responseCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Response Text:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.responseText}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Sequence:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.sequence}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap" sx={{ mb: "4px" }}>
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Status:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.status}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap">
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Token:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.token}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container wrap="nowrap">
                                                            <Grid item xs={5} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>RRN:</Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{ alignItems: "center" }} zeroMinWidth>
                                                                <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{refundLeg.rrn}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                }
            </Collapse >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            {props.webhookDetails?.url ?
                <Box>|
                <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2, flexGrow: 1 }}>Webhook Details</Typography>
                <Grid item xs={12}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={2}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047", mb: 1, flexGrow: 1 }}>URL:</Typography>
                                    </Grid>
                                    <Grid item xs={10} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.webhookDetails?.url}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {props.webhookDetails?.authUrl?
                            <Grid item xs={6}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047", mb: 1, flexGrow: 1 }}>Auth URL:</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.webhookDetails?.authUrl}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>:null
                            }
                             <Grid container spacing={2} sx={{ mb: "4px" }}>
                                <Grid item xs={12}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={2}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047", mb: 1, flexGrow: 1 }}>Status:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.webhookDetails?.status}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography fontSize={14} fontWeight={500} letterSpacing={0} sx={{ color: "#404047" }}>Auth Type:</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ alignItems: "center" }} zeroMinWidth>
                                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.webhookDetails?.authType}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                </Box>:null
            }
            </Collapse>
        </Box >
    );
}

TransactionLogs.propTypes = {
    threeDSecureDetails: PropTypes.object,
    transactionDetails: PropTypes.object,
    webhookDetails: PropTypes.object,
    symbol: PropTypes.string,
    currencyCode: PropTypes.string,
};

export default TransactionLogs;