
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../../styles.scss";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import moment from "moment";
import { TransactionStatus } from "../../../constants/transactionStatus";
import { getFormattedAmount, numberWithCommas } from "../../../utils/amountFormatter";

function PaymentLinkDetailedView(props) {

    const [status, setStatus] = useState("");

    const [transactionMoreDetails, setPaymentLinkMoreDetails] = useState();

    useEffect(() => {

        console.log(props.profileId);
        switch (props.transactionStatus) {
            case TransactionStatus.PAYMENT_CANCELLED:
            case TransactionStatus.PAYMENT_DECLINED:
            case TransactionStatus.PAYMENT_FAILED:
                setStatus("Failed");
                break;
            case TransactionStatus.PAYMENT_AUTHORISED:
            case TransactionStatus.PAYMENT_SETTLED:
            case TransactionStatus.PAYMENT_COMPLETED:
            case TransactionStatus.PAYMENT_PARTIALLY_REFUNDED:
            case TransactionStatus.PAYMENT_REFUNDED:
            case TransactionStatus.PAYMENT_REVERSED:
                setStatus("Successful");
                break;
            case TransactionStatus.PAYMENT_INITIATED:
                setStatus("Initiated");
                break;
            case TransactionStatus._3DS_INITIATED:
                setStatus("3DS Initiated");
                break;
            case TransactionStatus._3DS_ABANDONED:
                setStatus("3DS Abandoned");
                break;
            case TransactionStatus._3DS_AUTHORISED:
                setStatus("3DS Authorised");
                break;
            case TransactionStatus._3DS_FAILED:
                setStatus("3DS Failed");
                break;
            default:
                break;
        }

        let mostRecentDate = new Date(Math.max.apply(null, props.transactionMoreDetails?.map(moreDetail => {
            return new Date(moreDetail.lastModified);
        })));
        setPaymentLinkMoreDetails(props.transactionMoreDetails?.filter(moreDetail => {
            var d = new Date(moreDetail.lastModified);
            return d.getTime() === mostRecentDate.getTime();
        })[0]);
    }, [props])

    return (
        <Box width="100%">
            <Typography fontSize={16} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047", mb: 2 }}>Details</Typography>
            <Box>
                <Grid container>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Amount:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} sx={{ color: "#404047" }}>{props.symbol}{numberWithCommas(getFormattedAmount(props.amount))}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Reference:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.merchantRef}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Date:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.lastModified).format("YYYY/MM/DD")}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Time:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.lastModified).format("HH:mm:ss")}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Result:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.paymentLinkStatus}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Expires At:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{moment(props.expiredAt).format("YYYY/MM/DD HH:mm:ss")} </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Profile:</Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047" }}>{props.profileId}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={20}>
                        <Grid item xs={10} sx={{ alignItems: "center" }} zeroMinWidth>
                            <Grid container wrap="nowrap">
                                <Grid item xs={4} sx={{ alignItems: "center" }} zeroMinWidth>
                                    <Typography fontSize={14} fontWeight="bold" letterSpacing={0} sx={{ color: "#404047" }}>Payment Link URL:</Typography>
                                </Grid>
                                <Grid item xs={16} sx={{ alignItems: "center" }} zeroMinWidth>
                                    <Typography fontSize={14} letterSpacing={0} sx={{ color: "#404047", wordBreak: "break-word" }}>{props.redirectUrl ? props.redirectUrl : "NA"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

PaymentLinkDetailedView.propTypes = {
    amount: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    paymentLinkStatus: PropTypes.string,
    lastModified: PropTypes.string.isRequired,
    expiredAt: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    transactionMoreDetails: PropTypes.array,
    transactionDetails: PropTypes.object,
    merchantRef: PropTypes.string.isRequired,
    paymentType: PropTypes.string,
    transactions: PropTypes.array,
    redirectUrl: PropTypes.string.isRequired
};

export default PaymentLinkDetailedView;