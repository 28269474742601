import * as React from "react";
import Button  from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/system/Box";
import Info from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { PaymentLinkStatus } from "../../constants/paymentLinkStatus";
import Paginator from "../../components/paginator/paginator";
import { getFormattedAmount, numberWithCommas } from "../../utils/amountFormatter";
import { fetchPaymentLinkTransactions, updatePaymentLinkTransactions } from "../../stores/paymentLinkTransactions";
import PaymentLinkMoreInfo from "../../components/transaction-more-info/more-info/paymentLinkMoreInfo";
import PageRefresh from "../../components/page-refresh/page-refresh";
import OverflowTip from "../../components/overflow-tooltip/overflowTooltip";
import { getCurrencies } from '../../services/currencyService';


function PaymentLinkTransactions(props) {

    const dispatch = useDispatch();
    const { paymentLinkTransactions } = useSelector(state => state.paymentLinkTransactions);
    const [allCurrencies, setCurrencies] = useState([]);

    const changeSort = (sortValues) => {
        if (sortValues.length > 0) {
            if (sortValues[0].field !== "expectedAmount") {
                console.log(sortValues)
                props.setRequest({
                    ...props.request,
                    sortBy: sortValues[0].field,
                    sortOrder: sortValues[0].sort
                })
            }
        }
    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    useEffect(() => {
        if (props.request?.profileIds && props.request?.profileIds.length > 0) {
            fetchAllTransaction()
        } else {
            dispatch(updatePaymentLinkTransactions([]))
        }
    }, [props.request, dispatch])

    const fetchAllTransaction = useCallback(() => {
        if (props.request?.profileIds && props.request?.profileIds.length > 0) {
            props.setIsLoading(true);
            dispatch(fetchPaymentLinkTransactions({
                ...props.request
            }));
        }
        const fetchCurrencies = async () => {
            try {
                const response = await getCurrencies();
                setCurrencies(response.data)
                console.log('Fetched Currencies:', allCurrencies);
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        };

        fetchCurrencies();
    }, [props, dispatch]);

    const [moreInfoRequest, setMoreInfoRequest] = useState({

        isOpen: false,
        paymentLinkId: "",
        symbol: "",
        amount: 0,
        paymentLinkStatus: "",
        displayName: "",
        createdAt: "",
        lastModified: "",
        transactionMoreDetails: null,
        merchantRef: "",
        currencyCode: "ZAR",
        selectedprofile: "",
        merchantId: "",
        redirectUrl: "",
        transactions: []
    });

    const handleMoreInfoBackClick = () => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            isOpen: false
        })
    }

    const openMoreInfo = (paymentLinkId, symbol, amount, paymentLinkStatus, displayName, createdAt, expiredAt, lastModified, transactionMoreDetails, merchantRef, currencyCode, profileId, transactions, redirectUrl) => {
        var merchantId = props.profiles?.find(profile => profile.profileId === profileId)?.merchantId;
        var displayNameProfile = props.profiles?.find(profile => profile.profileId === profileId)?.displayName;

        setMoreInfoRequest({
            isOpen: true,
            paymentLinkId: paymentLinkId,
            symbol: symbol,
            amount: amount ? amount : 0,
            paymentLinkStatus: paymentLinkStatus,
            displayName: displayNameProfile,
            createdAt: createdAt,
            expiredAt: expiredAt,
            lastModified: lastModified,
            transactionMoreDetails: transactionMoreDetails,
            merchantRef: merchantRef,
            currencyCode: currencyCode,
            selectedprofile: profileId,
            merchantId: merchantId,
            transactions: transactions,
            redirectUrl: redirectUrl
        })
    }

    const handleChangePage = (newPage) => {
        props.setRequest({
            ...props.request,
            page: newPage
        })
    };

    const handleChangeRowsPerPage = (value) => {
        props.setRequest({
            ...props.request,
            limit: value
        })
    };

    useEffect(() => {
        props.setIsLoading(false);
    }, [paymentLinkTransactions])

    useEffect(() => {
        console.log(moreInfoRequest.redirectUrl);
    }, [paymentLinkTransactions])

    const handleTableUpdate = (status) => {
        setMoreInfoRequest({
            ...moreInfoRequest,
            paymentLinkStatus: status
        })

        const newTransactions = paymentLinkTransactions?.content.map(transaction => {
            if (transaction.transactionId === moreInfoRequest.transactionId) {
                console.log({ ...transaction, paymentLinkStatus: status });
                return { ...transaction, paymentLinkStatus: status };
            }
            return transaction;
        });

        const newTransactionsPaginated = {
            ...paymentLinkTransactions,
            content: newTransactions
        }

        dispatch(updatePaymentLinkTransactions(newTransactionsPaginated));
    }

    return (
        <Card>
            <DataGrid
                loading={props.isLoading}
                sx={{
                    height: "calc(100vh - 278px)",
                    "& .MuiDataGrid-columnHeadersInner": {
                        background: "#EBFAFF",
                        fontSize: 12
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        boxShadow: "0px 0px 10px #00000029",
                        display: "block"
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        overflowY: "overlay"
                    },
                    "& .amount-cell": {
                        fontWeight: "bold"
                    },
                    "& .cell-font": {
                        fontSize: 12
                    },
                    "& .cardstatus-cell": {
                        fontSize: 12
                    }
                }}
                getRowId={(row) => row.paymentLinkId}
                rows={paymentLinkTransactions?.content ? paymentLinkTransactions?.content : []}
                columns={[
                    {
                        align: "center", headerAlign: "center", field: 'createdAt', headerName: "Date", flex: 1, cellClassName: 'cell-font', valueFormatter: params => moment(params?.value).format("YYYY/MM/DD HH:mm:ss"),

                        renderCell: (params) => {

                            return (

                                <OverflowTip value={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} text={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} />

                            )

                        }
                    },

                    {
                        align: "center", headerAlign: "center", field: 'merchantRef', headerName: "Merchant Ref", flex: 1, cellClassName: 'cell-font',

                        renderCell: (params) => {

                            return (

                                <OverflowTip value={params?.value} text={params?.value} />

                            )

                        }
                    },

                    {
                        align: "center", headerAlign: "center", field: 'profileId', headerName: "Profile ID", flex: 1, cellClassName: 'cell-font',

                        renderCell: (params) => {

                            return (

                                <OverflowTip value={params?.value} text={params?.value} />

                            )

                        }
                    },

                    {
                        align: "center", headerAlign: "center", field: 'paymentLinkId', headerName: "Payment Link ID", flex: 1, cellClassName: 'cell-font',

                        renderCell: (params) => {

                            return (

                                <OverflowTip value={params?.value} text={params?.value} />

                            )

                        }
                    },

                    {
                        headerAlign: "center", field: 'paymentLinkStatus', width: 200, cellClassName: 'cardstatus-cell', sortable: false,

                        renderCell: (params) => {

                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{
                                        flexGrow: 1, textAlign: "center", fontSize: 12, color: (params?.value === "CREATED" ? "blue" : params?.value === "COMPLETED" ? "green" :

                                            params?.value === "EXPIRED" ? "RED" : "black")
                                    }}>
                                        <OverflowTip value={params?.value} text={PaymentLinkStatus[params?.value]} />
                                    </Typography>

                                </Box>

                            )

                        },
                        
                        renderHeader: () => (<Box sx={{ display: "flex", alignItems: "center", width: "100%" ,mr:2, sortable: false}}>
                        <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px", fontSize: 12 }} fontWeight="bold">
                        Status
                        </Typography>
                        
                        <IconButton onClick={() => handleClickOpen()} sx={{mr:5}}>
                            <Info />
                        </IconButton>
                        <Dialog
                         open={open}
                         onClose={handleClose}
                         aria-labelledby="alert-dialog-title"
                         aria-describedby="alert-dialog-description"
                       >
                         
                         <DialogTitle id="alert-dialog-title">
                           {"The three statuses mean:"}
                         </DialogTitle>
                         <DialogContent>
                           <DialogContentText id="alert-dialog-description">
                           <strong>Created</strong>- The payment link has been created successfully.
                           <td></td>
                           <strong>Completed</strong> -  The customer has completed the payment.
                           <td></td>
                           <strong>Expired</strong> - The payment link has expired.
                           </DialogContentText>
                         </DialogContent>
                         <DialogActions>
                           <Button onClick={handleClose}>Okay</Button>
                         </DialogActions>
                       </Dialog>
                        
                        
                    </Box>
                    
                    
                    ),
                    },
                    {
                        
                    renderHeader: () => (<Box sx={{ display: "flex", alignItems: "center", width: "100%" ,mr:2, sortable: false}}>
                    <Typography sx={{ flexGrow: 1, textAlign: "center", pl: "40px", fontSize: 12 }} fontWeight="bold">
                    Status
                    </Typography>
                    
                    <IconButton onClick={() => handleClickOpen()} sx={{mr:5}}>
                        <Info />
                    </IconButton>
                    <Dialog
                     open={open}
                     onClose={handleClose}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                   >
                     
                     <DialogTitle id="alert-dialog-title">
                       {"The three statuses mean:"}
                     </DialogTitle>
                     <DialogContent>
                       <DialogContentText id="alert-dialog-description">
                       <strong>Created</strong>- The payment link has been created successfully.
                       <td></td>
                       <strong>Completed</strong> -  The customer has completed the payment.
                       <td></td>
                       <strong>Expired</strong> - The payment link has expired.
                       </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                       <Button onClick={handleClose}>Okay</Button>
                     </DialogActions>
                   </Dialog>
                    
                    
                </Box>
                
                
                ),
                },

                    {
                        align: "center", headerAlign: "center", field: 'expiresAt', headerName: "Expires At", flex: 1, cellClassName: 'cell-font',

                        renderCell: (params) => {

                            return (

                                <OverflowTip value={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} text={moment(params?.value).format("YYYY/MM/DD HH:mm:ss")} />

                            )

                        }
                    },

                    {
                        headerAlign: "center", field: 'amount', headerName: "Amount", flex: 1, cellClassName: 'amount-cell',

                        renderCell: (params) => {

                            return (
                                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <Typography sx={{ flexGrow: 1, textAlign: "center", fontSize: 12 }} fontWeight="bold">
                                        {allCurrencies?.find(currency => currency.isoCode === params.row?.currency)?.symbol} { params.row.amount ? numberWithCommas(getFormattedAmount(params.row.amount)) : 0}
                                    </Typography>
                                    <IconButton sx={{ color: "#034EA2" }} onClick={() => openMoreInfo(params.row.paymentLinkId, allCurrencies?.find(currency => currency.isoCode === params.row?.currency)?.symbol, params.row?.amount, params.row?.paymentLinkStatus, "BuyAlot", params.row?.createdAt, params.row?.expiresAt, params.row?.createdAt, params.row?.transactionMoreDetails, params.row?.merchantRef, params.row?.currency, params.row?.profileId, params.row?.transactions, params.row?.redirectUrl)}>
                                        <Info />
                                    </IconButton>
                                </Box>
                            )
                        }
                    },
                ]}
                rowsPerPageOptions={[5, 10, 25]}
                pageSize={props.request.limit}
                rowCount={paymentLinkTransactions?.totalElements ? paymentLinkTransactions.totalElements : 0}
                page={props.request.page}
                pagination
                paginationMode="server"
                components={{ Footer: PageRefresh }}
                componentsProps={{
                    footer: {
                        refreshFunction: fetchAllTransaction
                    },
                    pagination: {
                        ActionsComponent: Paginator
                    }
                }}
                onPageSizeChange={(newLimit) => handleChangeRowsPerPage(newLimit)}
                onPageChange={(newPage) => handleChangePage(newPage)}
                onSortModelChange={changeSort}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
            />
            <PaymentLinkMoreInfo
                isOpen={moreInfoRequest.isOpen}
                paymentLinkId={moreInfoRequest.paymentLinkId}
                handleBack={handleMoreInfoBackClick}
                displayName={moreInfoRequest.displayName}
                symbol={moreInfoRequest.symbol}
                amount={moreInfoRequest.amount}
                createdAt={moreInfoRequest.createdAt}
                expiredAt={moreInfoRequest.expiredAt}
                lastModified={moreInfoRequest.lastModified}
                paymentLinkStatus={moreInfoRequest.paymentLinkStatus}
                transactionMoreDetails={moreInfoRequest.transactionMoreDetails}
                profileId={moreInfoRequest.selectedprofile ? moreInfoRequest.selectedprofile : ""}
                merchantRef={moreInfoRequest.merchantRef}
                currencyCode={moreInfoRequest.currencyCode}
                merchantId={moreInfoRequest.merchantId ? moreInfoRequest.merchantId : ""}
                handleTableUpdate={handleTableUpdate}
                setMoreInfoRequest={setMoreInfoRequest}
                redirectUrl={moreInfoRequest.redirectUrl}

                transactions={moreInfoRequest.transactions} />
        </Card>
    )
}

PaymentLinkTransactions.propType = {
    profiles: PropTypes.array.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    merchantRef: PropTypes.array.isRequired,
    handleChange: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    selectedRange: PropTypes.object.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
}

export default PaymentLinkTransactions;