import * as React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/system/Container";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

function Footer() {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = () => () => {
        setOpen(true);
        setScroll('paper');
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container className="footer">
            <Grid className="ribbon">
                <Link href="../../assets/files/disclaimer.txt" className="item">Disclaimer</Link>
                <Button className="item" onClick={handleClickOpen()} style={{ textTransform: 'none' }}>Terms & Conditions</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                            fontStyle="italic"
                        >
                            <Typography>Our commitment to you:</Typography>
                            <Typography>1. We are dedicated to ensuring your protection and safety.</Typography>
                            <Typography>2. We will never charge your card without your consent.</Typography>
                            <Typography>3. Our full solution is PCI-DSS Level 1 certified on an annual basis, and exceeds global payment industry security standards.</Typography>
                            <Typography>By submitting a payment instruction, you acknowledge and consent to the following:</Typography>
                            <Typography>1. You are the cardholder of the card which you have submitted on the secure payment page.</Typography>
                            <Typography>2. You provide consent to Transaction Junction to encrypt and securely store your payment card details in a PCI-DSS Level 1 certified card vault, which can be used to initiate refund transactions to your card if required.</Typography>
                            <Typography>3. Should you consent to the encryption of your card details for the purpose of initiating future transactions, and consent to a future transaction being initiated, Transaction Junction, on instruction by the merchant, may use your encrypted card details to initiate a payment.</Typography>
                            <Typography>Thank you for your valued and ongoing support.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ textTransform: 'none' }}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Link href="../../assets/files/paia-and-popi-manual.txt" className="item">PAIA & POPI Manual</Link>
            </Grid>
        </Container>
    )
}

export default Footer;