import React, { useEffect, useState } from 'react';
import { Card, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataGrid, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { Box, color } from '@mui/system';
import { Info } from '@mui/icons-material';
import CustomSwitch from '../custom-switch/customSwitch';
import RefreshIcon from '@mui/icons-material/Refresh';
import BulkSwitch from '../filters/bulkSwitch';

function BulkPageRefresh(props) {
  const [isTimerEnable, setIsTimerEnable] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const activateSelect = () => {
    props.setBulkSelect(true);
  };

  const deactivateSelect = () => {
    props.setBulkSelect(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked === !true) {
      activateSelect();
    } else {
      deactivateSelect();
    }
  };

  useEffect(() => {
    let timerId;
    if (isTimerEnable) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
  }, [isTimerEnable]);

  useEffect(() => {
    if (countDown < 0 && isTimerEnable) {
      props.refreshFunction();
      setCountDown(60 * 5);
    } else if (!isTimerEnable) {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box>
      <GridFooterContainer>
        <Box style={{ display: 'flex' }}>
          <div style={{ paddingLeft: 10 }}>
            <BulkSwitch
              cancelBulkSelect={() => props.setBulkSelect(false)}
              setBulkSelect={() => props.setBulkSelect(true)}
              handleChanges={props.handleChange}
              checked={props.checked}
              label={<span style={{ fontSize: '0.8rem' }}>Batch Mode</span>}
            />
          </div>

          <Tooltip title="Reload table">
            <IconButton
              sx={{ color: '#808080', ml: 1.5, mr: 1.9 }}
              onClick={() => {
                props.refreshFunction();
                if (isTimerEnable) {
                  setCountDown(60 * 5);
                }
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <CustomSwitch
            label={
              <span style={{ fontSize: '0.8rem' }}>
                Allow refresh every 5 minutes
              </span>
            }
            checked={isTimerEnable}
            onChange={(event) => setIsTimerEnable(event.target.checked)}
          />
        </Box>
        <GridFooter />
      </GridFooterContainer>
    </Box>
  );
}

BulkPageRefresh.propTypes = {
  refreshFunction: PropTypes.func.isRequired,
  setBulkSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.func.isRequired,
};

export default BulkPageRefresh;
