import Keycloak from 'keycloak-js';

const keycloakInstance = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  authServerUrl: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

const initKeycloak = (onAuthenticatedCallback) => {
  keycloakInstance
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      hasNewStyle();
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = () => {
  keycloakInstance.login();
};

const doLogout = () => {
  window.sessionStorage.removeItem('merchantId');
  keycloakInstance.logout({
    redirectUri:
      window.location.href.replace(window.location.pathname, '') + '/dashboard',
  });
};

const getToken = () => keycloakInstance.token;

const isLoggedIn = () => {
  if (!!keycloakInstance.token) {
    let merchantId = window.sessionStorage.getItem('merchantId');
    if (!merchantId || merchantId !== keycloakInstance.tokenParsed.merchantId) {
      window.sessionStorage.setItem(
        'merchantId',
        keycloakInstance.tokenParsed.merchantId
      );
    }
  }
  return !!keycloakInstance.token;
};

const hasNewStyle = () => {
  if (!!keycloakInstance.token) {
    if (keycloakInstance.tokenParsed.hasNewTheme) {
      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        const confirmSwitch = window.confirm("Would you like to switch to the new style?");
        if (confirmSwitch) {
          window.location.href = process.env.REACT_APP_MERCHANT_PORTAL;
        } else {
        }
      } else {
        window.location.href = process.env.REACT_APP_MERCHANT_PORTAL;
      }
    }
  }
};


const UpdatePassword = () => {
  keycloakInstance.login({ action: 'UPDATE_PASSWORD' });
};

const updateToken = (successCallback) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);

const getEmail = () => keycloakInstance.tokenParsed?.email;

const getUsername = () => keycloakInstance.tokenParsed?.preferred_username;

const hasRole = (role) => keycloakInstance.hasRealmRole(role);

const getDisplayName = () => keycloakInstance.tokenParsed?.name;

const hasResourceRole = (roles) => keycloakInstance.hasResourceRole(roles);

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getDisplayName,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  UpdatePassword,
  hasResourceRole,
  keycloakInstance,
  getEmail,
};

export default KeycloakService;
