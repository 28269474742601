import '@ipgw/react-date-range/dist/styles.css';
import './customDateRangePicker.scss';
import { DateRangePicker, createStaticRanges } from '@ipgw/react-date-range';
import { useEffect, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, Popover } from '@mui/material';
import { CustomTextField } from '../custom-text-field/customTextField';
import PropTypes from 'prop-types';

import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears
} from "date-fns";
import { Box } from '@mui/system';
import { DateRange } from '@mui/icons-material';
import moment from 'moment';
import TodayIcon from '@mui/icons-material/Today';

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: "Day",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Week",
            range: () => ({
                startDate: defineds.startOfLastSevenDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Month",
            range: () => ({
                startDate: defineds.startOfLastThirtyDay,
                endDate: defineds.endOfToday
            })
        }
    ];

    return customDateObjects;
};

function CustomDateRangePicker(props) {

    const sideBar = sideBarOptions();

    const [anchorEl, setAnchorEl] = useState();

    const [isOpen, setIsOpen] = useState(false);

    const [staticRanges] = useState([
        ...createStaticRanges(sideBar)
    ])

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })

    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection)
    }

    const handleClick = (event) => {
        if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false)
        } else {
            setAnchorEl(event.currentTarget);
            setIsOpen(true)
        }
    }

    const handleClose = (event) => {
        setAnchorEl(null);
        setIsOpen(false)
    }

    const handleSave = () => {
        props.setSelectedRange({
            startDate: moment(selectionRange.startDate),
            endDate: moment(selectionRange.endDate).endOf("day")
        });
        setIsOpen(false);
    }

    const closeCalendar = () => {
        setIsOpen(false);
        setSelectionRange({
            startDate: new Date(props?.selectedRange?.startDate),
            endDate: new Date(props?.selectedRange?.endDate),
            key: 'selection'
        })
    }

    useEffect(() => {
        setSelectionRange({
            ...selectionRange,
            startDate: new Date(props.selectedRange.startDate),
            endDate: new Date(props.selectedRange.endDate)
        })
    }, [props])

    return (
        <Box>
            <CustomTextField
                size="small"
                placeholder="Date Range"
                onClick={handleClick}
                sx={{ width: "100%", minWidth: "270px" }}
                value={moment(props.selectedRange.startDate).format("YYYY-MM-DD") + " to " + moment(props.selectedRange.endDate).format("YYYY-MM-DD")}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle date range"
                                onClick={handleClick}
                                onMouseDown={handleClick}
                                edge="end"
                                sx={{ color: "#034EA2" }}
                            >
                                <TodayIcon sx={{color : '#011731'}} />
                            </IconButton>
                        </InputAdornment>
                    )
                }} />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    staticRanges={staticRanges}
                    inputRanges={[]}
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                    rangeColors={["#EBFAFF"]}
                    weekStartsOn={1}
                    focusedRange={[0, 1]}
                />
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className="dashboard-button-white"
                            onClick={(event) => closeCalendar()}
                        >
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className="dashboard-button-blue"
                            onClick={handleSave}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Popover>
        </Box>
    )
}

CustomDateRangePicker.propTypes = {
    selectedRange: PropTypes.object.isRequired,
    setSelectedRange: PropTypes.func.isRequired,
};

export default CustomDateRangePicker;