import { ArrowBack, CheckCircleOutline, HighlightOff, PendingOutlined } from "@mui/icons-material";
import { Button, Divider, Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useState } from "react";
import "../../styles.scss";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { getFormattedAmount, numberWithCommas } from "../../utils/amountFormatter";
import TicketLog from "./ticket-log/ticket-log";

function HeaderInfo(props) {

    const [openTicket, setOpenTicket] = useState(false);

    const handleOpen = () => {
        setOpenTicket(true);
    }

    return (
        <Grid container alignItems="center" sx={{ zIndex: 1000, p: 3, background: "#EBFAFF", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", top: 0, position: "sticky", boxShadow: "0px 3px 6px #00000029" }} width="100%">
            <Grid item sx={{ flexGrow: 0 }}>
                <Button onClick={props.handleClose} variant="outlined" startIcon={<ArrowBack />} sx={{ color: "#0098D8", fontWeight: "bold", border: "1px solid #0098D8", textTransform: "none", height: "50px", width: "140px" }}>
                    Back
                </Button>
            </Grid>
            <Grid item sx={{ flexGrow: 1, ml: 2 }}>
                <Grid container direction="column">
                    <Typography fontSize={20} fontWeight="bold" sx={{ lineHeight: 1.2, color: "#034EA2" }}>
                        Transaction Details
                    </Typography>
                    <Typography fontSize={14} sx={{ color: "#9898A2" }}>
                        {props.displayName}
                    </Typography>
                </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid flexWrap="nowrap" item sx={{ flexGrow: 0, height: "100%" }}>
                <Grid container sx={{ ml: 2, alignItems: "center", width: "fit-content" }}>
                    {props.status === "Successful" || props.status === "Paid" || props.status === "Complete" ?
                        <CheckCircleOutline sx={{ fontSize: "50px", color: (props.status === "Failed" ||  props.status === "Expired" ||  props.status === "Declined" || props.status === "3DS Failed" ? "#DF372D" : props.status === "Successful" || props.status === "Complete" || props.status === "Paid" || props.status === "3DS Authorised" ? "#08F9BA" : "#00C1FF") }} /> : props.status === "Failed" ||  props.status === "Expired" || props.status === "3DS Failed" ?

                        <HighlightOff sx={{ fontSize: "50px", color: (props.status === "Failed" ||  props.status === "Expired" ||  props.status === "Declined" || props.status === "3DS Failed" ? "#DF372D" : props.status === "Successful" || props.status === "Complete" || props.status === "Paid" || props.status === "3DS Authorised" ? "#08F9BA" : "#00C1FF") }} /> :

                        <PendingOutlined sx={{ fontSize: "50px", color: (props.status === "Failed" ||  props.status === "Expired" ||  props.status === "Declined" || props.status === "3DS Failed" ? "#DF372D" : props.status === "Successful" || props.status === "Complete" || props.status === "Paid" || props.status === "3DS Authorised" ? "#08F9BA" : "#00C1FF") }} />
                    }
                    <Grid container direction="column" sx={{ ml: 1, mr: 2, width: "fit-content" }}>
                        <Typography fontSize={22} fontWeight="bold" sx={{ lineHeight: 1.2, width: "fit-content", color: (props.status === "Failed" || props.status === "3DS Failed" ? "#404047" : props.status === "Successful" || props.status === "3DS Authorised" ? "#404047" : "#404047") }}>{props.status}</Typography>
                        <Typography fontSize={14} color="#404047" sx={{ width: "fit-content" }}>{props.symbol}{numberWithCommas(getFormattedAmount(props.amount))}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Button startIcon={<ConfirmationNumberOutlinedIcon />} variant="contained" sx={{ color: "white", width: "170px", height: "44px", fontSize: "12px", textAlign: "left" }} style={{ textTransform: 'none' }} onClick={handleOpen}>
                    Log Ticket
                </Button>
                <TicketLog isOpen={openTicket} setOpenTicket={setOpenTicket} transactionId={props.transactionId} profileId={props.profileId} merchantId={props.merchantId} displayName={props.displayName} transactionStatus={props.transactionStatus} createdAt={props.createdAt} merchantRef={props.merchantRef} />
            </Grid>
        </Grid>
    )

}
HeaderInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    transactionId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    transactionStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    merchantRef: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default HeaderInfo;